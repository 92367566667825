import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Box, Typography, useMediaQuery } from "@mui/material";
import { GOALS, MOBILE } from "utils/constants";
import classes from "./styles";
import { CheckBox, CheckBoxOutlineBlank, RadioButtonChecked, RadioButtonUnchecked, RadioOutlined } from "@mui/icons-material";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { updateClientData } from "api/clients";
import { toast } from "react-toastify";
import DialogTransition from "../DialogTransition";
import DialogAppBar from "../DialogAppBar";


interface Props{
    open: boolean,
    onClose: ()=>void
}

export default function DialogGoal({open, onClose}:Props){

    const client = useSelector((state:RootState)=> state.clients.profile)
    const [goal, setGoal] = useState<string>(null)
    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.clients.requests.updateClientProfile === "pending")
    const mobile = useMediaQuery(MOBILE)

    useEffect(()=>{
        if(open) setGoal(client.goal ? client.goal : null)
    },[open])




    const onSubmit = () => {
        dispatch(updateClientData({clientId: client._id, dataToUpdate: {goal, "goalDefinedBy" : "coach"}})).unwrap().then((res)=>{
            if(res.client){
                toast.success('Objectif mis à jour')
                onClose()
            }
        })
    }

    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth={"xs"}
            TransitionComponent={DialogTransition}
            fullScreen={mobile}
            disableScrollLock={!mobile}
        >
            <DialogAppBar 
                title={"Définir un objectif"}
                onClose={onClose}
            />
           
            <DialogContent>
                {GOALS.map((elem)=> {
                    
                    const checked = goal === elem.en

                    return(
                        <Box
                            sx={classes.goal}
                            key={elem.en}
                            onClick={()=>setGoal(elem.en)}
                        >
                            {!checked && <RadioButtonUnchecked
                                sx={{marginRight: 1, marginLeft: 1}}
                            />}

                            {checked && (
                                 <RadioButtonChecked
                                    sx={{marginRight: 1, marginLeft: 1}}
                                />
                            )}
                            <Typography>
                                {elem.fr}
                            </Typography>
                            
                        </Box>
                    )
                })}
            </DialogContent>
            <DialogActions>
                {!mobile && (
                    <Button
                        onClick={onClose}
                        disabled={pending}
                    >
                        Fermer
                    </Button>
                )}
                <LoadingButton
                    onClick={onSubmit}
                    loading={pending}
                >
                    Valider
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}