import PropagateLoader from "react-spinners/PropagateLoader";
import { Dialog, DialogContent,Box, Typography, useMediaQuery, DialogTitle, DialogActions, Button } from "@mui/material";
import {primaryColor} from "utils/theme"
import classes from "./styles";
import { MOBILE } from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "app/store";


interface LoaderProps {
    open: boolean,
    onClose: ()=>void
}


export default function DialogMetabolism({open, onClose}:LoaderProps){

    const client = useSelector((state:RootState) => state.clients.profile)
    const mobile = useMediaQuery(MOBILE)

    return(
        <Dialog 
            open={open}
            disableScrollLock
            maxWidth={"sm"}
            
        >
            <DialogTitle>
                Métabolisme de base
            </DialogTitle>
            <DialogContent>
        
                    <Typography sx={{textAlign: "center"}} >
                        L'équation de <strong>Harris-Benedict</strong> est une formule mathématique qui utilise <strong>la taille, le poids, l'âge et le sexe</strong> de ton client pour calculer son métabolisme de base. Cependant, elle ne prend pas en compte la masse maigre et le rapport muscle/graisse de ton client, et peut donc s'avérer inexacte pour certaines personnes. 
                    </Typography>
                   
           
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}