import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import calcSets from "function/calcSets";
import calcSetsDone from "function/calcSetsDone";
import calcTonnage from "function/calcTonnage";
import Workout from "interfaces/Workout";
import React, { useEffect, useState } from "react";
import classes from "./styles";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { getWorkout } from "api/workouts";
import Scrollbars from "react-custom-scrollbars-2";
import { DESKTOP, MOBILE, TABLET } from "utils/constants";
import { SwapHoriz } from "@mui/icons-material";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";

interface Props{
    workout: Workout,
    open: boolean,
    onClose: ()=> void
}



export default function DialogWorkoutResults({workout,open,onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [workoutResults, setWorkoutResults] = useState<Workout>(null)
    const pending = useSelector((state:RootState)=> state.workouts.requests.get === "pending")
    const tablet = useMediaQuery(TABLET)
    const mobile = useMediaQuery(MOBILE)
    const desktop = useMediaQuery(DESKTOP)


    useEffect(()=>{
        if(open && !workoutResults){
            dispatch(getWorkout(workout._id)).unwrap().then((res)=> {
                if(res.workout){
                    setWorkoutResults(res.workout)
                }
            })
        } else if(!open){
            setTimeout(()=>{
                setWorkoutResults(null)
            },500)
            
        }
    },[workout,open])



    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"lg"}
            fullScreen={mobile? true:false}
            TransitionComponent={DialogTransition}
            disableScrollLock={!mobile}

        >
            <DialogAppBar
                title={pending ? "" : workoutResults?.name}
                onClose={onClose}
            />
            <DialogContent sx={classes.content}>
                <Grid container spacing={MOBILE? 0 : 3}>
                    <Grid item xs={12} lg={4}>
                
                        <Box sx={classes.resume}>
                                    <Box sx={classes.row}>
                                
                                <Typography>
                                    Date
                                </Typography>
                                
                                <Typography sx={classes.value}>
                                {(!pending && workoutResults) && format(new Date(workoutResults?.performed.date), "dd MMM yyyy à HH:MM", {locale: fr})}
                                </Typography>
                            </Box>
                            <Box sx={classes.row}>
                      
                                <Typography>
                                    Durée
                                </Typography>
                               
                                <Typography sx={classes.value}>
                                    {workoutResults?.performed.duration}
                                </Typography>
                            </Box>

                            <Box sx={classes.row}>
                                <Typography>
                                    Tonnage
                                </Typography>
                                <Typography sx={classes.value}>
                                    {calcTonnage(workout)} kg
                                </Typography>
                            </Box>

                            <Box sx={classes.row}>
                                <Typography>
                                    Séries
                                </Typography>
                                <Typography sx={classes.value}>
                                    {calcSetsDone(workout)}
                                </Typography>
                            </Box>

                            <Box sx={classes.row}>
                                <Typography>
                                    Complétion
                                </Typography>
                                <Typography sx={classes.value}>
                                    {Math.round(calcSetsDone(workout)/calcSets(workout) * 100)}%
                                </Typography>
                            </Box>

                            <Box sx={classes.row}>
                                <Typography>
                                    Difficulté
                                </Typography>
                                <Typography sx={classes.value}>
                                    {workoutResults?.performed.rpe} / 10
                                </Typography>
                            </Box>
                        </Box>

                        {/****************** 
                         * NOTE DU CLIENT 
                         * ************** */}
                        {(Boolean(workoutResults?.performed?.note) && workoutResults?.performed.note !== "") && (
                            <Box 
                                sx={classes.note}>
                                <Typography sx={classes.clientNoteTitle}>
                                    Note du client
                                </Typography>
                                <Typography sx={classes.clientNote}>
                                    {workoutResults.performed.note}
                                </Typography>
                            </Box>
                        )}
                    
                    
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Box 
                            component={(desktop) ? Scrollbars : "div"} 
                            disabled={true} 
                            style={{
                                height:"calc(100vh - 90px - 64px - 53px)",
                                paddingRight: 3
                            }}
                        >


                            {workoutResults?.blocks.map((block,blockIndex)=> {
                                return(
                                    <Box 
                                        key={blockIndex} 
                                        sx={classes.block}
                                    >
                                        <Typography 
                                            style={classes.blockName}>
                                                #{blockIndex +1} - {block.name}
                                        </Typography>
                                        <Typography 
                                            style={classes.blockTrack}>
                                                {block.track ? "Avec suivi des performances" : "Aucun suivi des performances"}
                                        </Typography>

                              
                                        <Box>
                                            {block.content.map((blockContent, blockContentIndex)=>{
                                                return(

                                                    <React.Fragment  key={blockContentIndex} >
                                                        {blockContent.exercices.length>1 && (
                                                            <Box sx={classes.supersetIndicator}>
                                                                Superset
                                                            </Box>
                                                        )}
                                                    
                                                    <Box 
                                                        sx={[
                                                            classes.groupContent, 
                                                            blockContent.exercices.length>1 && classes.superset
                                                        ]}
                                                    >
                                                        {blockContent.exercices.map((exercice, exerciceIndex)=> {

                                                            const setsLength = exercice.sets.length
                                                            const setsDone = exercice.sets.filter((set)=> set.performances.performed).length
                                                            

                                                            return(
                                                                <Box 
                                                                    key={exerciceIndex} 
                                                                    sx={classes.exercice}
                                                                >
                                                                    <Box 
                                                                        sx={classes.header}
                                                                    >
                                                                        <Box 
                                                                            sx={classes.exerciceHeaderInfos}
                                                                        >
                                                                            {/*** IMAGE *** */}

                                                                            {!mobile && (
                                                                                <Box sx={classes.imageContainer}>
                                                                                    <Box component={"img"} src={exercice.exercice.image?.url} sx={classes.exerciceImage} />
                                                                                    
                                                                                </Box>
                                                                            )}

                                                                            {(exercice.replaced && !mobile) && (
                                                                                <Tooltip
                                                                                    title="Exercice remplacé par le client">
                                                                                    <Box
                                                                                        sx={classes.replacedIndicator}>
                                                                                            <SwapHoriz
                                                                                                sx={{color:"white"}}/>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            )}

                                                                            {/*** EXERCICE NAME *** */}
                                                                            <Box sx={{display:"flex", flexDirection: "column"}}>
                                                                                <Typography sx={classes.exerciceName}>
                                                                                    {exercice.replaced? exercice.replaced.name.fr : exercice.exercice.name.fr }
                                                                                </Typography>
                                                                                {block.track && (
                                                                                    <Typography sx={classes.exerciceSetsNumber}>
                                                                                        {setsDone}/{setsLength} séries éffectuées
                                                                                    </Typography>
                                                                                )}
                                                                            </Box>
                                                                        </Box>

                                                                        {/*** COMPLETION *** */}
                                                                        {block.track && (
                                                                            <Box sx={classes.exerciceHeaderCompletion}>
                                                                                <Typography sx={classes.exerciceCompletion}>
                                                                                    {Math.round(setsDone/setsLength * 100)}%
                                                                                </Typography>
                                                                            </Box>
                                                                        )}
                                                                            
                                                                        
                                                                    </Box>
                                                                    
                                                                    {setsDone > 0 && (
                                                                        <Box sx={classes.exerciceContent}>

                                                                            <Box sx={classes.setHeader}>
                                                                                <Typography sx={[classes.setHeaderLabel, classes.setNumber]}>
                                                                                    {mobile ? "#" : "Série"}
                                                                                </Typography>
                                                                                <Typography sx={classes.setHeaderLabel}>
                                                                                    {mobile ? "Reps" : "Répétitions"}
                                                                                </Typography>
                                                                                <Typography sx={classes.setHeaderLabel}>
                                                                                    Poids {!mobile ?"(kg)" :""}
                                                                                </Typography>
                                                                            </Box>
                                                                            {exercice.sets.filter((set)=> set.performances.performed).map((set, setIndex)=>{

                                                                       
                                                                                return(
                                                                                    <React.Fragment key={setIndex}>
                                                                                        <Box sx={[classes.set, Boolean(set.performances.dropset) && classes.withoutBorder]}>
                                                                                            <Typography sx={[classes.perf,classes.setNumber]}>
                                                                                                {setIndex + 1}
                                                                                            </Typography>
                                                                                            <Typography sx={[classes.perf, classes.perfValue]}>
                                                                                                {set.performances.reps}
                                                                                            </Typography>
                                                                                            <Typography sx={[classes.perf, classes.perfValue]}>
                                                                                                {set.performances.weight}
                                                                                            </Typography>
                                                                                        </Box>

                                                                                        {Boolean(set.performances.dropset) && set.performances.dropset.map((dropset, dropsetIndex) => {

                                                                                            return(
                                                                                                <Box 
                                                                                                    sx={[classes.set, dropsetIndex !== set.performances.dropset.length -1 && classes.withoutBorder]} 
                                                                                                    key={dropsetIndex}
                                                                                                >
                                                                                                    <Typography sx={[classes.perf,classes.setNumber, classes.drop]}>
                                                                                                        Drop
                                                                                                    </Typography>
                                                                                                    <Typography sx={[classes.perf, classes.perfValue]}>
                                                                                                        {dropset.reps}
                                                                                                    </Typography>
                                                                                                    <Typography sx={[classes.perf, classes.perfValue]}>
                                                                                                        {dropset.weight}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            )
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    )}
                                                                    
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                    
                                                    {blockContent.note && (
                                                        <Box
                                                            sx={[classes.clientNote, classes.clientNoteContent]}>
                                                                Note du client : {blockContent.note}

                                                            <Box 
                                                                sx={classes.noteIndicator} 
                                                            />
                                                        </Box>
                                                    )}
                                                    

                                                    </React.Fragment>
                                                )
                                            })}
                                        </Box>
                                      
                                    </Box>
                                )
                            })}
                        </Box>

                    </Grid>

                </Grid>
            </DialogContent>

            {!mobile && (
            <DialogActions>
                <Button onClick={onClose}>
                    Fermer
                </Button>
            </DialogActions>
            )}
        </Dialog>
    )
}