import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    row : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1,
    },

    value: {
        fontWeight: "600"
    },

    question :{
        fontWeight: 500,
        marginBottom: 1.5
    }
}


export default classes