import { AppBar, Box, IconButton, useMediaQuery } from "@mui/material";
import { ArrowBackIos, SvgIconComponent } from "@mui/icons-material";
import { BORDER_COLOR, MOBILE } from "utils/constants";
import PageTitle from "components/atoms/Typography/PageTitle";
import SecondaryButton from "components/atoms/Buttons/Secondary";

interface Props{
    onClose: ()=>void,
    title: string,
    secondaryButton?:{
        onClick:()=>void,
        disabled:boolean,
        Icon: SvgIconComponent,
        label: string
    }
}

export default function DialogAppBar({onClose,title, secondaryButton}:Props){

    const mobile = useMediaQuery(MOBILE)

    return(
        <AppBar 
            sx={{ position: 'relative', backgroundColor: "white", height: 75, borderBottom: `solid 1px ${BORDER_COLOR}`, display:"flex", alignItems:"center", flexDirection:"row", justifyContent:"space-between", paddingLeft: 3, paddingRight: 3 }} 
            elevation={0}
        >
            <Box
                sx={{display:"flex", flexDirection: "row", alignItems:"center"}}
            >
                {mobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        sx={{width: 45, height:45, display:"flex", alignItems:"center", justifyContent:"center"}}
                    >
                        <ArrowBackIos 
                            sx={{color: "black", fontSize: 16, marginRight:1.5}}
                        />
                    </IconButton>
                )}

                <PageTitle>
                    {title}
                </PageTitle>
            </Box>
            <Box>
                {(secondaryButton && !mobile) && (
                    <SecondaryButton 
                        onClick={secondaryButton.onClick}
                        startIcon={<secondaryButton.Icon />}
                    >
                        {secondaryButton.label}
                    </SecondaryButton>
                )}

                {(secondaryButton && mobile) && (
                    <IconButton 
                        onClick={secondaryButton.onClick}
                     
                    >
                       <secondaryButton.Icon 
                        sx={{color: "black"}}
                       />
                    </IconButton>
                )}

            </Box>
                
         
        </AppBar>
    )
}