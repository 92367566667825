import { theme } from "utils/theme"

const classes = {
    container:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
    },

    title:{
        fontWeight: 600,
        fontSize: {xs:theme.typography.pxToRem(18), sm: theme.typography.pxToRem(18)},
        color: "rgba(0,0,0,0.85)",
    },
    

    noMargin:{
        marginBottom: 0
    },

    buttonBack:{
        backgroundColor: "whitesmoke",
        borderRadius: "100px !important",
        marginRight: 2
    }
}


export default classes