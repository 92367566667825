import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import Exercice from "interfaces/Exercice"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import {DebouncedFunc} from "lodash"
import Recipe from "interfaces/Recipe"
import { manualSaveRecipe } from "api/recipes"
import { toast } from "react-toastify"

interface Props{
    open: boolean,
    onClose: ()=> void,
    recipe:Recipe,
    debounce: DebouncedFunc<(formData: Exercice) => void>
}

export default function DialogSaveRecipe({open,onClose, recipe, debounce}:Props){

    const pendingManualSave = useSelector((state:RootState)=> state.recipes.requests.manualSave === "pending")
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useHistory()

    const onSave = () => {
        debounce.cancel()
        dispatch(manualSaveRecipe(recipe)).unwrap().then(()=>{
            if(recipe.draft){
                navigate.push(`/recipes`)
                toast.success('Recette publiée')
            }
        })
    }

    return(
        <Dialog 
            disableScrollLock={true}
            open={open}>
            <DialogTitle>
                Publier la recette
            </DialogTitle>
            <DialogContent>
                Souhaites-tu publier la recette <strong>{recipe.name.fr}</strong> et la rendre disponible à tes clients ? Cette dernière sera consultable sur l'application mobile Traener, et tu pourras également l'intégrer dans tes plans alimentaires.
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose}>
                    Annuler
                </Button>
                <LoadingButton 
                    disabled={pendingManualSave}
                    loading={pendingManualSave}
                    onClick={onSave}
                >
                    Confirmer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}