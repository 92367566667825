import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles"
import { useState } from "react";
import { addDays, differenceInDays, format, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";

import Program from "interfaces/Program";
import { checkProgramPlanningDate, createNewProgram, importProgram } from "api/programs";
import { toast } from "react-toastify";
import Workout from "interfaces/Workout";
import { getWorkouts, pasteMultiplesWorkouts } from "api/workouts";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { createSelector } from "@reduxjs/toolkit";
import removePerformedSets from "function/removePerformedSets";
import TemplatePrograms from "../TemplatesPrograms";
import { BORDER_COLOR, MOBILE, TABLET } from "utils/constants";
import { Close, Download } from "@mui/icons-material";
import MealPlan from "interfaces/MealPlan";
import TemplateMealPlans from "../TemplateMealPlans";
import { getMealPlanTemplate } from "api/mealPlans";
import { onImportMealPlan } from "features/mealPlanSlice";
import DialogTransition from "components/molecules/DialogTransition";
import DialogAppBar from "components/molecules/DialogAppBar";


//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,

}





export default function DialogImportMealPlan({ open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const client:string = useSelector((state:RootState) => state.planning.user?._id) // Planning user le cas échéant
    const mealPlans = useSelector((state:RootState)=> state.mealPlans.templates.filtered)
    const [mealPlanSelected, setMealPlanSelected] = useState<MealPlan>(mealPlans[0])
    //const pending = useSelector((state:RootState) => state.mealPlans.requests.import === "pending")
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const desktop = !mobile
    const [pending, setPending] = useState<boolean>(false)

    const onSelectMealPlan = (mealPlan:MealPlan)=>{
        setMealPlanSelected(mealPlan)
    }

    /**************************************************** 
     * RECUPERATION DES DONNEES DU programme alimentaire 
     * **************************************************/

    const onImport = () => {
        setPending(true)
        

        dispatch(getMealPlanTemplate(mealPlanSelected._id)).unwrap().then((res:any)=>{
            if(res.mealPlan){
                const mealPlan:MealPlan = {
                    ...res.mealPlan, 
                    _id: undefined, 
                    createdBy: undefined, 
                    name: undefined, 
                    template: false,
                    status: "disabled"
                }
                dispatch(onImportMealPlan({mealPlan}))
                toast.success('Plan alimentaire importé')
                onClose()
                setPending(false)
            }else{
                toast.error("Erreur serveur - Impossible d'importer ce plan alimentaire")
                setPending(false)

            }
        })

        
    }

    return(
    <Dialog 
        open={open} 
        fullWidth 
        maxWidth={"md"}
        fullScreen={mobile || tablet}
        disableScrollLock={!mobile}
        TransitionComponent={DialogTransition}
    >

        {/*}
        <DialogTitle>
            {mobile && (
                <IconButton sx={{marginRight: 1.5}} onClick={onClose}>
                    <Close sx={{color: "rgba(0,0,0,0.85)"}}/>
                </IconButton>
            )}

            <span>Importer un plan alimentaire</span>
            
        </DialogTitle>*/}

        <DialogAppBar
            title={"Importer un plan alimentaire"}
            onClose={onClose}
        />

        <DialogContent 
            sx={classes.content}
        >

            {/*********************************** 
             * LISTE DES TEMPLATES DE PROGRAMME 
             * **********************************/}

            <Box sx={classes.list}>
                <TemplateMealPlans 
                    fromImport={true}
                    onSelectMealPlan={onSelectMealPlan}
                    mealPlanSelectedForImport={mealPlanSelected}
                />
            </Box>
        </DialogContent>
            <DialogActions 
                sx={{borderTop: desktop ? "solid 1px #e1e1e1" : "none"}}
            >
                {desktop && (
                    <Button 
                        onClick={onClose} 
                        disabled={pending}>
                            Fermer
                    </Button>
                )}
                <LoadingButton 
                    variant="contained" 
                    onClick={onImport}
                    disabled={pending}
                    loading={pending}
                    startIcon={<Download />}
                >
                    Importer
                </LoadingButton>
            </DialogActions>
        
    </Dialog>
    )
}