import { Box, Button, Grid, Popover, Typography } from "@mui/material"
import classes from "./style"
import { CLIENTS_FILTERS, CLIENTS_FILTERS_TRADS} from "utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { addClientFilter, removeClientFilter } from "features/clientsSlice"

interface Props{
    open: boolean,
    onClose: ()=>void,
    anchorEl: HTMLButtonElement
}


export default function ClientsFilters({onClose, open, anchorEl}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const filters = useSelector((state:RootState)=> state.clients.filters)


    const onClearFilter = () => {
        //dispatch(clearExerciceFilter())
    }


    /************************* CHECK FILTER *********************** */

    const handleFilter = (newFilter:string) => ()=>{

        const exist = filters.find((elem)=> elem === newFilter)

        if(!exist){ 
            dispatch(addClientFilter({newFilter}))
        } else {
            dispatch(removeClientFilter({newFilter}))
        }
    }


   



    return(
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            disableScrollLock={true}
            sx={classes.popover}
        >
        <Box 
            sx={[classes.filterMenu]}
        >
            <Box 
                sx={{position: "relative"}}
            >

                {/**** FILTER HEADER **** */}
                <Box sx={classes.filterHeader}>
                    <Typography sx={{fontWeight: 600, fontSize: 15}}>
                        Filtrer
                    </Typography>
                    <Button 
                        sx={{textTransform: "none"}} 
                        onClick={onClearFilter}
                    >
                        Décocher
                    </Button>
                </Box>

                <Grid sx={{display:"flex", flexDirection: "row"}}>

                    <Grid 
                        item 
                        xs={12}
                        sx={{width: 200}}
                    >

                        {/**** LISTE DES GROUPES **** */}
                        {CLIENTS_FILTERS.map((filter)=> {

                            const checked = filters.find((elem)=> elem === filter)

                            return(
                                <Box 
                                    key={filter} 
                                    sx={classes.filter} 
                                    onClick={handleFilter(filter)}
                                >

                                    {checked && <CheckBox sx={[classes.checkbox, classes.checboxChecked]}/>}
                                    {!checked && <CheckBoxOutlineBlank sx={classes.checkbox}/>}
                                    
                                    <Typography sx={classes.group}>
                                        {CLIENTS_FILTERS_TRADS[filter].fr}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Grid>

                    

                </Grid>
            

            </Box>
        </Box>
        </Popover>
    )
}