// ROUTER
import { Link, useHistory } from "react-router-dom"
// STORE
import { AppDispatch } from "app/store"
// MUI
import {Box, TextField, Typography, Stack, InputAdornment, IconButton, Card, useMediaQuery} from "@mui/material"
// REDUX
import { useDispatch } from "react-redux"
// FEATURES
import classes from "./styles"
import { useEffect, useState } from "react"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import { DoubleArrow, Visibility, VisibilityOff } from "@mui/icons-material";
import { signin } from "api/user";
import { MOBILE } from "utils/constants"


/*********** MAIN COMPONENT ************** */

export default function Signin(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const [submit, setSubmit] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)

    /******* SOUMISSION DU FORMULAIRE ********* */
    const onSubmit = async () => {
        setSubmit(true)
        await dispatch(signin({email,password}))
        setSubmit(false)
    }


    /******* AFFICHAGE DU PASSWORD ********* */
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }


    const goToSignup = () => {
        history.push('/register')
    }

    useEffect(() => {
        const onKeyDown = (e:any) => {
         if(e.key === 'Enter'){
            onSubmit()
         }
        }
       
        window.addEventListener('keydown', onKeyDown)
        return () => window.removeEventListener('keydown', onKeyDown)
    },[email, password])


    return(
        <Box sx={[classes.signinLayout]} className="layout">
            {mobile && (
                <>
                    <Box sx={classes.logoContainer}>
                        <Box 
                            component="img" 
                            src="/assets/logo.svg" 
                            sx={{ width: "auto", height: mobile? 26 : 24}} 
                        />
                    </Box>
                    
                    <Typography sx={[classes.subtitle, {marginBottom: 0}]}>
                        Prêt à booster ton coaching !
                    </Typography>
                </>
            )}

            {!mobile && (
                <>
                    <Typography sx={[classes.title]}>
                        Content de te revoir
                    </Typography>
                    
                    <Typography sx={[classes.subtitle, {marginBottom: 4}]}>
                        Prêt à booster ton coaching !
                    </Typography>

                   
                    
                </>

            )}
            
            
            <Card 
                sx={classes.card}
                elevation={mobile? 0 : 1}
            >

                
            {!mobile && (
                <>
                   
                    <Box sx={classes.logoContainer}>
                        <Box 
                            component="img" 
                            src="/assets/logo.svg" 
                            sx={{ width: "auto", height: mobile? 26 : 24}} 
                        />
                    </Box>
                    
                </>

            )}
            
                <Stack sx={{width: "100%"}}>

                    <Typography sx={classes.textfieldLabel}>
                        Email
                    </Typography>
                    
                    <TextField 
                        type="email"
                        fullWidth={true} 
                        sx={{marginBottom: 3}}
                        autoComplete="current-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Adresse email"
                        
                    />

                    <Box sx={{display:"flex", flexDirection: "row", justifyContent:"space-between", width: "100%"}}>

                        <Typography sx={classes.textfieldLabel}>Mot de passe</Typography>
                            <Typography 
                                component={Link} 
                                to="/forgotpassword" 
                                sx={classes.forgotPassword}
                            >
                                Mot de passe oublié ?
                            </Typography>
                        </Box>
                    
                        <TextField 
                            fullWidth={true} 
                            sx={{marginBottom: 1}}
                            type={showPassword ? "text" :"password"}
                            autoComplete="current-password" 
                            value={password}
                            placeholder="Mot de passe"
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment:(
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            
                        />
                        
                </Stack>

                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width: "100%", mt: 3}}>
                    <LoadingButton 
                        fullWidth={true} 
                        variant="contained" 
                        sx={{borderRadius:3, height: 48, minWidth: "100%", fontSize: 18}}
                        disableElevation={true}
                        disabled={submit}
                        onClick={onSubmit}
                    >
                        {"Connexion"}
                    </LoadingButton>
                </Box>
            </Card>


            <Box sx={classes.newAccountContainer}>
                <Typography sx={classes.newAccountTypography}>
                    Tu n'as pas de compte ?
                </Typography>
                <Typography 
                    sx={[classes.newAccountButton]} 
                    onClick={goToSignup}
                >
                    Inscris-toi ici
                </Typography>
            </Box>
        </Box>
           

                
        
    )
}