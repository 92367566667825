import { Box, Typography } from "@mui/material";
import classes from "./styles";


interface Props{
    onClick: ()=> void,
    activated: boolean,
}

export default function SwitchButton({onClick, activated}:Props){
    return(
        <Box
            sx={classes.container}
        >
            <Box
                sx={[
                    classes.button,
                    classes.left,
                    activated && classes.buttonSelected
                ]}
                onClick={onClick}
            >
                <Typography
                    sx={[
                        classes.label,
                        activated && classes.labelSelected

                    ]}
                >
                    Activé
                </Typography>
                
            </Box>
            <Box
                sx={[
                    classes.button,
                    classes.right,
                    !activated && classes.buttonSelectedDesactived
                ]}
                onClick={onClick}
            >
                <Typography
                    sx={[
                        classes.label,
                        !activated && classes.labelSelected
                    ]}
                >
                    Désactivé
                </Typography>
            </Box>
        </Box>
    )
}