import { Box, Card, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import Recipe from "interfaces/Recipe";
import classes from "./styles";
import { useEffect, useRef, useState } from "react";
import calcBoxHeight from "function/calcBoxHeight";
import { useHistory } from "react-router-dom";
import { RECIPE_TAGS } from "utils/constants";
import { Delete, Image, MoreHorizOutlined } from "@mui/icons-material";
import DialogDeleteRecipe from "components/molecules/DialogDeleteRecipe";


interface Props{
    recipe: Recipe
}


export default function RecipeCard({recipe}:Props){

    const navigate = useHistory()
    const [mediaHeight, setMediaHeight] = useState<number>(0)
    const imageRef = useRef<any>()
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [openDeleteRecipe, setOpenDeleteRecipe] = useState<boolean>(false)

    const onOpenMenu = (e:any)=> {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        
    }


    const onCloseMenu = (e:any) => {
        e.stopPropagation()
        setAnchorEl(null)
        
    } 


    const goToRecipeBuilder = () => {
        navigate.push(`/recipes/${recipe._id}`)

    }

     /******************** CALCUL DE LA HAUTEUR DES CADRES IMAGE ET VIDEO ***************** */

     useEffect(() => {
        if (imageRef.current) {
            const boundingRect = imageRef.current.getBoundingClientRect()
            const videoWidth = boundingRect.width;
            setMediaHeight(calcBoxHeight(1080, 1920, videoWidth))
        }
    }, [imageRef.current]);


    const onDeleteRecipe = () => {
        setAnchorEl(null)
        setOpenDeleteRecipe(true)
    }


    return(
        <Grid 
            item xs={12} 
            sm={6} 
            md={4} 
            lg={4} 
            xl={3} 
            sx={{position:"relative"}}
        >
            <Card
                sx={classes.card}
                onClick={()=>goToRecipeBuilder()}
                elevation={0}
                
            >
                <Box
                    sx={[
                        classes.imageContainer, 
                        {
                            height: mediaHeight
                        }]}
                    ref={imageRef}>


                        {recipe.cover?.url && (
                            <Box
                                component="img"
                                src={recipe.cover?.url}
                                sx={[
                                    classes.cover,
                                    {
                                        width: "100%",
                                        height: mediaHeight
                                    }
                                ]}
                            />
                        )}

                        {!recipe.cover?.url && (
                            <Image 
                                sx={{color: "rgb(114 114 114 / 87%)", fontSize: "2rem"}}
                            />
                        )}
                </Box>

                <Box 
                    sx={classes.nameContainer}
                >

                    <Typography
                        sx={classes.recipeName}
                    >
                        {recipe.name.fr}
                    </Typography>

                    {/*****************
                     *  MORE BUTTON 
                     * ************* */}
                     
                    <Box 
                        sx={classes.moreButton}>
                        <IconButton 
                            sx={{borderRadius: "100px !important", width: 48, height: 48}} 
                            onClick={onOpenMenu}
                        >
                            <MoreHorizOutlined 
                                sx={{color: "black"}} 
                            />
                        </IconButton>
                    </Box>
                   
                    
                </Box>
                
                <Box
                    sx={classes.tags}
                >
                        {recipe.tags.map((tag,tagIndex)=>{
                            return(
    
                                <Box 
                                    key={tagIndex}
                                    sx={[
                                        classes.tag,
                                        
                                    ]}>
                                        {RECIPE_TAGS.find((elem)=> elem.id === tag).label.fr}
                                </Box>
                               
                            )
                        })}

                        {recipe.tags.length ===0 && (
                           
                            <Box sx={classes.tag}>
                                Aucun tag
                            </Box>
                       
                        )}
                </Box>
            </Card>

            {/****** MENU ****** */}
            <Menu 
                    disableScrollLock={true} 
                    open={Boolean(anchorEl)}
                    onClose={onCloseMenu}
                    anchorEl={anchorEl}
                    
                >
              
                    <MenuItem 
                        onClick={onDeleteRecipe}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>
                            Supprimer cette recette
                        </ListItemText>
                    </MenuItem>

                    

                    

                </Menu>

            <DialogDeleteRecipe
                open={openDeleteRecipe}
                onClose={()=> setOpenDeleteRecipe(false)}
                recipe={recipe}
            />
        </Grid>
    )
}