import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { deleteRecipeImage, manualSaveRecipe } from "api/recipes";


interface Props{
    open: boolean,
    onClose: ()=> void,
    onSelectThumbnail: (thumbnail:{url:string})=> void

}

export default function DialogDeleteRecipeImage({open, onClose,onSelectThumbnail}:Props){

    const deletePending:boolean = useSelector((state:RootState) => state.recipes.requests.deleteImage === "pending")
    const recipeId = useSelector((state:RootState) => state.recipes.builder._id)
    const recipe = useSelector((state:RootState) => state.recipes.builder)
    const dispatch = useDispatch<AppDispatch>()
    const isDraft: boolean = useSelector((state:RootState) => state.recipes.builder?.draft, shallowEqual)

    const onDeleteImage = () => {
        dispatch(deleteRecipeImage({recipeId})).unwrap().then((res:any)=>{
            if(res.cover){
                onSelectThumbnail(null)
                onClose()
                if(!isDraft){
                    dispatch(manualSaveRecipe({...recipe, cover: null}))
                }
            }
            
        })
    }

    return(
        <Dialog 
            open={open}
            disableScrollLock
        >
            <DialogTitle>
                Supprimer une image téléchargée
            </DialogTitle>
            <DialogContent>
                Voulez-vous supprimer l'image téléchargée de cette recette ? {!recipe.draft && "La suppression entraînera la sauvegarde des modifications de votre recette."}
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={deletePending}>
                        Annuler
                </Button>
                <LoadingButton 
                    loading={deletePending} 
                    disabled={deletePending} 
                    onClick={onDeleteImage}
                >
                    {deletePending ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}