import {  Search } from "@mui/icons-material";
import {IconButton, InputBase, Card} from "@mui/material"
import React, { useState } from "react";
import { DESKTOP, INPUT_BG_COLOR } from "utils/constants";
import classes from "./styles"

interface FieldProps{
    onChange: (e:any)=> void,
    placeholder: string,
    fullWidth?: boolean,
    borderRadius?: number,
    color?: "white" | "gray"
}


export default function SearchTextfield({onChange, placeholder, fullWidth,color}:FieldProps){

  const [value,setValue] = useState("")


  const onValueChange = (e:any) => {
    setValue(e.target.value)
    onChange(e)
  }


  return (
    <Card sx={[
      classes.card, 
      fullWidth && classes.fullWidth, 
      {
        backgroundColor: color? color : INPUT_BG_COLOR
      }]} 
      elevation={0}>
        <IconButton 
          type="button" 
          sx={{ p: '10px' }} 
          aria-label="search" 
          disabled
        >
            <Search/>
        </IconButton>
        <InputBase
          placeholder={placeholder}
          onChange={onValueChange}
          value={value}
          sx={{ml: 1, flex: 1 }}
        />
      </Card>
       
    )
      
}