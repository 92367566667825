

export const classes= {
    kcal : {
        fontWeight: 500,
        fontSize: 17,

    },

    kcalContainer : {
        display:"flex",
        alignItems:"center",
        marginTop: {xs: 1, sm: 0},
        //backgroundColor: "#f7f7f7",
        borderRadius: 2,
  
    },

}

export default classes