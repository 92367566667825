import { theme } from "utils/theme"

const classes ={


    feedHeader : {
        display:"flex",
        flexDirection: {
            xs: "column",
            sm: "row",
        },
        alignItems: {
            xs: "flex-start",
            sm: "center"
        },
        justifyContent:"space-between",
        marginBottom: {
            xs: 3,
            sm: 2
        }
    },


    sectionTitle: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        marginBottom: {
            xs: 1.5,
            sm: 0
        }
        
    },


    eventContainer : {
        display:"flex",
        justifyContent:"center",
        flexDirection: "column",
        position: "relative",
        flex:1
    },

    eventsTimeline : {
        display:{
            xs: "none",
            sm: "block"
        },
        position:"absolute",
        left: 15,
        top: 0,
        height: "100%",
        borderRight: "dashed 2px #e1e1e1"
    },


    date : {
        marginBottom: 3,
        color: "rgba(0,0,0,0.85)",
        fontWeight: 600,
        fontSize: 18
    },


    blockEvents : {
        marginBottom: 3
    },


   

    
}

export default classes