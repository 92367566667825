import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "utils/axios"
import sleep from 'function/sleep';
import Billing from 'interfaces/Billing';
import User from 'interfaces/User';
import MealPlan from 'interfaces/MealPlan';



export interface UserDataToUpdate{
  firstname?: string,
  lastname?: string,
  newEmail?:string,
  newPassword?:string,
  currentPassword?:string,
  billing?:Billing,
  customerId?:string,
  gender?:string,
  goal?: string,
  goalDefinedBy?: "coach" | "client",
  birthday?:Date,
  mealPlan?: MealPlan,
  coachNap?: number,
  napDefinedBy? : "user" | "coach",
  energyIntake? : {
    kcal: number,
    proteins: number,
    carbs: number,
    lipids: number
}


}


/************** LOGIN via email ************** */
export const signin = createAsyncThunk('userSlice/signin', async (data:{email:string,password:string}) => {

  try{
    const response = await axios.post(`auth/signin`, data)
    const accessToken = response.data.accessToken
    const user = response.data.user
    axios.defaults.headers.common['authorization'] = `Bearer ${accessToken}`
    await sleep(500)
    return {user}

  }catch(err:any){
      if(err.response.status === 404){
        return {
          error: "Serveur en maintenance"
        }
      }
      return {
        error : err.response.data?.message[0]
      }
  } 
})


/************** SIGNUP ************** */
export const signup = createAsyncThunk('userSlice/signup', async (data:{email:string,firstname:string,lastname:string,password:string}) => {
  try{
    const response = await axios.post(`auth/signup`, data)
    const accessToken = response.data.accessToken
    const user = response.data.user
    axios.defaults.headers.common['authorization'] = `Bearer ${accessToken}`
    await sleep(1000)
    return {user}

  }catch(err:any){
      if(err.response.status === 404){
        return {
          error: "Serveur en maintenance"
        }
      }
      return {
        error : err.response.data?.message[0]
      }
  } 
})


/************** RECUPERATION D'UN ACCESS TOKEN ************** */
export const getAccessToken = createAsyncThunk('userSlice/getAccessToken', async () => {
  try{

    const response = await axios.get(`auth/accesstoken`, { withCredentials: true })
    const {user, accessToken} = response.data
    if(accessToken) {
      axios.defaults.headers.common['authorization'] = `Bearer ${accessToken}`
    }
    return {user, accessToken}

  }catch(err:any){
      return {error: "Aucun jeton d'accès"}
  } 
})


/************** LOGOUT ************** */

export const logout = createAsyncThunk('userSlice/logout', async()=>{

  try{
    await axios.post('auth/logout')
    axios.defaults.headers.common['authorization'] = null
    return {disconnected: true}
  }catch(err){
    return {error: "Impossible de se déconnecter pour le moment"}
  }
})


/************** Mot de passe oublié ************** */

export const forgotPassword = createAsyncThunk('userSlice/forgotpassword', async(data:{email:string}) => {
  const {email} = data

  try{
    const result = await axios.post('auth/forgotpassword', {email})
    await sleep(1000)
    return {success: result.data.success}
  }catch(err:any){
    return {message: err.response.data?.message[0] || "Impossible de réinitialiser le mot de passe"}
  }
})


/************** Submit digit (Reset password) ************** */

export const submitDigit = createAsyncThunk('userSlice/submitDigit', async(data:{digit:number, email:string}) => {
  const {digit, email} = data
  try{
    const result = await axios.post('auth/forgotpassword/digit', {digit, email})
    await sleep(1000)
    return {digitIsCorrect: result.data.digitIsCorrect, token: result.data.token}
  }catch(err:any){
    return {message: err.response.data?.message[0] || "Impossible de réinitialiser le mot de passe"}
  }
})


/************** Reset Password ************** */

export const resetPassword = createAsyncThunk('userSlice/resetPassword', async(data:{email:string, token:string, password:string}) => {
const {password, email,token} = data

try{
  const result = await axios.post('auth/resetpassword', {password, email, token})
  await sleep(1000)
  return {success: result.data.updated}
}catch(err:any){
  return {message: err.response.data?.message[0] || "Impossible de réinitialiser le mot de passe"}
}
})
  
  
/********** MISE A JOUR DES DONNEES ********* */

export const updateUserData = createAsyncThunk('userSlice/updateData', async (data:UserDataToUpdate)=>{

  try{
        const result = await axios.put('user/me', data)
        const updatedUser:User = result.data.user

        return{user:updatedUser}
    }catch(err:any){
        return {error: err.response.data?.message[0]}
    }
})



  
  
  /************ SUPPRESSION DE L'AVATAR ********* */
  
  export const deleteAvatar = createAsyncThunk('userSlice/deleteAvatar', async ()=>{

    try{
      await axios.delete(`images/avatar`)
      return {avatarDeleted: true}
    }
    catch(err:any){
      return {error: err.response.data?.message[0]}
    }
  })
  
  /************ UPLOAD D'UN NOUVEL AVATAR ********* */
  
  export const uploadAvatar = createAsyncThunk('userSlice/postAvatar', async(file:Blob)=>{
  
    var formData = new FormData();
    formData.append('file', file)
  
    try{
      // On poste l'image pour téléchargement sur cloudflare
      const result = await axios.post(`images/avatar`, formData)
      const avatar = result.data.avatar
      return {avatar}
    }
    catch(err:any){
      return {error: err.response.data?.message}
    }
  })


  /************ CRER UN NOUVEAU CUSTOMER ID POUR STRIPE ********* */

  export const createCustomerId = createAsyncThunk('userSlice/createCustomerId', async()=>{
  
    try{
      const response = await axios.post(`subscriptions/newCustomer`)
      return {customerId : response.data.customerId} // on retourne le CustomerId nouvellement créé
    }
    catch(err:any){
      return {error: err.response.data?.message[0]}
    }
  })


  /************ SUPPRIMER LE COMPTE ********* */

  export const deleteAccount = createAsyncThunk('userSlice/deleteAccount', async(data:{userId: string, password: string, email: string}) => {

    const {userId, password, email} = data

    try{
      
      const response = await axios.delete(`user/${userId}`, {data:{password, email}})
      await sleep(2000)
      return { success : response.data.success}

    }catch(err:any){
      return {error: err.response.data?.message[0]}
    }
  })


   /************ Création d'un fake user  ********* */


  export const createDemoUser = createAsyncThunk('userSlice/createDemoUser', async() => {
    try{
      const response = await axios.post(`user/demo`)
      const client:User = response.data.user
      return {client}

    }catch(err:any){
      return {error: err.response.data?.message[0]}
    }
  })



