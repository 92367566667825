import { BORDER_COLOR } from "utils/constants";

const classes = {
    stats: {
        backgroundColor: "white",
        width: "100%",
        border: {xs:0,sm:`solid 1px ${BORDER_COLOR}`},
        padding: {
          xs: 0, sm:3
        },
        marginBottom: 0,
        paddingTop: 3
        
    },

    statsTitle: {
        fontWeight: 700
    },

   

    macroContainerTitle:{
      marginTop: 5
    },

     macroContainer: {
        display:"flex",
        flexDirection: "row",
        justifyContent: {xs: "space-between", sm: "flex-start"},
        alignItems:"flex-start",
        marginTop:3,
        position: "relative",
        width: "100%"
     },

     microLabel : {
        minWidth: {
          sm: 220,
          lg: 170,
          xl: 220
        },
        maxWidth: {
          sm: 220,
          lg: 170,
          xl: 220
        },
        fontSize: 14,
        color: "rgba(0,0,0,0.75)"
     },

     micro : {
      display:"flex",
      minWidth: {
        sm: 180,
        lg: "auto",
        xl: 180
      },
      maxWidth: {
        sm: 180,
        lg: "auto",
        xl: 180
      },
      flexDirection: "row",
        fontSize: 14,
        color: "rgba(0,0,0,0.75)",
     },

     macroLabel : {
        fontWeight: 700,
        fontSize: 15,
        color: "#2e2e2e"
     },

     absoluteIndicator: {
      width:"1px",
      left: "50%",
      height: "15px",
      //backgroundColor: "black",
      borderRight: "dashed 2px black",
      top: {
        xs: 8,
        sm: 4,
        lg: 8,
        xl: 4
      },
      position: "absolute",
      zIndex: 2
     },

     VNRIndicator : {
      width:"100px",
      display:"flex",
      justifyContent:"center",
      flexDirection:"row",
      alignItems:"center",
      left: "calc(50% - 45px)",
      //backgroundColor: "black",
      top: -45,
      position: "absolute",
      zIndex: 2
     },

     VNRTypo:{
      color: "rgba(0,0,0,0.75)",
      fontSize: 13,
      marginRight: 0.5
     },

     VNRContainer:{
      display:"flex",
      justifyContent:"center",
      flexDirection:"row",
      alignItems:"center",

     },

     microValue:{
      display:"flex",
      justifyContent:{xs: "flex-end", sm: "flex-start"},
      alignItems:{xs: "flex-end", sm: "flex-start"},
      flexDirection: {
        xs: "column", 
        sm: "row", 
        lg:"column", 
        xl: "row"
      },
      flex: 1,
      position: "relative",
     },

     progress : {
      width: "100%", 
      height: 15, 
      backgroundColor: "#f7f7f7",
      marginTop: {
        xs: 1,
        sm: 0,
        lg: 1,
        xl: 0
      }
     }
}


export default classes