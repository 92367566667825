// MUI
import { Box,IconButton,ListItemIcon,ListItemText,Menu,MenuItem,Typography, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
// DATE FNS
import { differenceInCalendarWeeks, endOfWeek, format, getMonth, isBefore, isFirstDayOfMonth, isSameDay, lastDayOfWeek } from "date-fns";
import { fr } from "date-fns/locale";
// APP
import React, {useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Styles
import classes from "./styles";
// API
import { createSelector } from "@reduxjs/toolkit";
import Workout from "interfaces/Workout";
import PlanningWorkout from "components/organisms/PlanningWorkout";
import { Add, ContentCopy, Download, EventRepeat } from "@mui/icons-material";
import { createWorkout, pasteWorkout } from "api/workouts";
import DialogImportWorkout from "components/organisms/DialogImportWorkout";
import DialogCreateProgram from "components/organisms/DialogCreateProgram";
import { MOBILE, WORKOUT_COVERS } from "utils/constants";
import ContextMenuCategory from "components/atoms/Typography/ContextMenuCategory";
import DialogImportProgram from "../DialogImportProgram";
import removePerformedSets from "function/removePerformedSets";
import PlanningProgram from "../PlanningProgram";
import Program from "interfaces/Program";
import DialogScheduleQuestionnaire from "../DialogScheduleQuestionnaire";
import Checkup, { ScheduledQuestionnaire } from "interfaces/Checkup";
import { PlanningQuestionnaire } from "../PlanningQuestionnaire";
import { PlanningQuestionnaireCompleted } from "../PlanningQuestionnaireCompleted";
import { useLocation } from "react-router-dom";



////// INTERFACES /////////

interface Props{
    date: Date,
    calendarDayNumber?: number,
    isProgramTemplate?: boolean,
    programsPadding: number,
    programInfos: {
        _id: string,
        name: string,
        day: number,
        color: string,
        duration: number
    },
    program: Program,
    isFirstDayOfProgram?:boolean
}


////// SELECTORS /////////

const wodsSelector = createSelector([
    (state:RootState) => state.planning.builder,
    (_, date:Date) => date
],
    (planning,date) => planning.workouts.filter((workout:Workout) => isSameDay(new Date(workout.schedule), date)) // Liste des séances de ce jour
)


/******** MAIN COMPONENT ********* */

export function PlanningDay({
    programsPadding, 
    date, 
    programInfos,
    isProgramTemplate, 
    calendarDayNumber,
    program,
    isFirstDayOfProgram
}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation<{openModal: {date:Date,type: "punctual" | "recurrence"}}>()
    const user = useSelector((state:RootState) => state.planning.user)
    const coach = useSelector((state:RootState) => state.user.data)
    const planningStart = useSelector((state:RootState)=> state.planning.builder.startDate)
    const workouts:Workout[] = useSelector((state:RootState)=> wodsSelector(state, date))
    const workoutCopied = useSelector((state:RootState) => state.planning.builder.workoutCopied)
    const checkupsCompleted = useSelector((state:RootState) => user?._id !== coach?._id ? state.planning.builder.checkups : [])
    const scheduledQuestionnaires:ScheduledQuestionnaire[] = useSelector((state:RootState) => user?._id !== coach?._id ? state.clients.profile?.scheduledQuestionnaires : [])
    const [punctual, setPunctual] = useState<boolean>(false)
    const programTemplateId = useSelector((state:RootState) => state.planning.builder?._id)

    const [anchorEl, setAnchorEl] = useState(null)
    const [openCreateProgram, setOpenCreateProgram] = useState(false)
    const [openImportWorkout, setOpenImportWorkout] = useState(false)
    const [openImportProgram, setOpenImportProgram] = useState(false)
    const [openQuestionnaire, setOpenQuestionnaire] = useState<boolean>(false)
    const [showQuestionnaires, setShowQuestionnaires] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const desktop = !mobile     


    useEffect(()=>{
        setTimeout(()=>{
            setShowQuestionnaires(true)
        },500)
    },[])
    


    useEffect(()=>{
        if(location.state?.openModal){
            const {type} = location.state.openModal
            const startDate = location.state.openModal.date
            if(isSameDay(new Date(startDate), new Date(date)) && !openQuestionnaire){
                setPunctual(type=== "punctual"? true:false)
                setOpenQuestionnaire(true)
            }
            
        }
    },[location.state])


    const questionnaires = useMemo(()=>{
        const list:ScheduledQuestionnaire[] = []
        if(scheduledQuestionnaires?.length>0){
            scheduledQuestionnaires.forEach((scheduledQuestionnaire)=> {
                const currentDay = format(date, "eeee").toLowerCase()
                const scheduleStart = new Date(scheduledQuestionnaire.start)

                if(scheduledQuestionnaire?.recurrence === "none" && isSameDay(date, new Date(scheduledQuestionnaire?.start))){
                    list.push(scheduledQuestionnaire)
                }

                // Récurrence toutes les semaines
                if(scheduledQuestionnaire?.recurrence !== "month"){
                    if(currentDay === scheduledQuestionnaire?.recurrence && (isBefore(scheduleStart,date) || isSameDay(date,scheduleStart))){
                        list.push(scheduledQuestionnaire)
                    }
                }
                // Récurrence tous les mois
                else if(scheduledQuestionnaire?.recurrence === "month"){
                    const montRecurrenceStartDay = format(scheduleStart, "eeee").toLowerCase()
                    if((isSameDay(date, scheduleStart) || differenceInCalendarWeeks(date,scheduleStart) % 4 ===0) && montRecurrenceStartDay === currentDay ){
                        list.push(scheduledQuestionnaire)
                    }
                }
            })
        }

        return list
        
    },[scheduledQuestionnaires, checkupsCompleted])





    /******** MOIS EN COURS AFFICHEE DANS LE PLANNING******** */
    const currentMonth:number = useMemo(()=>{
        const date = endOfWeek(new Date(planningStart), {locale: fr})
        return getMonth(date)
    },[planningStart])


    /******** LE JOUR EST IL DU MOIS PRECEDENT ******** */
    const dayIsNotFromSelectedMonth:boolean = useMemo(()=>{
        return getMonth(date) < currentMonth || getMonth(date) > currentMonth
    },[currentMonth])


    /******** AUJOURD'HUI ******** */
    const today:Boolean = useMemo(()=>{
        return isSameDay(new Date(), date)
    },[date])

    
    /******** GESTION DU MENU ******* */
    const onOpenDayMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }

    /****** Création d'une séance **** */
    const onAddNewWorkout = () => {
        //const noti = toast.loading("Création d'une séance ...")

        const workout:Workout = {
            name: "Nouvelle séance", 
            template: !user, 
            createdFor: user?._id || undefined, 
            schedule: date.toDateString(),
            status:"disabled",
            cover: {url: WORKOUT_COVERS[0]},
            programTemplate: programTemplateId,
            level: "medium"
        }

        dispatch(createWorkout({workout}))
        setAnchorEl(null)
    }


    /******* COLLAGE D'UNE SEANCE ****** */
    const onPasteWorkout = (enabled:boolean) => ()=> {
        //const noti = toast.loading("Copie d'une séance ...")

        const workout:Workout = {
            ...removePerformedSets(workoutCopied), 
            _id: undefined, 
            schedule: date.toDateString(), 
            status:enabled? "enabled" : "disabled", 
            template: !user, 
            createdFor: user?._id || undefined,
            programTemplate: programTemplateId,
            performed: null
        }

        dispatch(pasteWorkout({workout}))
        setAnchorEl(null)
    }

    /******* IMPORT D'UNE SEANCE ****** */
    const onOpenImportWorkout = () => {
        setOpenImportWorkout(true)
        setAnchorEl(null)
    }


    /******* Créer un nouveau programme ****** */
    const onAddNewProgram = () => {
        setOpenCreateProgram(true)
        setAnchorEl(null)
    }

    
    /******* IMPORT D'UN Program ****** */
    const onImportProgram = () => {
        setOpenImportProgram(true)
        setAnchorEl(null)
    }


    const onAddQuestionnaire = () => {
        setOpenQuestionnaire(true)
        setPunctual(false)
        setAnchorEl(null)
    }

    const onAddPunctualQuestionnaire = () => {
        setOpenQuestionnaire(true)
        setPunctual(true)
        setAnchorEl(null)
    }


    const LAST_WEEK_DAY = useMemo(()=>{
        //const isLastDay = isSameDay(date, lastDayOfWeek(date,{locale: fr}))
        const isLastDay = format(date, "eeee", {locale:fr}) === "dimanche"
        return isLastDay
    },[date])

    const FIRST_DAY_WEEK = useMemo(()=>{
        const isFirtDay = format(date, "eeee", {locale:fr}) === "lundi"
        return isFirtDay
    },[date])




   
    
    const DISABLE_PROGRAM_CREATION = useMemo(()=>{
        if(program) return true
        else return false
    },[program])



    const CANNOT_SCHEDULE_QUESTIONNAIRE = useMemo(()=>{
        const today = new Date(new Date().setHours(0,0,0,0))

        const isPast = isBefore(date, today)
        if(isPast){
            return true
        }

        /*******************
         * Un questionnaire est déja complété
         ********************/ 

        const hasAlreadyCompletedQuestionnaire = Boolean(checkupsCompleted?.find((elem:Checkup) => {
            return isSameDay(new Date(elem.createdAt), date) || isSameDay(new Date(elem.scheduledAt), date)
        }))

        if(hasAlreadyCompletedQuestionnaire){
            return true
        }

        /*******************
         * Un questionnaire programmé?
         ********************/ 

         /*******************
         * Questionaire hebdo
         ********************/ 

        const hasSameRecurence = scheduledQuestionnaires?.find((elem) => {
            return elem.recurrence === format(date, "eeee").toLowerCase()
        })

        if(hasSameRecurence){
            return true
        }

        /************************
         * Questionaire mensuel
         ************************/ 

        const monthsRecurence = scheduledQuestionnaires?.filter((elem) => {
            return elem.recurrence === "month"
        })

        if(monthsRecurence?.length>0){
            const todayRecurence = format(date, "eeee").toLowerCase()
            const monthRecurenceStart = monthsRecurence.find((elem)=> format(new Date(elem.start),"eeee").toLowerCase() === todayRecurence)
            if(Boolean(monthRecurenceStart)){
                return true
            }
        }

        return false

        // Jour antérieure à la date du jour
        //return  hasAlreadyScheduleQuestionnaire || hasAlreadyCompletedQuestionnaire || isPast || monthQuestionnaireExistAtSameDay
    },[questionnaires, checkupsCompleted, scheduledQuestionnaires])

    
    const CANNOT_SCHEDULE_PUNCTUAL_QUESTIONNAIRE = useMemo(()=>{
        const hasAlreadyCompletedQuestionnaire = Boolean(checkupsCompleted?.find((elem) => isSameDay(new Date(elem.createdAt), date)))
        const hasPunctualQuestionnaire = Boolean(questionnaires.find((elem) => elem.recurrence === "none" && isSameDay(new Date(elem.start), date)))
        const isPast = isBefore(date, new Date(new Date().setHours(0,0,0,0)))
        return hasPunctualQuestionnaire || hasAlreadyCompletedQuestionnaire || isPast
        
    },[questionnaires, checkupsCompleted])

   
    /******** JSX ******** */

    return(
        <React.Fragment>
            <Box sx={[
                classes.day, 
                LAST_WEEK_DAY && classes.lastDay,
                FIRST_DAY_WEEK && {borderLeft: {xs: `solid 1px #e4e4e4` , sm: 0, xl: `solid 1px #e4e4e4`}}

            ]}>


                    {/* ************* 
                        PROGRAMME (MOBILE)
                    ******** ********/}
                    {(mobile && isFirstDayOfProgram && programInfos?.name && programInfos?.day > -1) && (
                        <PlanningProgram
                            left={"0px"}
                            width={"100%"}
                            program={program}
                            borderRadius="0px"
                        />
                    )}

                    {(mobile && program && programInfos?.name && programInfos?.day > -1) && (
                        <Box
                            sx={{position: "absolute", left: 0, width: 5, backgroundColor: programInfos.color, height: "100%", zIndex: 3}}
                        />
                    )}

                
            
                {/************************* 
                 *          LABEL 
                 * ********************* */}

                <Box sx={[
                    classes.labelContainer, 
                    {
                        marginBottom: desktop ? programsPadding + "px" : 0
                    }
                ]}>

                    {/**********************************
                    *          DATE (Custom) 
                    * ********************************/}
                        <Typography sx={[
                            classes.dateLabelContainer,
                            (dayIsNotFromSelectedMonth && !Boolean(programTemplateId)) && classes.pastDayLabel, // Jour du mois précédent ou suivant
                            (isFirstDayOfMonth(date) && !Boolean(programTemplateId)) && classes.firstDayOfMonth, // Premier jour du mois en cours
                        ]}>
                            
                            {(!mobile && !isProgramTemplate) && (
                                <Box component="span"
                                    sx={[
                                        classes.dateLabel,
                                        (today && !Boolean(programTemplateId)) && classes.today,
                                    ]}
                                >
                                    {format(date, "d", { locale: fr })}
                                </Box>
                            )}

                            {(isFirstDayOfMonth(date) && !isProgramTemplate && !mobile) && (
                                <Box component="span"
                                    sx={{
                                        marginLeft: 1
                                    }}
                                >
                                    {format(date, "MMM", {locale: fr})}
                                </Box>
                            )}

                            {(mobile && !isProgramTemplate) && (
                                <span>
                                    {format(date, "EE d", { locale: fr })}
                                </span>
                            )}
                        

                            {isProgramTemplate && (
                                <span>
                                    j{calendarDayNumber}
                                </span>
                            )}

                            {(isFirstDayOfMonth(date) && !isProgramTemplate && mobile) && (
                                <Box component="span"
                                    sx={{
                                        marginLeft: 1
                                    }}
                                >
                                    {format(date, "MMM", {locale: fr})}
                                </Box>
                            )}

                           
                        </Typography>
                </Box>

            
                

                 {/**********************************
                 *          LISTE DU CONTENU
                 * ********************************/}

                <Box 
                    sx={classes.content}
                >
                    
                        
            


                    {/* ************** 
                    LISTE DES SEANCES 
                    *****************/}
                     {workouts?.map((workout:Workout)=> (
                        <PlanningWorkout 
                            key={workout._id}
                            workout={workout}
                        />
                    ))}


                    {/* ********************* 
                    LISTE DES QUESTIONNAIRES 
                    ******** ****************/}

                    {showQuestionnaires && questionnaires?.map((elem)=> {
                        // On cherche un questionnaire rem
                        if(!checkupsCompleted?.find((completed)=> {
                            if(isSameDay(new Date(completed.scheduledAt), date)){
                                return elem
                            } else if(!completed.scheduledAt && isSameDay(new Date(completed.createdAt), date)){
                                return elem
                            }
                           
                        }))

                        {
                            const punctual = questionnaires?.find((elem)=> elem.recurrence === "none" && isSameDay(new Date(elem.start), date))
                            // Ponctuel
                            if(Boolean(punctual) && elem._id === punctual._id && !isProgramTemplate){
                                return(
                                    <PlanningQuestionnaire 
                                        key={elem._id}
                                        questionnaire={elem}
                                    />
                                )
                            } 
                            // Récurrent
                            else if(!Boolean(punctual) && !isProgramTemplate){
                                return(
                                    <PlanningQuestionnaire 
                                        key={elem._id}
                                        questionnaire={elem}
                                    />
                                )
                            }
                        }
                        
                    })}

                    {/***************************
                     * Formulaires complétés
                     ***************************/}

                    {showQuestionnaires && checkupsCompleted?.filter((elem)=> {
                        if(isSameDay(new Date(elem.scheduledAt), date)){
                            return elem
                        } else if(!elem.scheduledAt && isSameDay(new Date(elem.createdAt), date)){
                            return elem
                        }
                    }).map((elem)=> (
                     
                        <PlanningQuestionnaireCompleted
                            key={elem._id}
                            questionnaire={elem}
                        />
                    ))}
                </Box>
                
                
                {/* ******** ADD BUTTON ******** */}
                <Box sx={classes.addButtonContainer}>
                    <IconButton 
                        onClick={onOpenDayMenu} 
                        sx={classes.addButton}
                    >
                        <Add 
                            sx={classes.addButtonIcon}
                        />
                    </IconButton>
                </Box>


            </Box>

            {/********* MENU CONTEXTUELLE ******* */}
            <Menu disableScrollLock={true} 
                id="day-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={()=> setAnchorEl(null)}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >

                    {/****** SEANCES ****** */}
                    <ContextMenuCategory>
                        Séances
                    </ContextMenuCategory>
                    <MenuItem 
                        onClick={onAddNewWorkout} 
                    >
                        <ListItemIcon>
                            <Add />
                        </ListItemIcon>
                        <ListItemText>
                            Ajouter une séance
                        </ListItemText>
                    </MenuItem>
                   
                    <MenuItem 
                        onClick={onPasteWorkout(true)} 
                        disabled={!workoutCopied}
                    >
                        <ListItemIcon>
                            <ContentCopy />
                        </ListItemIcon>
                        <ListItemText>
                            Coller une séance (activée)
                        </ListItemText>
                    </MenuItem>
                    <MenuItem 
                        onClick={onPasteWorkout(false)} 
                        disabled={!workoutCopied}
                    >
                        <ListItemIcon>
                            <ContentCopy />
                        </ListItemIcon>
                        <ListItemText>
                            Coller une séance (désactivée)
                        </ListItemText>
                    </MenuItem>
                    
                    <MenuItem 
                        onClick={onOpenImportWorkout} 
                    >
                        <ListItemIcon>
                            <Download />
                        </ListItemIcon>
                        <ListItemText>
                            Importer une séance
                        </ListItemText>
                    </MenuItem>

                        {/****** PROGRAMMES (affiché uniquement dans les plannings utilisateurs) ****** */}
                        {!programTemplateId && (
                            <ContextMenuCategory>
                                Programmes
                            </ContextMenuCategory>
                        )}
                        {!programTemplateId && (
                            <MenuItem 
                                onClick={onAddNewProgram}
                                disabled={DISABLE_PROGRAM_CREATION}
                            >
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    Ajouter un programme
                                </ListItemText>
                            </MenuItem>
                        )}
                        {!programTemplateId && (
                            <MenuItem 
                                onClick={onImportProgram}
                                disabled={DISABLE_PROGRAM_CREATION}
                            >
                                <ListItemIcon>
                                    <Download />
                                </ListItemIcon>
                                <ListItemText>
                                    Importer un programme
                                </ListItemText>
                            </MenuItem>
                        )}

                        {user?._id !== coach._id && (
                     
                            <ContextMenuCategory>
                                Questionnaire
                            </ContextMenuCategory>
                        )}
                        {user?._id !== coach._id && (
                            <MenuItem 
                                onClick={onAddPunctualQuestionnaire}
                                disabled={CANNOT_SCHEDULE_PUNCTUAL_QUESTIONNAIRE}
                            >
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    Ajouter un questionnaire
                                </ListItemText>
                            </MenuItem>
                        )}
                        {user?._id !== coach._id && (

                            <MenuItem 
                                onClick={onAddQuestionnaire}
                                disabled={CANNOT_SCHEDULE_QUESTIONNAIRE}
                            >
                                <ListItemIcon>
                                    <EventRepeat />
                                </ListItemIcon>
                                <ListItemText>
                                    Programmer une récurrence
                                </ListItemText>
                            </MenuItem>
                      
                        )}
                    
            </Menu>


            {/********* DIALOGS ******* */}
            
            <DialogImportWorkout
                open={openImportWorkout}
                onClose={()=> setOpenImportWorkout(false)}
                date={new Date(date)}
            />

            <DialogCreateProgram
                open={openCreateProgram}
                onClose={()=> setOpenCreateProgram(false)}
                startDate={new Date(date)}
            />

           

            <DialogImportProgram
                open={openImportProgram}
                onClose={()=> setOpenImportProgram(false)}
                date={date}
            />

            <DialogScheduleQuestionnaire
                punctual={punctual}
                open={openQuestionnaire}
                onClose={()=>setOpenQuestionnaire(false)}
                defaultDate={date}
            />

        </React.Fragment>
    )
}


function areEqual(prev:Props, next:Props){
    return isSameDay(prev.date,next.date)
}

export default React.memo(PlanningDay, areEqual)