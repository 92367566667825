import { BORDER_COLOR } from "utils/constants";
import { theme } from "utils/theme";


export const classes = {
    container:{
            width: "100%",
            marginTop: 1,
            marginBottom: 4,
            padding: {
                xs: 2,
                sm:0
            },
            paddingBottom : 0,
            //border: `solid 1px ${BORDER_COLOR}`,
            //backgroundColor: "white",
            borderRadius: "16px !important"
        
    },

    sectionLabel:{
        width: "100%",
        marginBottom: 2,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(18)
    },
}

export default classes