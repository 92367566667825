
import { Tab, Tabs as MuiTabs, styled, Box, useMediaQuery, SxProps, Theme } from "@mui/material";
import classes from "./styles"
import { BORDER_COLOR, MOBILE } from "utils/constants";

/******** INTERFACES ****** */

interface Props{
    value: number,
    onChange: (event: React.SyntheticEvent<Element, Event>, value: any) =>void,
    tabs: string[],
    sx?: SxProps<Theme>
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }


interface StyledTabProps {
    label: string;
    className: string
}



  /******** STYLED COMPONENTS ****** */

  const StyledTabs = styled((props: StyledTabsProps) => (
      <MuiTabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{paddingBottom: 0}}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile 
      />
    ))(({ theme }) => ({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      top: 43
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: "40%",
      width: '100%',
      borderRadius: 0,
      height: 5,
      backgroundColor: theme.palette.primary.main,
    },
}));


  const StyledTab = styled((props: StyledTabProps) => (
        <Tab disableRipple {...props} />
  ))(({ theme }) => ({
        textTransform: 'none',
        fontWeight: "600",
        fontSize: theme.typography.pxToRem(14.5),
        marginRight: theme.spacing(4),
        "&.MuiTab-root":{
          minWidth: 0,
          paddingLeft:theme.spacing(1),
          paddingRight:theme.spacing(1),
        },

        '&.Mui-selected': {
            color: 'rgba(0,0,0,0.85)',
            //color: 'primary.main',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
  }));




export default function Tabs({ value, tabs, onChange,sx = {}}: Props) {


  const mobile = useMediaQuery(MOBILE)

    return (
      
        <StyledTabs 
            value={value} 
            onChange={onChange}
            sx={sx}
           
            
        >
            {tabs.map((tabElem: string, index: number) => (
                
                  <StyledTab 
                      label={tabElem} 
                      key={index} 
                      sx={classes.tab} 
                      className={`tab-${index}`}
                  />
              
            ))}
        </StyledTabs>
       
    )
}