
import { Badge, Button, IconButton, IconProps, SvgIconTypeMap, Typography} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ComponentType } from "react";
import { BORDER_COLOR, DARKER_BORDER_COLOR, HOVER_COLOR, INPUT_BG_COLOR } from "utils/constants";
import { theme } from "utils/theme";



interface Props{
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    onClick: (event: React.MouseEvent<HTMLButtonElement>)=>void,
    white?:boolean,
    darkerBorder?:boolean,
    marginRight?: number,
    marginLeft?: number,
    badge?:number,
    showLabel? : boolean,
    label?: string
}

export default function CustomIconButton({Icon, onClick, white, darkerBorder, marginRight, marginLeft, badge, showLabel, label = "Filter"}:Props){

    if(showLabel){
        return(
        <Badge
                badgeContent={badge} 
                color="primary"
            >
        <Button
            onClick={onClick}
            variant="contained"
            disableElevation={true}
            startIcon={<Icon sx={{color: "rgba(0,0,0,0.85)",marginLeft: 1 }}/>}
            sx={{
                backgroundColor: white ? "white" :INPUT_BG_COLOR,
                border: `solid 1px ${darkerBorder? DARKER_BORDER_COLOR : BORDER_COLOR}`,
                "&:hover":{
                        backgroundColor: HOVER_COLOR
                },
                height: 40,
                marginRight: {xs:marginRight? marginRight : 0},
                marginLeft : {xs:marginLeft? marginLeft : 0},
                fontWeight: 600,
                fontSize: theme.typography.pxToRem(14),
                color: "rgba(0,0,0,0.85)"

            }}
        >
            
            Filter
       
        </Button>
        </Badge>
        )
    }else{

        return(
            <IconButton 
                sx={{
                    marginRight: {xs:marginRight? marginRight : 0},
                    marginLeft : {xs:marginLeft? marginLeft : 0},
                    backgroundColor: white ? "white" :INPUT_BG_COLOR,
                    border: `solid 1px ${darkerBorder? DARKER_BORDER_COLOR : BORDER_COLOR}`,
                    "&:hover":{
                        backgroundColor: HOVER_COLOR
                    },
                    height: 40
                }} 
                onClick={onClick}
            >
                <Badge
                    badgeContent={badge} 
                    color="primary"
                >
                    <Icon 
                        sx={{color: "rgba(0,0,0,0.85)"}}
                    />
                </Badge>
            </IconButton>
        )
    }
}