import { Box, Button, IconButton, InputAdornment, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./styles"
import TitleTextField from "components/molecules/TitleTextField";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { getProgram, updateProgram } from "api/programs";
import { differenceInCalendarWeeks } from "date-fns";
import AutosaveIndicator from "components/molecules/AutosaveIndicator";
import { toast } from "react-toastify";
import Loader from "components/molecules/Loader";
import DialogHandleProgramWeeks from "components/organisms/DialogHandleProgramWeeks";
import Planning from "components/organisms/Planning";
import { handleProgramTemplateDescription, handleProgramTemplateName, programIsSaving, resetPlanning } from "features/planningSlice";
import debounce from "lodash.debounce";
import { AUTOSAVE_TIMER, INPUT_DEBOUNCE_TIMER } from "utils/constants";
import DialogLoader from "components/molecules/DialogLoader";
import PlanningType from "interfaces/Planning";
import Header from "components/molecules/Header";
import MainContent from "components/molecules/MainContent";
import Toolbar from "components/molecules/Toolbar";
import { ArrowBack, CalendarMonth } from "@mui/icons-material";
import ProgramDescription from "components/molecules/ProgramDescription";
import Navbar from "components/organisms/NavBar";


export default function ProgramBuilder(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.planning.requests.update === "pending")
    const params:{programid:string} = useParams()
    const program = useSelector((state:RootState)=> state.planning.builder)
    const [openProgramWeeks, setOpenProgramWeeks] = useState<boolean>()
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const builderIsReady = useSelector((state:RootState)=> state.planning.builder.isReady)
    const [localName, setLocalName] = useState<string>('')
    const [autosaveActivated, setAutosaveActivated] = useState<boolean>(false) 
    const [localDescription, setLocalDescription] = useState<string>("")


    /********* RECUPERATION DES DONNEES DU PROGRAMME ******* */

    useEffect(()=>{
        if(params?.programid){
            dispatch(getProgram(params.programid)).unwrap().then((res:any)=>{
                if(res.program?.template){
                    setLocalName(res.program?.name)
                    setLocalDescription(res.program?.description)
                }else{
                    history.push('/templates')
                    toast.error('Impossible de modifier ce programme')
                }
                
            })
        }
    },[params?.programid])


    /******** CLEANUP ****** */

    useEffect(()=>{
        return function cleanup() {
            dispatch(resetPlanning())
        };
    },[dispatch])



    /*********** CHANGEMENT DU NOM ************* */

    const debounceOnChangeName = useRef(debounce((data)=> {
        const {newName} = data
        dispatch(handleProgramTemplateName({ name : newName}))
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
        const newName:string = event.target.value
        setLocalName(newName)
        debounceOnChangeName({newName: newName})
    }


    /*********** CHANGEMENT DE DESCRIPTION ************* */

    const debounceOnChangeDescription = useRef(debounce((data)=> {
        const {newDescription} = data
        dispatch(handleProgramTemplateDescription({ description : newDescription}))
    },INPUT_DEBOUNCE_TIMER)).current

    const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalDescription(event.target.value)
        debounceOnChangeDescription({newDescription: event.target.value})
    }


    /********* NOMBRE DE SEMAINES DU PROGRAMME ******* */

    const weeks:{_id:string}[] = useMemo(()=>{
        if(program?._id){
            const nbWeeks = differenceInCalendarWeeks(new Date(program.endDate), new Date(program.startDate))
            const weeksList:{_id:string}[] = []
            Array.from(Array(nbWeeks)).forEach((_,index)=>{
                weeksList.push({_id: index.toString()})
            })

            return weeksList
        } else return []
    },[program])


    /********* RETOUR A LA LISTE DES TEMPLATES ******* */

    const backTo = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }

        history.push({
            pathname:`/templates`,
            state: {
                tabs: 2, 
            }
        })
        return
    }

    /*********** AUTOSAVE ************** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((programUpdated:PlanningType)=> {
        dispatch(updateProgram({program: programUpdated}))
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if(builderIsReady && autosaveActivated){
            dispatch(programIsSaving())
            debounceAutoSave({...program, name: localName, description: localDescription})
        } else if(builderIsReady && !autosaveActivated){
            setAutosaveActivated(true)
        }
        // eslint-disable-next-line
    }, [localName, localDescription, weeks])



    /********* VERIFICATION DU STATUS DE SAUVEGARDE DU PROGRAMME */

    useEffect(()=>{
        // Si le programme a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)
            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 2, 
                }
            })
        }
    },[openWaitinUpdate, pending])


    if(builderIsReady){

    return(
        <React.Fragment>
            {/*}
            <Header>
                <Toolbar>
                        <Tooltip title={"Liste des modèles"}>
                            <IconButton 
                                sx={{marginRight: 2}} 
                                onClick={backTo}
                            >          
                                <ArrowBack sx={{color: "black"}}/>
                            </IconButton>
                        </Tooltip>
                        <TitleTextField 
                            fullWidth 
                            label={''} 
                            value={localName} 
                            onChange={onChangeName}
                            variant="standard" 
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Nom du modèle : </InputAdornment>, 
                            }}
                        />
                    
                        <Box sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"flex-end", flex:1}}>
                            <AutosaveIndicator />
                        </Box>
                </Toolbar>
            </Header>
            */}

            <Navbar
                pageTitle={"Modèle de programme"}
                fullWidth={true}
                backButton={backTo}
                showAutoSave={true}
            />

            <MainContent>

                <Box
                    sx={{paddingLeft: 0 ,paddingRight:0}}
                >
                    <TitleTextField 
                        fullWidth 
                        value={localName} 
                        onChange={onChangeName}
                        variant="standard" 
                        sx={{
                            marginBottom: 3, 
                            "& .MuiInput-root": {
                                fontSize: "1.6rem !important"
                        }}}

                    />
                </Box>

                 {/*******
                 * TITLE
                *********/}

                

                <Box>
                    <ProgramDescription
                        value={localDescription}
                        onChange={onChangeDescription}
                        noMargin={true}
                    />
                </Box>

                {/********* builder DURATION ******** */}

                <Box sx={classes.name}>
                    <Button 
                        sx={classes.duration} 
                        onClick={()=> setOpenProgramWeeks(true)}
                        startIcon={<CalendarMonth />}
                    >

                        Durée - {weeks.length} semaine(s)
                    </Button>
                </Box>

                


                <Box sx={classes.planning}>
                    <Planning
                        hideDatePicker={true}
                        hideDayLabels={true}
                        startDate={new Date(program.startDate)}
                        endDate={new Date(program.endDate)}
                        weeks={weeks}
                        isProgramTemplate={true}
                    />
                </Box>
            </MainContent>

            <DialogHandleProgramWeeks
                open={openProgramWeeks}
                onClose={()=>setOpenProgramWeeks(false)}
            />


            <DialogLoader
                open={openWaitinUpdate}
                text={"Sauvegarde en cours ..."}
            />              
        </React.Fragment>
    )

    }else{
        return(
            <Loader />
        )
    }
}