import { Box, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography, useMediaQuery } from "@mui/material";
import classes from "./styles";
import { Delete, DonutSmall } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { useMemo } from "react";
import { FoodPortion } from "interfaces/Food";
import { DEFAULT_PORTIONS, MOBILE } from "utils/constants";
import { onChangeIngredientPortion, onChangePortionLabel, onDeleteIngredient } from "features/mealPlanSlice";
import { MealContent } from "interfaces/Meal";
import MoreButton, { MenuElem } from "components/atoms/Buttons/MoreButton";

interface Props{
    ingredientIndex: number,
    mealIndex: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
    onSelectFood: (ingredient:MealContent)=> void
}

export default function MealFoodItem({ingredientIndex, mealIndex, day, onSelectFood}:Props){

    const ingredient:MealContent = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex])
    const portion = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].portion)
    const foodPortions = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].food.portions)
    const name = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].food.name.fr)
    const label = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].label)
    const dispatch = useDispatch<AppDispatch>()
    const mobile = useMediaQuery(MOBILE)

    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeIngredientPortion({
            day,
            foodIndex: ingredientIndex,
            mealIndex,
            portion: event.target.value,
            foodPortions: foodPortions
        }))
    }

    const changePortionLabel = (event: SelectChangeEvent<string>) => {
        dispatch(onChangePortionLabel({
            day,
            foodIndex: ingredientIndex,
            mealIndex,
            newLabel: event.target.value,
            foodPortions: foodPortions
        }))
    }


    const onDelete = () => {
        dispatch(onDeleteIngredient({
            day,
            ingredientIndex,
            mealIndex
        }))
    }


    {/************** 
        MENU MORE 
    ***************/}

    const MENU:MenuElem[] = useMemo(()=>{
        var menu:MenuElem[] = []


        menu.push(
            {
                label: "Répartition des nutriments",
                icon: <DonutSmall />,
                callback: ()=>onSelectFood(ingredient)
            },
            {
                label: "Supprimer",
                icon: <Delete />,
                callback: onDelete
            }
        )
       
           
   
        return menu

       
    },[])



    const PORTIONS:FoodPortion[] = useMemo(()=>{
        const allPortions:FoodPortion[] =  [...DEFAULT_PORTIONS]

        
        if(foodPortions){
            return allPortions.concat(foodPortions)
        }else{
            return allPortions
        }
    },[foodPortions])


    return(
        <Box
            sx={classes.item}
        >   
            <Box
                sx={classes.itemInfos}
            >

                {/**************
                 * INGREDIENT INFOS
                 *************/}

                <Box
                    sx={classes.infos}
                >

                    {/*************
                     * PORTIONS
                     **************/}


                    <TextField
                        value={portion}
                        sx={[
                            classes.textField, 
                            classes.portionInput
                        ]}
                        size="small"
                        onFocus={event => {
                            event.target.select();
                        }}
                        onChange={onChangePortion}
                        variant="outlined"
                    />

                    <Select
                        size="small"
                        value={label}
                        onChange={changePortionLabel}
                        sx={[
                            classes.textField,
                            classes.labelInput
                        ]}
                    >
                        {PORTIONS.map((elem, index)=> {
                            return(
                                <MenuItem 
                                    key={index}
                                    value={elem.label.fr}
                                >
                                    {elem.label.fr}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Box>

                {/*************
                 * NAME
                 **************/}

                <Typography
                    sx={classes.itemName}
                >
                    {name}
                </Typography>
            </Box>


            {mobile && (
                <Box
                    sx={{display:"flex", alignItems:"flex-start", alignSelf:"flex-start"}}
                >
                <MoreButton
                    menuList={MENU}
                />
                </Box>
            )}


            {!mobile && (
                <Box
                    sx={{marginLeft: 2, display:"flex"}}
                >

                    
                    <IconButton
                        onClick={()=>onSelectFood(ingredient)}
                    >
                        <DonutSmall />
                    </IconButton>
                    

                    <IconButton
                        onClick={onDelete}
                    >
                        <Delete/>
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}