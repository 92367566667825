import { Box, Button, Grid, Popover, Typography } from "@mui/material"
import classes from "./style"
import { EVENTS_TYPES, EVENTS_TYPES_TARD } from "utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { addDashboardEventFilter, clearDashboardEventsFilters, removeDashboardEventFilter } from "features/dashboardSlice"
import { addClientHistoryEventFilter, clearClientHistoryEventsFilters, removeClientHistoryEventFilter } from "features/clientsSlice"

interface Props{
    open: boolean,
    onClose: ()=>void,
    anchorEl: HTMLButtonElement,
    forClient?: boolean
}


export default function EventsFilters({onClose, open, anchorEl, forClient = false}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const filters = useSelector((state:RootState)=> forClient ? state.clients.clientHistoryFilters : state.dashboard.filters)



    /*****************
     *  CLEAR FILTERS
     * ************** */

    const onClearFilter = () => {
        if(!forClient){
            dispatch(clearDashboardEventsFilters())
        } else {
            dispatch(clearClientHistoryEventsFilters())

        }
    }


    /*****************
     *  CHECK FILTER 
     * ************** */

    const handleFilter = (newFilter:string) => ()=>{

        const exist = filters.find((elem)=> elem === newFilter)

        if(!exist){ 
            if(!forClient){
                dispatch(addDashboardEventFilter({newFilter}))
            }else{
                dispatch(addClientHistoryEventFilter({newFilter}))

            }
        } else {
            if(!forClient){
                dispatch(removeDashboardEventFilter({newFilter}))
            }else{
                dispatch(removeClientHistoryEventFilter({newFilter}))

            }
        }
    }






    return(
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            disableScrollLock={true}
            sx={classes.popover}
        >
        <Box 
            sx={[classes.filterMenu]}
        >
            <Box 
                sx={{position: "relative"}}
            >

                {/**** FILTER HEADER **** */}
                <Box sx={classes.filterHeader}>
                    <Typography sx={{fontWeight: 600, fontSize: 15}}>
                        Filtrer
                    </Typography>
                    <Button 
                        sx={{textTransform: "none"}} 
                        onClick={onClearFilter}
                    >
                        Décocher
                    </Button>
                </Box>


                <Grid 
                    sx={{display:"flex", flexDirection: "row"}}
                >

                    <Grid 
                        item 
                        xs={12}
                        sx={{width: 200}}
                    >

                        {/********************** 
                         *  LISTE DES FILTRES 
                         * *********************/}

                        {EVENTS_TYPES.map((event,index)=> {

                            const checked = filters.find((elem)=> elem === event)

                            return(
                                <Box 
                                    key={index} 
                                    sx={classes.filter} 
                                    onClick={handleFilter(event)}
                                >

                                    {checked && <CheckBox sx={[classes.checkbox, classes.checboxChecked]}/>}
                                    {!checked && <CheckBoxOutlineBlank sx={classes.checkbox}/>}
                                    
                                    <Typography sx={classes.group}>
                                        {EVENTS_TYPES_TARD[event].fr}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Grid>
                </Grid>
            

            </Box>
        </Box>
        </Popover>
    )
}