import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {

    card : {
        display:"flex", 
        height: "100%", 
        position: "relative", 
        backgroundColor: "white", 
        justifyContent: "center", 
        flexDirection: "column", 
        padding: 4, 
        paddingTop: 5,
        borderRadius: 1,
        alignItems:"center", 
        border: `solid 1px ${BORDER_COLOR}`,

        "&:hover" : {
            backgroundColor: HOVER_COLOR,
            cursor: "pointer"
        }
    },

    moreButton: {
        width: 48,
        height: 48, 
        display:"flex", 
        position: "absolute", 
        borderRadius: "100px !important",
        top: 30, 
        right: 15,
        zIndex: 3
    },


    statusBox: {
        position: "absolute", 
        top: 15, 
        left: 10,
        display:"flex",
        flexDirection: "row"
    },

    status : {
        backgroundColor: "#61c9b2",
        paddingLeft: 1.5,
        paddingRight: 1.5,
        color: "white",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(13),
        borderRadius: "3px"
    },


    inactif : {
        backgroundColor: "#ef7b69"
    },

    emailButton: {
        position: "absolute", 
        top: 15, 
        left: 15,  
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        backgroundColor: LIGHT_BUTTON_COLOR,
        borderRadius: "100px !important", 
        height: 40, 
        width: 40
    },



    avatarContainer: {
        borderRadius: "100%",
        width: 80,
        height: 80, 
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
        
    },
            
        
    
    
    avatar: {
        width: 80,
        height: 80, 
        borderRadius: 100, 
        backgroundColor: "#f1f1f1", 
        overflow:"hidden", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        marginTop: 3
    },

    avatarLetters : {
        fontWeight: 700, 
        fontSize: theme.typography.pxToRem(18), 
        color: "rgb(88 88 88)"
    },

    name: {
        fontWeight: 600, 
        marginTop: 2,
        color: "rgba(0,0,0,0.85)", 
        fontSize: theme.typography.pxToRem(19),
        textAlign: "center"
    },

    emailSubTitle: {
        fontWeight: 400, 
        color: "rgba(0,0,0,0.85)", 
        fontSize: theme.typography.pxToRem(13),
        textAlign: "center",
        marginBottom: 2
    },



    program : {
        marginTop: 0.5,
        marginBottom: 1,
        display: "flex",
        alignItems:"center",
        flexDirection: "column",
        paddingTop: 2,
        borderTop: "dashed 2px #d5d5d5"
    },
    

    email: {
        fontWeight: 400, 
        color: "rgba(0,0,0,0.75)", 
        fontSize: theme.typography.pxToRem(14)
    },

    programmed: {
        fontWeight: 400, 
        color: "rgba(0,0,0,0.75)", 
        fontSize: theme.typography.pxToRem(15)
    },


    programLabel : {
        fontWeight: 600, 
        color: "rgba(0,0,0,0.75)",  
        fontSize: theme.typography.pxToRem(14)
    },

    programLabelLight: {
        color: "rgba(0,0,0,0.35)"
    },

    programIcon:{
        fontSize: 18, 
        marginRight: 1
    },


    programContainer : {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        padding: "3px", 
        borderRadius: 3, 
        marginTop: 2, 
        paddingRight: 1
    },

    nutritionContainer : {
        backgroundColor: "#77cc77",
        marginLeft: 1
    },

    nutritionLabel : {
        fontSize: 14,
        fontWeight: 500
    }
}


export default classes