import { Box, useMediaQuery } from "@mui/material"
import React, { useMemo } from "react"
import { theme } from "utils/theme"
import { HEADER_MIN_HEIGHT, HEADER_MIN_HEIGHT_EXPANDED, HEADER_MIN_HEIGHT_CLIENTPROFILE, MOBILE, DESKTOP, FIXED_SEARCH_HEIGHT, TABLET} from "utils/constants"

interface Props{
    children: React.ReactNode,
    header?: "simple" | "withTabs" | 'clientProfile',
    padding?: boolean,
    withSearch?:boolean
}

export default function MainContent({children, header = "simple", padding = true, withSearch = false}:Props){

    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const desktop = useMediaQuery(DESKTOP)


    const PADDINGTOP_TOP = useMemo(()=>{
        
        /***************** 
         * CLIENT PROFILE 
         * *** ************/

        if(header === "clientProfile"){
             const calculedHeight = `calc(${(HEADER_MIN_HEIGHT_CLIENTPROFILE+"px + " + theme.spacing(3))}) !important`
            return calculedHeight
        } 

        /***************** 
         * AVEC RECHERCHE 
         * *** ************/

        else if(withSearch){
            
            /*************
             * TABLETTE
             *************/

            if(!desktop){
                
                /*************
                 * AVEC TABS
                 ************
                if(header !== "simple"){
                    let height = HEADER_MIN_HEIGHT_EXPANDED
                    if(withSearch && !desktop){
                        height = height + FIXED_SEARCH_HEIGHT
                    }
                    const calculedHeight = `calc(${(height+"px + " + theme.spacing(3))}) !important`
                    return(calculedHeight)
                } else {
                 */

                    /*************
                     * SANS TABS
                     *************/
                    let height = HEADER_MIN_HEIGHT
                    if(withSearch && !desktop){
                        height = height +  (tablet || mobile ? FIXED_SEARCH_HEIGHT -30 : FIXED_SEARCH_HEIGHT)
                    }
                    const calculedHeight = `calc(${(height+"px + " + theme.spacing(3))}) !important`
                    return(calculedHeight)
                //}
            }

            /******
             * PC
             ******/
            return "0px !important"
        }


        if(header === "simple"){
            let calculedHeight = `calc(${(HEADER_MIN_HEIGHT+"px + " + theme.spacing(3))}) !important`
            return calculedHeight
        }else {
            let height = HEADER_MIN_HEIGHT_EXPANDED
            if(withSearch && !desktop){
                height = height + FIXED_SEARCH_HEIGHT
            }
            const calculedHeight = `calc(${(height+"px + " + theme.spacing(3))}) !important`
            return(calculedHeight)
        } 

    },[header, withSearch,desktop])

    return(
        <Box sx={{
            padding: {
                xs: padding ? 2 : 0, 
                sm: padding? 3 : 0
            }, 
            paddingTop: PADDINGTOP_TOP
        }}>
            {children}
        </Box>
    )
}