import React, { useEffect, useMemo, useRef, useState } from "react";
// MUI
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
// REDUX
import { useSelector } from "react-redux";
import { shallowEqual, useDispatch } from "react-redux";
// DEBOUNCE
import debounce from "lodash.debounce"
// API
import { getWorkout, updateWorkout} from "api/workouts"
// REDUCERS
import { selectDrawerItem } from "features/appSlice";
import { handleName, workoutIsSaving, addBlockToWorkout, reorderWorkout, resetWorkoutBuilder, handleLevel, onChangeDescription } from "features/workoutSlice"
// INTERFACES
import Workout, {WorkoutBlock as WorkoutBlockType} from "interfaces/Workout";
import Image from "interfaces/Image";
import { AppDispatch, RootState } from "app/store";
// LOCAL COMPONENTS
import DialogActivateWorkout from "components/molecules/DialogActivateWorkout";
import WorkoutBlock from "components/organisms/WorkoutBlock";
// DND
import { DragDropContext } from "react-beautiful-dnd";
// SHARED COMPONENTS
import TitleTextField from "components/molecules/TitleTextField";
import Loader from "components/molecules/Loader";
import DialogLoader from "components/molecules/DialogLoader";
// STYLES
import classes from "./styles";
import { AUTOSAVE_TIMER, INPUT_DEBOUNCE_TIMER, MOBILE, TRAD_LEVEL } from "utils/constants";
import { format } from "date-fns";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fr } from "date-fns/locale";
import MainContent from "components/molecules/MainContent";
import { CheckCircle, Edit, Event, ExpandMore, HighlightOff, Save, SignalCellularAlt, SignalCellularAlt1Bar, SignalCellularAlt2Bar } from "@mui/icons-material";
import DialogSaveWorkoutAsTemplate from "components/molecules/DialogSaveWorkoutAsTemplate";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkoutDescription from "components/molecules/WorkoutDescription";
import Navbar from "components/organisms/NavBar";
import SwitchButton from "components/molecules/SwitchButton";

const LEVEL_ICON = {
    beginner: <SignalCellularAlt1Bar sx={classes.tagInfoIcon} />,
    medium: <SignalCellularAlt2Bar sx={classes.tagInfoIcon} />,
    advanced: <SignalCellularAlt sx={classes.tagInfoIcon} />,

}



function ButtonActivation({onOpenActivation, status}:{onOpenActivation:()=>void, status: "enabled" | "disabled" | "done" | "missed" | "started"}){
    return(
        <Box 
            sx={[
                classes.tagInfo,
                classes.tagInfoClickable,
                status === "enabled"?  classes.tagInfoEnabled : classes.tagInfoDisabled
            ]} 
            onClick={onOpenActivation}
        >
            {status=== "enabled"? 
                <CheckCircle 
                    sx={[classes.tagInfoIconEnabled]} 
                /> : 
                <HighlightOff sx={[
                    classes.tagInfoIcon, 
                    classes.disabledIcon
                ]} />
            }
            
            <Typography 
                sx={classes.tagInfoValue}
            >
                {status=== "enabled"? "Séance activée" : "Séance désactivée"}
            </Typography>
            <Edit 
                sx={{fontSize: "1rem", marginLeft: 1, color: "rgba(0,0,0,0.85)"}}
            />
        </Box>
    )
}


/********* MAIN COMPONENT ****** */

export default function WorkoutBuilder() {

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const params:{workoutid:string} = useParams()
    const location = useLocation<{planningRange: {start:Date, end: Date}, backToDashboard:boolean, planningScroll: number}>()
    
    // Selectors
    const wodId:string = params.workoutid
    const name: string = useSelector((state:RootState)=> state.workouts.builder.name)
    const isTemplate:boolean = useSelector((state:RootState)=> state.workouts.builder.template)
    const programTemplate:string = useSelector((state:RootState)=> state.workouts.builder.programTemplate)
    const level:"beginner"|"medium"|"advanced" = useSelector((state:RootState)=> state.workouts.builder.level)
    const schedule:string = useSelector((state:RootState)=> state.workouts.builder.schedule)
    const blocks: WorkoutBlockType[] = useSelector((state:RootState)=> state.workouts.builder.blocks, shallowEqual)
    const cover:Image = useSelector((state:RootState)=> state.workouts.builder.cover)
    const status:"enabled" | "disabled" | "done" | "missed" | "started" = useSelector((state:RootState)=> state.workouts.builder.status)
    const pending = useSelector((state:RootState)=> state.workouts.requests.update === "pending")
    const coachId = useSelector((state:RootState) => state.user.data._id)
    const userProfile = useSelector((state:RootState)=> state.clients.profile)
    const [anchorElMenuLevel, setAnchorElMenuLevel] = React.useState<null | HTMLElement>(null);
  
    // Local state
    const [client, setClient] = useState<string>(null)
    const [formIsReady, setFormIsReady] = useState<boolean>(false)
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [openActivateWorkout, setOpenActivateWorkout] = useState<boolean>(false)
    const [localName, setLocalName] = useState<string>('')
    const [localDescription, setLocalDescription] = useState<string>('')
    const [openSaveAsTemplate, setOpenSaveAsTemplate] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const mobile = useMediaQuery(MOBILE)
   

     /************* INITIALISATION **************** */
     useEffect(() => {
        if(isTemplate){
            dispatch(selectDrawerItem(3)) // Séance de planning
        }

        return function cleanup() {
            dispatch(resetWorkoutBuilder())
        }
    }, [dispatch])


    /****** FERMETURE, LE WORKOUT N'EST PLUS READY ******** */
    useEffect(()=>{
        return function cleanup() {
            setFormIsReady(false)
        };
    },[dispatch])


    /*********** CHANGEMENT DU NOM ************* */

    const debounceOnChangeName = useRef(debounce((data)=> {
        const {newName} = data
        dispatch(handleName({ name : newName}))
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
        const newName:string = event.target.value
        setLocalName(newName)
        debounceOnChangeName({newName: newName})
    }


    /*********** CHANGEMENT DE LA DESCRIPTION ************* */

    const debounceOnChangeDescription = useRef(debounce((data)=> {
        dispatch(onChangeDescription({description: data.description}))
    },500)).current


    const onChangeLocalDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription:string = event.target.value
        setLocalDescription(newDescription)
        debounceOnChangeDescription({description: newDescription})
    }



    /************* RECUPERATION DES DONNEES LE CAS ECHEANT **************** */

    useEffect(()=>{
        // Récupération des result Metrics
        
        dispatch(getWorkout(params.workoutid)).unwrap().then((res:any)=>{
            setFormIsReady(true)
            setLocalName(res.workout.name)
            setLocalDescription(res.workout.description)
            if(res.workout.createdFor){
                setClient(res.workout.createdFor)
            }
        })
        
    },[params.workoutid])
    


     /************* AJOUT D'UN BLOC ********* */
     const onAddBlockToWorkout = () => {
        setAnchorEl(null)
        dispatch(addBlockToWorkout())
        //toast.success('Bloc ajouté')
    }

    /************* REORGANISATION DES BLOCKS **************** */

    const reorderBlocks = (result:any) => {
        dispatch(reorderWorkout({ result }))
    }


    /******** MISE EN FORME DES DONNES DU FORMULAIRE ********/

    const workoutForm = useMemo(()=>{
        const form:Workout={
            _id: wodId,
            schedule: schedule,
            template:isTemplate,
            name,
            blocks,
            cover,
            status,
            description: localDescription,
            level,
            createdFor: client
        }
        return form
    },[blocks, name, schedule, status, cover, localDescription, level])


    /*********** AUTOSAVE ************** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((workout)=> {
        dispatch(updateWorkout({workout}))
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if(formIsReady){
            dispatch(workoutIsSaving())
            debounceAutoSave(workoutForm)
        }
        // eslint-disable-next-line
    }, [blocks, name, status, localDescription, level])


    /********* VERIFICATION DU STATUS DE SAUVEGARDE DU WORKOUT */

    useEffect(()=>{
        // Si le workout a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)

            if(location.state?.backToDashboard){
                history.push(`/dashboard`)
            }
            else if(programTemplate){
                history.push(`/program/${programTemplate}`)
            }else if(isTemplate){
                history.push({
                    pathname:`/templates`,
                    state: {
                        tabs: 0, 
                    }
                })
            }
             // Retour au planning client
            else if(coachId !== client){
                history.push(
                    {
                        pathname: `/clients/${client}`,
                        state: { 
                            tabs: 2, 
                            planningRange:{
                                start: location.state?.planningRange?.start,
                                end: location.state?.planningRange?.end,
                            },
                            planningScroll: location.state?.planningScroll
                        },
                    }
                )
            } 
            // Retour au planning coach
            else {
                history.push(
                    {
                        pathname: `/planning`,
                        state: { 
                            planningRange:{
                                start: location.state?.planningRange?.start,
                                end: location.state?.planningRange?.end,
                            },
                            planningScroll: location.state?.planningScroll
                        },
                    }
                )
            }   

            
        }
    },[openWaitinUpdate, pending])


    /********** RETOUR A LA PAGE PRECEDENTE ****** */

    const backToPreviousScreen = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }

        if(location.state?.backToDashboard){
            history.push(`/dashboard`)
        }

        // Retour au programme template
        else if(programTemplate){
            history.push(`/program/${programTemplate}`)
        }

        // Retour au template de séance
        else if(isTemplate){
            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 0, 
                }
            })
        }

        // Retour au planning client
        else if(coachId !== client){
            history.push(
                {
                    pathname: `/clients/${client}`,
                    state: { 
                        tabs: 2, 
                        planningRange:{
                            start: location.state?.planningRange?.start,
                            end: location.state?.planningRange?.end,
                        },
                        planningScroll: location.state?.planningScroll
                    },
                }
            )
        } 
        // Retour au planning coach
        else {
            history.push(
                {
                    pathname: `/planning`,
                    state: { 
                        planningRange:{
                            start: location.state?.planningRange?.start,
                            end: location.state?.planningRange?.end,
                        },
                        planningScroll: location.state?.planningScroll
                    },
                }
            )
        }   
    }


    const onOpenSaveAsTemplate = () => {
        setAnchorEl(null)
        setOpenSaveAsTemplate(true)
    }


    const handleCloseLevelMenu = (level: "advanced" | "medium" | "beginner") => ()=> {
        setAnchorElMenuLevel(null)
        dispatch(handleLevel({level}))
    }





    /*************************** */
    /***       JSX        *** */
    /*************************** */


    if (formIsReady) {
        return (
            <React.Fragment>
               
                <Navbar
                    pageTitle={!isTemplate ? "Créateur de séance" : "Modèle de séance"}
                    backButton={backToPreviousScreen}
                    action={{
                        Icon: MoreHorizIcon,
                        callback: (event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}
                    }}
                    showAutoSave={true}
                />


                    <Menu disableScrollLock={true} 
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={()=>setAnchorEl(null)}
                    >
                        <MenuItem 
                            onClick={onOpenSaveAsTemplate}>
                            <ListItemIcon>
                                <Save fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Enregistrer comme modèle
                            </ListItemText>
                        </MenuItem>
                    </Menu>

                <MainContent 
                    padding={mobile? false : true}
                >
                    <Grid 
                        container 
                        justifyContent="center" 
                        sx={{paddingBottom: 10,}}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>

                            {/*********************************
                             * TITLE  + DESCRIPTION DE SEANCE
                            ***********************************/}

                            <Box
                                sx={{paddingLeft: {xs:2, sm:0} ,paddingRight:{xs:2, sm:0}}}
                            >
                                <Box>
                                    <TitleTextField 
                                        fullWidth 
                                        value={localName} 
                                        onChange={onChangeName}
                                        variant="standard" 
                                        sx={{
                                            marginBottom: 3, 
                                            "& .MuiInput-root": {
                                                fontSize: "1.6rem !important"
                                        }}}

                                    />
                                    
                                </Box>


                               
                            </Box>



                            {/****************
                             * LISTE DES TAGS
                             ****************/}

                            <Box 
                                sx={classes.tags}
                            >


                                {/************ 
                                 * ACTIVATION 
                                 * ***********/}
                            
                                {/*(!isTemplate) && (
                                    <ButtonActivation 
                                        status={status}
                                        onOpenActivation={()=>setOpenActivateWorkout(true)}
                                    />
                                )*/}

                                {(!isTemplate) && (
                                    <Box
                                        sx={{marginRight: 1.5}}
                                    >
                                    <SwitchButton 
                                        activated={status==="enabled"}
                                        onClick={()=>setOpenActivateWorkout(true)}
                                    />
                                    </Box>
                                )}

                                {/************* 
                                 * MENU LEVEL 
                                 * ************/}

                                {isTemplate && (
                                    <Box sx={[classes.tagInfo, classes.tagInfoClickable]} onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorElMenuLevel(event.currentTarget)}}>
                                        {LEVEL_ICON[level] || <SignalCellularAlt/>}
                                        <Typography sx={classes.tagInfoLabel}>
                                            Niveau
                                        </Typography>
                                        <Typography sx={classes.tagInfoValue}>
                                            {TRAD_LEVEL[level]}
                                        </Typography>
                                        <ExpandMore />
                                    </Box>
                                )}

                                <Menu disableScrollLock={true} 
                                    anchorEl={anchorElMenuLevel}
                                    open={Boolean(anchorElMenuLevel)}
                                    onClose={()=> setAnchorElMenuLevel(null)}
                                >
                                    <MenuItem onClick={handleCloseLevelMenu("beginner")} selected={level === "beginner"}>
                                        Débutant
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseLevelMenu("medium")} selected={level === "medium"}>
                                        Intermédiaire
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseLevelMenu("advanced")} selected={level === "advanced"}>
                                        Avancé
                                    </MenuItem>
                                </Menu>


                                {/************* 
                                 * ASSIGNEE A 
                                 * ************/}

                                {(!isTemplate && !mobile && (coachId !== client)) && (
                                    <Box sx={[classes.tagInfo]}>
                                        <AssignmentIndIcon 
                                            sx={classes.tagInfoIcon}
                                        /> 
                                        <Typography sx={classes.tagInfoLabel}>
                                            Assignée à
                                        </Typography>
                                        <Typography sx={[classes.tagInfoValue, {fontWeight: 600}]}>
                                                {userProfile?.firstname} {userProfile?.lastname}
                                        </Typography>
                                    </Box>
                                )}

                                
                                {/******
                                 * DATE
                                 * ****/}

                                {!isTemplate  && !mobile && (
                                    <Box sx={[classes.tagInfo, {marginRight: 0}]}>
                                        <Event 
                                            sx={classes.tagInfoIcon}
                                        /> 
                                        
                                        <Typography sx={classes.tagInfoValue}>
                                            {format(new Date(workoutForm.schedule), "eee dd MMM yyyy", {locale: fr})}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>



                            <WorkoutDescription 
                                    value={localDescription} 
                                    onChange={onChangeLocalDescription}
                                />

                            


                            {/***************************** 
                             * LISTE DES BLOCS DE SEANCE 
                             * ************************* */}

                            <Box 
                                className="container"
                            >
                                <DragDropContext onDragEnd={reorderBlocks}>
                                    {blocks.map((block,index)=>{
                                        return(
                                            <WorkoutBlock 
                                                blockId={block.blockId}
                                                blockIndex={index}
                                                key={block.blockId}
                                                onAddBlockToWorkout={onAddBlockToWorkout}
                                                showAddBlock={index === blocks.length - 1}
                                            />
                                        )
                                    })}
                                </DragDropContext>
                            </Box>

                           
                        </Grid>
                    </Grid>
                </MainContent>



                <DialogLoader
                    open={openWaitinUpdate}
                    text={"Sauvegarde en cours ..."}
                />


                {schedule && (
                    <DialogActivateWorkout
                        open={openActivateWorkout}
                        onClose={()=>setOpenActivateWorkout(false)}
                        workout={workoutForm}
                    />
                )}

                <DialogSaveWorkoutAsTemplate
                    open={openSaveAsTemplate}
                    onClose={()=> setOpenSaveAsTemplate(false)}
                    workout={workoutForm}
                />


                
                

            </React.Fragment>

        )
    } else {
        return (
            <Loader />
        )
    }

}