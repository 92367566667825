import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {

    header:{
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        borderBottom: "solid 1px #eeeeee",
        alignItems:"center",
        fontWeight: 700,
        fontSize: 20
    },


    indicators : {
        minWidth: 250, 
        display:"flex", 
        flexDirection: "row", 
        alignItems:"center", 
        justifyContent:"flex-end", 
        flex:1, 
        flexWrap: "nowrap"
    },

    pageHeader:{
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        alignItems:"center",
        marginBottom: 2.5,
        width: "100%",
    },

    thumbnail:{
        maxWidth: "100%",
        cursor: "pointer",
        opacity: 0.6,
        border: "solid 3px white",
        backgroundColor: "whitesmoke",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },


    thumbnailSelected: {
        border: "solid 3px black",
        cursor: "pointer",
        opacity: 1
    },

    thumbnailItem: {
        backgroundColor: "black",
        width: "100%"
    },


    uploadField:{
        width: "100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        backgroundColor: "whitesmoke",
        fontSize: 13,
        padding: 1,
        textAlign: "center",
        cursor: "pointer",
        textTransform: "none"
    },

    dialogTitle: {
        fontWeight: 700,
        fontSize: 20
    },

    saveButtonContainer: {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginBottom: 6
    },


    content:{
        minWidth: 960, 
        padding: 0
    },

    videoContainer:{
        width: "100%",  
        backgroundColor: "#f9f9f9", 
        marginTop: 2, 
        position: "relative"
    },

    iconYoutube:{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        zIndex: 3
    },

    // Section Equipement - Muscles - type
    section: {
        minHeight: 80, 
        padding: 3,
        display:"flex", 
        flexDirection: "column", 
        alignItems:"flex-start", 
        justifyContent:"space-between", 
        marginBottom: 3,
        border: `solid 1px ${BORDER_COLOR}`,
        backgroundColor: "white"
    },

    sectionLabel:{
        width: "100%",
        marginBottom: 3,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18)
    },

    noMargin: {
        marginBottom: 0
    },
    

    chips:{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "flex-start",
        flexWrap: "wrap", 
        marginBottom: 1,
        flex: 1,
        width: "100%"
    },

    // Cards media

    mediaSelector:{
        width: "100%", 
        height: "100%",
        border: "dashed 4px #ededed", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        flexDirection: "column"
    },

    actions :{
        borderTop: "solid 1px #eeeeee",
    },


    videoThumbnail:{
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "black",
        width: "100%",
        zIndex: 2,
        padding: 0,
        margin:0,
        alignItems:"center",
        justifyContent:"center",
        display:"flex"
    },



    draftJsEditor:{
        width: "100%",
        marginBottom: 2
    },


    deleteImageButton:{
        position: "absolute",
        top: 25,
        right: 0,
        backgroundColor: "black",
        width: 40,
        height: 40,
        zIndex: 2
    },
   


    

    

}

export default classes