import { Box, InputAdornment, TextField, useMediaQuery } from "@mui/material"
import { AppDispatch } from "app/store"
import { changePrescriptionValue } from "features/workoutSlice"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { INPUT_DEBOUNCE_TIMER, MOBILE } from "utils/constants"
import classes from "./styles"
import TimeMaskInput from "components/molecules/TimeInput"

/******** INTERFACES ******* */


interface Props{
    restTextField?: boolean,
    defaultValue: any,
    setIndex: number,
    columnIndex: number,
    blockId: string,
    groupId: string,
    exerciceId: string,
    metricDefaultValue: any,
    unit: string,
    select?:boolean,
    minValue?: number,
    maxValue?:number,
    type? : string,
    format?: string,
    fixedValue?:boolean,
    regex?: string,
    metricId: string
    
}



function regexFromString(string:string) {
    const matchFlags = /^(.*)(\/([a-z]*))$/.exec(string)
    if (matchFlags && matchFlags.length >= 4)
        return new RegExp(matchFlags[1], matchFlags[3])

    return new RegExp(string)
}


/******** MAIN COMPONENT ******* */

export function SetTextField({ defaultValue, unit, blockId, exerciceId, groupId, setIndex, metricDefaultValue, columnIndex, minValue, maxValue, regex, type, fixedValue, metricId}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [value, setValue] = useState<number | string>("")
    const [inputIsReady, setInputIsReady] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)

    /************** VALEUR PAR DEFAULT ********* */

    useEffect(()=>{
        setValue(defaultValue) // Valeur par défaut
        setInputIsReady(true)
    },[])

    useEffect(()=>{
        if(inputIsReady) {
            setValue(metricDefaultValue)
        }
    },[metricDefaultValue])



    /************** CHANGEMENT DE LA PRESCRIPTION ********* */

    const debounceOnChange = useRef(debounce((data)=> {
        const {blockId, groupId, exerciceId, setIndex, columnIndex, value} = data
        dispatch(changePrescriptionValue({
            blockId, 
            groupId, 
            exerciceId, 
            setIndex, 
            columnIndex, 
            value
        }))
    },INPUT_DEBOUNCE_TIMER)).current

    
    /************** CHANGEMENT DE VALEUR ****** ********* */
    const onChangePrescriptionValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const numericRegex = /^[+-]?\d+(\.\d+)?$/
        let newValue:string = event.target.value

        // 1 - Remplacemet des espaces
        newValue = newValue.replace(/\s+/g, ''); 

        // 2 - Vérification du premier caractère
        if(newValue.length > 1 && !numericRegex.test(newValue.slice(0,1))){
            newValue = ""
            return
        }


        const regexNumber = /^(?!.*-)[0-9]*(?:[,.][0-9]{0,2})?$/

        // 3 - Si type === nombre mais pas la valeur, retour
        if(type === "number" && !regexNumber.test(newValue)){
            return
        }

   
        if(regex){
            const regexToTest = new RegExp(regexFromString(regex))
            if(!regexToTest.test(newValue)){
                return
            }
        }

        if(metricId === "63e36e9c5882116100ce5a1f" && newValue === "m"){
            setValue("max")
            debounceOnChange({value: "max", blockId, groupId, exerciceId, setIndex, columnIndex})
            return
        }


        setValue(newValue)
        debounceOnChange({value: newValue, blockId, groupId, exerciceId, setIndex, columnIndex})

        
    }


    const onChangeTimeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue:string = event.target.value
        setValue(newValue)
        debounceOnChange({value: newValue, blockId, groupId, exerciceId, setIndex, columnIndex})
    }


    const onBlurTime = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value
        const regex = /^[0-5]?\d:[0-5]\d$/
        if(!regex.test(value)){
            setValue("00:00")
            debounceOnChange({value: "00:00", blockId, groupId, exerciceId, setIndex, columnIndex})
        }
    }

    const onBlurReps = (e: React.FocusEvent<HTMLInputElement>) => {
        var newValue = e.target.value
        const blankRegex = /^\s*$/

         // SI REPS
         if(metricId === "63e36e9c5882116100ce5a1f"){
            const regexTiret = /-/;
            if(regexTiret.test(newValue)){
                const regex = /^\d{1,3}-\d{1,3}$/;
                if(!regex.test(newValue)){ // La valeur a un tiret, mais n'est pas complété
                    const regex2 = /^(\d{1,3})-/;
                    const match = newValue.match(regex2);
                    setValue(match[1])
                    debounceOnChange({value: match[1], blockId, groupId, exerciceId, setIndex, columnIndex})
                } else {
                   
                    const regex3 = /^(\d{1,3})-(\d{1,3})$/;
                    const match = newValue.match(regex3);
                    if(parseInt(match[1]) > parseInt(match[2])){ // La seconde valeur est plus petite que la première, erreur
                        setValue(match[1])
                        debounceOnChange({value: match[0], blockId, groupId, exerciceId, setIndex, columnIndex})
                    }
                    
                }
            }
        }

        // Tempos
        // ON Complète le tempo si il n'est pas sur 4 caractères
        if(metricId === "63e36e9c5882116100ce5a21" && newValue.length>0){
            if(newValue.length < 4){
                const diff = 4 - newValue.length
                for(var i = 0;  i< diff; i++){
                    newValue = newValue.toString() + 0
                }
                setValue(newValue)
                debounceOnChange({value: metricDefaultValue, blockId, groupId, exerciceId, setIndex, columnIndex})
                return

            }
        }


        // Champ vide, on remet la valeure par défaut
        if(blankRegex.test(newValue)){
            setValue(metricDefaultValue)
            debounceOnChange({value: metricDefaultValue, blockId, groupId, exerciceId, setIndex, columnIndex})
            return
        }
        
        

       
       
    }


    /********* JSX **********/

    if(type === "number" || type==="text"){
        return(
            <Box 
                sx={classes.prescriptionValue}
            >
                <TextField
                    type={type}
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    value={value}
                    sx={classes.prescriptionTextField}
                    onChange={onChangePrescriptionValue}
                    disabled={fixedValue}
                   
                    onFocus={event => {
                        event.target.select();
                    }}
                    onBlur={onBlurReps}
                    InputProps={{
                        endAdornment: metricId === "63e36e9c5882116100ce5a28" ||  metricId === "63e36e9c5882116100ce5a20"?
                        <InputAdornment position="end" sx={{color:"primary.main", fontWeight: 700}} disableTypography>
                            {mobile ? "" : "kg"}
                        </InputAdornment> : null
                    }}
                />

            </Box>
        )
    }else if(type==="time"){
        return(
            <Box 
                sx={classes.prescriptionValue}
            >
                <TextField
                    type={"text"}
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    value={value.toString()}
                    sx={classes.prescriptionTextField}
                    onChange={onChangeTimeValue}
                    onBlur={onBlurTime}
                    InputProps={{
                        endAdornment: 
                        <InputAdornment position="end" sx={{color:"primary.main", fontWeight: 700}} disableTypography>
                            {mobile ? "" : unit}
                        </InputAdornment>,
                        inputComponent: TimeMaskInput as any,
                    }}
                    onFocus={event => {
                        event.target.select();
                    }}
                    disabled={fixedValue}
                />
            </Box>
        )
    }
}


function areEqual(prev:Props,next:Props){
    return prev.blockId === next.blockId && 
    prev.unit === next.unit && 
    prev.blockId === next.blockId &&
    prev.exerciceId === next.exerciceId &&
    prev.groupId === next.groupId &&
    prev.setIndex === next.setIndex &&
    prev.metricDefaultValue === next.metricDefaultValue
}

export default React.memo(SetTextField, areEqual)