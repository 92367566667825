

export function calcMb(gender:'male' | 'female', size:number, weight:number, age:number){
    var metabolism = 0
    if(gender === "female"){
        const calc = 9.740  * weight + (184.96 * size) - 4.6756 * age + 655.0955
        //const calc = ((0.963*(Math.pow(weight,0.48))) * (Math.pow(size,0.5)) * (Math.pow(age,-0.13)))  * (1000/4.1855)
        metabolism = parseInt(calc.toFixed(1))
    }else{
        const calc = (13.7516 * weight) + (500.33 * size) - (6.7550 * age) + 66.479
        //const calc = ((1.083*(Math.pow(weight,0.48))) * (Math.pow(size,0.5)) * (Math.pow(age,-0.13))) * (1000/4.1855)
        metabolism = parseInt(calc.toFixed(1))
    }

    return metabolism
}