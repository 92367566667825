import { Button, CircularProgress } from "@mui/material"
import { SxProps } from '@mui/system';
import SecondaryButton from "components/atoms/Buttons/Secondary"

interface Props{
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    variant?: "contained" | "text" | "outlined",
    disabled?: boolean,
    loading?: boolean,
    children: React.ReactNode,
    rounded?: boolean,
    type?: "button" | "submit",
    theme?: "primary" | "secondary"
    fullWidth?:boolean,
    sx?: SxProps,
    disableElevation?: boolean,
    startIcon?: React.ReactNode
}

export default function ({onClick, variant, disabled, loading, children, rounded, type, fullWidth, sx, disableElevation, theme = "primary", startIcon}:Props){


    if(theme === "primary"){
        return(
            <Button 
                onClick={onClick} 
                variant={variant || "contained"} 
                disabled={disabled} 
                startIcon={loading? <CircularProgress size={16} sx={{color: variant === "text" ? "primary.main" :"white"}}/> : startIcon ? startIcon : ""}
                sx={{borderRadius: rounded ? "10px" : "0px", fontWeight:600,  backgroundColor: variant !== "text" ? "primary.main" : "transparent", ...sx,}}
                type={type ? type : "button"}
                fullWidth={fullWidth? true : false}
                disableElevation={true}
                
            >
                {children}
            </Button>
        )
    }else if(theme === "secondary"){
        return(
            <SecondaryButton 
                onClick={onClick} 
                disabled={disabled} 
                startIcon={loading? <CircularProgress size={16} sx={{color: "white"}}/> : startIcon ? startIcon : ""}
                fullWidth={fullWidth? true : false}
                disableElevation={disableElevation}
            >
                {children}
            </SecondaryButton>
        )
    }

    
}