import { Dialog, DialogActions, Button, DialogContent, useMediaQuery, Typography, Box, FormGroup, FormControlLabel, TextField, InputAdornment} from "@mui/material";
import { BORDER_COLOR, GOALS, MOBILE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import DialogTransition from "../DialogTransition";
import DialogAppBar from "../DialogAppBar";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import classes from "./styles";
import { useEffect, useMemo, useState } from "react";
import Switch from '@mui/material/Switch';
import { toast } from "react-toastify";
import MacrosListMobile from "components/organisms/MacrosListMobile";
import { updateClientData } from "api/clients";

interface Props{
    open: boolean,
    onClose: ()=>void,
    metabolism: number

}

export default function DialogCalories({open, onClose, metabolism}:Props){

    const client = useSelector((state:RootState)=> state.clients.profile)
    const dispatch = useDispatch<AppDispatch>()
    const mobile= useMediaQuery(MOBILE)
    const pending = useSelector((state:RootState)=> state.clients.requests.updateClientProfile === "pending")
    const [calcByTraener, setCalcByTraener] = useState<boolean>(true)
    const [coachValue, setCoachValue] = useState<number>(2500)
    const [proteins, setProteins] = useState<number>(25)
    const [carbs, setCarbs] = useState<number>(50)
    const [lipids, setLipids] = useState<number>(25)

    useEffect(()=>{
        if(open && client.energyIntake?.useCustom){
            setCalcByTraener(false)
            setCoachValue(client.energyIntake.kcal)
            setProteins(client.energyIntake.proteins)
            setCarbs(client.energyIntake.carbs)
            setLipids(client.energyIntake.lipids)
        }
    },[open])
    


    const onChangeSwitch =  (event: React.ChangeEvent<HTMLInputElement>) => {
        setCalcByTraener(!event.target.checked ? true : false)
      };
    
    const onSubmit = () => {
        if(!calcByTraener && (coachValue === 0 || !coachValue)){
            toast.error('Tu dois indiquer ton estimation')
            return
        }

        if(!calcByTraener && ((proteins + carbs + lipids) !== 100)){
            toast.error('Le total des macronutriments doit faire 100%')
            return
        }

    
        const data:{
            energyIntake : {
                useCustom: boolean,
                kcal: number,
                proteins: number,
                carbs: number,
                lipids: number
            }
        } = {
            energyIntake : {
                useCustom: calcByTraener ? false : true,
                kcal: coachValue,
                proteins,
                carbs,
                lipids
            }
        }

        dispatch(updateClientData({clientId: client._id, dataToUpdate: data})).unwrap().then((res)=>{
            if(res.client){
                onClose()
            }
        })


    }

    const GOAL_KCAL = useMemo(()=>{

        if(client?.goal){
            switch (client.goal) {
                case "bulking" : {
                    return 200
                }
                case "weightloss" : {
                    return -200
                }
               
                case "competition" : {
                    return 200
                }
                default: return 0
                
            }
        } else {
            return 0
        }
    },[client?.goal, metabolism])

    const CALORIES = useMemo(()=>{
        return client.nap ? Math.round((metabolism * (client.napDefinedBy === "coach" ? client.coachNap : client.nap?.value ? client.nap.value : 1)) + GOAL_KCAL) : "-"
    },[client])

    useEffect(()=>{
        if(!coachValue){
            setCoachValue(CALORIES !== "-" ? CALORIES as number : 2500)
        }
    },[CALORIES])

    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}
            fullScreen={mobile}
        >
            <DialogAppBar
                title={"Estimation de l'apport calorique"}
                onClose={onClose}
            />
            <DialogContent>

                <Typography
                    sx={{fontWeight: 400, marginBottom: 1.5}}
                >
                    Traener utilise la formule de Harris & Benedict pour calculer une estimation du besoin calorique journalier de ton client. En fonction de son objectif, l'application ajoute un déficit ou un surplus calorique.
                </Typography>

               
               
                <Box
                    sx={classes.row}
                >
                    <Typography>
                        Métabolisme de base
                    </Typography>
                    <Typography>
                        {metabolism? metabolism + " kcal" : "Manque de données"}
                    </Typography>
                </Box>

                <Box
                    sx={classes.row}
                >
                    <Typography>
                        NAP
                    </Typography>
                    <Typography>
                        {client.napDefinedBy === "coach" ? client.coachNap : client.nap?.value ? client.nap.value : "-"}
                    </Typography>
                </Box>

                <Box>

                </Box>

                <Box
                    sx={[classes.row, , {borderBottom: `solid 1px ${BORDER_COLOR}`, paddingBottom: 3, marginBottom: 3}]}
                >
                    <Typography>
                        Objectif
                    </Typography>
                    <Typography>
                        {client?.goal ? GOALS.find((elem)=> elem.en === client.goal).fr : "-"}
                    </Typography>
                </Box>

                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Switch 
                                onChange={onChangeSwitch}
                                checked={!calcByTraener}
                            />
                        } 
                        label={calcByTraener? "Calculé par Traener" : "Calculé par le coach"} 
                        sx={{marginBottom: 2, marginTop: 2}}
                    />
          
                </FormGroup>
                
                {calcByTraener && (
                    <>
                        <Box
                            sx={[classes.row]}
                        >
                            <Typography>
                                {(GOAL_KCAL > 0) ? "Surplus calorique" : GOAL_KCAL < 0 ? "Déficit calorique" : "Déficit/surplus calorique"}
                            </Typography>
                            <Typography>
                                {GOAL_KCAL} kcal
                            </Typography>
                        </Box>
                        <Box
                            sx={classes.row}
                        >
                            <Typography>
                                Estimation totale
                            </Typography>
                            <Typography
                                sx={{fontWeight: 600}}
                            >
                                {CALORIES !== 0 ? CALORIES + " kcal" : "-"}
                            </Typography>
                        </Box>
                        <Box
                            sx={classes.row}
                        >
                            <Typography>
                               Protéines
                            </Typography>
                            <Typography>
                                {CALORIES ? `25%` : "-"}
                            </Typography>
                        </Box>
                        <Box
                            sx={classes.row}
                        >
                            <Typography>
                                Lipides
                            </Typography>
                            <Typography>
                                {CALORIES ? `25%` : "-"}
                            </Typography>
                        </Box>
                        <Box
                            sx={classes.row}
                        >
                            <Typography>
                                Glucides
                            </Typography>
                            <Typography>
                                {CALORIES ? `50%` : "-"}
                            </Typography>
                        </Box>


                        
                    </>
                )}


                {!calcByTraener && (
                    <>
                    <Box
                        sx={classes.row}
                    >
                        <Typography>
                                Ton estimation
                        </Typography>
                        <TextField 
                            value={coachValue}
                            type={"number"}
                            size="small"
                            onChange={(e)=> setCoachValue(Math.round(parseFloat(e.target.value)))}
                            sx={{maxWidth: 130}}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        kcal
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box
                        sx={classes.row}
                    >
                        <Typography>
                                Protéines
                        </Typography>
                        <TextField 
                            value={proteins}
                            type={"number"}
                            size="small"
                            onChange={(e)=> setProteins(Math.round(parseFloat(e.target.value)))}
                            sx={{maxWidth: 130}}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box
                        sx={classes.row}
                    >
                        <Typography>
                                Glucides
                        </Typography>
                        <TextField 
                            value={carbs}
                            type={"number"}
                            size="small"
                            onChange={(e)=> setCarbs(Math.round(parseFloat(e.target.value)))}
                            sx={{maxWidth: 130}}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box
                        sx={classes.row}
                    >
                        <Typography>
                                Lipides
                        </Typography>
                        <TextField 
                            value={lipids}
                            type={"number"}
                            size="small"
                            onChange={(e)=> setLipids(Math.round(parseFloat(e.target.value)))}
                            sx={{maxWidth: 130}}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                            }}
                        />
                    </Box>

                   
                    </>
                )}

                    <MacrosListMobile 
                        macros={{
                            proteins: !calcByTraener && (proteins && coachValue) ? Math.round(coachValue * (proteins/100) /4) : Number(CALORIES) ? Math.round(CALORIES as number * (0.25) /4) : 0,
                            carbs: !calcByTraener && (carbs && coachValue) ? Math.round(coachValue * (carbs/100) /4) :  Number(CALORIES) ? Math.round(CALORIES as number * (0.50) /4) : 0,
                            lipids: !calcByTraener && (lipids && coachValue) ? Math.round(coachValue * (lipids/100) /9) :  Number(CALORIES) ? Math.round(CALORIES as number * (0.25) /9): 0,
                            kcal: !calcByTraener && (coachValue) ? coachValue : Number(CALORIES) ? CALORIES as number : 0
                        }}
                    />
              
                
            </DialogContent>

            <DialogActions>
                {!mobile && (
                    <Button
                        onClick={onClose}
                        disabled={pending}
                    >
                        Fermer
                    </Button>
                )}
                <LoadingButton 
                    variant="contained" 
                    onClick={onSubmit} 
                    loading={pending} 
                    disabled={pending}
                >
                    Confirmer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}