import { Dialog, DialogActions, Button, DialogContent, Box, Typography, useMediaQuery, Grid, FormGroup, Switch, FormControlLabel, InputAdornment } from "@mui/material";
import { fitnessLevels,MOBILE, walkingLevels, workingLevels } from "utils/constants";
import classes from "./styles";
import { RadioButtonUnchecked } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import DialogTransition from "../DialogTransition";
import DialogAppBar from "../DialogAppBar";
import defineNAPLevel from "function/defineNAPLevel";
import { theme } from "utils/theme";
import TitleTextField from "../TitleTextField";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateClientData } from "api/clients";
import { toast } from "react-toastify";


interface Props{
    open: boolean,
    onClose: ()=>void,
}




export default function DialogNap({open, onClose}:Props){

    const client = useSelector((state:RootState)=> state.clients.profile)
    const dispatch = useDispatch<AppDispatch>()
    const [coachNap, setCoachNap] = useState<string | "">("1")
    const mobile= useMediaQuery(MOBILE)
    const [calcByTraener, setCalcByTraener] = useState<boolean>(true)
    const pending = useSelector((state:RootState) => state.clients.requests.updateClientProfile === "pending")

    const onChangeSwitch =  (event: React.ChangeEvent<HTMLInputElement>) => {
        setCalcByTraener(!event.target.checked ? true: false)
    };

    

    useEffect(()=>{
        if(client.nap?.value && (!client.napDefinedBy || client.napDefinedBy === "user")){
            setCoachNap(client.nap?.value.toString() || "1")
        } else if(client.coachNap && client.napDefinedBy === "coach"){
            setCoachNap(client.coachNap.toString())
        }
    },[client])


    useEffect(()=>{
        if(client.napDefinedBy === "coach"){
            setCalcByTraener(false)
        }
    },[open])


    const onChangeNapValue = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const value = e.target.value
        const regex = /^(|([1-2](\.\d)?|3(\.0)?))$/
        if(value.length === 2 && Number(value[0]) && e.target.value[1] === "."){
            setCoachNap(value)
        }
        else if(regex.test(e.target.value) && e.target.value !== "" && e.target.value.length < 4){
            const formated = e.target.value.replace(',',".")
            setCoachNap(formated)
            return
        } else if(e.target.value === ""){
            setCoachNap("")
            return
        } else {
            return
        }
       
    }


    const onSubmit = () => {
        const data:{
            napDefinedBy: "user" | "coach",
            coachNap: number,
        } = {
            napDefinedBy: calcByTraener ? "user" : "coach",
            coachNap : calcByTraener ? client.nap?.value || 1 : parseFloat(coachNap)
        }

        dispatch(updateClientData({clientId: client._id, dataToUpdate: data})).unwrap().then((res)=>{
            if(res.client){
                onClose()
            }
        })
    }


    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth={"md"}
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}
            fullScreen={mobile}
        >
            <DialogAppBar
                title={"Niveau d'activité physique"}
                onClose={onClose}
            />
            <DialogContent>

            <Typography>
                Le niveau d'activité physique est estimé par Traener lorsque ton client a complété le questionnaire d'inscription sur l'application mobile.
            </Typography>

            <Grid container>
                <Grid item xs={12} sm={12} md={12}>

                    <FormGroup
                        sx={{width: 250}}
                    >
                        <FormControlLabel 
                            control={
                                <Switch 
                                    onChange={onChangeSwitch}
                                    checked={!calcByTraener}
                                />
                            } 
                            label={calcByTraener ? "Calculé par Traener" : "Calculé par le coach"} 
                            sx={{marginBottom: 2, marginTop: 2}}
                        />
                    </FormGroup>

                    {calcByTraener && (
                        <>
                            <Typography
                                sx={{fontSize: 18, fontWeight: 500, marginBottom: 1, marginTop: 3}}
                            >
                                Estimé via le questionnaire client :
                            </Typography>

                            <Typography sx={{fontSize: 22, fontWeight: 600, marginBottom: 4}}>
                                {client.nap &&  `${defineNAPLevel(client.nap.value)} - ${client.nap.value} `}
                                {!client.nap && (
                                <Typography>
                                    Ton client n'a pas encore complété le formulaire
                                </Typography>
                                )}
                            </Typography>
                            </>
                    )}

                    {!calcByTraener && (
                        <>
                        <Typography
                            sx={{fontSize: 18, fontWeight: 500, marginBottom: 0.5, marginTop: 3}}
                        >
                            Ton estimation du NAP :
                        </Typography>
                        <Box
                            sx={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom: 4,}}
                        >
                            <Typography sx={{fontSize: 22, fontWeight: 600, marginRight: 1}}>
                                {coachNap !== "" ?  `${defineNAPLevel(parseFloat(coachNap))} - ` : "Sédentaire"} 
                            </Typography>
                            <TitleTextField
                                onChange={onChangeNapValue}
                                value={coachNap}
                                variant="standard"
                                sx={{
                                    marginBottom: "30px !important",
                                    marginTop:0,
                                    height: 10,
                                    "& .MuiInput-root": {
                                        fontSize: theme.typography.pxToRem(22),
                                        fontWeight: 600,
                                        fieldset: {
                                            border: 0,
                                            //borderBottom: "1px dashed",

                                        },
                                        
                                    },
                                }}
                            />
                        </Box>
                    </>
                    )}

                   {client.nap?.value && (
                    <>
                    <Box
                        sx={{marginBottom: 3}}
                    >
                        <Typography
                            sx={classes.question}
                        >
                            1 - As-tu un métier sédentaire ?
                        </Typography>

                        <Box>
                        {workingLevels.map((elem, index)=> {

                            const current = elem.description === client.nap?.details?.working

                            if(current){
                                return(
                                    <Box
                                        key={index}
                                        sx={{display:"flex", flexDirection:"row",paddingTop: 1.5, paddingBottom: 1.5}}
                                    >
                                        {/*current && ( <RadioButtonChecked sx={{color: theme.palette.primary.main}} />)*/}
                                        {!current && ( <RadioButtonUnchecked sx={{opacity: 0.7}} />)}
                                        <Typography
                                                sx={{opacity: current? 1 : 0.7 }}
                                            >
                                            {elem.description}
                                        </Typography>
                                    </Box>
                                )
                            }
                        })}
                        </Box>
                    </Box>


                    <Box
                        sx={{marginBottom: 3}}
                    >
                        
                            <Typography
                                sx={classes.question}
                            >
                                2 - Combien de temps marches-tu par jour ?
                            </Typography>

                            <Box>
                            {walkingLevels.map((elem, index)=> {

                                const current = elem.description === client.nap?.details?.walking

                                if(current){
                                return(
                                    <Box
                                        key={index}
                                        sx={{display:"flex", flexDirection:"row",paddingTop: 1.5, paddingBottom: 1.5}}
                                    >
                                        
                                        {!current && ( <RadioButtonUnchecked sx={{opacity: 0.7}}/>)}
                                        <Typography
                                            sx={{opacity: current? 1 : 0.7 }}
                                        >
                                            {elem.description}
                                        </Typography>
                            </Box>
                                )
                            }
                            })}
                        </Box>
                    </Box>

                    <Box
                        sx={{marginBottom: 3}}
                    >
                        
                            <Typography
                                sx={classes.question}
                            >
                                3 - Combien de fois comptes-tu t'entrainer par semaine ? ?
                            </Typography>

                            <Box>
                            {fitnessLevels.map((elem, index)=> {

                                const current = elem.description === client.nap?.details?.workout
                                if(current){
                                return(
                                    <Box
                                        key={index}
                                        sx={{display:"flex", flexDirection:"row",paddingTop: 1.5, paddingBottom: 1.5}}
                                    >
                                       
                                        {!current && ( <RadioButtonUnchecked sx={{opacity: 0.7}}/>)}
                                        <Typography
                                            sx={{opacity: current? 1 : 0.7 }}
                                        >
                                            {elem.description}
                                        </Typography>
                                    </Box>
                                )
                            }
                            })}
                        </Box>
                    </Box>
                    </>
                   )}
                </Grid>
            </Grid>

                
                
            </DialogContent>
            <DialogActions>
                {!mobile && (
                    <Button
                        onClick={onClose}
                        disabled={pending}
                    >
                        Fermer
                    </Button>
                )}

                <LoadingButton 
                    variant="contained" 
                    onClick={onSubmit} 
                    loading={pending} 
                    disabled={pending}
                >
                    Confirmer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}