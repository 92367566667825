import { MACROS_COLORS } from "utils/constants"

const classes = {

    mealMacros:{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        //marginBottom: 3,
        //width: "auto",
        justifyContent:"space-between",
        marginTop: 3 
    },

    macro: {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        position: "relative",
        alingItems:"center",
        width: "100%",
        marginBottom: 2,
        alignItems:"center",
    },

    macroLabel : {
        fontSize: "0.9rem",
        marginTop: {xs:0, sm: 0},
        textAlign:"start",
        fontWeight: 400,
        width: 200,
        
    },


    macroProgress : {
        display:"flex",
        flexDirection: {xs: "column", xl: "row"},
        alignSelf: "flex-start",
        flex: 1,
        alignItems:{xs: "flex-start" , xl:"center"}
    },

    macroProgressLabel : {
        marginRight: {xs: 0, sm: 2},
        width: 150,

        display:"flex",
        flexDirection: "row",
        marginBottom: {xs: 0.5, xl: 0}
    },

    proteins:{
        color: MACROS_COLORS.proteins,
        //backgroundColor: "#e6fbfb",

    },
    carbs:{
        color: MACROS_COLORS.carbs,
        //backgroundColor: "#fce9ff",
    },
    lipids:{
        color: MACROS_COLORS.lipids,
        //backgroundColor: "#e6f2ff",
    },
    energie:{
        color: MACROS_COLORS.calories,
        //backgroundColor: "#ffe2d0",

    },
    fibers:{
        color:"#a27806",
        //backgroundColor: "#fff0c5",

    }
}


export default classes