import { useEffect, useMemo, useState } from "react";
// REDUX
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// MUI
import { Box, Typography, Grid, Select, MenuItem, SelectChangeEvent, useMediaQuery} from "@mui/material";
// APP
import { AppDispatch, RootState } from "app/store";
// APIS
import { getClientsHistory} from "api/clients";
// SHARED COMPONENTS
import Loader from "components/molecules/Loader";
// STYLES
import classes from "./styles";
// LOcal component
import { handleSubscriptionPresentation, selectDrawerItem } from "features/appSlice";
import EventCard from "components/molecules/EventCard";
import { HistoryEventDetails } from "interfaces/User";
import { addDays, format, isSameDay } from "date-fns";
import EmptySearch from "components/molecules/EmptySearch";
import MainContent from "components/molecules/MainContent";
import {FilterList, Info} from "@mui/icons-material";
import {MOBILE } from "utils/constants";
import CustomIconButton from "components/atoms/Buttons/IconButton";
import EventsFilters from "components/organisms/EventsFilters";
import { fr } from "date-fns/locale";
import Navbar from "components/organisms/NavBar";


/***************** 
 * MAIN COMPONENT 
 * *************** */

export default function Home(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data)
    const history:HistoryEventDetails[] = useSelector((state:RootState) => state.dashboard.history)
    const pending = useSelector((state:RootState)=> state.dashboard.requests.get === "pending")
    const openDemoUser:boolean = useSelector((state:RootState) => state.app.openUserDemoPresentation, shallowEqual)
    const [range, setRange] = useState<{start:Date, end: Date}>({start: addDays(new Date(), -3), end: new Date()})
    const [filter, setFilter] = useState<string>('3')
    const mobile = useMediaQuery(MOBILE)
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const filters = useSelector((state:RootState)=> state.dashboard.filters)



    /********** CREATION DE L'HISTORIQUE ******** */

    useEffect(()=>{
        dispatch(selectDrawerItem(0))
    },[dispatch])


    const handleChangeFilter = (event: SelectChangeEvent) =>{
        setFilter(event.target.value as string)
    }


    /********************************
     * Récupération de l'historique
     ********************************/

    useEffect(()=>{
        const startDate = addDays(new Date(range.end), -(parseInt(filter)+1))
        dispatch(getClientsHistory({start: startDate, end: new Date()}))
    },[filter])


    /***** MISE A JOUR DE L'HISTORIQUE SUITE A L'AJOUT DE DEMO USER ***** */
    useEffect(()=>{
        if(openDemoUser){
            const startDate = addDays(new Date(range.end), -parseInt(filter))
            dispatch(getClientsHistory({start: startDate, end: new Date()}))
        }
    },[openDemoUser])


    /*********************** 
     * FILTRAGE DES EVENTS 
     * *********************/

    const filteredEvents:HistoryEventDetails[] = useMemo(() => {
        
        var workouts:HistoryEventDetails[] = []
        var checkups:HistoryEventDetails[] = []
        var measures:HistoryEventDetails[] = []
        var meals:HistoryEventDetails[] = []

    
        workouts = history.filter((elem)=> {
            return elem.action === "workout"
        })
        

       
        checkups = history.filter((elem)=> {
            return elem.action === "questionnaire"
        })
        

       
        measures = history.filter((elem)=> {
            return elem.action === "measure"
        })


        meals = history.filter((elem)=> {
            return elem.action === "meal"
        })
        

        const events = workouts?.concat(checkups).concat(measures).concat(meals)

        const sortedEvents = [...events.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())]

        // Filtrage selon les choix users
        var filtered:HistoryEventDetails[] = []

        if(filters.length === 0){
            filtered = [...sortedEvents]
        } else {

            filters.forEach((elem)=> {
                filtered = filtered.concat([...sortedEvents].filter((event) => event.action === elem))
            })
        }

        return filtered


    },[history, filters])


    /**************************************** 
     * REGROUPEMENT DES EVENEMENTS PAR JOUR
     * ***************************************/


    const SORTED_EVENTS_BY_DAY = useMemo(()=>{

        const eventsByDate:{date: Date, events: HistoryEventDetails[]}[] = []
        const parsedFilter = parseInt(filter)
        Array.from(Array(parsedFilter)).forEach((_, index)=> {
            const date = addDays(new Date(range.end), -index)
            const eventsFound = filteredEvents.filter((event)=> isSameDay(new Date(event.date), date))
            if(eventsFound.length>0){
                eventsByDate.push({
                    date,
                    events: eventsFound
                })
            }
        })

        return eventsByDate
    },[filteredEvents, filter])



    useEffect(()=>{
        if(!user.trialIsDone){
            setTimeout(()=>{
                dispatch(handleSubscriptionPresentation({open:true}))
            },1000)
            
        }
    },[user])






    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget)
    }


    return(
        <>

                               
        

            {/**************
             * NAVIGATION
             ***************/}

            <Navbar
                pageTitle="Tableau de bord"
                expanded={false}
                showInstagram={true}
                
            />


            {/**************
             * CONTENT
             ***************/}


            <MainContent
                header="simple"
            >   
                {mobile && (
                    <Box
                        sx={classes.mobileMessage}
                    >
                        <Info 
                            sx={{marginRight: 2,
                                color: "#549e9e"
                            }}
                        />
                        <Typography>
                            Traener est optimisé pour une utilisation sur tablette ou PC.
                        </Typography>
                    </Box>
                )}
                <Grid 
                    container 
                    justifyContent="center" 
                    spacing={0}
                >
                    <Grid 
                        item xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >
                        <Box 
                            sx={classes.historyContainer}
                        >

                        {/********* EVENTS *********/}

                      
                            <Box 
                                sx={{display:"flex", flexDirection: "column", flex: 1}}
                            >
                                <Box 
                                    sx={classes.feedHeader}
                                >

                                    <Typography 
                                        sx={classes.sectionTitle}
                                    >
                                        Activité de tes clients
                                    </Typography>



                                    <Box
                                        sx={{display:"flex", flexDirection: "row", alignItems:"center"}}
                                    >

                                        {/*********************
                                         * BOUTON DE FILTRE
                                         *********************/}

                                        <CustomIconButton
                                            Icon={FilterList}
                                            onClick={onOpenFilterList}
                                            white={true}
                                            darkerBorder={true}
                                            marginRight={1}
                                            badge={filters.length}
                                            showLabel={true}
                                        />

                                        {/*********************
                                         * LISTE DES FILTRES
                                         *********************/}

                                        <EventsFilters
                                            open={Boolean(anchorFilterList)}
                                            onClose={()=>setAnchorFilterList(null)}
                                            anchorEl={anchorFilterList}
                                         />


                                        {/*********************
                                         * SELECTION DES JOURS
                                         *********************/}

                                        <Select 
                                            value={filter}
                                            size="small"
                                            onChange={handleChangeFilter}
                                            sx={{backgroundColor: "white"}}
                                            MenuProps={{
                                                disableScrollLock: true
                                            }}
                                            inputProps={{
                                                sx:{
                                                    border: `none`,
                                                }
                                            }}
                                        >
                                            <MenuItem value={3}>3 derniers jours</MenuItem>
                                            <MenuItem value={7}>7 derniers jours</MenuItem>
                                            <MenuItem value={15}>15 derniers jours</MenuItem>
                                        </Select>
                                    </Box>

                                </Box>


                                {/******** LOADER ****** */}

                                {pending && (
                                    <Loader />
                                )}

                                {/******** LISTE DES EVENNEMENTS ****** */}
                                {!pending && SORTED_EVENTS_BY_DAY?.map((eventDate, index) => {
                                    return(
                                        <Box
                                            key={index}
                                            sx={classes.blockEvents}
                                        >
                                            <Typography
                                                sx={classes.date}
                                            >
                                                {isSameDay(eventDate.date, new Date()) ? "Aujourd'hui" : format(eventDate.date, "eeee dd MMM", {locale: fr})}
                                            </Typography>

                                            <Box>
                                            {!pending && eventDate.events?.map((event, historyIndex:number) => {
                                                return(
                                                    <Box 
                                                        key={historyIndex} 
                                                        sx={classes.eventContainer}
                                                    >
                                                        <Box 
                                                            sx={classes.eventsTimeline}
                                                        />
                                                        <EventCard 
                                                            event={event} 
                                                        />
                                                    </Box>
                                                )
                                            })}
                                            </Box>
                                        </Box>
                                    )
                                })}
                                


                                {/***** AUCUN EVENEMENT ***** */}
                                {!pending && SORTED_EVENTS_BY_DAY?.length ===0 && (
                                    <EmptySearch
                                        description={`Aucune activité de tes clients sur les ${filter} derniers jours`}
                                    />
                                    
                                )}

                            </Box>  
                 
                                
                    
                        </Box>
                    </Grid>
                </Grid>
            </MainContent>

           

           
        </>
    )
}