


export default function defineNAPLevel(NAP:number){
    if(NAP <= 1.4){
        return "Sédentaire"
    } else if(NAP > 1.4 && NAP < 1.6){
        return "Un peu actif"
    }  else if(NAP > 1.5 && NAP < 1.9){
        return "Actif"
    }else if(NAP > 1.8){
        return "Très actif"
    }
}