import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from "@mui/material";
import DialogAppBar from "components/molecules/DialogAppBar";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Meal from "interfaces/Meal";
import MacrosListMobile from "../MacrosListMobile";
import classes from "./styles";
import { DEFAULT_PORTIONS, MOBILE } from "utils/constants"

interface Props{
    open: boolean,
    onClose: ()=>void,
    meals: Meal[],
    date:Date
}

export default function DialogDayMeals({open, onClose, meals, date}:Props){

    const mobile = useMediaQuery(MOBILE)

    return(
        <Dialog
            open={open}
            fullScreen={mobile}
            fullWidth={true}
            maxWidth={"sm"}
        >

            <DialogAppBar
                title={date ? `${format(date, "eee dd MMM yyyy",{locale: fr})}` : ""}
                onClose={onClose}
            />
            <DialogContent>
                <MacrosListMobile
                    meals={meals}
                />

                {meals.map((meal, index)=> {
                    return(
                        <Box
                            key={index}
                            sx={{marginBottom: index !== meals.length -1 ? 3 : 0}}
                        >
                            <Typography
                                sx={classes.mealTitle}
                            >
                               {meal.time} - {meal.name}
                            </Typography>

                            {meal.content.map((elem,index)=> {
                                if(elem.type === "food"){

                                const foodPortions = [...DEFAULT_PORTIONS].concat(elem.food.portions)

                                    return(
                                        <Box
                                            key={index}
                                            sx={classes.mealContent}
                                        >
                                            <Box sx={classes.foodInfo}>
                                                <Typography
                                                    style={classes.mealContentTitle}
                                                >
                                                    {elem.food.name.fr}
                                                </Typography>
                                                <Typography>
                                                    {elem.portion} {foodPortions.find((portion) => portion.label.fr === elem.label).label.fr}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                } else if(elem.type === "scanned"){
                                    return(
                                        <Box
                                            key={index}
                                            sx={classes.mealContent}
                                        >

                                        
                                            <Box sx={classes.foodInfo}>
                                                <Typography
                                                    style={classes.mealContentTitle}
                                                >
                                                    {elem.foodScanned.name.fr}
                                                </Typography>
                                                <Typography>
                                                    {elem.portion} {elem.label}
                                                </Typography>
                                                <Typography
                                                    style={classes.brands}
                                                >
                                                    Marque : {elem.foodScanned.brands}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                } else if(elem.type === "recipe"){

                                    return(
                                        <Box
                                            key={index}
                                            sx={classes.mealContent}
                                        >
                                            <Box sx={classes.foodInfo}>
                                                <Typography
                                                    style={classes.mealContentTitle}
                                                >
                                                    {elem.recipe.name.fr}
                                                </Typography>
                                                <Typography>
                                                    {elem.portion} portion{elem.portion > 1 ?  "s" :""}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                }
                })}
                        </Box>
                    )
                })}
            </DialogContent>
            {!mobile && (
                <DialogActions>
                    <Button
                        onClick={()=> onClose()}
                    >
                        Fermer
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}