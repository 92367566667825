import { Box,IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material"
import MoreButton, { MenuElem } from "components/atoms/Buttons/MoreButton"
import Exercice from "interfaces/Exercice"
import classes from "./styles"
import { useMemo } from "react"
import { Add, CheckCircle, Delete, DesignServices, SwapHoriz } from "@mui/icons-material"
import RowCard from "../../atoms/Box/RowCard"
import RowCardTitle from "components/atoms/Typography/RowCardTitle"
import { EXERCICE_GROUPS_TRAD, MOBILE } from "utils/constants"




interface Props{
    exercice: Exercice,
    onOpenDeleteExerciceDialog?: (exercice:Exercice)=> ()=> void,
    onClick?: (exercice:Exercice)=>void,
    addExercice?: (exercice: Exercice) => (e: React.SyntheticEvent) => void, // Add exercice to workout
    onSwapExercice?: (exercice: Exercice) => (e: React.SyntheticEvent) => void, // Replace exercice
    added?: boolean
}


export default function ListItemExercice({exercice, onOpenDeleteExerciceDialog, onClick, addExercice, onSwapExercice, added}:Props){

    const mobile = useMediaQuery(MOBILE)

    {/******* MENU MORE ****** */}
    const MENU:MenuElem[] = useMemo(()=>{
        if(onOpenDeleteExerciceDialog){
            const menu = [{
                label: "Supprimer",
                icon: <Delete />,
                callback: onOpenDeleteExerciceDialog(exercice)
            }]

            return menu
        }else {
            return []
        }
    },[exercice])


    /***** CLICK SUR L'EXERCICE ***** */
    const onClickRow = () =>{
        if(onClick){
            onClick(exercice)
        }
    }


    return(

        <RowCard
            onClick={onClickRow}>
                
            <Box 
                sx={classes.row}
            >

                
                <Box 
                    sx={[
                        classes.imageContainer, 
                        exercice.draft && {
                            backgroundColor: "#bed3eb"
                        }]}
                >

                    {(exercice.draft && !exercice.image) && (
                        <Box
                            sx={classes.draftBoxContainer}
                        >
                            
                            <DesignServices
                                sx={{color: "white"}}
                            />
                            <Typography 
                                sx={classes.draftBoxLabel}
                            >
                                Brouillon
                            </Typography>
                            
                        </Box>
                    )}

                    { /******************** 
                     * IMAGE DE L'EXERCICE 
                     * ***************** */}

                    {(exercice.image?.url) && (
                        <Box 
                            component={"img"} 
                            src={exercice.image?.url} 
                            sx={classes.image}
                        />
                    )}

                    { /***** NOM DE L'EXERCICE ***** */}
                    {(!exercice.draft && !exercice.image?.url) && (
                        <Box>
                            {exercice.name.fr[0]}{exercice.name.fr[1]}
                        </Box>
                    )}

                </Box>

                { /********************* 
                 * INFOS DE L'EXERCICE 
                 * ********************/}

                <Box 
                    sx={{display:"flex", flexShrink: 1, flexDirection: "column"}}
                >
                    <Box 
                        sx={classes.exerciceInfos}
                    >
                        <RowCardTitle>
                            {exercice.name.fr !== "" ? exercice.name.fr : "Aucun nom"}
                        </RowCardTitle>
                    </Box>
                    
                    <Box sx={classes.infos}>

                        {/*********** 
                         * MUSCLES 
                         * ******* */}
                      
                        {!mobile && !exercice.draft && exercice.groups.map((group, groupIndex)=> {
                            return(
                                <Box
                                    sx={classes.muscle}
                                    key={groupIndex}
                                >
                                    {EXERCICE_GROUPS_TRAD[group].fr}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>

            { /***** MORE BUTTON ***** */}
            
                <Box 
                    sx={classes.actions}
                >
                    
                    {(added) && (
                        <Tooltip title="Ajouté au bloc de séance">
                            <CheckCircle color="primary" sx={{marginRight: 2}}/>
                        </Tooltip>
                    )}

                    {(!exercice.appExercice && onOpenDeleteExerciceDialog) && (
                        <MoreButton 
                            menuList={MENU}
                        />
                    )}

                    {(addExercice && !onSwapExercice) && (
                        <IconButton onClick={addExercice(exercice)} sx={classes.button}>
                            <Add />
                        </IconButton>
                    )}

                    {onSwapExercice && (
                        <IconButton onClick={onSwapExercice(exercice)} sx={classes.button}>
                            <SwapHoriz />
                        </IconButton>
                    )}
                </Box>
        


            
        </RowCard>

    )
}