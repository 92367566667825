import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "app/store";
import { getExerciceDetails } from "function/getExerciceDetails";
import { WorkoutExercice } from "interfaces/Workout";
import { useSelector, useDispatch} from "react-redux";
import React, {useEffect, useState} from "react"
import {onChangeExerciceNote} from "features/workoutSlice"
import { toast } from "react-toastify";
import { MOBILE } from "utils/constants";
import { Close } from "@mui/icons-material";
import classes from "./styles";


interface Props{
    open: boolean,
    onClose: ()=>void,
    exerciceName: string,
    blockId: string,
    exerciceId: string,
    groupId: string
}


const noteSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.note
    }
)

export function DialogExerciceNote({open, onClose, exerciceName, groupId, exerciceId, blockId}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const note = useSelector((state:RootState)=> noteSelector(state,groupId, exerciceId, blockId))
    const [value,setValue] = useState<string>('')
    const mobile = useMediaQuery(MOBILE)

    const onChangeValue = (e:any) => {
        setValue(e.target.value)
    }


    useEffect(()=>{
        if(open){
            setValue(note? note : "")
        }
    },[open])


    const onSubmit = () => {
        dispatch(onChangeExerciceNote({groupId, exerciceId, blockId, note:value}))
        toast.success(value === "" ? "Note supprimée" : 'Note ajoutée')
        onClose()
    }


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={'xs'}
            disableScrollLock
        >
            <DialogTitle>
                
                Ajouter une note
             
            </DialogTitle>
            <DialogContent>
                <TextField
                    multiline={true}
                    rows={4}
                    fullWidth
                    value={value}
                    onChange={onChangeValue}
                    placeholder={`Note concernant l'exercice ${exerciceName}`}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Annuler
                </Button>
                <Button onClick={onSubmit} variant="contained">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
}


function areEqual(prev:Props, next:Props){
    return prev.blockId === next.blockId && prev.groupId === next.groupId && prev.open === next.open
}

export default React.memo(DialogExerciceNote, areEqual)