import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import emptySearchAnimation from "../../../lottie/emptySearch.json";
import classes from "./styles";


interface Props{
    description: string,
    title?: string,
    animation?: any,
    width? : number
}

export default function EmptySearch({description, title, animation, width}:Props){

    return(
        <Box sx={{width: '100%', display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>

            <Box
                sx={{
                    display:"flex",
                    width: width ? width : {xs: 250, sm:300},
                    flexDirection: "column"

                }}>

                <Lottie 
                animationData={animation? animation : emptySearchAnimation} 
                loop={true} 
     
            />
            
            </Box>

            <Box>
            <Typography sx={classes.oops}>
                {title? title : "Aucune activité"}
            </Typography>
            <Typography sx={classes.description}>
                {description}
            </Typography>
            </Box>
        </Box>
    )
}