// MUI
import { AssignmentOutlined, Event, ListAlt, Restaurant,} from "@mui/icons-material"
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery } from "@mui/material"
// REDUX
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// INTERFACES
import Checkup from "interfaces/Checkup"
import Workout from "interfaces/Workout"
import Program from "interfaces/Program"
import { AppDispatch, RootState } from "app/store"
// Atoms
import PageTitle from "components/atoms/Typography/PageTitle"
// Molecules
import Tabs from "components/molecules/Tabs"
// Organisms
import DialogCreateWorkout from "components/organisms/DialogCreateWorkout"
import DialogCreateCheckup from "components/organisms/DialogCreateCheckup"
import DialogCreateProgram from "components/organisms/DialogCreateProgram"
// REDUCERS
import {selectDrawerItem } from "features/appSlice"
import { useLocation } from "react-router-dom"
import { resetSearchCheckups, searchCheckupsTemplates } from "features/checkupSlice"
import { resetSearchWorkouts, searchWorkoutsTemplates } from "features/workoutSlice"
import { resetSearchPrograms, searchProgramsTemplates } from "features/programSlice"
import Header from "components/molecules/Header"
import MainContent from "components/molecules/MainContent"
import TemplatePrograms from "components/organisms/TemplatesPrograms"
import TemplateWorkouts from "components/organisms/TemplateWorkouts"
import TemplateCheckup from "components/organisms/TemplateCheckups"
import { DESKTOP, MOBILE, TABLET } from "utils/constants"
import EmptySearch from "components/molecules/EmptySearch"
import classes from "./styles"
import Toolbar from "components/molecules/Toolbar"
import MealPlan from "interfaces/MealPlan"
import DialogCreateMealTemplate from "components/organisms/DialogCreateMealTemplate"
import TemplateMealPlans from "components/organisms/TemplateMealPlans"
import { resetSearchMealPlans, searchMealPlansTemplates } from "features/mealPlanSlice"
import FixedSearchHeader from "components/molecules/FixedSearchHeader"
import Navbar from "components/organisms/NavBar"




/******* MAIN COMPONENT ********* */

export default function Templates(){

    // Local
    const location = useLocation<{tabs: number}>()
    const dispatch = useDispatch<AppDispatch>()
    const allPrograms:Program[] = useSelector((state:RootState) => state.programs.templates.list)
    const allWorkouts:Workout[] = useSelector((state:RootState) => state.workouts.templates.list)
    const allCheckups:Checkup[] = useSelector((state:RootState) => state.checkups.templates.list)
    const allMealPlans:MealPlan[] = useSelector((state:RootState) => state.mealPlans.templates.list)

    const programs:Program[] = useSelector((state:RootState) => state.programs.templates.filtered)
    const checkups:Checkup[] = useSelector((state:RootState) => state.checkups.templates.filtered)
    const workouts:Workout[] = useSelector((state:RootState) => state.workouts.templates.filtered)
    const mealPlans:MealPlan[] = useSelector((state:RootState) => state.mealPlans.templates.filtered)
    const [tabs, setTabs] = useState<number>(location.state?.tabs? location.state.tabs : 0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openCreateWorkout, setOpenCreateWorkout] = useState<boolean>(false)
    const [openCreateCheckup, setOpenCreateCheckup] = useState<boolean>(false)
    const [openCreateProgram, setOpenCreateProgram] = useState<boolean>(false)
    const [openCreateMealPlan, setOpenCreateMealPlan] = useState<boolean>(false)


    // REF

    useEffect(()=>{
        dispatch(selectDrawerItem(5))

        return function cleanup(){
            dispatch(resetSearchWorkouts())
            dispatch(resetSearchCheckups())
            dispatch(resetSearchPrograms())
            dispatch(resetSearchMealPlans())
        }
    },[dispatch])

    /************* TABS ***************** */
    const tabsLabels = useMemo(() => {

        return [
            `Séances (${workouts.length})`,
            `Formulaires (${checkups.length})`,
            `Programmes (${programs.length})`,
            `Plans alimentaires (${mealPlans.length})`,
            
        ]
    }, [programs,checkups,workouts, mealPlans])

    
    const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };


    useEffect(()=>{
        setAnchorEl(null)
    },[openCreateWorkout, openCreateCheckup, openCreateProgram, openCreateMealPlan])


    /************* RECHERCHE ***************** */

    const onSearch = (e:any) => {
        const search = e.currentTarget.value
        dispatch(searchCheckupsTemplates({search}))
        dispatch(searchWorkoutsTemplates({search}))
        dispatch(searchProgramsTemplates({search}))
        dispatch(searchMealPlansTemplates({search}))
    }



    return(
        <React.Fragment>

            

             {/********************** 
             * HEADER 
             * ********************/}

            <Navbar
                pageTitle="Mes modèles"
            />
             

            <FixedSearchHeader 
                onSearch={onSearch}
                onSubmit={(e)=>setAnchorEl(e.currentTarget)}
                submitLabel={"Ajouter un modèle"}
                //badge={groupFilters.length + typesFilters.length}
                searchLabel={"Rechercher un modèle"}
                filter={false}

            />


            <MainContent
                withSearch={true}
            >
                <Grid 
                    container 
                    justifyContent="center" 
                    sx={{paddingBottom: 10,}}
                >
                    
                    <Grid 
                        item 
                        xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >

                        {/*******
                         * TABS
                         *******/}

                        <Tabs 
                            tabs={tabsLabels} 
                            value={tabs} 
                            onChange={onChangeTabs} 
                            sx={{marginBottom: 2}}
                        />


                        {/*******
                         * MENU BOUTON ACTION
                         *******/}
                    
                        <Menu disableScrollLock={true} 
                            id="actions"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={()=> setAnchorEl(null)}
                        >                      
                            <MenuItem 
                                onClick={()=> setOpenCreateWorkout(true)}
                            >
                                <ListItemIcon>
                                    <ListAlt/>
                                </ListItemIcon>
                                <ListItemText>
                                    Séance
                                </ListItemText>
                            </MenuItem>
                            <MenuItem 
                                onClick={()=> setOpenCreateCheckup(true)}
                            >
                            <ListItemIcon>
                                    <AssignmentOutlined/>
                                </ListItemIcon>
                                <ListItemText>
                                    Questionnaire
                                </ListItemText>
                            </MenuItem>                          
                            <MenuItem 
                                onClick={()=> setOpenCreateProgram(true)}
                            >
                                <ListItemIcon>
                                    <Event/>
                                </ListItemIcon>
                                <ListItemText>
                                   Programme
                                </ListItemText>
                            </MenuItem> 
                            <MenuItem 
                                onClick={()=> setOpenCreateMealPlan(true)}
                            >
                                <ListItemIcon>
                                    <Restaurant/>
                                </ListItemIcon>
                                <ListItemText>
                                    Plan alimentaire
                                </ListItemText>
                            </MenuItem>                     
                        </Menu>



                       

                        {/****************** 
                         *      WORKOUTS 
                         * *****************/}

                        {(tabs === 0 && allWorkouts.length >0) && (
                            <TemplateWorkouts/>
                        )}

                        {(tabs === 0 && workouts.length === 0 && allWorkouts.length > 0) && (
                            <EmptySearch 
                                description={"Oops! Aucun modèle de séance trouvé."}
                                title={"Aucun modèle"}
                            />
                        )}

                        {(tabs === 0 && allWorkouts.length === 0) && (
                            <EmptySearch
                            description={`Tu n'as créé aucun modèle de séance pour le moment`}
                            title={"Aucune séance"}
                            />
                        )}


                        {/**************************** 
                         *      QUESTIONNAIRES 
                         * **************************/}

                        {(tabs === 1 && allCheckups.length > 0) && (
                            <TemplateCheckup />
                        )}

                        {(tabs === 1 && checkups.length === 0 && allCheckups.length > 0) && (
                            <EmptySearch 
                                description={"Oops! Aucun modèle de questionnaire trouvé."}
                                title={"Aucun modèle"}
                            />
                        )}


                        {(tabs === 1 && allCheckups.length === 0) && (
                            <EmptySearch
                            description={`Tu n'as créé aucun modèle de questionnaire pour le moment`}
                            title={"Aucun questionnaire"}
                            />
                        )}

                        {/**************************** 
                         *      PROGRAMMES 
                         * **************************/}

                        {(tabs === 2 && allPrograms.length > 0) && (
                            <TemplatePrograms/>
                        )}


                        {(tabs === 2 && programs.length === 0 && allPrograms.length > 0) && (
                            <EmptySearch 
                                description={"Oops! Aucun modèle de programme trouvé."}
                            />
                        )}


                        {(tabs === 2 && allPrograms.length === 0) && (
                            <EmptySearch
                                description={`Tu n'as créé aucun modèle de programme pour le moment`}
                                title={"Aucun programme"}
                            />
                        )}


                         {/**************************** 
                         *      PLAN ALIMENTAITRES 
                         * **************************/}

                        {(tabs === 3 && allMealPlans.length > 0) && (
                            <TemplateMealPlans/>
                        )}


                        {(tabs === 3 && mealPlans.length === 0 && allMealPlans.length > 0) && (
                            <EmptySearch 
                                description={"Oops! Aucun modèle de plan alimentaire trouvé."}
                            />
                        )}


                        {(tabs === 3 && allMealPlans.length === 0) && (
                            <EmptySearch
                                description={`Tu n'as créé aucun modèle de plan alimentaire pour le moment`}
                                title={"Aucun plan alimentaire"}
                            />
                        )}
                        

                       

                    </Grid>
                </Grid>

                

            </MainContent>


            {/********** LISTE DES DIALOGS ******* */}

            <DialogCreateProgram
                open={openCreateProgram}
                onClose={()=> setOpenCreateProgram(false)}
                template={true}
                startDate={new Date()}
            />


            <DialogCreateWorkout
                open={openCreateWorkout}
                onClose={()=> setOpenCreateWorkout(false)}
            />

            <DialogCreateCheckup
                open={openCreateCheckup}
                onClose={()=> setOpenCreateCheckup(false)}
            />


            <DialogCreateMealTemplate
                open={openCreateMealPlan}
                onClose={()=> setOpenCreateMealPlan(false)}
            />

            
              
           
     

          


        </React.Fragment>
        
    )
}


