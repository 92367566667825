import { lighten } from "@mui/material"
import { BORDER_COLOR, DRAWER_WIDTH_MIN, HEADER_MIN_HEIGHT, HOVER_COLOR, LIGHT_BUTTON_COLOR} from "utils/constants"
import { theme } from "utils/theme"

const classes = {

    header : {
        display:"flex",
        position: "fixed",
        flexDirection: "column",
        minHeight: HEADER_MIN_HEIGHT,
        zIndex: 10,
        left: {
            xs: 0,
            lg: DRAWER_WIDTH_MIN
        },
        paddingBottom: 0, 
        backgroundColor: "white", 
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        alignItems:"center",
        justifyContent:"center",
        paddingLeft: {
            xs: 0,
            sm: 3,
        },
        paddingRight: {
            xs: 0,
            sm: 3,
        },
        width: {
            xs: "100%",
            lg: `calc(100% - ${DRAWER_WIDTH_MIN}px)`,
        },
       

    },


    userData:{
        display:"flex", 
        flexDirection:"row",
        marginBottom: 0,
        justifyContent: "space-between",
        flex: 1,
        alignItems:"center",
        width: "100%",
        paddingLeft: {xs: 2, sm :0},
        paddingRight: {xs: 2, sm :0},
        height: 75
    },

   avatarContainer: {
        borderRadius: "100%",
        border: `dashed 3px ${theme.palette.primary.main}`,
        marginRight: {
            xs: 2,
            md: 2.5,
        }, 
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
        overflow: "visible",
        width: {
            xs: 40,
            lg: 55,
        }, 
        height: {
            xs: 40,
            lg: 55,
        }, 
   },
        
    avatar:{
        width: {
            xs: 30,
            lg: 50,
        }, 
        height: {
            xs: 30,
            lg: 50,
        }, 
    },

    email: {
        textOverflow: "ellipsis"
    },

    clickableAvatar:{
        cursor: "pointer"
    },

    deleteAvatarButton: {
        backgroundColor: LIGHT_BUTTON_COLOR,
        borderRadius: "100px !important",
        color: "black",
        position: "absolute",
        bottom: -15,
        right: -15,

        "&:hover":{
            backgroundColor: HOVER_COLOR
        }
    },

    infos:{
        display: "flex", 
        flexDirection: "column" ,
        flexGrow: 1,
        //overflow: "hidden",
        justifyContent: {
            xs: "center",
            md: "flex-start"
        }
    },

    tabs :{
        display:"flex", 
        flexDirection:"row", 
        width: "100%", 
        //borderBottom: `solid 1px ${BORDER_COLOR}`
    },

    tabSelected: {
        backgroundColor: LIGHT_BUTTON_COLOR, 
        color: "primary.main", 
        borderRadius: "8px 8px 0px 0px !important",

    },

    tabItem :{
        backgroundColor:"transparent", 
        color: "#dddddd", 
        height: 50,
        width: 200,


        "&:hover":{
            backgroundColor: lighten(theme.palette.primary.main, 0.95)
        }
    },

    userName: {
        fontWeight: 700, 
        fontSize: {
            xs: theme.typography.pxToRem(18),
            md: theme.typography.pxToRem(26),
        }
    },

    iconButton: {
        marginRight: 2,
        display:{
            xs: "flex",
            lg: "none"
        },
        alignItems:"center",
        justifyContent:"center",
        height: "40px",
        width: "40px"
      },
}   

export default classes