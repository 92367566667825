

const classes = {

thumbnail:{
    width: "100%",
    backgroundColor: "whitesmoke",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    overflow: "hidden",
    height: "auto",
    objectFit: "cover",
    opacity: 0.6,
    cursor: "pointer",

},


thumbnailSelected: {
    cursor: "pointer",
    opacity: 1,
    overflow: "hidden"
},

thumbnailItem: {
    backgroundColor: "black",
    width: "100%"
},





}

export default classes