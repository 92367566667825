import React, { useMemo } from "react"
import {Box, useMediaQuery} from "@mui/material"
import { addDays,differenceInCalendarDays, isAfter, isBefore, isSameDay, isWithinInterval } from "date-fns"
import { PlanningDay } from "components/organisms/PlanningDay"
import PlanningWeekButton from "../PlanningWeekButton"
import classes from "./styles"
import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { shallowEqual, useSelector } from "react-redux"
import Program from "interfaces/Program"
import PlanningProgram from "../PlanningProgram"
import { MOBILE } from "utils/constants"

const WEEK_DAYS = Array.from(Array(7))

interface Props{
    start: Date,
    end: Date,
    weekIndex:number,
    isProgramTemplate: boolean
}

// REDUX SELECTORS
const programsSelector = createSelector([
    (state:RootState) => state.planning.builder,
    (_, start:Date) => start,
    (_,__, end:Date) => end
],
    (planning, start, end) => {
        if(planning.programs?.length > 0 && !planning.template){ // Uniquement pour les plannings persos
            return planning.programs.filter((program:Program)=> {
                const startWithin = isWithinInterval(new Date(program.startDate),{start, end}) // Programme dont le début est dans la semaine
                const endWithin = isWithinInterval(new Date(program.endDate),{start, end}) // Programme dont la fin est dans la semaine
                const startAndEndNotWithin = isBefore(new Date(program.startDate), start) && isAfter(new Date(program.endDate), end) // Programme dont la fin et le début sont a l'extérieur de la semaine
                return (startWithin || endWithin) || startAndEndNotWithin
            })
        } else{
            return []
        }
        
    }
)



export function PlanningWeek({start, end,weekIndex,isProgramTemplate}:Props){

    const planningIsReady = useSelector((state:RootState)=> state.planning.builder.isReady)
    const programs:Program[] = useSelector((state:RootState) => programsSelector(state, start, end), shallowEqual)
    const user = useSelector((state:RootState)=> state.planning.user)
    const mobile = useMediaQuery(MOBILE)

    // Calcul du PaddingTop des jours selon le nombre de programmes
    const dayPaddingTop = useMemo(()=>{
        if(programs){
            const paddingTop = programs?.length > 0 ? 16 + (programs?.length * (24+ 8))  : 0
        return paddingTop
        }else{
            return 0
        }
        
    },[programs])


    
    return(
        <Box 
            sx={[classes.week]}
        >
            
                {/******** AFICHAGE DES INDICATEURS DE PROGRAMMES (Uniquement dans un planning client) ******** */}
                <PlanningWeekButton
                    weekStart={start.toString()}
                    weekEnd={end.toString()}
                />
                
                {(Boolean(user) && !mobile) && (
                    <Box sx={[classes.programs]}>
                        {planningIsReady && [...programs]?.sort((a,b) => isBefore(new Date(a.startDate), new Date(b.endDate)) ? -1 : 1).map((program:Program, programIndex: number)=>{

                            const startWithin = isWithinInterval(new Date(program.startDate),{start, end}) // le début du programme est il dans l'interval
                            const endWithin = isWithinInterval(new Date(program.endDate),{start, end}) // la fin du programme est-elle dans l'interval
                            var left = "0px"
                            var width = "100%"
                            var borderRadius = "0px"

                            const startProgramdiffFromStartWeek = differenceInCalendarDays(new Date(program.startDate), start)


                            // Start et end dans l'interval
                            if(startWithin && endWithin){
                                width = `calc(100% - 16px)`
                                if(programIndex === 0){
                                    left = startProgramdiffFromStartWeek === 0 ? "8px" : `calc(100% / 7 *${startProgramdiffFromStartWeek} + 8px)` 
                                }else{
                                    left = startProgramdiffFromStartWeek === 0 ? "8px" : `calc(100% / 7 *${startProgramdiffFromStartWeek - programIndex} + 8px)` 
                                }
                                borderRadius = "8px 8px 8px 8px"
                            }

                            // Start dans l'interval
                            else if(startWithin){
                                width = `calc((100% - 8px) - (100% / 7 *${startProgramdiffFromStartWeek}))`
                                if(programIndex === 0){
                                    left = startProgramdiffFromStartWeek === 0 ? "8px" : `calc(100% / 7 *${startProgramdiffFromStartWeek} + 8px)` 
                                }else{
                                    left = startProgramdiffFromStartWeek === 0 ? "8px" : `calc(100% / 7 *${startProgramdiffFromStartWeek - programIndex} + 8px)` 
                                }
                                //left= "0"
                                borderRadius = "8px 0px 0px 8px"

                                
                                
                            } else if(endWithin){
                                
                                const diff = differenceInCalendarDays( end, new Date(program.endDate))
                                width = `calc((100% - 16px) - (100% / 7 * ${diff} - 8px))`
                                borderRadius = "0px 8px 8px 0px"
                                left = "0px"

                            } else {
                                width = `100%`
                            }
                            

                            return(
                                <PlanningProgram
                                    key={program._id}
                                    program={program}
                                    left={left}
                                    width={width}
                                    borderRadius={borderRadius}
                                />
                            )
                        })}
                    </Box>
                )}

                {/****** ********************************** *****************
                *          AFFICHAGE DES 7 JOURS DE LA SEMAINES 
                * ******************************************************* */}

                <Box sx={classes.days}>      
                    {WEEK_DAYS.map((_:undefined, dayIndex: number)=>{
                        
                        const dayDate: Date = new Date(new Date(addDays(new Date(start) ,dayIndex)).setHours(0,0,0,0)) // Le jour du planning
                        const isWithingProgram = programs[0] ? isWithinInterval(dayDate,{start: new Date(programs[0].startDate), end: new Date(programs[0].endDate)}) : false
                        const programInfos = {
                            _id:  isWithingProgram ? programs[0]?._id : null,
                            name: isWithingProgram ? programs[0]?.name : null,
                            day: programs[0]?.startDate ? differenceInCalendarDays(dayDate, new Date(programs[0].startDate)) : 0,
                            color: programs[0]?.color,
                            duration: programs[0]? differenceInCalendarDays(new Date(programs[0].endDate), new Date(programs[0].startDate)) + 1 : 0
                        }


                       // if(((!fromPreviousMonth && (mobile)) || !mobile || isProgramTemplate)){
                            return(
                                <PlanningDay
                                    calendarDayNumber={(weekIndex * 7) + (dayIndex + 1)}
                                    key={dayDate.toDateString()}
                                    date={dayDate}
                                    programsPadding={dayPaddingTop}
                                    isProgramTemplate={isProgramTemplate}
                                    programInfos={programInfos}
                                    program={isWithingProgram ? programs[0] : null}
                                    isFirstDayOfProgram={isSameDay(new Date(programs[0]?.startDate), dayDate)}
                                />
                            )
                       // }
                    })}
                </Box>


              


            </Box>
    
    )
}

export default React.memo(PlanningWeek)