import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { getMealPlanTemplate } from "api/mealPlans";
import { AppDispatch, RootState } from "app/store";
import AutosaveIndicator from "components/molecules/AutosaveIndicator";
import DialogLoader from "components/molecules/DialogLoader";
import Header from "components/molecules/Header";
import Loader from "components/molecules/Loader";
import MainContent from "components/molecules/MainContent";
import TitleTextField from "components/molecules/TitleTextField";
import Toolbar from "components/molecules/Toolbar";
import Navbar from "components/organisms/NavBar";
import Nutrition from "components/organisms/Nutrition";
import { onChangeMealPlanName, resetMealPlanBuilder } from "features/mealPlanSlice";
import debounce from "lodash.debounce";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { INPUT_DEBOUNCE_TIMER } from "utils/constants";



export default function MealPlanBuilder(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const params:{mealplanid:string} = useParams()
    const pending = useSelector((state:RootState)=> state.mealPlans.requests.update === "pending")
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [localName, setLocalName] = useState<string>('')
    const builderIsReady = useSelector((state:RootState)=> state.mealPlans.builderIsReady)


    
    useEffect(()=>{
        return function cleanup() {
            dispatch(resetMealPlanBuilder())
        };
    },[dispatch])

    /*************************************** 
     * RECUPERATION DES DONNEES DU TEMPLATE 
     * *************************************/

    useEffect(()=>{
        if(params?.mealplanid){
            dispatch(getMealPlanTemplate(params.mealplanid)).unwrap().then((res:any)=>{
                if(res.mealPlan?.template){
                    setLocalName(res.mealPlan?.name)
                }else{
                    history.push('/templates')
                    toast.error('Impossible de modifier ce plan alimentaire')
                }
                
            })
        }
    },[params?.mealplanid])



    useEffect(()=>{
        // Si le mealPlan a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)
            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 3, 
                }
            })
        }
    },[pending, openWaitinUpdate])


        /*********************************** 
         * RETOUR A LA LISTE DES TEMPLATES 
         * ******************************* */

        const backTo = () =>{


            if(pending){
                setOpenWaitinUpdate(true)
                return
            }

            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 3, 
                }
            })
            return
        }


        /**********************
         * CHANGEMENT DU NOM 
         * ************* *****/

        const debounceOnChangeName = useRef(debounce((data)=> {
            const {newName} = data
            dispatch(onChangeMealPlanName({ name : newName}))
        },INPUT_DEBOUNCE_TIMER)).current
    
    
        const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
            const newName:string = event.target.value
            setLocalName(newName)
            debounceOnChangeName({newName: newName})
        }

    if(builderIsReady){

          
    return(
        <React.Fragment>
            {/*}
            <Header>
                <Toolbar>
                        <Tooltip title={"Liste des modèles"}>
                            <IconButton 
                                sx={{marginRight: 2}} 
                                onClick={backTo}
                            >          
                                <ArrowBack sx={{color: "black"}}/>
                            </IconButton>
                        </Tooltip>
                        <TitleTextField 
                            fullWidth 
                            label={''} 
                            value={localName} 
                            onChange={onChangeName}
                            variant="standard" 
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Nom du modèle : </InputAdornment>, 
                            }}
                        />
                    
                        <Box sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"flex-end", flex:1}}>
                            <AutosaveIndicator />
                        </Box>
                </Toolbar>
            </Header>
            */}

            <Navbar
                pageTitle={"Modèle de plan alimentaire"}
                fullWidth={true}
                backButton={backTo}
                showAutoSave={true}
            />

            <MainContent>

                {/*******
                 * TITLE
                *********/}

                <Box
                    sx={{paddingLeft: 0 ,paddingRight:0}}
                >
                    <TitleTextField 
                        fullWidth 
                        value={localName} 
                        onChange={onChangeName}
                        variant="standard" 
                        sx={{
                            marginBottom: 3, 
                            "& .MuiInput-root": {
                                fontSize: "1.6rem !important"
                        }}}

                    />
                </Box>
                <Nutrition 
                    template={true}
                />
            </MainContent>

            <DialogLoader
                open={openWaitinUpdate}
                text={"Sauvegarde en cours ..."}
            />         
        </React.Fragment>
    )
    }else{
        return(
            <Loader />
        )
    }
    
}