import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography, useMediaQuery } from "@mui/material";
import classes from "./styles";
import { Close } from "@mui/icons-material";
import { MOBILE } from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import DialogAppBar from "../DialogAppBar";
import DialogTransition from "../DialogTransition";

interface Props{
    open: boolean,
    onClose: ()=> void
}

export default function DialogDemoUserAdded({open, onClose}:Props){

    const mobile = useMediaQuery(MOBILE)
    const user = useSelector((state:RootState) => state.user.data)

    return(
        <Dialog 
            open={open} 
            fullScreen={mobile} 
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}
        >

            <DialogAppBar
                title="Client de démonstration"
                onClose={onClose}
            />
            <DialogContent>
                <Box sx={{padding: mobile? 3 : 5, paddingTop: 5}}>
                    
                    <Box sx={classes.avatarRow}>
                        <Box sx={classes.avatarContainer}>
                            <Box 
                                component={"img"} 
                                src={"https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/5d889c29-815b-4572-714a-b3936fa0e000/public"} 
                            />
                        </Box>
                        <Typography style={classes.name}>
                            Hello, je suis Arthur !
                        </Typography>
                        
                    </Box>
                    <Typography
                        sx={{lineHeight: "26px"}}
                    >
                        Bienvenue sur Traener <strong>{user.firstname}</strong> ! Je suis Arthur, ton faux client de démonstration. <br/> <br/>Tu peux consulter ma fiche client depuis la page <strong>"clients"</strong> dans le menu latéral et accéder à mon planning, mon historique, me programmer des séances ou encore me faire un plan alimentaire aux petits oignons !
                    </Typography>
                    <Typography
                        sx={{marginTop: 3}}
                    >
                        Tu peux également te connecter avec mon compte avec sur l'application mobile Traener avec les identifiants ci-dessous et l'a découvrir comme tes clients !
                    </Typography>
                    <Typography sx={{marginTop: 3}}>
                        Email : <strong>demo.{user.email}</strong>.
                    </Typography>
                    <Typography>
                        Et <strong>ton mot de passe actuel</strong>
                    </Typography>

                    <Box
                        sx={{display:"flex", flexDirection: "row", marginTop: 3, overflow: "hidden", maxWidth: "100%", justifyContent:{xs: "space-between", sm: "flex-start"}}}
                    >
                            <a href="https://play.google.com/store/apps/details?id=traenerapp.com&hl=fr" target="_blank">
                                <Box 
                                    sx={{display:"flex", flexShrink: 2, width: {xs: 150, sm: 200}, height:"auto", marginRight: {xs: 0, sm: 3}}}
                                    component={"img"} 
                                    src={"assets/download_google.png"} 
                                />
                            </a>
                            <a href="https://apps.apple.com/fr/app/traener/id1611883492" target="_blank">
                                <Box 
                                    sx={{display:"flex", flexShrink: 2, width: {xs: 150, sm: 200}, height:"auto"}}
                                    component={"img"} 
                                    src={"assets/download_ios.png"} 
                                />
                            </a>
                    </Box>
                    <Typography
                        sx={{textAlign: {sm:"center"}, marginTop: 3}}
                    >
                        Si tu me supprimes de ta base de clients, ce n'est pas grave ! Tu peux de nouveau m'ajouter en cliquant sur "Ajouter un client de démonstration" sur la page client.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                {!mobile && (
                <Button
                onClick={onClose}
                >
                    J'ai compris !
                </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}