import { theme } from "utils/theme"

const classes = {


    header: {
        //paddingBottom: 3,
        flexDirection: "row", 
        display:"flex",
        justifyContent:"space-between", 
        alignItems:"flex-start", 

    },


    toolbar : {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        marginBottom: 0.5
    },


    title:{
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700,
        
       
    },


    tabsContainer : {
        display:"flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
        justifyContent:"space-between",
        alignItems:"center"
    },


    coverChoiceItem : (selected:boolean)=>({
        width: "100%", 
        cursor: "pointer",
        backgroundColor: "whitesmoke",
        "&: hover": {
            opacity: 1
        },
        opacity: selected? 1 : 0.3
    }),

    selectedIndicator:{
        position: "absolute",
        right: 5, 
        top :25, 
        zIndex: 2 
    },

    checkCircle:{
        color: 'primary.main', 
        backgroundColor: "white", 
        borderRadius: "100%"
    },


    cropper : {
        backgroundColor: "#efefef",
        position: "relative",
        height: 300,
        width: "100%",
        marginBottom: 3

    },

    fakeInput: {
        display:"none"
    },

    inputLabel: {
        height: 300,
        backgroundColor: "whitesmoke",
        width: "100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection: "column",
        border: "dashed 5px #d5d5d5",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#f2f2f2"
        }
    },

    uploaderLabel : {
        width: "100%",
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 500,
        textAlign: "center",
        color: "gray",
        marginTop: 1,
       
        
    },

    labelImage : {
        color: "gray",
        fontSize: 40
    },

    uploaderSubLabel : {
        color: "gray",
        fontSize: theme.typography.pxToRem(14),

    },

    cropperButtons : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"center",
        alignItems:"center",
        marginTop: 2
    },

    deleteIcon: {
        position: "absolute",
        right: 0,
        top: 0,
        height: 48,
        width: 48,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor: "rgba(0,0,0,0.85)",
        zIndex: 2,
        cursor: "pointer"
    },

    validIcon: {
        position: "absolute",
        right: 0,
        top: 48,
        height: 48,
        width: 48,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor: theme.palette.success.light,
        zIndex: 2,
        cursor: "pointer"
    },


    delete:{
        color: "white",
    },

    valid:{
        color: "white"
    },

    filters : {
        display:"flex",
        flexDirection: "row",
        marginBottom: 2
    }
}

export default classes