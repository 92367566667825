import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import MealsBarChart from "components/molecules/MealsBarChart"
import { UserMeasures } from "interfaces/User"
import { BODY_MEASURES, BORDER_COLOR, GOALS } from "utils/constants"
import classes from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
import { useEffect, useMemo, useState } from "react"
import Meal from "interfaces/Meal"
import { getClientMeals, getUserMeasures } from "api/clients"
import { addMonths, addWeeks, differenceInCalendarYears, endOfMonth, endOfWeek, format, isWithinInterval, startOfMonth, startOfWeek } from "date-fns"
import { fr } from "date-fns/locale"
import DialogChartMeasure from "../DialogChartMeasure"
import MeasureCard from "../MeasureCard"
import { Measure } from "@amcharts/amcharts5/.internal/charts/stock/drawing/Measure"
import defineNAPLevel from "function/defineNAPLevel"
import { ChevronLeft, ChevronRight, DirectionsRun, EmojiEvents, FactCheck, LocalFireDepartment, SvgIconComponent } from "@mui/icons-material"
import { theme } from "utils/theme"
import { calcMb } from "function/calcMb"
import DialogGoal from "components/molecules/DialogGoal"
import DialogWorkoutStats from "components/molecules/DialogWorkoutStats"
import DialogNap from "components/molecules/DialogNap"
import DialogCalories from "components/molecules/DialogCalories"

const defineAssiduite = (value:number) => {
    if(!value){
        return "Pas assez de données"
    }
    if(value > 90){
        return 'Excellente'
    }
    if(value > 70){
        return 'Très bonne'
    }
    if(value > 65){
        return 'Bonne'
    }
    if(value > 50){
        return 'Moyenne'
    }
    if(value > 40){
        return 'Mauvaise'
    }
    else{
        return 'Très mauvaise'
    }
}


function Stat({label, value, Icon, indication, action, noPaddingTop = false}:{label:string, noPaddingTop?: boolean, value:any, Icon: SvgIconComponent, indication:string, action: ()=> void}){
    return(
        <Grid 
            item 
            xs={12} 
            sm={6} 
            lg={3}
            sx={{paddingTop:{xs: noPaddingTop ?"0 !important" :3, sm: "24px !important"}}}
            
            onClick={action}
        >
            <Box sx={classes.stat}>
            <Icon 
                sx={{color: theme.palette.primary.main, fontSize: 26, marginTop: "3px"}}
              
            />
            <Box
                sx={{marginLeft: 2}}
            >
                <Typography
                    sx={classes.statLabel}
                >
                    {label}
                </Typography>
                <Typography
                    sx={classes.statValue}
                >
                    {value}
                </Typography>
                <Typography
                    sx={classes.statBy}
                >
                    {indication}
                </Typography>
            </Box>
            </Box>
            
        </Grid>
    )
}


export default function UserStats(){

    const client = useSelector((state:RootState) => state.clients.profile)
    const dispatch = useDispatch<AppDispatch>()
    const [meals, setMeals] = useState<Meal[]>([])
    const [start, setStart] = useState<Date>(startOfMonth(new Date()))
    const [end, setEnd] = useState<Date>(endOfMonth(new Date()))
    const [open, setOpen] = useState<boolean>(false)
    const [openGoal, setOpenGoal] = useState<boolean>(false)
    const [openWorkoutStats, setOpenWorkoutStats] = useState<boolean>(false)
    const [openNap, setOpenNap] = useState<boolean>(false)
    const [type, setType] = useState<string>(null)
    const [range, setRange] = useState<"month"|"week">("month")
    const [displayRange, setDisplayRange] = useState<{start:Date, end: Date}>({start:startOfMonth(new Date()), end:endOfMonth(new Date())})
    const [_, setMeasures] = useState<Measure[]>([])
    const [openCalories, setOpenCalories] = useState<boolean>(false)


     /**********************************
     * METABOLISME DE BASE + ESTIMATION
     ***********************************/


    const METABOLISM = useMemo(()=>{
        if(client.birthday && client.gender && client.size && client.measures['weight'] && client.measures["weight"]?.length >0 ){
            var lastMeasure:number = client?.measures?.weight[0]?.value
            const age = differenceInCalendarYears(new Date(), new Date(client.birthday))
            const size = client.size/100
            const weight=(Boolean(lastMeasure) ? lastMeasure : null)

            const metabolism = calcMb(client.gender, size, weight, age)
            return Math.round(metabolism)

        } else {
            return null
        }
    },[client])


    const GOAL_KCAL = useMemo(()=>{

        if(client?.goal){
            switch (client.goal) {
                case "bulking" : {
                    return 200
                }
                case "weightloss" : {
                    return -200
                }
               
                case "competition" : {
                    return 200
                }
                default: return 0
                
            }
        } else {
            return 0
        }
    },[client?.goal, METABOLISM])

    /***************************
     * RECUPERATION DES MESURES
     ***************************/

    useEffect(()=>{
        dispatch(getUserMeasures({start,end, type: "weight", user: client})).unwrap().then((res)=>{
            if(res.measures){
                setMeasures(res.measures)
            }
        })
        
    },[])


    /***************************
     * RECUPERATION DES REPAS
     ***************************/
    useEffect(()=>{
        dispatch(getClientMeals({start, end, userId: client._id})).unwrap().then((res)=>{
            if(res.meals){
                setMeals(res.meals)
            }
        })
    },[start, end])





    /***************************
     * CHANGEMENT DU RANGE
     ***************************/

    const onChangeRange = (newRange: "month"|"week") => ()=> {
        setRange(newRange)
        if(newRange === "month"){
            setDisplayRange({start: startOfMonth(displayRange.start), end: endOfMonth(displayRange.end)})
        }else if(format(start,"MMMM") === format(new Date(), "MMMM")){
            setDisplayRange({start: startOfWeek(new Date(),{locale:fr}), end: endOfWeek(new Date(),{locale:fr})})
        }else{
            setDisplayRange({start: startOfWeek(end,{locale:fr}), end: endOfWeek(end,{locale:fr})})
        }
       
    }

    // Affichage
    const RANGE_DISPLAY = useMemo(()=>{
        var display = ""
        if(range === "week" && isWithinInterval(new Date(), {start:displayRange.start,end:displayRange.end})){
                display = "Cette semaine"
        }else if(range === "week"){
            display = `du ${format(displayRange.start, "dd MMM", {locale:fr})} au ${format(displayRange.end, "dd MMM", {locale:fr})}`
            
        } else {
                display = format(displayRange.start, "MMM yyyy", {locale:fr})
        }

        return display
            
        
    },[displayRange.start,displayRange.end, range])



    /***************************
     * STATISTIQUES DES SEANCES
     ***************************/


    const workoutsStats:{missed:number, done:number, attendance: number} = useMemo(()=>{
        const done = client?.workoutsStats?.done[0]?.records || 0
        const missed = client?.workoutsStats?.missed[0]?.records || 0


        return(
            {
                missed: client?.workoutsStats?.missed[0]?.records || 0,
                done: client?.workoutsStats?.done[0]?.records || 0,
                attendance: (done + missed) > 6 ? done * 100 / (done + missed) || 0 : null
            }
        )
        },[client]
    )


    const onCloseDialogMeasure = () => {
        setOpen(false)
        setTimeout(()=>{
            setType(null)
        },200)
    }



    useEffect(()=>{
        if(type)
            setOpen(true)
    },[type])



    const NAP = useMemo(()=>{
        if(client?.napDefinedBy === "coach"){
            return client.coachNap
        } else {
            return client?.nap?.value
        }
    },[client])


    const ENERGY_INTAKE:{kcal: number,proteins:number, carbs:number, lipids: number, useCustom?: boolean} = useMemo(()=>{
        // Définit par le coach
        if(client.energyIntake?.useCustom){
            return client.energyIntake
        } 
        // Définit par Traener si NAP
        else 
        {
            if(client.nap){
                const kcal = Math.round((METABOLISM * (client.napDefinedBy === "coach" ? client.coachNap : client.nap?.value ? client.nap.value : 1)) + GOAL_KCAL)
                const proteinsG = Math.round(kcal as number * 0.25 /4)
                const carbsG = Math.round(kcal as number * 0.50 /4)
                const lipidsG = Math.round(kcal as number * 0.25 /9)

                return {
                    kcal,
                    proteins: proteinsG,
                    lipids: carbsG,
                    carbs: lipidsG,
                    useCustom: false
                }

            } else {
                return {
                    kcal:0,
                    proteins: 25,
                    lipids: 25,
                    carbs: 25,
                    useCustom: false

                }
            }
           
        }
    },[client])


    const prevData = () => {
        if(range === "month"){
            const prevMonth = addMonths(displayRange.start, -1)
            const newStart = startOfMonth(new Date(prevMonth))
            setStart(newStart)
            setEnd(endOfMonth(newStart))
            setDisplayRange({start: newStart, end: endOfMonth(newStart)})
        } else if(range === "week"){
            const currentMonth = format(start, "MMMM")
            const prevWeek = addWeeks(displayRange.start, -1)
            const newStart = startOfWeek(new Date(prevWeek),{locale:fr})
            // Nouveau mois ?

            if(currentMonth !== format(newStart,"MMMM")){
                setStart(startOfMonth(newStart))
                setEnd(endOfMonth(newStart))
            }
            
            setDisplayRange({start: newStart, end: endOfWeek(newStart,{locale:fr})})
        }
    }

    const nextData = () => {
        if(range === "month"){
            const nextMonth = addMonths(displayRange.end, 1)
            const newEnd = endOfMonth(new Date(nextMonth))
            setEnd(newEnd)
            setStart(startOfMonth(newEnd))
            setDisplayRange({start: startOfMonth(newEnd), end: endOfMonth(newEnd)})
        } else if(range === "week"){
            const currentMonth = format(start, "MMMM")
            const nextWeek = addWeeks(displayRange.end, 1)
            const newEnd = endOfWeek(new Date(nextWeek),{locale:fr})
            // Nouveau mois ?
            if(currentMonth !== format(nextWeek,"MMMM")){
                setStart(startOfMonth(newEnd))
                setEnd(endOfMonth(newEnd))
            }
            
            setDisplayRange({start: startOfWeek(newEnd,{locale:fr}), end: newEnd})
        }
    }
    

    return(
        <Grid 
            container 
            justifyContent="center" 
            spacing={0}
        >
            <Grid
                item 
                xs={12} 
                sm={12} 
                md={12} 
                lg={12} 
                xl={8}
            >

            {/************ 
             * STATS 
             * *********** */}


        
            <Grid 
                container 
                spacing={3} 
                sx={{marginTop: 0}}
            >
                <Stat 
                    label="Objectif"
                    value={`${client.goal ? GOALS.find((elem) => elem.en === client.goal).fr : "-"}`}
                    Icon={EmojiEvents}
                    indication={client.goalDefinedBy === "coach" ? "Défini par le coach" : "Défini par le client"}
                    action={()=>setOpenGoal(true)}
                    noPaddingTop={true}
                />

                <Stat 
                    label="Assiduité des entrainements"
                    value={defineAssiduite(Math.round(workoutsStats.attendance))}
                    Icon={FactCheck}
                    indication={"Calculé par Traener"}
                    action={()=>setOpenWorkoutStats(true)}
                />

                <Stat 
                    label="Niveau d'activité physique"
                    value={`${NAP ? defineNAPLevel(NAP) + ` - ${NAP}` : "-"}`}
                    Icon={DirectionsRun}
                    indication={client?.napDefinedBy === "coach" ? "Défini par le coach" : "Calculé par Traener"}
                    action={()=>setOpenNap(true)}

                />

                <Stat 
                    label="Estimation de l'apport calorique"
                    value={(ENERGY_INTAKE.kcal && ENERGY_INTAKE.kcal !== 0) ? ENERGY_INTAKE.kcal + " kcal" : "-"}
                    Icon={LocalFireDepartment}
                    indication={ENERGY_INTAKE?.useCustom ? "Défini par le coach" : "Calculé par Traener"}
                    action={()=>setOpenCalories(true)}

                />
            </Grid>
          
               
                
        
            {/**********************************
             * APPORTS CALORIQUES JOURNALIERS
             ***********************************/}

            <Box
              sx={{backgroundColor: "white", padding: {xs: 0.5, sm: 2.5}, border: `solid 1px ${BORDER_COLOR}`, marginTop: 3}}>

                {/*************
                 * BUTTON RANGE
                 *************/}

                <Box
                    sx={{display: "flex", alignItems:"center", flexDirection:"column", marginBottom:1}}
                >
                    <Typography
                        sx={{fontWeight: 600, fontSize: "1.2rem", padding: {xs: 2.5, sm: 0}}}
                    >
                        Apports caloriques journaliers
                    </Typography>
                    <Box sx={classes.rangeButtons}>
                        <Button 
                            variant="outlined" 
                            onClick={onChangeRange("week")}
                            sx={[range === "week" && classes.selectedRange, {marginRight: 1}]}
                        >
                            1 semaine
                        </Button>
                        <Button 
                            variant="outlined" 
                            onClick={onChangeRange("month")}
                            sx={range === "month" && classes.selectedRange}
                        >
                            1 mois
                        </Button>
                    </Box>
                </Box>


                 {/********************
                 * RANGE DESCRIPTION
                 **********************/}

                <Box
                    sx={{display:"flex", justifyContent: "center", marginBottom: 2, alignItems:"center"}}
                >
                    <IconButton
                        onClick={prevData}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <Typography
                        sx={{fontWeight: 500, marginLeft: 3, marginRight: 3}}
                    >
                        {RANGE_DISPLAY}
                    </Typography>
                    <IconButton
                        onClick={nextData}
                    >
                        <ChevronRight />
                    </IconButton>
                </Box>


                {/********
                 * CHART
                 ********/}

                <MealsBarChart 
                    meals={meals}
                    start={start}
                    range={range}
                    displayRange={displayRange}
                />
                </Box>
                


                {/**********************
                 * DONNEES COPORELLES
                 **********************/}

                <Grid
                    container
                    spacing={3}
                    sx={{marginTop: 3}}
                >    

                                    
                    {BODY_MEASURES.map((measure:{label: keyof UserMeasures,fr:string, units:("kg" | "cm" | "%")})=>{

                        return(
                            <MeasureCard 
                                key={measure.label}
                                type={measure.label} // Weight, legs ...
                                measures={client.measures[measure.label]}
                                user={client}
                            />
                        )
                    })}
                </Grid>

            </Grid>


            <DialogGoal
                open={openGoal}
                onClose={()=>setOpenGoal(false)}
            />

            <DialogWorkoutStats
                open={openWorkoutStats}
                onClose={()=>setOpenWorkoutStats(false)}
                workoutsStats={workoutsStats}
                attendance={defineAssiduite(Math.round(workoutsStats.attendance))}
            />

            <DialogNap 
                open={openNap}
                onClose={()=>setOpenNap(false)}
            />

            <DialogCalories 
            open={openCalories}
            onClose={()=>setOpenCalories(false)}
            metabolism={METABOLISM}
            />

            <DialogChartMeasure
                open={open}
                onClose={onCloseDialogMeasure}
                type={type}
                user={client}
            />
        </Grid>
    )
}