import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material"
import { manualSaveExercice } from "api/exercices"
import { AppDispatch, RootState } from "app/store"
import Exercice from "interfaces/Exercice"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import {DebouncedFunc} from "lodash"
import { MOBILE } from "utils/constants"

interface Props{
    open: boolean,
    onClose: ()=> void,
    exercice:Exercice,
    debounce: DebouncedFunc<(formData: Exercice) => void>
}

export default function DialogSaveExercice({open,onClose, exercice, debounce}:Props){

    const pendingManualSave = useSelector((state:RootState)=> state.exercices.requests.manualSave === "pending")
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useHistory()
    const mobile = useMediaQuery(MOBILE)

    const onSave = () => {
        debounce.cancel()
        dispatch(manualSaveExercice({exercice})).unwrap().then(()=>{
            if(exercice.draft){
                navigate.push(`/exercices`)
            }
        })
    }

    return(
        <Dialog 
            open={open}
            disableScrollLock={!mobile}
        >
            <DialogTitle>
                Ajouter l'exercice à ta bibliothèque
            </DialogTitle>
            <DialogContent>
                Souhaites-tu publier l'exercice <strong>{exercice.name.fr}</strong> et le rendre disponible dans tes séances ? Il sera également disponible sur l'application mobile de tes clients. <strong>Attention, tu ne pourras plus modifier le type d'exercice une fois publiée.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Annuler
                </Button>
                <LoadingButton disabled={pendingManualSave} loading={pendingManualSave} onClick={onSave}>
                    Confirmer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}