import Food from "interfaces/Food";
import Meal, { MealContent } from "interfaces/Meal";


interface macros{
  proteins: number,
  carbs: number,
  lipids: number,
  kcal: number,
  fibers?: number,
}

function calcIngredientMacros(food:Food, grammage:number,total:macros){
  if(food){
    const totalGrammage = grammage // La portion en gramme
    const part = totalGrammage/100    // Combien représente de fois le nouveau grammage par rapport a 100 g
    total.proteins = parseFloat((total.proteins + (food.macros.proteins * part)).toFixed(1))
    
    total.carbs = parseFloat((total.carbs + (food.macros.carbs * part)).toFixed(1))
    total.lipids = parseFloat((total.lipids + (food.macros.lipids * part)).toFixed(1))
    //total.fibers = parseFloat((total.fibers + (food.micros.fibers * part)).toFixed(1))
    return total
  }
}


export default function calcMacros(meals:Meal[]){


    var total:macros = {
        proteins: 0,
        carbs: 0,
        lipids: 0,
        fibers: 0,
        kcal: 0,
      }

    meals?.forEach((meal)=> {
        meal.content.forEach((mealContent) => {

          if(mealContent.type === "food" || mealContent.type === "scanned"){
            total = calcIngredientMacros(mealContent.food? mealContent.food : mealContent.foodScanned, mealContent.grammage, total)
          } else if(mealContent.type === "recipe"){
            const baseRecipePortion = mealContent.recipe.portions
            const multiplicateur = mealContent.portion / baseRecipePortion

            mealContent.recipe.ingredients?.forEach((ingredient)=>{
                if(ingredient?.ingredient?.food){
                  total = calcIngredientMacros(ingredient.ingredient.food, ingredient.ingredient.grammage * multiplicateur, total)
                }
            })
          }
        })
    })

    total.kcal = Math.round(total.proteins *4 + total.carbs * 4 + total.lipids * 9)
    return total
}