import { Delete, DonutSmall } from "@mui/icons-material"
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography, useMediaQuery } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import { FoodPortion } from "interfaces/Food"
import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import classes from "./styles"
import { onChangeRecipeIngredientPortion, onChangeRecipeIngredientPortionLabel, onDeleteRecipeIngredient } from "features/recipesSlice"
import DialogFood from "../DialogFood"
import MoreButton, { MenuElem } from "components/atoms/Buttons/MoreButton"
import { MOBILE } from "utils/constants"


interface Props{
    ingredientIndex: number,
}


export default function RecipeItem({ingredientIndex}:Props){

    const ingredient= useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient)
    const portion = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.portion)
    const foodPortions = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.food.portions)
    const name = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.food.name.fr)
    const label = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.label)
    const [openNutriments, setOpenNutriments] = useState<boolean>()
    const mobile = useMediaQuery(MOBILE)
    const dispatch = useDispatch<AppDispatch>()


    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeRecipeIngredientPortion({
            index: ingredientIndex,
            portion: event.target.value,
            foodPortions: foodPortions
        }))
    }

    const changePortionLabel = (event: SelectChangeEvent<string>) => {
        dispatch(onChangeRecipeIngredientPortionLabel({
            index: ingredientIndex,
            newLabel: event.target.value,
            foodPortions: foodPortions
        }))
    }


    const onDelete = () => {
        dispatch(onDeleteRecipeIngredient({index:ingredientIndex}))
    }


    const PORTIONS:FoodPortion[] = useMemo(()=>{
        const allPortions:FoodPortion[] = [
            {
                label: {
                    fr: "grammes",
                    en: "grams"
                },
                equivalent: {
                    portion: 100,
                    value: 100
                } 
            },
            {
                label: {
                    fr: "millilitres",
                    en: "milliliters"
                },
                equivalent: {
                    portion: 100,
                    value: 100
                } 
            }
        ]

        
        if(foodPortions){
            return allPortions.concat(foodPortions)
        }else{
            return allPortions
        }
    },[foodPortions])

     {/************** 
        MENU MORE 
    ***************/}

    const MENU:MenuElem[] = useMemo(()=>{
        var menu:MenuElem[] = []


        menu.push(
            {
                label: "Répartition des nutriments",
                icon: <DonutSmall />,
                callback: ()=> setOpenNutriments(true)
            },
            {
                label: "Supprimer",
                icon: <Delete />,
                callback: onDelete
            }
        )
       
           
   
        return menu

       
    },[])


    return(
        <Box
            sx={classes.item}
        >   
            <Box
                sx={classes.itemInfos}
            >

                <Box
                    sx={classes.infos}>
                    <TextField
                        value={portion}
                        size="small"
                        onFocus={event => {
                            event.target.select();
                        }}
                        sx={[
                            classes.textField, 
                            classes.portionInput
                        ]}
                        onChange={onChangePortion}
                    />

                    <Select
                        size="small"
                        value={label}
                        onChange={changePortionLabel}
                        sx={[
                            classes.textField,
                            classes.labelInput
                        ]}
                    >
                        {PORTIONS.map((elem, index)=> {
                            return(
                                <MenuItem 
                                    key={index}
                                    value={elem.label.fr}
                                >
                                    {elem.label.fr}
                                </MenuItem>
                            )
                        })}
                        
                        
                            
                    </Select>
                </Box>

                <Typography
                    sx={classes.itemName}
                >
                    {name}
                </Typography>

            </Box>

            {!mobile && (
            <Box>
                <IconButton
                    onClick={()=>setOpenNutriments(true)}
                >
                    <DonutSmall/>
                </IconButton>
                <IconButton
                    onClick={onDelete}
                >
                    <Delete/>
                </IconButton>
            </Box>
            )}


            {mobile && (
                <Box
                    sx={{display:"flex", alignItems:"flex-start", alignSelf:"flex-start"}}
                >
                <MoreButton
                    menuList={MENU}
                />
                </Box>
            )}

            <DialogFood
                open={openNutriments}
                onClose={()=> setOpenNutriments(false)}
                item={{
                    type: "food",
                    food:ingredient.food,
                    portion,
                    label,
                    grammage: ingredient.grammage
                }}
            />
        </Box>
    )


}