import { createSlice, current } from '@reduxjs/toolkit'
import { createRecipe, deleteRecipe, deleteRecipeImage, getRecipe, getRecipes, manualSaveRecipe, updateRecipe, uploadRecipeImage } from 'api/recipes'
import fuseSearch from 'function/fuseSearch'
import { FoodPortion } from 'interfaces/Food'
import Recipe, { Ingredient } from 'interfaces/Recipe'
import { toast } from 'react-toastify'
import { RECIPE_TAGS } from 'utils/constants'


interface RecipesSlice{
    list: {
        draft: Recipe[],
        custom: Recipe[]
    },
    filtered:{
      draft: Recipe[],
        custom: Recipe[]
    },
    tagsFilter:string[],
    search :string,
    builder: Recipe,
    requests: {
      create: "idle" | "pending",
      get: "idle" | "pending",
      update: "idle" | "pending",
      uploadImage: "idle" | "pending",
      deleteImage: "idle" | "pending",
      delete: "idle" | "pending",
      manualSave: "idle" | "pending"
    },
    hasUnsavedChanges: boolean,


}

/******** Recette vierge ********** */

const builder:Recipe ={
  _id: null,
  name:{
    fr: "",
    en: ""
  },
  publishForClient: false,
  ingredients:[],
  steps:[],
  portions: 1,
  tags:[],
  description: {
    fr: "",
    en: ""
  },
  video: null,
  cover: null,
  createdBy: null,
  draft: true,
  
}


const initialState = {
    list: {
        draft: [],
        custom: []
    },

    filtered:{
      draft: [],
        custom: []
    },
    search :"",
    builder,
    requests: {
      create: "idle",
      get:'idle',
      update: "idle",
      uploadImage: "idle"

    },
    hasUnsavedChanges: false,
    tagsFilter:[]


} as RecipesSlice







export const recipesSlice = createSlice({
    name: 'recipes',
    initialState,
    reducers: {

        recipeIsSaving: (state) => {
          state.requests.update = "pending"
        },

        cleanRecipeBuilder : (state) => {
          state.builder = builder
          state.hasUnsavedChanges = false
        },

        // FUSE SEARCH
        searchRecipe: (state, action:{payload:{search?:string, tagsFilter?: string[] }}) => {
            const {search, tagsFilter} = action.payload
            
      
            // Si nouvelle recherche
            state.search = search === null ? state.search : search
            
      
            const keys:string[] = [
              "name.fr",
            ]

            const filteredLists = {
              draft: current(state.list.draft),
              custom: current(state.list.custom),
            }

            // on trie par tags
            if(tagsFilter?.length>0){
              filteredLists.custom = [...state.list.custom].filter((elem)=> {
                
                return elem.tags.some((tag)=> {
                  const tagId = RECIPE_TAGS.find((elem) => elem.id === tag)?.id
                  return state.tagsFilter.includes(tagId)
                })
              })

              filteredLists.draft = [...state.list.draft].filter((elem)=> {
                
                return elem.tags.some((tag)=> {
                  const tagId = RECIPE_TAGS.find((elem) =>  elem.id === tag)?.id
                  return state.tagsFilter.includes(tagId)
                })
              })
            }
      
            
      
            // nouvelle recherche ou recherche courrant
            const newSearch = search === null ?  state.search : search
      
            // Filtrage des données
            const draft = newSearch !== "" ? fuseSearch(filteredLists.draft, newSearch, keys) : filteredLists.draft
            const custom = newSearch !== "" ? fuseSearch(filteredLists.custom, newSearch, keys) : filteredLists.custom
      
      
            state.filtered = {
              draft,
              custom,
            }
        },

        clearRecipeTagsFilter:  (state)=> {
          state.tagsFilter = []
        },

        // Reset de la recherche
        resetSearchRecipes: (state) => {
          state.filtered = {
            custom:state.list.custom,
            draft: state.list.draft,
          }
      },

        addFilterTag : (state,action) => {
          const tag = action.payload.tag
          state.tagsFilter.push(tag)
        },

        removeFilterTag : (state,action) => {
          const tag = action.payload.tag
          const index = state.tagsFilter.findIndex((elem)=> tag === elem)
          state.tagsFilter.splice(index,1)
        },

        recipeHasUnsavedChanges: (state) =>{
          state.hasUnsavedChanges = true
        },

        onAddStep:(state) => {
            state.builder.steps.push("")
        },


        onChangeRecipeDescription: (state,{payload}) => {
          state.builder.description.fr = payload.description
        },

        onChangeRecipeName: (state,{payload}) => {
          state.builder.name = payload.name
        },

        onAddFoodToRecipe:(state, {payload}) => {
          const food= payload.food
          const newIngredient:Ingredient = {
                food,
                type:"food",
                portion: food.portions?.length > 0 ? food.portions[0].equivalent.portion : 100,
                label: food.portions?.length > 0 ? food.portions[0].label.fr : "grammes",
                grammage: food.portions?.length > 0 ? food.portions[0].equivalent.portion * (food.portions[0].equivalent.value / food.portions[0].equivalent.portion) : 100
              
            }
          state.builder.ingredients.push({ingredient:newIngredient})
        },

       

        onDeleteFoodToRecipe : (state, {payload}) => {
          const ingredientIndex = payload.ingredientIndex
    
          state.builder.ingredients.splice(ingredientIndex,1)
    
        },


        onChangeRecipeIngredientPortion : (state, {payload}) => {
          const index = payload.index
          const foodPortions:FoodPortion[] = payload.foodPortions // Portions personnalisées de l'ingrédient (ex: gros, moyen ...)
          const currentLabel = state.builder.ingredients[index].ingredient.label
          state.builder.ingredients[index].ingredient.portion = payload.portion
    
          // Calcul du grammage
          
          // Grammes ou mililitres
          if(currentLabel === "grammes" || currentLabel === "millilitres"){
            state.builder.ingredients[index].ingredient.grammage = payload.portion
          }
    
          // Portions personnalisées
          else{
            // Calcul du grammage
            const label:string = state.builder.ingredients[index].ingredient.label
            const foodPortion = foodPortions.find((elem)=> elem.label.fr === label)
            const grammage = payload.portion * (foodPortion.equivalent.value / foodPortion.equivalent.portion)
    
            state.builder.ingredients[index].ingredient.grammage = grammage
    
          }
          
    
        },
    
        onChangeRecipeIngredientPortionLabel : (state, {payload}) => {
          const index = payload.index
          const newLabel = payload.newLabel
          const foodPortions:FoodPortion[] = payload.foodPortions // Portions personnalisées de l'ingrédient (ex: gros, moyen ...)
    
          state.builder.ingredients[index].ingredient.label = newLabel
          const portion = state.builder.ingredients[index].ingredient.portion
    
          if(newLabel === "grammes" || newLabel === "millilitres"){
            state.builder.ingredients[index].ingredient.grammage = portion
          } else {
            const foodPortion = foodPortions.find((elem)=> elem.label.fr === newLabel)
            const grammage = portion * (foodPortion.equivalent.value / foodPortion.equivalent.portion)
            state.builder.ingredients[index].ingredient.grammage = grammage
          }
        },

        onDeleteRecipeIngredient : (state, {payload}) => {
          const index = payload.index
          state.builder.ingredients.splice(index,1)
    
        },

        onDeleteStep:(state, {payload}) => {
          state.builder.steps.splice(payload.stepIndex,1)
      },

        onChangeStep:(state, {payload}) => {
          state.builder.steps[payload.stepIndex] = payload.step
        },

        onHandleRecipeTag : (state,{payload}) => {
          const id = payload.tag
          const index = state.builder.tags.findIndex((elem) => elem === id)
          if(index > -1){
            state.builder.tags.splice(index,1)
          } else {
            state.builder.tags.push(id)
          }
        },

        onChangeRecipeBuilderPortions:(state, {payload}) => {
          state.builder.portions = parseInt(payload.portions)

        }
    },

    extraReducers(builder) {
      builder


    .addCase(getRecipes.fulfilled, (state,{payload}) => {
      
        if(payload.recipes){
          const result = [...payload.recipes]
          state.list.custom = result.filter((elem)=> !elem.draft)
          state.filtered.custom = result.filter((elem)=> !elem.draft)
          state.list.draft = result.filter((elem)=> elem.draft)
          state.filtered.draft = result.filter((elem)=> elem.draft)
        }
    })

     /********* CREATE RECIPE ********* */

    .addCase(createRecipe.pending, (state)=>{
      state.requests.create = "pending"
    })

    .addCase(createRecipe.fulfilled, (state, {payload})=>{
        const recipe = payload.recipe
        state.requests.create = "idle"

        if(recipe){
          state.builder = recipe
          const drafts = [...current(state.list.draft)]
          drafts.push(recipe)
          const sortedListDrafts = drafts.sort((a,b)=> {
            if(a.name > b.name){
              return 1
            } else if(a.name < b.name){
              return -1
            }else return 0
          })

          state.list.draft = sortedListDrafts


          const customdraftsFiltered = [...current(state.filtered.draft)]
          customdraftsFiltered.push(recipe)
          const sortedFilteredDrafts = drafts.sort((a,b)=> {
            if(a.name > b.name){
              return 1
            } else if(a.name < b.name){
              return -1
            }else return 0
          })

          state.filtered.draft = sortedFilteredDrafts


        }
    })


      /********* GET RECIPE ********* */

      .addCase(getRecipe.pending, (state) => {
        state.requests.get = "pending"
      })

      .addCase(getRecipe.fulfilled, (state,{payload}) => {
        state.requests.get = "idle"
        if(payload.recipe){
          state.builder = payload.recipe
        }
      })

      /********* DELETE RECIPE ********* */

      .addCase(deleteRecipe.pending, (state) => {
        state.requests.delete = "pending"
      })


      .addCase(deleteRecipe.fulfilled, (state,{payload}) => {
        state.requests.delete = "idle"
        const recipe:Recipe = payload.recipe
        if(recipe){
          // Suppresssion de la recette dans la liste
          const indexListCustom = state.list.custom.findIndex((elem) => elem._id === recipe._id)
          if(indexListCustom !== -1){
            state.list.custom.splice(indexListCustom,1)
          }
          const indexListDraft = state.list.draft.findIndex((elem) => elem._id === recipe._id)
          if(indexListDraft !== -1){
            state.list.draft.splice(indexListDraft,1)
          }

          const indexFilteredCustom = state.filtered.custom.findIndex((elem) => elem._id === recipe._id)
          if(indexFilteredCustom !== -1){
            state.filtered.custom.splice(indexFilteredCustom,1)
          }
          const indexFilteredDraft = state.filtered.draft.findIndex((elem) => elem._id === recipe._id)
          if(indexFilteredDraft !== -1){
            state.filtered.draft.splice(indexFilteredDraft,1)
          }
          
        }
      })



    /***************** 
     * UPDATE RECIPE
     *  ******* ******/
    .addCase(updateRecipe.pending, (state) => {
      state.requests.update = "pending"
    })
    .addCase(updateRecipe.fulfilled, (state,{payload}) => {
          state.requests.update = "idle"
          const recipe:Recipe = {
            ...payload.recipe, 
            createdAt: payload.recipe.createdAt
          }

          const indexListCustom = state.list.custom.findIndex((elem:Recipe)=> elem._id === recipe._id)
          if(indexListCustom !== -1){
            state.list.custom[indexListCustom] = recipe
          }
          const indexListDrafts = state.list.draft.findIndex((elem:Recipe)=> elem._id === recipe._id)
          if(indexListDrafts !== -1){
            state.list.draft[indexListDrafts] = recipe
          }

          const indexFilteredCustom = state.filtered.custom.findIndex((elem:Recipe)=> elem._id === recipe._id)
          if(indexFilteredCustom !== -1){
            state.filtered.custom[indexFilteredCustom] = recipe
          }
          const indexFilteredDraft = state.filtered.draft.findIndex((elem:Recipe)=> elem._id === recipe._id)
          if(indexFilteredDraft !== -1){
            state.filtered.draft[indexFilteredDraft] = recipe
          }
    })



    /********************* 
    * UPDATE RECIPE IMAGE
    ******** ***********/
    .addCase(uploadRecipeImage.pending, (state) => {
      state.requests.uploadImage = "pending"
    })

    .addCase(uploadRecipeImage.fulfilled, (state,{payload}) => {
      state.requests.uploadImage = "idle"

      if(payload?.image){
        state.builder.cover = payload.image
        state.hasUnsavedChanges = false

      } else {
        toast.error(payload.error)
      }
    })



    /********************* 
    * DELETE RECIPE IMAGE
    ******** ***********/
    .addCase(deleteRecipeImage.pending,(state)=>{
      state.requests.deleteImage = "pending"
    })

    .addCase(deleteRecipeImage.fulfilled,(state, action)=>{
      state.requests.deleteImage = "idle"

      if(action.payload.cover){

        const recipeId = action.payload.recipeId
        const cover = action.payload.cover
        state.builder.cover = null
        state.hasUnsavedChanges = false

        const indexListCustom = state.list.custom.findIndex((elem:Recipe)=> elem._id === recipeId)
        if(indexListCustom !== -1){
          state.list.custom[indexListCustom].cover = null
        }
        const indexListDrafts = state.list.draft.findIndex((elem:Recipe)=> elem._id === recipeId)
        if(indexListDrafts !== -1){
          state.list.draft[indexListDrafts].cover = null
        }

        const indexFilteredCustom = state.filtered.custom.findIndex((elem:Recipe)=> elem._id === recipeId)
        if(indexFilteredCustom !== -1){
          state.filtered.custom[indexFilteredCustom].cover = null
        }
        const indexFilteredDraft = state.filtered.draft.findIndex((elem:Recipe)=> elem._id === recipeId)
        if(indexFilteredDraft !== -1){

          state.filtered.draft[indexFilteredDraft].cover = null
        }

      } else {
        toast.error(action.payload.error)
      }

    })

    .addCase(deleteRecipeImage.rejected,(state)=>{
      state.requests.deleteImage = "idle"
      toast.error('Impossible de supprimer cette image')
    })



    // MANUAL SAVE
    .addCase(manualSaveRecipe.pending,(state)=>{
      state.requests.manualSave = "pending"
    })

    .addCase(manualSaveRecipe.fulfilled,(state, action)=>{
      state.requests.manualSave = "idle"

      // Si la recette était un brouillon
      if(action.payload.recipe){
        
        const recipeIndexDraft:number = state.list.draft.findIndex((elem)=> elem._id === action.payload.recipe._id)

        if(recipeIndexDraft > -1){
          // Filtered index
          const recipeIndexFilteredDraft:number = state.filtered.draft.findIndex((elem)=> elem._id === action.payload.recipe._id)
          // Suppression des brouillons
          state.filtered.draft.splice(recipeIndexFilteredDraft,1)
          state.list.draft.splice(recipeIndexDraft,1)

         
          // Ajout dans les customs
          state.list.custom.push(action.payload.recipe)
          state.filtered.custom.push(action.payload.recipe)
          state.hasUnsavedChanges = false
          if(!state.builder.draft){
            toast.success('Recette enregistrée')
          }
        } 
        
        // Sinon
        else{
          const recipeIndexFilteredCustom:number = state.filtered.custom.findIndex((elem)=> elem._id === action.payload.recipe._id)
          const recipeIndexListCustom:number = state.list.custom.findIndex((elem)=> elem._id === action.payload.recipe._id)

          state.list.custom[recipeIndexListCustom] = action.payload.recipe
          state.filtered.custom[recipeIndexFilteredCustom] = action.payload.recipe
          toast.success('Modifications enregistrées')
          state.hasUnsavedChanges = false
        
        }
      }
    })

    .addCase(manualSaveRecipe.rejected,(state)=>{
      state.requests.manualSave = "idle"
      toast.error('La sauvegarde a échouée')
    })

      
    }

})

export const {
  onAddStep,
  onDeleteStep,
  onChangeStep,
  onAddFoodToRecipe,
  onChangeRecipeIngredientPortion,
  onChangeRecipeIngredientPortionLabel,
  onDeleteRecipeIngredient,
  onHandleRecipeTag,
  onChangeRecipeBuilderPortions,
  onChangeRecipeDescription,
  recipeIsSaving,
  onChangeRecipeName,
  recipeHasUnsavedChanges,
  searchRecipe,
  clearRecipeTagsFilter,
  addFilterTag,
  removeFilterTag,
  resetSearchRecipes,
  cleanRecipeBuilder
  } = recipesSlice.actions
  
  
  export default recipesSlice.reducer