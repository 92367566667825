import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material"
import { Box, Grid, Chip as MuiChip, lighten} from "@mui/material"
import React from "react"
import { theme, primaryColor } from "utils/theme"
import classes from "./styles"


/******************* CHIPS ******************* */

interface Props {
    label: string,
    selected: boolean,
    onClick: ()=>void,
    disabled?: boolean
}

export function ChipSelector({label, selected, onClick, disabled}:Props){

    

    return(
        <Grid item xs={6} sm={3} md={2} lg={2}>
        <Box>
            <MuiChip 
                label={label} 
                sx={[
                    classes.chip, 
                    selected && classes.selected
                ]} 
                clickable={!disabled}
                onClick={!disabled ? onClick : ()=> null}
                icon={selected ? <CheckCircle sx={{color: `${lighten(primaryColor,0.2)} !important`,fontSize: 20}}/> : <RadioButtonUnchecked sx={{color: "#e3e3e3 !important", fontSize: 20}}/>}
            />
        </Box>
        </Grid>
    )
}

function areEqual(prev:Props,next:Props){
    return prev.selected === next.selected
}

export default React.memo(ChipSelector, areEqual)

