import { createSlice } from '@reduxjs/toolkit'
// Interfaces
import User from 'interfaces/User';
// Api
import {
  createCustomerId,
  deleteAccount,
  deleteAvatar,
  getAccessToken, 
  logout, 
  signin, 
  signup,
  updateUserData,
  uploadAvatar, 
} from "api/user"
import { toast } from 'react-toastify';
import { handleSubscriptionStatus, startTrial } from 'api/subscriptions';
import { getUserData } from 'api/clients';

/************** INIT ****************/

interface Init{
  data: User,
  isAuth: boolean,
  requests:{
    update: "pending" | "idle",
    delete: "pending" | "idle"
  },
  facebookConnected: boolean,
  isReady: boolean,
}


const initialState = {
  data:{
    _id: null,
    firstname: "",
    lastname: "",
    avatar: null,
    gender: "male",
    customerId: null,
    birthday: new Date().toDateString(),
    email: null,
    subscription: {
      current: null,
      scheduled: null,
    },
    trialIsDone: null,
    mealPlan:{
      status: "disabled",
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    }
  },
  isAuth: false,
  requests:{
    update: "idle",
    delete: 'idle'
  },
  isReady: false,
  facebookConnected: false,
  
} as Init





// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    
    userIsReady: (state) => {
      state.isReady = true
    },

    onTrialActivated: (state) => {
      state.requests.update = "idle"
    },


    
      
  },


  /*****************
   * EXTRRA REDUCER
   *****************/

  extraReducers(builder) {

    /****** SIGNIN ******* */
    builder.addCase(signin.fulfilled, (state,{payload}) => {
      if(payload.user) {
          const user:User = payload.user
          state.data = user
          state.isAuth = true
          state.isReady = true
      } else {
          toast.error(payload.error)
      }
    })


    /****** SIGNUP ******* */
    builder.addCase(signup.fulfilled, (state,{payload}) => {
      if(payload.user) {
          const user:User = payload.user
          state.data = user
          state.isAuth = true
          state.isReady = true
          //toast.success('Vous êtes connecté')
      } else {
          toast.error(payload.error)
      }
    })


    /****** LOGOUT ******* */
    builder.addCase(logout.fulfilled, (state,{payload}) => {
      if(payload.disconnected) {
          state.data = initialState.data
          state.isAuth = false
          state.isReady = false
      } else {
          toast.error(payload.error)
      }
    })


    /****** SILENT AUTH ******* */
    builder.addCase(getAccessToken.fulfilled, (state,{payload}) => {
        if(payload.accessToken && payload.user) {
            const user:User = payload.user
            state.data = user
            state.isAuth = true
            state.isReady = true
        }
        state.isReady = true
        
    })


    .addCase(getUserData.fulfilled,(state, {payload }) =>{
      if(payload.subscriptionEnd){
        state.data.subscription.current = null
        state.data.trialIsDone = true
        toast.error('Ton abonnement est terminé')
      }
    })

    /****** UPDATE DES DONNEES ******* */
    builder.addCase(updateUserData.pending, (state) =>{
      state.requests.update = "pending"
    })

    builder.addCase(updateUserData.fulfilled, (state,{payload}) =>{
      state.requests.update = "idle"
      if(payload.user){
        state.data = payload.user
        toast.success('Données mises à jour')
      }else{
        toast.error(payload.error)
      }
    })

    builder.addCase(updateUserData.rejected, (state) =>{
      state.requests.update= "idle"
      toast.error("Impossible de mettre à jour vos données pour le moment")
    })


    /****** UPDATE DE l'AVATAR ******* */
    builder.addCase(uploadAvatar.pending, (state) =>{
      state.requests.update = "pending"
    })

    builder.addCase(uploadAvatar.fulfilled, (state,{payload}) =>{
      state.requests.update = "idle"
      if(payload.avatar){
        state.data.avatar = payload.avatar
        toast.success('Avatar modifié')
      }else{
        toast.error(payload.error)
      }
    })

    builder.addCase(uploadAvatar.rejected, (state) =>{
      state.requests.update = "idle"
      toast.error("Impossible de mettre à jour ton avatar")
    })


    /****** SUPPRESSION DE l'AVATAR ******* */
    builder.addCase(deleteAvatar.pending, (state) =>{
      state.requests.update = "pending"
    })

    builder.addCase(deleteAvatar.fulfilled, (state,{payload}) =>{
      state.requests.update = "idle"
      if(payload.avatarDeleted){
        state.data.avatar = null
        toast.success('Avatar supprimée')
      }else{
        toast.error(payload.error)
      }
    })

    builder.addCase(deleteAvatar.rejected, (state) =>{
      state.requests.update = "idle"
      toast.error("Impossible de supprimer ton avatar")
    })


    /****** Status de l'abonnement ******* */
    builder.addCase(handleSubscriptionStatus.pending, (state) =>{
      state.requests.update = "pending"
    })

    builder.addCase(handleSubscriptionStatus.fulfilled, (state,{payload}) =>{
      state.requests.update = "idle"
      const cancel_at_period_end = payload.cancel_at_period_end
      state.data.subscription.current.cancel_at_period_end = cancel_at_period_end
      toast.success(`Abonnement ${!cancel_at_period_end ? "activée" : "annulée"}`)
    })

    builder.addCase(handleSubscriptionStatus.rejected, (state) =>{
      state.requests.update = "idle"
      toast.error("Impossible de modifier ton abonnement")
    })



    builder.addCase(createCustomerId.pending, (state)=> {
      state.requests.update = "pending"
    })

    .addCase(startTrial.pending, (state)=> {
      state.requests.update = "pending"
    })

    .addCase(startTrial.fulfilled, (state,action)=> {
        if(action.payload.trial){
            state.data.subscription.current = action.payload.trial
            state.data.trialIsDone = true
        }
    })

    .addCase(startTrial.rejected, (state)=> {
      state.requests.update = "idle"
      toast.error("Impossible de démarrer ta période d'essai")
    })

    
    /******* DELETE ACCOUNT ******** */
    .addCase(deleteAccount.pending, (state) => {
      state.requests.delete = "pending"
    })
    .addCase(deleteAccount.fulfilled, (state, action) => {
      if(action.payload.success){
        state.requests.delete = "idle"
        state.data = initialState.data
        state.isAuth = false
        state.isReady = false
      }
    })
  }
})



export const {
  onTrialActivated,
  userIsReady,
} = userSlice.actions


export default userSlice.reducer