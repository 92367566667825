import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { AppDispatch } from "app/store";
import { onHandleDropSet } from "features/workoutSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MOBILE } from "utils/constants";
import DialogTitleCustom from "components/molecules/DialogTitleFullScreen";

interface Props{
    open: boolean,
    onClose: ()=> void,
    blockId: string, 
    groupId: string, 
    exerciceId: string, 
    setIndex: number,
    dropset?: {
        weight:number,
        sets: number
    }
}





export default function DialogDropset({open, onClose, blockId, groupId, exerciceId, setIndex, dropset}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [sets,setSets] = useState<number>(0)
    const [weight,setWeight] = useState<number>(20)
    const mobile = useMediaQuery(MOBILE)


    useEffect(()=>{
        if(open && dropset){
            setSets(dropset.sets)
            setWeight(dropset.weight)
        }
    },[open, dropset])


    const onValidDropset= () => {
        dispatch(onHandleDropSet({
            blockId,
            groupId,
            exerciceId,
            setIndex,
            sets,
            weight
        }))
        onClose()
    }

    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth="sm" 
            fullScreen={mobile}
        >
           
            <DialogTitleCustom
                title={`Ajouter des séries dégressives à la série ${setIndex+1}`}
                onClose={onClose}
            />
            
            <DialogContent>
                <Box sx={{display:"flex", flex:1, width: "100%", flexDirection:"column"}}>
                    <Stack>
                        <Typography sx={{marginBottom: 2}}>
                            Entre le nombre de séries dégressives que devra éffectuer ton client <strong> une fois la série {setIndex + 1} terminée </strong>
                        </Typography>
                        <TextField
                            label={"Nombre de séries dégressives"}
                            type="number"
                            value={sets}
                            onChange={(e)=> setSets(parseInt(e.target.value))}
                            sx={{marginTop: 1}}
                            fullWidth
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            label={"Diminution du poids entre chaque série (%)"}
                            type="number"
                            value={weight}
                            onChange={(e)=> setWeight(parseInt(e.target.value))}
                            sx={{marginTop: 2}}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                    </Stack>

                    {sets === 0 && (
                        <Typography sx={{marginTop: 2}}>
                            Aucune série dégressive
                        </Typography>
                    )}
                    {sets === 1 && (
                        <Typography sx={{marginTop: 2}}>
                            <strong>Une fois la série {setIndex + 1} terminée</strong>, ton client devra effectuer <strong>un maximum de répétitions</strong> sur <strong>{sets} série dégressive de plus</strong> en baissant le poids de <strong>{weight} %</strong> puis prendre un temps de repos. L'application mobile Traener calculera automatiquement le poids nécessaire.
                        </Typography>
                    )}
                    {sets > 1 && (
                        <Typography sx={{marginTop: 2}}>
                            <strong>Une fois la série {setIndex + 1} terminée</strong>, ton client devra effectuer <strong>un maximum de répétitions</strong> sur <strong>{sets} séries dégressives de plus</strong>  en baissant le poids de <strong>{weight} %</strong> entre chaque série, puis prendre un temps de repos. L'application mobile Traener calculera automatiquement le poids nécessaire.
                        </Typography>
                    )}

                </Box>
                

            </DialogContent>
            {mobile && (
                <Box sx={{paddingBottom: 2, paddingTop: 2, paddingLeft: 2, paddingRight: 2}}>
                    <Button sx={{width: "100%"}} variant="contained" onClick={onValidDropset}>
                        Valider
                    </Button>
                </Box>
            )}
            {!mobile && (
                <DialogActions>
                    <Button onClick={onValidDropset}>
                        Valider
                    </Button>
                </DialogActions>
            )}
                
            
        </Dialog>   
    )
}