import React, { useEffect, useState } from "react"
// Redux
import { useDispatch, useSelector } from "react-redux"
// Mui
import { Grid, Typography, Box, useMediaQuery } from "@mui/material"
// Interfaces
import { AppDispatch, RootState } from "app/store";
// Local components
import SubscriptionCard from "components/organisms/SubscriptionCard"
import { definePageTitle } from "features/appSlice"
import { getProducts } from "api/subscriptions";
import classes from "./styles"
import SubscriptionProduct from "interfaces/SubscriptionProduct";
import MainContent from "components/molecules/MainContent";
import Navbar from "components/organisms/NavBar";
import { DESKTOP } from "utils/constants";
const colors = ['rgb(210 222 239)', "#facf90", "#fad86b"]

// SUBSCRIPTIONS //

export default function Subscriptions(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data)
    const clients = useSelector((state:RootState) => state.clients.list.clients.length)
    const invitations = useSelector((state:RootState) => state.clients.list.invitations.length)
    const products:SubscriptionProduct[] = useSelector((state:RootState) => state.subscriptions.products)
    const desktop = useMediaQuery(DESKTOP)


    useEffect(()=>{
        dispatch(getProducts())
    },[dispatch])


    useEffect(()=>{
        dispatch(definePageTitle({pageTitle: 'Abonnements'}))
    },[clients])


        
    /*************** RENDER *********** */

    return(
        <React.Fragment>
            {/**************
             * NAVIGATION
             ***************/}

            {!desktop && (
            <Navbar
                pageTitle="Nos abonnements"
                expanded={false}
                
            />
            )}


            {/**************
             * CONTENT
             ***************/}


            <MainContent
                header="simple"
            >   
                <Grid 
                    container 
                    sx={{justifyContent:"center", padding: {xs:1, lg: 3}, paddingTop: 0 }}
                >
                    <Grid 
                        container 
                        item 
                        xs={12} 
                        xl={10} 
                        sx={{justifyContent:"center", alignItems:'center'}}
                    >

                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection: "column", mb:3}}>
                            <Typography sx={classes.title}>
                                Des tarifs clairs, abordables et sans engagement
                            </Typography>
                            {user.subscription?.current?.subscriptionId && (
                                <Typography sx={{fontSize: 16, mt:2}}>
                                    Tu es actuellement abonné à l'offre <strong>{user.subscription.current?.product?.name}</strong>
                                </Typography>
                            )}
                            <Typography sx={{fontSize: 16, mt:2}}>
                                Tu as actuellement <strong>{clients} client(s)</strong> et <strong>{invitations} invitation(s)</strong> en cours
                            </Typography>
                        </Box>

                        
                        <Grid 
                            container 
                            spacing={{xs: 6, sm: 1, lg: 6}} 
                            justifyContent='center'
                            alignItems={'center'}
                            alignSelf={"center"}
                            sx={{maxWidth: 1480, marginTop: 3,}}
                        >

                            {/***** LISTE DES ABONNEMENTS *****/}
                            {[...products].sort((a,b) => a.default_price.unit_amount > b.default_price.unit_amount ? 1 : -1).map((product:SubscriptionProduct, index)=> {

                                const currentSubscription:boolean = user.subscription?.current?.product.id === product.id
                                const hasTooManyClients = (clients + invitations) > parseInt(product.metadata.clients)

                                return(
                                    <SubscriptionCard 
                                        product={product} 
                                        current={currentSubscription} 
                                        hasTooManyClients={hasTooManyClients}
                                        key={product.id}
                                        color={colors[index]}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>

            </MainContent>
        </React.Fragment>
    )
}