import { theme } from "utils/theme"


const classes = {
    avatarContainer:{
        width: 128,
        height: 128,
        backgroundColor: "whitesmoke",
        overflow: "hidden",
        borderRadius: "100px",
        marginBottom: 2
    },

    avatarRow: {
        display:"flex",
        flexDirection: "column",
        alignItems:"center",
        justifyContent:"center",
        flex: 1,
        marginBottom: 6
    },

    name: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 700,
        color: "rgba(0,0,0,0.85)",
        
    }
}


export default classes