import { lighten } from "@mui/material"
import { BORDER_COLOR, HOVER_COLOR } from "utils/constants"



const classes = {

    container:{
        borderRadius: 100, 
        backgroundColor: "rgb(245 246 247)", 
        display:"flex", 
        flexDirection:"row",
        border: `solid 1px ${BORDER_COLOR}`,
        height: "32px !important",
        maxHeight: "32px !important",
        minHeight: "32px !important",
        /*"&:hover": {
            backgroundColor: HOVER_COLOR
        }*/
    },

    button: {
        width: 120, 
        height:32, 
        borderRadius: "100px", 
        display:"flex", 
        justifyContent:"center", 
        backgroundColor:"transparent", 
        alignItems:"center" ,
        cursor: "pointer",
        
    },

    left:{
        borderRadius: "100px 0px 0px 100px", 
    },

    right:{
        borderRadius: "0px 100px 100px 0px", 
    },

    buttonSelected: {
        backgroundColor:"rgb(8 146 243)",
        "&:hover": {
            backgroundColor: "#3a94f0"
        }
    },

    buttonSelectedDesactived: {
        backgroundColor: "rgb(112 130 142)",
        "&:hover": {
            backgroundColor: "rgb(133 162 182)"
        }
    },

    label : {
        fontWeight: 500, 
        fontSize: 15,
        color: "#74747494",
        width: "100%",
        height: "32px",
        textAlign:"center",
        lineHeight: "32px",

        "&:hover" : {
            color: "rgba(0,0,0,0.75)"
        }
    },

    labelSelected : {
        color: "white",
        "&:hover" : {
            color: "white"
        }
    }

    
   
}


export default classes