import { BODY_BGCOLOR, BORDER_COLOR, HOVER_COLOR } from "utils/constants";
import { theme } from "utils/theme";


const classes = {
  
    foodItem : {
        minHeight: {xs: 60, sm: 100},
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        width: "100%",
        border: `solid 1px ${BORDER_COLOR}`,
        paddingLeft: 3,
        borderRadius: 1,
        backgroundColor: "white",
        marginBottom: 2,
        paddingRight: {xs: 1, sm: 3}

        
    },

   

    itemLabels : {
        display:"flex",
        justifyContent:"flex-start",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
        position: "relative"
    },

    macroItem:{
        marginRight: 3,
        flexDirection: "row",
        alignItems:"center",
        marginTop: "3px",
        display: {
            xs: "none",
            sm: "flex"
        }
    },

    macroItemTitle:{
        fontSize: {
            xs: 13,
            sm: 15
        }
    },

    nameContainer : {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        overflow:"hidden",
        flex:1,
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        marginRight: 2
    },

    foodItemName : {
        textAlign: "left",
        flex: 2,
        paddingBottom: 0.5,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        maxHeight: 60,
        
    },

    macros: {
        display:"flex",
        flex: 1,
        alignItems:"flex-end",
        justifyContent:"flex-start",
        flexDirection: "row",
    },


    withBackground : {
        backgroundColor: " white",
        cursor: "pointer",
        minHeight: 110,
        marginBottom: {xs: 2, sm: 3},
        paddingRight: 3,
        border : `solid 1px ${BORDER_COLOR}`,
        "&:hover" : {
            backgroundColor: HOVER_COLOR
        }

    },


}


export default classes