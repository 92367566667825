import { useEffect, useMemo, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery} from "@mui/material"
import {BODY_MEASURES, MOBILE} from "utils/constants"
import Measure from "interfaces/Measure";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import { getUserMeasures } from "api/clients";
import { addDays, endOfMonth, endOfQuarter, endOfWeek, endOfYear, format, getMonth, getYear, isWithinInterval, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from "date-fns";
import { toast } from "react-toastify";
import { fr } from "date-fns/locale";
import { theme } from "utils/theme";
import classes from "./styles"
import StatsChart from "../../molecules/StatsChart";
import User from "interfaces/User";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";


const formatEvolution = (lastValue:number, firstValue:number, units:string) => {
    const diff = lastValue - firstValue
    if(diff<0){
        return diff.toFixed(1) + units
    }
    if(diff>=0){
        return "+" + diff.toFixed(1) + units
    }
    //{MIN_MAX?.lastValue? (parseFloat(calcDiffValues(MIN_MAX.lastValue?.value, MIN_MAX.firstValue?.value)) > 0 && "+") + parseFloat(calcDiffValues(MIN_MAX.lastValue?.value, MIN_MAX.firstValue?.value)).toFixed(1) + units : ""}

    return "+1"
}


/******** INTERFACES ********** */

interface DialogProps{
    open?: boolean,
    type: string,
    onClose?: ()=> void,
    user?: User,
}


/***************** 
 * MAIN COMPONENT 
 * ************* */


export default function DialogChartMeasure({open, type, user, onClose} : DialogProps){

    const dispatch = useDispatch<AppDispatch>()
    const [range, setRange] = useState<"month"|"year"|"quarter" |"week">("month")
    const [start, setStart] = useState<Date>(startOfMonth(new Date()))
    const [end, setEnd] = useState<Date>(endOfMonth(new Date()))
    const [isReady, setIsReady] = useState(false)
    const [measures, setMeasures] = useState<Measure[]>([])
    const [year, setYear] = useState(getYear(new Date()))
    const [yearsFetched, setYearsFetched] = useState<number[]>([getYear(new Date())])
    const mobile = useMediaQuery(MOBILE)

    /********* FONCTION DE RECUPERATION DES DONNEES ******** */

    const getData =  async (yearToFetch:number) =>{
        
        return new Promise<Measure[]>((resolve, reject) => {
            var newStart:Date, newEnd:Date
            newStart = startOfYear(new Date(`01/01/${yearToFetch}`)) // Année en cours - 1
            newEnd = endOfYear(new Date(`01/01/${yearToFetch}`)) // Année en cours + 1
            dispatch(getUserMeasures({start: newStart,end: newEnd, type, user})).unwrap().then((res)=>{
                resolve(res.measures)
            }).catch((err:any)=>{
                reject()
                toast.error('Impossible de récupérer les données')
            })
        })
    }


    /********** A L'OUVERTURE, RECUPERATION DES DONNEES ********* */
    useEffect(()=>{
        if(open && type){
            getData(year).then((values:Measure[])=> {
                if(Boolean(values)){
                    setMeasures(values)
                }else{
                    toast.error('Impossible de récupérer les données de cette année')
                }

                setYearsFetched([year])
            })
            setIsReady(true)
        }
    },[open])

    const units = useMemo(()=>{
        if(type){
        const units:string =  BODY_MEASURES.find((bodyPart) => bodyPart.label === type).units
        return units
        }else{
            return ""
        }
    },[measures, type])


    /********** ANNE CHANGE, RECUPERATION DES DONNEES le cas échéant ********* */
    useEffect(()=>{
        if(isReady){
            const yearIsAlreadyFetched = yearsFetched.find((elem)=> elem === year)
            const maxYear = Math.max(...yearsFetched)
            const minYear = Math.min(...yearsFetched)
                
            if(!yearIsAlreadyFetched){
                getData(year).then((values:Measure[])=> {
                    const currentList:Measure[] = [...measures]
                    const length:number = values.length

                    // AJOUT DES VALEURS ANNEE PRECEDENTE
                    if(year<minYear){
                        values.forEach((value, index:number)=>{
                            currentList.splice(0,0,values[(length-1) - index])
                        })
                    } 
                    // AJOUT DES VALEURS ANNEE SUIVANTE
                    else if(year>maxYear){
                        values.forEach((value, index:number)=>{
                            currentList.push(currentList[currentList.length -1],value)
                        })
                    }

                    setMeasures(currentList)
                    const list:number[] = [...yearsFetched] // Permet de voir quelles années ont déja des données récupérées pour ne pas le reproduire
                    list.push(year)
                    setYearsFetched(list)
                })
            }
        }
    },[year])


    /**************************************** 
     *          GESTION DES MOIS 
     ************************************** */

     /********** MOIS PRECEDENT ********** */
     const prevMonth = async () => {
        var newYear = year
        const currentMonth = getMonth(new Date(start))              // Mois courant (index)
        const prevMonth:number = currentMonth - 1                   // SOUSTRACTION d'un mois au mois en cours (index)
        var formatedMonth = prevMonth === -1 ? 12 : (prevMonth + 1)  // Format du mois en lisibilité humaine
        
        if(prevMonth === -1){
            newYear = year - 1
            setYear(newYear)
        }

        const lastDayOfPreviousMonth: Date = endOfMonth(new Date(`${formatedMonth}/01/${newYear}`)) // Dernier joru du mois prochain
        setStart(new Date(startOfMonth(lastDayOfPreviousMonth)))
        setEnd(new Date(lastDayOfPreviousMonth))
    }

    /********** MOIS SUIVANT ********** */
    const nextMonth = async () => {
        var newYear = year
        const currentMonth = getMonth(new Date(start))              // Mois courant (index)
        const nextMonth:number = currentMonth + 1                   // Ajout d'un mois au mois en cours (index)
        var formatedMonth = nextMonth === 12 ? 1 : (nextMonth + 1)  // Format du mois en lisibilité humaine
        
        
        if(nextMonth === 12){ // Prochaine année
            newYear = year + 1
            setYear(newYear)
        }

        const lastDayOfNexMonth: Date = endOfMonth(new Date(`${formatedMonth}/01/${newYear}`)) // Dernier joru du mois prochain
        setStart(startOfMonth(lastDayOfNexMonth))
        setEnd(lastDayOfNexMonth)
    }


    /**************************************** 
     *          GESTION DES SEMAINES 
     ************************************** */

    const prevWeek = () => {
        const prevWeekEnd:Date = endOfWeek(addDays(new Date(start),-1), {locale: fr}) 
        const prevWeekStart:Date = startOfWeek(prevWeekEnd, {locale: fr}) 

        const month = getMonth(prevWeekEnd)

        if(month === 11){
            setYear(year-1)
        }

        setStart(prevWeekStart)
        setEnd(prevWeekEnd)
    }

    const nextWeek = () => {
        var year:number = getYear(endOfWeek(new Date(start), {locale:fr}))
        const newtWeekStart:Date = startOfWeek(addDays(new Date(end),1), {locale: fr})
        const nextWeekEnd:Date = endOfWeek(newtWeekStart, {locale: fr})

        const month = getMonth(newtWeekStart)

        if(month === 0){
            setYear(year+1)
        }
        
        setStart(newtWeekStart)
        setEnd(nextWeekEnd)
    }



    /**************************************** 
     *          GESTION DES TRIMESTRES 
     ************************************** */


    /********** Trimestre PRECEDENT ********** */
    const prevQuarter = () => {
        const prevQuarterEnd:Date = endOfQuarter(addDays(new Date(start),-1)) 
        const prevQuarterStart:Date = startOfQuarter(prevQuarterEnd) 

        const month = getMonth(prevQuarterEnd)

        if(month === 11){
            setYear(year-1)
        }
        
        
        setStart(prevQuarterStart)
        setEnd(prevQuarterEnd)
    }

    /********** Trimestre SUIVANT ********** */
    const nextQuarter = () => {
        var year:number = getYear(endOfWeek(new Date(start), {locale:fr}))
        const nextQuarterStart:Date = startOfQuarter(addDays(new Date(end),1))
        const nextQuarterEnd:Date = endOfQuarter(nextQuarterStart)

        const month = getMonth(nextQuarterStart)
        if(month === 0){
            setYear(year+1)
        }
        
        setStart(nextQuarterStart)
        setEnd(nextQuarterEnd)
    }


    /**************************************** 
     *          GESTION DES Années 
     ************************************** */


    /********** MOIS PRECEDENT ********** */
    const prevYear = () => {
        const newYear = year - 1
        setStart(startOfYear(new Date(`01/01/${newYear}`)))
        setEnd(endOfYear(new Date(`01/01/${newYear}`)))
        setYear(newYear)

    }

    /********** MOIS PRECEDENT ********** */
    const nextYear = () => {
        const newYear = year + 1
        setStart(startOfYear(new Date(`01/01/${newYear}`)))
        setEnd(endOfYear(new Date(`01/01/${newYear}`)))
        setYear(newYear)
    }


    /**************************************** 
     *          GESTION DES RANGE 
     ************************************** */

    const selectMonthRange = () => {
        setStart(startOfMonth(start))
        setEnd(endOfMonth(start))
        setRange('month')
    }


    const selectQuarterRange = () => {
        setStart(startOfQuarter(start))
        setEnd(endOfQuarter(start))
        setRange('quarter')
    }

    const selectYearRange = () => {
        setStart(startOfYear(start))
        setEnd(endOfYear(start))
        setRange('year')
    }

    const selectWeekRange = () => {
        setStart(startOfWeek(start, {locale: fr}))
        setEnd(endOfWeek(start, {locale: fr}))
        setRange('week')
    }



    const MIN_MAX:{firstValue:Measure, lastValue:Measure} = useMemo(()=>{
        const selected = measures.filter((measure)=> isWithinInterval(new Date(measure.date), {start, end}))

        if(selected.length>0){
            const firstValue = selected[0]
            const lastValue = selected.length > 1 ? selected[selected.length-1] : null
            return {
                firstValue,
                lastValue
            }
        } else {
            return null
        }
       
    },[range,start,end, measures])

    




    /********* JSX ******** */

    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"xl"}
            fullScreen={mobile? true:false}
            TransitionComponent={DialogTransition}
        >

            <DialogAppBar
                title={BODY_MEASURES.find((bodyPart) => bodyPart.label === type)?.fr}
                onClose={onClose}
            />
            
            <DialogContent
                style={{padding: mobile? 0 : 3}}
            >

                <Box sx={classes.header}>

                        {/******** BOUTON DE SELECTION DE L'INTERVAL ********** */}

                        <Box sx={classes.rangeButtons}>

                            <Button 
                                variant="outlined" 
                                onClick={selectWeekRange} 
                                sx={[classes.button, range === "week" && classes.selectedRange]}
                            >
                                1 semaine
                            </Button>
                            <Button 
                                variant="outlined" 
                                onClick={selectMonthRange} 
                                sx={[classes.button, range === "month" && classes.selectedRange]}
                            >
                                1 mois
                            </Button>
                            {!mobile && (
                                <Button 
                                    variant="outlined" 
                                    onClick={selectQuarterRange} 
                                    sx={[classes.button, range === "quarter" && classes.selectedRange]}

                                >
                                    3 mois
                                </Button>
                            )}
                            {!mobile && (
                            <Button 
                                variant="outlined" 
                                onClick={selectYearRange} 
                                sx={[classes.button, range === "year" && classes.selectedRange]}

                            >
                                1 an
                            </Button>
                            )}
                        </Box>


                       

                        {/******** MOIS DU GRAPHIQUE ********** */}
                        {range === "month" && (
                            <Box sx={classes.periodIndicator}>

                                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between", width: 350}}>
                                    <IconButton onClick={prevMonth} disabled={!isReady}>
                                        <ChevronLeft />
                                    </IconButton>

                                    <Typography 
                                        sx={{fontSize: theme.typography.pxToRem(18)}}
                                    >
                                        {format(endOfWeek(new Date(start), {locale: fr}),"MMMM", {locale: fr})} {format(new Date(start),"yyyy", {locale: fr})}
                                    </Typography>

                                    <IconButton onClick={nextMonth} disabled={!isReady}>
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}


                        {/******** Trimestre DU GRAPHIQUE ********** */}
                        {range === "quarter" && (
                            <Box sx={classes.periodIndicator}>

                                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between", width: 350}}>
                                    <IconButton onClick={prevQuarter} disabled={!isReady}>
                                        <ChevronLeft />
                                    </IconButton>

                                    <Typography 
                                        sx={{fontSize: theme.typography.pxToRem(18)}}
                                    >
                                        {format(start,"MMMM", {locale: fr})} à {format(end,"MMMM", {locale: fr})} {format(new Date(start),"yyyy", {locale: fr})}
                                    </Typography>

                                    <IconButton onClick={nextQuarter} disabled={!isReady}>
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}


                        {range === "week" && (
                            <Box sx={classes.periodIndicator}>

                                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between", width: 350}}>
                                    <IconButton 
                                        onClick={prevWeek} 
                                        disabled={!isReady}>
                                            <ChevronLeft />
                                    </IconButton>

                                    <Typography 
                                        sx={{fontSize: theme.typography.pxToRem(18)}}
                                    >
                                        {format(start,"dd MMM", {locale: fr})} à {format(end,"dd MMM", {locale: fr})} {format(new Date(start),"yyyy", {locale: fr})}
                                    </Typography>

                                    <IconButton 
                                        onClick={nextWeek} 
                                        disabled={!isReady}
                                    >
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}

                        {/******** ANNEE DU GRAPHIQUE ********** */}
                        {range === "year" && (
                            <Box sx={classes.periodIndicator}>

                                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between", width: 350}}>
                                    <IconButton onClick={prevYear} disabled={!isReady}>
                                        <ChevronLeft />
                                    </IconButton>

                                    <Typography 
                                        sx={{fontSize: theme.typography.pxToRem(18)}}
                                    >
                                        {year}
                                    </Typography>

                                    <IconButton onClick={nextYear} disabled={!isReady}>
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}

                        {(MIN_MAX) && (
                            <Typography>
                                {/*** PREMIERE VALEURE */}
                                <strong>
                                    {MIN_MAX?.firstValue?.value + units}
                                </strong> 

                                {MIN_MAX.lastValue && (
                                    <>
                                {/*** SEPARATOR */}
                                <span>
                                    {MIN_MAX?.lastValue? " => " : ""}
                                </span>
                                {/*** DEUXIEME VALEURE */}
                                <strong>
                                    {MIN_MAX?.lastValue? MIN_MAX?.lastValue?.value + units : ""}
                                </strong> 
                                {/*** Sépration avec évolution */}
                                <span>
                                    {MIN_MAX?.lastValue? " | " : ""}
                                </span>
                                <strong>
                                    {formatEvolution(MIN_MAX?.lastValue?.value, MIN_MAX?.firstValue?.value, units)}
                                </strong>
                                </>
                                )}
                            </Typography>
                        )}

                        {(!MIN_MAX) && (
                            <Typography>
                                Aucune donnée
                            </Typography>
                        )}
                </Box>

                {/******** Le graph ********** */}
                {(type && open) && (
                <StatsChart
                    measures={measures}
                    type={type}
                    start={start}
                    end={end}
                    yearsFetched={yearsFetched}
                    interval={range}
                    year={year}
                    dialogIsReady={isReady}
                />
                )}
            </DialogContent>
            
            {!mobile && (
                <DialogActions>
                    <Button
                        onClick={onClose}
                    >
                        Fermer
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}