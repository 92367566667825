import { Box, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import classes from "./styles";
import { Add, Close, Delete } from "@mui/icons-material";
import { WorkoutExercice } from "interfaces/Workout";
import { createSelector } from "@reduxjs/toolkit";
import { getExerciceDetails } from "function/getExerciceDetails";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { ExerciceMetrics } from "interfaces/Exercice";
import { addMetricToExercice, onChangeExerciceMetric, onDeleteExerciceMetric } from "features/workoutSlice";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import DialogTitleFullScreen from "components/molecules/DialogTitleFullScreen";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";

interface Props{
    open: boolean,
    onClose: ()=>void,
    exerciceId: string,
    blockId: string,
    groupId: string,
}


const exerciceSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice
    }
)



const metricsSelector = createSelector(
    (state:RootState) => state.metrics,
    (metrics) => {
        const listOfMetrics = [...metrics.list]
        const list = listOfMetrics.sort((a, b) => {
            var textA = a.label.fr.toUpperCase();
            var textB = b.label.fr.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }); 

       return list
    }
)

export default function DialogExerciceSettings({open, onClose, blockId, groupId, exerciceId}:Props){


    const exercice = useSelector((state:RootState)=>exerciceSelector(state, groupId, exerciceId, blockId) )
    
    const metricsList:ExerciceMetrics[] = useSelector(metricsSelector)
    const dispatch = useDispatch<AppDispatch>()


    /******** CHANGEMENT DE METRIC ******* */

    const onChangeMetric = (metric:ExerciceMetrics,column:number) => () => {
        setTimeout(()=>dispatch(onChangeExerciceMetric({
            blockId, 
            exerciceId,
            column, 
            groupId,
            metric
        })), 50 )
    }


    /******* AJOUTER UNE COLONNE ***********/ 
    const onAddMetric = () => {
        const listOfUsedMetrics:string[] = []
        // On récupère l'ID de tous les metrics utilisés
        exercice?.metrics.forEach((elem)=>{
            listOfUsedMetrics.push(elem._id)
        })
        // ON cherche le premier metric qui n'est pas utilisé
        const findFirstMetric = metricsList.find((elem) => {
            if(!listOfUsedMetrics.includes(elem._id)) return elem
        })
        dispatch(addMetricToExercice({blockId,groupId,exerciceId, newMetric: findFirstMetric}))
    }


    const onDeleteColumn = (index:number) => () => {
        dispatch(onDeleteExerciceMetric({blockId,groupId,exerciceId,metricIndex: index}))
    }



    return(
        <Dialog
            open={open}
            fullScreen={true}
            TransitionComponent={DialogTransition}
        >
           
           <DialogAppBar 
            title={"Configuration"}
            onClose={onClose}
           />
            

            {/***** CONTENT **** */}
            <DialogContent sx={classes.content}>

                <Box sx={classes.exerciceName}>
                    {exercice.exercice.name.fr}
                </Box>
                {exercice?.metrics.map((metric:ExerciceMetrics, metricIndex:number)=>(
                    <Box 
                    sx={[classes.metric]} 
                    className={"metrics"}
                    key={metric._id}>

                        {/**** TEXTFIELD DU METRIC ******* */}

                        <TextField
                            sx={classes.metricTextField}
                            defaultValue={metric._id}
                            select={true}
                            fullWidth={true}
                            size="small"
                            
                        >

                            {/**** LISTE DES OPTIONS (LISTE DES METRICS) ******* */}

                            {metricsList.map((optionMetric: ExerciceMetrics, optionIndex:number) => {

                                // ON Recherche les metrics déja utilisés par l'exercice pour les désactiver
                                const disabled = exercice.metrics.find(elem => elem._id === optionMetric._id) ? true : false

                                return (
                                    <MenuItem 
                                        key={optionIndex} 
                                        value={optionMetric._id} 
                                        disabled={disabled}
                                        onClick={onChangeMetric(optionMetric,metricIndex)}
                                    >
                                        {optionMetric.label.fr}
                                    </MenuItem>
                                )
                            })}
                            
                            {/* SUPPRESSION D'UNE COLONNE 
                            {metricIndex!==0 && (
                                <MenuItem sx={{color: "primary.main", fontWeight: 700}} onClick={onDeleteColumn(metricIndex)}>
                                    Supprimer
                                </MenuItem>
                            )}
                            */}
                        </TextField>

                        <IconButton 
                            onClick={onDeleteColumn(metricIndex)}
                            disabled={exercice?.metrics.length === 1}
                        >
                            <Delete />
                        </IconButton>
                </Box>
                ))}

        
                <Box>
                    <SecondaryButton 
                        onClick={onAddMetric} 
                        disabled={exercice?.metrics.length === 3}
                        startIcon={<Add/>}
                    >
                        Ajouter
                    </SecondaryButton>
                </Box>
                
            </DialogContent>
        </Dialog>
    )
}