
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material"
import classes from "./styles"
import { HEADER_MIN_HEIGHT, HEADER_MIN_HEIGHT_EXPANDED, LIGHT_BUTTON_COLOR, MOBILE } from "utils/constants"
import PageTitle from "components/atoms/Typography/PageTitle"
import { ArrowBackIos, Instagram, Menu, StarRate, SvgIconComponent } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
import { handleDrawer } from "features/appSlice"
import AutosaveIndicator from "components/molecules/AutosaveIndicator"
import ContainedButton from "components/atoms/Buttons/ContainedButton"
import { useState } from "react"
import DialogTruspilot from "components/molecules/DialogTrustPilot"
import { theme } from "utils/theme"


interface HeaderProps{
    expanded?: boolean,
    children?: React.ReactNode,
    pageTitle?:string,
    defineTabs?: (index:number)=> void,
    hideTabs?:boolean,
    userProfile?: boolean,
    showDisconnect?: boolean
    profile?:boolean,
    tabs?:number,
    backButton? : ()=> void,
    fullWidth?: boolean,
    action?: {
        Icon: SvgIconComponent,
        callback: (e?:any)=>void
    },
    showAutoSave?: boolean,
    showDraft?: boolean,
    showInstagram?: boolean,

}


export function Toolbar({expanded = false, children}:HeaderProps){
    return(
        <Box sx={[
            classes.toolbar, 
            {
                flexDirection: expanded ? "column" : "row",
                alignItems: expanded? "flex-start" : "center"
            }
        ]}>
            {children}
        </Box>
    )
    
}



export default function Navbar({
        expanded, 
        pageTitle = "Page",
        profile = false,
        backButton,
        fullWidth,
        action,
        showAutoSave,
        showDraft,
        showInstagram,

    }:HeaderProps){

    const dispatch = useDispatch<AppDispatch>()
    const [openTruspilot, setOpenTrustPilot] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const user = useSelector((state:RootState) => state.user.data)

    const onOpenTruspilot = () => {
        setOpenTrustPilot(true)
    }


    const goToInstagram = () => {
        window.open("https://www.instagram.com/traenerapp/", '_blank');
    }


    return(
        <>
        <Box 
            sx={[
                classes.header,
                {
                    height: expanded ? HEADER_MIN_HEIGHT_EXPANDED : HEADER_MIN_HEIGHT
                }, 
                
            ]}
        >
            <Grid 
                container 
                justifyContent="center"
            >
                <Grid 
                    item 
                    xs={12} 
                    sm={12} 
                    md={12} 
                    lg={fullWidth? 12 : 10} 
                    xl={fullWidth ? 12 : 8}
                >

                    <Toolbar
                        expanded={expanded}
                    >

                        {/*************************
                         * PAGE TITLE + HAMBURGER
                         *************************/}
                       
                        <Box
                            sx={classes.buttonTitle}
                        >

                            {/****************** 
                             *      HAMBURGER
                             * *****************/}
                       
                             {!backButton && (
                                <IconButton
                                    sx={classes.iconButton}
                                    onClick={()=>dispatch(handleDrawer({open:true}))}
                                >
                                    <Menu 
                                        sx={{color: "rgba(0,0,0,0.85)"}}
                                    />
                                </IconButton>
                             )}


                             {/****************** 
                             *      BACK
                             * *****************/}

                            {backButton && (
                                <IconButton
                                    sx={[
                                        classes.iconButton, 
                                        classes.backButton
                                    ]}
                                    onClick={()=>backButton()}
                                >
                                    <ArrowBackIos 
                                        sx={{color: "rgba(0,0,0,0.85)", fontSize: 18}}
                                    />
                                </IconButton>
                             )}
                            

                           
                            <Box>

                                {/****************** 
                                 *      PAGE TITLE 
                                 * *****************/}

                                {!profile && (
                                    <PageTitle>
                                        {pageTitle}
                                    </PageTitle>
                                )}

                                {/****************** 
                                 *      PROFILE HEADER
                                 * *****************/}


                                
                            </Box>
                            
                        </Box>



                        

                        
                        {/*************************
                         * ACTION + AUTOSAVE
                         *************************/}

                        <Box
                            sx={{display:"flex", flexDirection:"row"}}
                        >

                            {(showInstagram) && (
                                <Box>
                                    {!mobile && (
                                        <ContainedButton 
                                            onClick={goToInstagram}
                                            startIcon={<Instagram />} 
                                            sx={{backgroundColor: LIGHT_BUTTON_COLOR,}}
                                        >
                                            Nous suivre
                                        </ContainedButton>
                                    )}

                                    {mobile && (
                                        <IconButton 
                                            onClick={goToInstagram}
                                            sx={{backgroundColor: LIGHT_BUTTON_COLOR,}}
                                        >
                                            <Instagram sx={{color: theme.palette.primary.main,}} />
                                        </IconButton>
                                    )}



                                    {!mobile && user.subscription?.current?.status === "active" && (
                                        <ContainedButton 
                                            onClick={onOpenTruspilot}
                                            startIcon={<StarRate />} 
                                            sx={{backgroundColor: LIGHT_BUTTON_COLOR,}}
                                        >
                                            Noter Traener
                                        </ContainedButton>
                                    )}
                                </Box>
                            )}
 

                            {showAutoSave && (
                                <AutosaveIndicator
                                showDraft={showDraft}
                                />
                            )}

                            {action && (
                                <Box
                                    onClick={(event: React.MouseEvent<HTMLElement>)=> action.callback(event)}
                                    sx={classes.actionButton}
                                >
                                    <action.Icon />
                                </Box>
                            )}

                        </Box>

                    </Toolbar>
                
                </Grid>

            </Grid>
                
        </Box>

        <DialogTruspilot
            open={openTruspilot}
            onClose={()=>setOpenTrustPilot(false)}
        />
        </>
    )
}