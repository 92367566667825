import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import Loader from "components/molecules/Loader";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import { EventCard } from "components/molecules/EventCard";
import { HistoryEventDetails } from "interfaces/User";
import { addDays, format, isSameDay } from "date-fns";
import { getClientHistory } from "api/clients";
import EmptySearch from "components/molecules/EmptySearch";
import { fr } from "date-fns/locale";
import CustomIconButton from "components/atoms/Buttons/IconButton";
import EventsFilters from "../EventsFilters";
import { FilterList } from "@mui/icons-material";


export default function ClientHistory(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.clients.profile)
    const pending = useSelector((state:RootState)=> state.clients.requests.getClientHistory === "pending")
    const history:HistoryEventDetails[] = useSelector((state:RootState) => state.clients.clientHistory)
    const [filter, setFilter] = useState<string>('3')
    const [range, setRange] = useState<{start:Date, end: Date}>({start: addDays(new Date(), -3), end: new Date()})
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const filters = useSelector((state:RootState)=> state.clients.clientHistoryFilters)

   

    const handleChangeFilter = (event: SelectChangeEvent) =>{
        setFilter(event.target.value as string)
    }


    /********************************
     * Récupération de l'historique
     ********************************/

    useEffect(()=>{
        const startDate = addDays(new Date(range.end), -(parseInt(filter)-1))
        if(user?._id){
            dispatch(getClientHistory({
                user, 
                start: startDate, 
                end: new Date()
            }))
        }
    },[filter, user])


    /********************** 
     * FILTRAGE DES EVENTS 
     **********************/

    const filteredEvents:HistoryEventDetails[] = useMemo(() => {
        
        var workouts:HistoryEventDetails[] = []
        var checkups:HistoryEventDetails[] = []
        var measures:HistoryEventDetails[] = []
        var meals:HistoryEventDetails[] = []

    
        workouts = history.filter((elem)=> {
            return elem.action === "workout"
        })
        

        checkups = history.filter((elem)=> {
            return elem.action === "questionnaire"
        })
        

        measures = history.filter((elem)=> {
            return elem.action === "measure"
        })

        meals= history.filter((elem)=> {
            return elem.action === "meal"
        })
        

        const events = workouts?.concat(checkups).concat(measures).concat(meals)

        const sortedEvents = [...events.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())]

        // Filtrage selon les choix users
        var filtered:HistoryEventDetails[] = []

        if(filters.length === 0){
            filtered = [...sortedEvents]
        } else {

            filters.forEach((elem)=> {
                filtered = filtered.concat([...sortedEvents].filter((event) => event.action === elem))
            })
        }


        return filtered


    },[history,user, filters])


    /**************************************** 
     * REGROUPEMENT DES EVENEMENTS PAR JOUR
     * ***************************************/


    const SORTED_EVENTS_BY_DAY = useMemo(()=>{

        const eventsByDate:{date: Date, events: HistoryEventDetails[]}[] = []
        const parsedFilter = parseInt(filter)
        Array.from(Array(parsedFilter)).forEach((_, index)=> {
            const date = addDays(new Date(range.end), -index)
            const eventsFound = filteredEvents.filter((event)=> isSameDay(new Date(event.date), date))
            if(eventsFound.length>0){
                eventsByDate.push({
                    date,
                    events: eventsFound
                })
            }
        })

        return eventsByDate
    },[filteredEvents, filter])


    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget)
    }


    return(
        <>
            <Grid 
                container 
                justifyContent="center" 
                spacing={0}
            >
                <Grid 
                    item xs={12} 
                    sm={12} 
                    md={12} 
                    lg={10} 
                    xl={8}
                >
                    {/********************* 
                    *        HEADER 
                    ***********************/}
                    
                    <Box 
                        sx={classes.feedHeader}
                    >

                                    <Typography 
                                        sx={classes.sectionTitle}
                                    >
                                        Activité 
                                    </Typography>


                        <Box
                            sx={{display:"flex", flexDirection: "row", alignItems:"center"}}
                        >

                            {/*********************
                             * BOUTON DE FILTRE
                             *********************/}

                            <CustomIconButton
                                Icon={FilterList}
                                onClick={onOpenFilterList}
                                white={true}
                                darkerBorder={true}
                                marginRight={1}
                                badge={filters.length}
                                showLabel={true}
                            />

                            {/*********************
                             * LISTE DES FILTRES
                             *********************/}

                            <EventsFilters
                                open={Boolean(anchorFilterList)}
                                onClose={()=>setAnchorFilterList(null)}
                                anchorEl={anchorFilterList}
                                forClient={true}
                            />


                        <Select 
                            value={filter}
                            size="small"
                            onChange={handleChangeFilter}
                            sx={{backgroundColor: "white"}}
                            MenuProps={{
                                disableScrollLock: true
                            }}
                            inputProps={{
                                sx:{
                                    border: `none`,
                                }
                            }}
                        >
                            <MenuItem 
                                value={3}>
                                    3 derniers jours
                            </MenuItem>
                            <MenuItem 
                                value={7}>
                                    7 derniers jours
                            </MenuItem>
                            <MenuItem 
                                value={15}>
                                    15 derniers jours
                            </MenuItem>
                        </Select>
                        </Box>

                    </Box>

                    {/********************* 
                    *        LOADER 
                    ***********************/}

                    {pending && (
                        <Loader />
                    )}


                    {/***********************
                     * LISTE DES EVENNEMENTS 
                     ***********************/}

                    {!pending && SORTED_EVENTS_BY_DAY?.map((eventDate, index) => {
                       return(
                        <Box
                            key={index}
                            sx={classes.blockEvents}
                        >
                            <Typography
                                sx={classes.date}
                            >
                                {isSameDay(eventDate.date, new Date()) ? "Aujourd'hui" : format(eventDate.date, "eeee dd MMM", {locale: fr})}
                            </Typography>

                            <Box>
                            {!pending && eventDate.events?.map((event, historyIndex:number) => {
                                return(
                                    <Box 
                                        key={historyIndex} 
                                        sx={classes.eventContainer}
                                    >
                                        <Box 
                                            sx={classes.eventsTimeline}
                                        />
                                        <EventCard 
                                            event={event} 
                                        />
                                    </Box>
                                )
                            })}
                            </Box>
                        </Box>
                    )
                    })}


                    {/****************** 
                     * AUCUN EVENEMENT 
                     * *************** */}

                    {!pending && filteredEvents?.length ===0 && (
                        <EmptySearch
                            description={`Il semblerait que ton client n'ait aucune activité sur les ${filter} derniers jours`}
                        />
                                    
                    )}

                </Grid>
            </Grid>

        </>
    )
}