
import { useEffect, useMemo, useState } from "react"
// MUI
import {Box, TextField, InputAdornment, IconButton, Typography, Paper, Stack, Grid, Card, useMediaQuery} from "@mui/material"
import {VisibilityOff, Visibility, CheckCircle} from "@mui/icons-material/"
// REDUX
import { useDispatch } from "react-redux"
import { AppDispatch } from "app/store"
// FEATURES
import classes from "./styles"
import { theme } from "utils/theme"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import { signup } from "api/user"
import { MOBILE } from "utils/constants"



/*********** SIGNIN ************** */

export default function Signup(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const [showPassword, setShowPassword] = useState(false)
    const [firstname, setFirstname] = useState<string>("")
    const [lastname, setLastname] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [submiting, setSubmiting] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    
    /******* AFFICHAGE DU PASSWORD ********* */
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }



    useEffect(() => {
        const onKeyDown = (e:any) => {
         if(e.key === 'Enter'){
            onSubmit()
         }
        }
       
        window.addEventListener('keydown', onKeyDown)
        return () => window.removeEventListener('keydown', onKeyDown)
    },[email,firstname,lastname,password, confirmPassword])


    /******* SOUMISSION DU FORMULAIRE ********* */
    const onSubmit = async () => {

        if(firstname === ""){
            toast.error('Renseigne ton prénom')
            return
        }

        if(lastname === ""){
            toast.error('Renseigne ton nom')
            return
        }

        if(lastname === ""){
            toast.error('Renseigne ton nom')
            return
        }

        const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/

        if(!regex.test(password)){
            toast.error('Le format du mot de passe est incorrect')
            return
        }

        if(password !== confirmPassword){
            toast.error('Les mots de passe ne correspondent pas')
            return
        }


        setSubmiting(true)
        await dispatch(signup({email,firstname,lastname,password}))
        setSubmiting(false)

    }


    
    const goToSignin = () => {
        history.push('/login')
    }


    const validatePasswordFormat = useMemo(()=>{
        var characters = false
        var number = false
        var capital= false

        const charactersRegex = /.{8}/
        if(charactersRegex.test(password)){
            characters = true
        }

        const regexNumber = /([0-9]){1}/
        if(regexNumber.test(password)){
            number = true
        }

        const regexCapital = /([A-Z]){1}/
        if(regexCapital.test(password)){
            capital = true
        }

        return {
            characters,number,capital
        }

    },[password])



    return(
        <Box 
            sx={[classes.layout]} 
            className="layout"
        >
                <Typography sx={classes.title}>
                    Démarre ta période d'essai
                </Typography>
                <Typography sx={classes.subtitle}>
                    14 jours pour tester l'application Traener gratuitement. Sans engagement, sans carte bleue, tu es libre d'arrêter à tout moment.
                </Typography>
        
                <Card 
                    sx={classes.card}
                    elevation={mobile ? 0 : 1}
                >

                    <Box 
                        sx={classes.logoContainer}>
                        <Box 
                            component="img" 
                            src="/assets/logo.svg" 
                            sx={{ width: "auto", height: mobile? 20 : 24}} 
                        />
                    </Box>

                    {/****** FORMULAIRE ******* */}
                        
                    <Stack sx={{width: "100%"}}>
                            <form>
                           
                            {/***** NOM ****** */}
                            <Typography sx={classes.textfieldLabel}>
                                Nom
                            </Typography>
                            
                            <TextField 
                                value={lastname}
                                fullWidth={true} 
                                sx={{marginBottom: 2.5}}
                                onChange={(e) => setLastname(e.target.value)}
                                autoComplete="family-name"
                                name="family-name"
                                placeholder="Ton nom"
                            />
                        
                    
                        
                            <Typography sx={classes.textfieldLabel}>
                                Prénom
                            </Typography>
                            
                            <TextField 
                                value={firstname}
                                fullWidth={true} 
                                sx={{marginBottom: 2.5}}
                                onChange={(e) => setFirstname(e.target.value)}
                                autoComplete="given-name"
                                name="firstname"
                                placeholder="Ton prénom"

                            />
                            
                       
                    
                            <Typography sx={classes.textfieldLabel}>
                                E-mail
                            </Typography>
                        
                    
                            <TextField 
                                value={email}
                                type="email"
                                fullWidth={true} 
                                sx={{marginBottom: 2.5}}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete='email'
                                name="email"
                                placeholder="Ton adresse email"


                            />
                        
                            <Typography sx={classes.textfieldLabel}>
                                Mot de passe
                            </Typography>

                        
                            <TextField 
                                name="new-password"
                                value={password}
                                fullWidth={true} 
                                sx={{marginBottom: 2.5}}
                                type={showPassword ? "text":"password"}
                                onChange={(e)=> setPassword(e.target.value)}
                                helperText={
                                    <Box sx={classes.passwordHelper}>
                                        <Box sx={classes.passwordHelperElem}>
                                            <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.characters && {color: "#57d657",}]} />
                                            8 caractères
                                        </Box>
                                        <Box sx={classes.passwordHelperElem}>
                                        <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.capital && {color: "#57d657",}]} />
                                            1 majuscule
                                        </Box>
                                        <Box sx={classes.passwordHelperElem}>
                                        <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.number && {color: "#57d657",}]} />
                                            1 chiffre
                                        </Box>
                                    </Box>
                                }
                                placeholder="Ton mot de passe"
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                
                            />
                        
                            <Typography sx={classes.textfieldLabel}>
                                Confirmer le mot de passe
                            </Typography>

                        
                            <TextField 
                                name="confirm-password"
                                value={confirmPassword}
                                onChange={(e)=> setConfirmPassword(e.target.value)}
                                fullWidth={true} 
                                sx={{marginBottom: 2.5}}
                                type="password"
                                autoComplete="new-password"
                                placeholder="Confirme ton mot de passe"

                                
                            />

                        </form>
                    
                    </Stack>
                    <Box sx={classes.buttonContainer}>
                        <LoadingButton 
                            onClick={onSubmit}
                            fullWidth={true} 
                            variant="contained" 
                            sx={{borderRadius:3, height: 48, minWidth: "100%", fontSize: 18}}
                            disableElevation={true}
                            disabled={submiting}
                            loading={submiting}
                        >
                            Créer un compte
                        </LoadingButton>
                    </Box>

                    <Box sx={classes.cguContainer}>
                        <Typography sx={classes.cgu}>
                            En t'inscrivant, tu acceptes implicitement <a href="https://traener.com/cgv">nos conditions générales de ventes (CGV)</a>.
                        </Typography>
                    </Box>
                </Card>
        
            
                <Box sx={{display:"flex", flexDirection: "column", marginTop: 2, alignItems:"center"}}>
                    <Typography sx={{fontSize: theme.typography.pxToRem(16)}}>
                        Tu as déjà un compte ?
                    </Typography>
                    <Typography 
                        sx={{fontWeight: 700, fontSize: theme.typography.pxToRem(16), color: "primary.main", cursor: "pointer", marginBottom: 3}} 
                        onClick={goToSignin}
                    >
                        Retourner à l'écran de connexion
                    </Typography>
                </Box>
        </Box>
    )
}