import { LocalFireDepartment } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import calcMacros from "function/calcMacros";
import Meal from "interfaces/Meal";
import classes from "./styles";


interface Props{
    meals: Meal[]
}

export default function Kcal({meals}:Props){
    return(
        
            <Box
                sx={classes.kcalContainer}
            >
            <LocalFireDepartment 
                sx={{  marginRight: 1, color: "#e75023", fontSize: 18}}
            />
            <Typography
                sx={classes.kcal}
            >
                {calcMacros(meals)?.kcal} kcal
            </Typography>
        </Box>
    )
}