import { darken } from "@mui/material"
import { BORDER_COLOR, HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {



    imageSelector : {
        display:"flex",
        width: "100%",
        height: 300,
        backgroundColor: "#edf2fc",
        borderBottom: `solid 6px ${theme.palette.primary.main}`,
        overflow: "hidden",
        justifyContent:"center",
        alignItems:"center",
        position: "relative"
    },

    imageButton: {
        position :"absolute",
        top: 15,
        right: 15,
        zIndex: 3,
        backgroundColor: HOVER_COLOR,
        borderRadius: 10
    },



thumbnail:{
    width: "100%",
    backgroundColor: "whitesmoke",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    overflow: "hidden",
    height: "auto",
    objectFit: "cover"
},


thumbnailSelected: {
    cursor: "pointer",
    opacity: 1,
    overflow: "hidden"
},

thumbnailItem: {
    backgroundColor: "black",
    width: "100%"
},



imageTabs : {
    display:"flex",
    flexDirection: "row",
},

imageTab : {
    display:"flex",
    flex: 1,
    height: 45,
    backgroundColor: "white",
    alignItems:"center",
    justifyContent:"center",
    cursor: "pointer",
    color: "#adadad",
    "&:hover": {
        backgroundColor: HOVER_COLOR
    }
},

imageTabSelected : {
    backgroundColor: darken(HOVER_COLOR, 0.01),
    color: theme.palette.primary.main

},

}

export default classes