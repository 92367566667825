import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes= {

    title: {
        fontWeight: 700,
        
    },

    content : {
        backgroundColor: "#f6f6f6", 
        overflowY: {
            xs: "auto",
            lg: "hidden", 
        },
        padding: 0,
    },

    scrollBar: {
        height:"calc(100vh - 90px - 64px - 53px)", 
        paddingRight: 3
    },

    resume:{
        paddingBottom: 3,
        borderBottom: "solid 1px whitesmoke",
        backgroundColor: "white",
        padding: 2,
        marginTop: 3,
        marginLeft: {
            xs: 1, 
            sm: 3, 
            md:3
        },
        marginRight: {
            xs: 1,
            sm: 3
        }
    },

    row: {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1
    },

    rowLabel : {
        display:"flex",
        alignItems:"center",
        flexDirection:"row",
        justifyContent:"center"
    },

    value: {
        fontWeight: 700
    },

    block: {
        backgroundColor: "white",
        marginTop: 3,
        marginRight: {
            xs: 1,
            sm: 3,
            md: 3
        },
        padding: {
            xs: 2,
            sm: 3
        },
        marginLeft: {
            xs: 1, 
            sm: 3,
            lg: 0
        },
    },
    

    blockName: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 700,
        
    },


    blockTrack : {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 400,
    },

    exercice:{
        display:"flex",
        flexDirection: "column",
        position: "relative"
    },

    imageContainer:{
        width: 60,
        height: 60,
        borderRadius: 100,
        overflow: "hidden",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
    },

    exerciceImage:{
        width: 110,
    },

    replacedIndicator:{
        position: "absolute",
        top: "48px",
        left: "55px",
        width: 30,
        height: 30,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 100,
        justifyContent:"center",
        alignItems:"center",
        display: "flex"
    },

    header: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "#f9f9f9",
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight:2
    },

    exerciceHeaderInfos:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    exerciceName: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        marginLeft: 2
    },

    exerciceSetsNumber: {
        marginLeft: 2,
        fontSize: theme.typography.pxToRem(14)
    },

    exerciceContent:{
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight: {
            xs: 0,
            sm: 2,
        },
        paddingTop: 2
    },

    setHeader:{
        display:"flex",
        flexDirection: "row",
        width: "100%",
        justifyContent:"space-between",
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        paddingBottom: 1.5,
    },

    setHeaderLabel : {
        color: "black",
        fontWeight: 800,
        textTransform: "uppercase",
        fontSize: theme.typography.pxToRem(14),
        display:"flex",
        flexGrow: 1,
        flex: 1,
        justifyContent:"center"
    },

    set : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
    },

    withoutBorder : {
        borderBottom: 0,
    },

    setNumber: {
        width: {
            xs: "auto",
            sm: 100
        },
        maxWidth:{
            xs: "auto",
            sm: 100
        },
        minWidth: {
            xs: "auto",
            sm: 100
        },
        flexGrow: {
            xs: 1,
            sm:0,
        },
        justifyContent:"center",
        textAlign: "center",
        display:"flex",
        borderRight: `solid 1px ${BORDER_COLOR}`
    },

    perf: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        flex: 1,
        justifyContent:"center"
    },

    perfValue:{
        fontWeight: 500,
        color: "rgba(0,0,0,0.85)"
    },


    exerciceHeaderCompletion : {
        borderRadius: "100%",
        height: 48,
        justifyContent:"center",
        alignItems:"center",
        display:"flex"
    },


    exerciceCompletion: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(15)
    },

    groupContent : {
        backgroundColor: "white",
        marginTop: 3,
        border: "solid 1px whitesmoke",
        
    },

    superset: {
        borderLeft: `solid 5px ${theme.palette.primary.main}`,
        marginTop: 0,

    },


    supersetIndicator : {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        display:"flex",
        width: 90,
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        textAlign: "center",
        justifyContent:"center",
        alignItems:"center",
        marginTop: 3,
    },

    note: {
        backgroundColor: "white",
        marginTop: 3,
        padding: {
            xs: 2, 
            sm: 2
        },
        marginLeft: {
            xs: 1, 
            sm: 3, 
            md:3
        },
        marginRight: {
            xs: 1, 
            sm: 3, 
            md: 3
        },
    },

    clientNoteTitle: {
        fontSize: theme.typography.pxToRem(14)
    },

    clientNote: {
        fontSize: theme.typography.pxToRem(16),
        fontStyle: "italic"
    },

    clientNoteContent:{
        marginTop: 3,
        backgroundColor: "#d6edf9",
        padding: 3,
        position: "relative"
    },


    noteIndicator: {
        position: "absolute", 
        top: -13, 
        left: 40, 
        backgroundColor: "#d6edf9",
        transform: "rotate(-45deg)",
        borderRight: `solid 1px ${BORDER_COLOR}`,
        borderTop:`solid 1px ${BORDER_COLOR}`,
        width: 26, 
        height: 26, 
        zIndex: 3
    },

    drop: {
        fontSize: theme.typography.pxToRem(13),
        fontStyle: "italic"
    }


}


export default classes