import { lighten } from "@mui/material"
import { theme } from "utils/theme"

const classes = {
    header : {
        display:"flex", 
        flexDirection: "column", 
        justifyContent:"center", 
        alignItems:"center",
        marginTop: 4
    },

    button: {
        display:{xs: "flex", sm: "block"},

        width: {xs: 120, sm: 140},
        maxWidth: {xs: 120, sm: 140},
    },

    selectedRange:{
        backgroundColor: lighten(theme.palette.primary.main,0.90),
        
    },

    rangeButtons:{
        display:"inline-flex", 
        alignItems:"center", 
        justifyContent:{xs: "space-around", sm: "space-between"}, 
        flexDirection: "row", 
        marginBottom: 3,
        width: {
            xs: "260px",
            sm: 600
        }
    },

    periodIndicator:{
        display:"flex", 
        width: "100%", 
        alignItems:"center", 
        justifyContent:"center"
    }
}


export default classes