import { BORDER_COLOR, DRAWER_WIDTH_MIN, HEADER_MIN_HEIGHT, HOVER_COLOR } from "utils/constants"

const classes= {
        header : {
            display:"flex",
            position: "fixed",
            minHeight: HEADER_MIN_HEIGHT,
            zIndex: 10,
            left: {
                xs: 0,
                lg: DRAWER_WIDTH_MIN
            },
            paddingBottom: 0, 
            backgroundColor: "white", 
            borderBottom: `solid 1px ${BORDER_COLOR}`,
            alignItems:"center",
            justifyContent:"center",
            paddingLeft: {
                xs: 2,
                sm: 3,
            },
            paddingRight: {
                xs: 2,
                sm: 3,
            },
            width: {
                xs: "100%",
                lg: `calc(100% - ${DRAWER_WIDTH_MIN}px)`,
            },
           

        },

        toolbar : {
            display:"flex", 
            alignItems:"center", 
            width: "100%",
            justifyContent:"space-between",
            flexDirection: "row",
            //paddingLeft: 3,
            //paddingRight: 3,
        },


        tabs: {
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          },

          buttonTitle : {
            display: "flex",
            flexDirection: "row",
            alignItems:"center",
            justifyContent:"center",

          },

          iconButton: {
            marginRight: {xs: 1, md:1},
            display:{
                xs: "flex",
                lg: "none"
            },
            alignItems:"center",
            justifyContent:"center",
            height: "40px",
            width: "40px"
          },

          backButton: {
            display: "flex !important",
            fontSize: "14px !important"
          },

          actionButton: {
            width: 40, 
            height: 40, 
            cursor: "pointer",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            borderRadius: "100%",
            marginLeft: 3,
            "&:hover" : {
                backgroundColor: HOVER_COLOR
            }
          }

}

export default classes