
import React, { useEffect, useMemo, useState } from "react"
// MUI
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
// REDUX
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
// SHARED COMPONENTS
import SearchTextfield from "components/molecules/Search";
// LOCAL COMPONENTS
import SendInvitation from "components/organisms/DialogSendInvitation";
// REDUCERS
import { resetSearcClients, searchClients } from "features/clientsSlice";
import { handleSubscriptionPresentation, selectDrawerItem } from "features/appSlice";
import { DESKTOP, MOBILE, TABLET } from "utils/constants";
import PageTitle from "components/atoms/Typography/PageTitle";
import { toast } from "react-toastify";
import Header from "components/molecules/Header";
import MainContent from "components/molecules/MainContent";
import User from "interfaces/User";
import DialogDeleteClient from "components/molecules/DialogDeleteClient";
import Invitation from "interfaces/ClientInvitation";
import DialogDeleteInvitation from "components/molecules/DialogDeleteInvitation";
import ClientCard from "components/organisms/ClientCard";
import DialogInvitationDescription from "components/molecules/DialogInvitationDescription";
import EmptyState from "components/molecules/EmptyState";
import ContainedPrimaryButton from "components/atoms/Buttons/ContainedButton";
import classes from "./styles";
import { Add, FilterList, PersonAdd, RememberMe } from "@mui/icons-material";
import MoreButton from "components/atoms/Buttons/MoreButton";
import { getClients } from "api/clients";
import Toolbar from "components/molecules/Toolbar";
import { createDemoUser } from "api/user";
import CustomIconButton from "components/atoms/Buttons/IconButton";
import ClientsFilters from "components/organisms/ClientsFilters";
import FixedSearchHeader from "components/molecules/FixedSearchHeader";
import Navbar from "components/organisms/NavBar";
import EmptySearch from "components/molecules/EmptySearch";

/************************* CLIENTLIST *********************** */

export default function ClientsList() {

    const dispatch = useDispatch<AppDispatch>()
    const totalClients = useSelector((state:RootState)=> state.clients.list.clients.length + state.clients.list.invitations.length )
    const pending = useSelector((state:RootState) => state.clients.requests.postInvitation === "pending")
    const user = useSelector((state: RootState) => state.user.data)
    const [openInvitationDialog, setOpenInvitationDialog] = useState(false)
    const clients = useSelector((state:RootState)=> state.clients.searchResults.clients)
    const invitations = useSelector((state:RootState)=> state.clients.searchResults.invitations)
    const [clientSelected, setClientSelected] = useState<User>(null)
    const [invitationToDelete, setInvitationToDelete] = useState<Invitation>(null)
    const [openDeleteModalClient, setOpenDeleteModalClient] = useState(false)
    const [openDeleteModalInvitation, setOpenDeleteModalInvitation] = useState(false)
    const [openInvitationDescription, setOpenInvitationDescription] = useState<{name:string, email:string, open:boolean}>({open:false, name:"", email:""})
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const filters = useSelector(
        (state: RootState) => state.clients.filters
    );

    const mobile = useMediaQuery(MOBILE);
    const IS_TABLET = useMediaQuery(TABLET);
    const IS_DESKTOP = useMediaQuery(DESKTOP);


    useEffect(()=>{
        dispatch(getClients()) // récupération des clients
    },[dispatch])

    /******************* 
     * DONNEES FORMATEES + FILTRAGE
     * *************** */

    const allCards = useMemo(()=>{
        const elems:{client:User, invitation?:Invitation}[] = []
        clients.forEach((client)=> {
            elems.push({client: client})
        })

        invitations.forEach((invitation) => {
            const fakeUser:User = {
                ...invitation.to,
                isNotActivated: true
            }

            elems.push({client: fakeUser, invitation:invitation})
        })

        if(elems.length>1){

            const sorted = elems.sort((a,b)=> {
                if(a.client.firstname > b.client.firstname){
                    return 1
                }else if(a.client.lastname < b.client.firstname){
                    return -1
                } else return 0
            })

            var list:{
                client: User;
                invitation?: Invitation;
            }[] = []

            var finalList:{
                client: User;
                invitation?: Invitation;
            }[] = []

            /// Aplication des filtres
            // active
            if(filters.length>0){
                if(Boolean(filters.find((elem)=> elem === "active"))){
                    list = list.concat([...sorted].filter((card) => card.client?.workoutsScheduled?.length > 0))
                } 
                if(Boolean(filters.find((elem)=> elem === "inactive"))){
                    list = list.concat([...sorted].filter((card) => card.client?.workoutsScheduled?.length < 1))
                }
                if(Boolean(filters.find((elem)=> elem === "withProgram"))){
                    list = list.concat([...sorted].filter((card) => card?.client?.programs?.length > 0))
                } 
                if(Boolean(filters.find((elem)=> elem === "withoutProgram"))){
                    list = list.concat([...sorted].filter((card) => card?.client?.programs?.length < 1))
                } 
                if(Boolean(filters.find((elem)=> elem === "withNutrition"))){
                    list = list.concat([...sorted].filter((card) => card?.client?.mealPlan?.status ==="enabled"))
                } 
                if(Boolean(filters.find((elem)=> elem === "withoutNutrition"))){
                    list = list.concat([...sorted].filter((card) => card?.client?.mealPlan?.status ==="disabled"))
                } 

                 // Suppression des doublons
                list.forEach((elem)=> {
                    const found = finalList.find((client)=> client.client._id === elem.client._id)
                    if(!found){
                        finalList.push(elem)
                    }
                })
            }
            else {
                finalList = [...sorted]
            }

            return finalList
        } else {
            return elems
        }

    },[clients, invitations, filters])

     /************ SELECTION D'UN CLIENT A SUPPRIMER ************* */

     const onSelectClientToDelete = (client:User) => {
        setClientSelected(client)
        setOpenDeleteModalClient(true)
    }


     /************ SELECTION D'UNE INVItATION A SUPPRIMER ************* */

    const onSelectInvitationToDelete = (invitation:Invitation) => {
        setInvitationToDelete(invitation)
        setOpenDeleteModalInvitation(true)
    }

    /********GESTION DE LA PAGE **** */
    useEffect(() => {
        dispatch(selectDrawerItem(1))

        return function cleanup(){
            dispatch(resetSearcClients())
        }
    }, [dispatch])


    
    /***** OUVERTURE DE l'INVITATION ***** */
    const onOpenInvitationDialog = (e:any) => {

        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(null)

        // Aucun abonnement en cours
        if(!user.subscription || !user.subscription?.current){
            dispatch(handleSubscriptionPresentation({open:true}))
        }

        // Vérification du nombre de clients - Abonnement en cours
        else if(user.subscription?.current?.clients === totalClients){
            toast.error('Tu as atteint le maximum de clients de ton abonnement')
            return
        }

        else if(user.subscription?.current?.status === "active" || user.subscription?.current?.status === "trialing"){
            setOpenInvitationDialog(true)
        }
        
    }

    /************************* RECHERCHE *********************** */

    const onSearch = (e:any) => {
        dispatch(searchClients({search:e.currentTarget.value}))
    }

    const onOpenInvitationDescription = (name:string, email:string) => {
        setOpenInvitationDescription({
            open: true,
            name,
            email
        })
    }

    /************************* 
     * MENU BOUTON AJOUT 
     * *********************** */

    const openMenu = (e:any) =>{
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(e.currentTarget)
    }

    const closeMenu = (e:any) => {
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(null)
    }


    /************************* 
     * CREATION D'UN USER DEMO
     * *********************** */

    const onCreateDemoClient = (e:any) => {

        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(null)

        // Aucun abonnement en cours
        if(!user.subscription || !user.subscription?.current){
            dispatch(handleSubscriptionPresentation({open:true}))
        }

        // Vérification du nombre de clients - Abonnement en cours
        else if(user.subscription?.current?.clients === totalClients){
            toast.error('Tu as atteint le maximum de clients de ton abonnement')
            return
        }

        else if(user.subscription?.current?.status === "active" || user.subscription?.current?.status === "trialing"){
            const toasting = toast.loading("Création de l'utilisateur...")
            dispatch(createDemoUser()).unwrap().then((res)=> {
                if(res.client){
                    toast.update(toasting, { render: "Profil démo créé", type: "success", isLoading: false });
                    setTimeout(()=>{
                        toast.dismiss()
                    },1500)
                    
                }
            })
        }
    }

    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    };


    /************************* JSX RENDER *********************** */

    return (
        
            <React.Fragment>



                {/********************
                 *      HEADER 
                 ********************/}

                <Navbar
                    pageTitle="Mes clients"
                    expanded={false}
                />

                <ClientsFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />

                
                {/**************************
                 *      FIXED SEARCH 
                 **************************/}
                 
                <FixedSearchHeader 
                    onSearch={onSearch}
                    onSubmit={openMenu}
                    onOpenFilterList={onOpenFilterList}
                    submitLabel={"Ajouter un client"}
                    badge={filters.length}
                    searchLabel={"Rechercher un client"}
                    withTabs={false}

                />


                                        <Menu 
                                            disableScrollLock={true}  
                                            anchorEl={anchorEl} 
                                            open={Boolean(anchorEl)} 
                                            onClose={closeMenu}
                                        >

                                            <MenuItem 
                                                onClick={onOpenInvitationDialog}
                                            >
                                                <ListItemIcon>
                                                    <PersonAdd />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    Client physique
                                                </ListItemText>
                                            </MenuItem>
                                    
                                            <MenuItem 
                                                onClick={onCreateDemoClient}
                                                disabled={clients.findIndex((elem)=> elem?.role === "demo") > -1}
                                            >
                                                <ListItemIcon>
                                                    <RememberMe />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    Client de démonstration
                                                </ListItemText>
                                            </MenuItem>
                                    
                                </Menu> 
                

                 {/********************
                 *      CONTENT 
                 ********************/}

                <MainContent
                    header={"simple"}
                    withSearch={true}
                >

                    {/******* EMPTY STATE ****** */}

                    {totalClients === 0 && (
                       
                     
                        <EmptySearch
                            title="Aucun client"
                            description={`Tu n'as ajouté aucun client pour le moment`}
                        />
                            
                        
                    )}


                    {/******* LISTE DES CLIENTS ****** */}

                    {totalClients > 0 && (

                        <Grid 
                            container 
                            justifyContent="center" 
                            sx={{paddingBottom: 10,}}
                        >
                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={10} 
                                xl={8}
                            >



                                {/*}
                                <Box 
                                    sx={classes.listHeader}
                                >
                                    <Typography>
                                        Tu as actuellement <strong>{totalClients} client{totalClients > 1 ? "s" : ""}</strong>
                                    </Typography>

                                   
                                </Box>*/}
                                
                                <Grid container spacing={3}>
                                    {allCards.map((client)=>{

                               

                                        return(
                                            <ClientCard
                                                key={client.client?._id ? client.client._id : client.invitation._id}
                                                client={client.client}
                                                onSelectClientToDelete={onSelectClientToDelete}
                                                onSelectInvitationToDelete={onSelectInvitationToDelete}
                                                onOpenInvitationDescription={onOpenInvitationDescription}
                                                invitation={client.invitation}
                                            />
                                        )

                                    })} 
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                

                </MainContent>

                {/********** Dialog d'envoie d'invitation ********** */}

                <SendInvitation
                    open={openInvitationDialog}
                    onClose={()=>setOpenInvitationDialog(false)}
                />


                {/********** DIALOG SUPP CLIENT ********** */}
                <DialogDeleteClient
                    open={openDeleteModalClient}
                    onClose={()=> setOpenDeleteModalClient(false)}
                    client={clientSelected}
                />

                {/********** DIALOG SUPP INVITATION ********** */}
                <DialogDeleteInvitation
                    open={openDeleteModalInvitation}
                    onClose={()=>setOpenDeleteModalInvitation(false)}
                    invitation={invitationToDelete}
                />
                

                <DialogInvitationDescription
                    open={openInvitationDescription.open}
                    name={openInvitationDescription.name}
                    email={openInvitationDescription.email}
                    onClose={()=>setOpenInvitationDescription({open: false, name:"", email: ""})}
                />


                {/********** TABLET ********** 

                <FixedFab
                    onClick={()=>setOpenInvitationDialog(true)}
                />

                */}

                
            </React.Fragment>
    

    )
}