import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "utils/axios"

interface App {
    status: string,
    theme: string,
    pageTitle: string,
    backButton:{
        show: boolean,
        navigateTo: string
    },
    facebookAPIIsReady: boolean,
    googleSDKIsReady: boolean,
    openMenu: boolean,
    drawerItemSelected: number,
    openNutritionPresentation: boolean,
    openSubscriptionPresentation: boolean,
    openUserDemoPresentation: boolean,
    containerRef : string,
    drawerIsOpen: boolean
}


const initialState:App = {
    status: null,
    theme: "light",
    pageTitle: "Accueil",
    backButton:{
        show: false,
        navigateTo: ""
    },
    facebookAPIIsReady: false,
    googleSDKIsReady: false,
    openMenu: false,
    drawerItemSelected: 0,
    openNutritionPresentation: false,
    openSubscriptionPresentation: false,
    openUserDemoPresentation: false,
    containerRef: null,
    drawerIsOpen: false


}


/********** API ************ */

export const pingServer = createAsyncThunk('pingServer', async () => {
    try {
        await axios.post('app/ping')
        return ({ status: "online" })
    } catch (err) {
        return ({ status: "offline" })
    }
})


/********** SLICE ************ */
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {

        definePageTitle:(state,action) =>{
            state.pageTitle = action.payload.pageTitle
        },

        defineContainerRef:(state,action) => {
            state.containerRef = action.payload.ref
        },


        handleDrawer:(state,action) => {
            const open = action.payload.open
            state.drawerIsOpen = open
        },

        handleNutritionPresentation:(state,action) => {
            const open:boolean = action.payload.open
            state.openNutritionPresentation = open
        },

        handleSubscriptionPresentation:(state,action) => {
            const open:boolean = action.payload.open
            state.openSubscriptionPresentation = open
        },

        handleDemoUserPresentation:(state,action) => {
            const open:boolean = action.payload.open
            state.openUserDemoPresentation = open
        },

        showBackButton: (state,action) => {
            state.backButton.show = true
            state.backButton.navigateTo = action.payload.navigateTo
        },

        hideBackButton: (state) => {
            state.backButton.show = false
            state.backButton.navigateTo = ""
        },

        facebookSdkIsloaded: (state) => {
            state.facebookAPIIsReady = true
        },
        googleSDKIsLoaded: (state) => {
            state.googleSDKIsReady = true
        },

        selectDrawerItem : (state,action) => {
            state.drawerItemSelected = action.payload
        },
       
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(pingServer.fulfilled, (state, action) => {
                state.status = action.payload.status
            })
            .addCase(pingServer.rejected, (state) => {
                state.status = "offline"
            })
    }
})


export default appSlice.reducer
export const { 
    facebookSdkIsloaded, 
    googleSDKIsLoaded, 
    handleDrawer, 
    selectDrawerItem, 
    definePageTitle, 
    showBackButton, 
    hideBackButton,
    handleNutritionPresentation,
    handleSubscriptionPresentation,
    handleDemoUserPresentation,
    defineContainerRef

} = appSlice.actions
