import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import { handleTrack } from "features/workoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "utils/theme";
import classes from "./styles";
import { Check, Rule } from "@mui/icons-material";
import { MOBILE } from "utils/constants";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";

interface Props{
    open: boolean,
    onClose: ()=> void,
    blockIndex: number,
    blockId: string,
    currentTrack: boolean
}

export default function DialogBlockTracking({open,blockIndex, blockId, onClose, currentTrack}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const block = useSelector((state:RootState)=> state.workouts?.builder?.blocks[blockIndex])
    const mobile = useMediaQuery(MOBILE)

    const onHandleTrack = (track:boolean) => {
        dispatch(handleTrack({track, blockId}))
        //onClose()
    }


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"} 
            disableScrollLock={!mobile}
            fullScreen={mobile}
            TransitionComponent={DialogTransition}
        >

            <DialogAppBar 
                title={block?.name}
                onClose={onClose}
            />
            
            <DialogContent>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Box>
                            <Box sx={classes.imageContainer}>
                                <Box 
                                    component={"img"}
                                    src="/assets/avec_performances.jpg"
                                    sx={{width:{xs: "100%", sm: "500px"}}}
                                />
                            </Box>
                    
                                <Typography sx={{fontWeight: 600, fontSize: theme.typography.pxToRem(22), marginBottom: 1}}>
                                    Suivi des performances
                                </Typography>

                                <Box
                                    sx={{backgroundColor: block.track ? "#bcf6bc": "#ffdddd", width: "auto", display:"inline-block", fontWeight: 600, marginBottom: 1.5, borderRadius: 1.5, padding: 1, paddingBottom: 0.5, paddingTop: 0.5}}
                                >
                                    <Typography sx={{fontWeight: 600, fontSize: "14px"}}>{block.track ? "Suivi activé" : "Suivi désactivé"}</Typography>
                                </Box>
                                   
                          
                           
                           
                            {block.track && (
                                <Typography sx={{fontWeight: 400, fontSize: theme.typography.pxToRem(16), color:"rgba(0,0,0,0.75)", lineHeight: 1.5 }}>
                                    Ton client devra indiquer les performances réalisées (poids, répétitions ...) sur chacun des exercices de ce bloc.
                                </Typography>
                            )}
                            {!block.track && (
                                <Typography sx={{fontWeight: 400, fontSize: theme.typography.pxToRem(16), color:"rgba(0,0,0,0.75)", lineHeight: 1.5 }}>
                                    Ton client devra simplement suivre les prescriptions des exercices de ce bloc sans enter ses performances.
                                </Typography>
                            )}
                            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", marginTop: 2, flexDirection: "row"}}>
                                {!currentTrack && 
                                    <SecondaryButton onClick={()=>onHandleTrack(true)}>
                                        Activer le suivi
                                    </SecondaryButton>
                                }
                                {currentTrack && (
                                    <SecondaryButton onClick={()=>onHandleTrack(false)}>
                                        Désactiver le suivi
                                    </SecondaryButton>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                  
                </Grid>
                {!mobile && (
                <DialogActions>
                    <Button
                        onClick={onClose}>
                        Fermer
                    </Button>
                </DialogActions>
                )}
                
                
            </DialogContent>
            
        </Dialog>
    )
}