import { BORDER_COLOR, DRAWER_WIDTH_MIN } from "utils/constants";
import { theme } from "utils/theme";

const classes = {


    fixed: {
        position: "fixed",
        //backgroundColor: "white",
        top: 0,
        zIndex: 0,
        left: {
            xs: 0,
            lg:DRAWER_WIDTH_MIN
        },
        width: {
            xs: "100vw",
            lg:`calc(100vw - ${DRAWER_WIDTH_MIN}px) !important`,
        },
        paddingLeft: 3,
        paddingRight: 3,
        borderBottom: "solid 1px #e6e6e6",
        height: 48,
       
    },

    openFixed:{
        top: 85,
        zIndex: 200,
    },

    openFixedCoach:{
        top: 70,
    },


    daysList : {
        display:"flex",
        flexDirection:"row",
        width: "100%"
        
    },

    
    menu:{
        display:"flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        marginTop: 2,
        //border: `solid 1px ${BORDER_COLOR}`,
        overflowX: {xs: "scroll", sm: "hidden"},
        border: `solid 1px ${BORDER_COLOR}`,
    },

    day: {
        display:"flex",
        width: "100%",
        height: 60,
       justifyContent:"center",
       alignItems:"center",
       backgroundColor: "whitesmoke",
       cursor: "pointer",
       flexDirection: "column",
       borderRadius: 1,
       borderRight: `solid 1px ${BORDER_COLOR}`,
       //marginRight: 1.5,
       minWidth: {
        xs: 100,
        sm: "auto"
       },

       "&:hover":{
            backgroundColor: "white"
       }
        
    },

    daySelected: {
        backgroundColor: "white"
    },

    dayLabel : {
        fontSize: {
            xs: theme.typography.pxToRem(13),
            lg: theme.typography.pxToRem(14)
        },
        fontWeight: 400,
        textAlign: "center",
        color: "#b8b8b8"
    },

    kcal: {
        fontSize: theme.typography.pxToRem(14),
        
    },

    kcalSelected : {
        fontWeight: 700,
        color: theme.palette.primary.main

    },

    dayLabelSelected:{
        fontWeight: 600,
        color: theme.palette.primary.main
    },

    valueLabel : {
        fontWeight: 700,
        color: theme.palette.primary.main
    }


}

export default classes