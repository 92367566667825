import { InputAdornment, styled, TextField } from "@mui/material"
import { AppDispatch } from "app/store"
import { handleQuestion } from "features/checkupSlice"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { INPUT_DEBOUNCE_TIMER } from "utils/constants"

/******** INTERFACE ******** */

interface Props{
    question:string,
    questionIndex:number
}

/******** STYLED COMPONENTS ******** */

const Question = styled(TextField)(() => ({
    "& .MuiInput-root": {
        fontSize: '1.2rem',
        fontWeight: "600",
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            

        }
    },
  }))

/******** MAIN COMPONENTS ******** */

export function CheckupQuestionTextfield({question,questionIndex}:Props){
    

    const dispatch = useDispatch<AppDispatch>()
    const [value,setValue] = useState("")

    useEffect(()=>{
        setValue(question)
    },[])

    /****** CHANGEMENT DE LA QUESTION ******* */

    const debounceOnChange = useRef(debounce((data)=> {
        dispatch(handleQuestion({questionIndex: data.questionIndex, question: data.question}))
    },INPUT_DEBOUNCE_TIMER)).current

    const onChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const question:string = e.target.value
        setValue(question)
        debounceOnChange({questionIndex, question})
    }

    return(
        <Question
            name={"name"}
            value={value}
            onChange={onChangeQuestion}
            multiline={true}
            label={''}
            placeholder={"Question"}
            variant="standard"
            sx={{backgroundColor: "white", width: "100%"}}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{fontWeight: 400, color: "#4e4e4e"}}>{questionIndex+1} : </InputAdornment>,
            }}
            autoComplete="off"
        />

    )
}

function areEqual(prev:Props,next:Props){
    return prev.questionIndex === next.questionIndex
}

export default React.memo(CheckupQuestionTextfield, areEqual)