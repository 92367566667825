import { createSlice } from '@reduxjs/toolkit'
import { getClientsHistory } from 'api/clients'
import { HistoryEventDetails } from 'interfaces/User'

/**
 * INIT
 */

interface Dashboard{
    history: HistoryEventDetails[],
    requests:{
        get: "idle" | "pending"
    },
    filters: string[]
}

const initialState = {
    history: [],
    requests:{
        get:"idle"
    },
    filters:[]
} as Dashboard


export const dashboardSlice = createSlice({
    name:"dashboard",
    initialState,
    reducers:{
       addDashboardEventFilter : (state,action) => {
        const newFiler:string = action.payload.newFilter
        state.filters.push(newFiler)
      },

      removeDashboardEventFilter : (state,action) => {
        const filter = action.payload.newFilter
        const index = state.filters.findIndex((elem)=> elem === filter)
        if(index !== -1){
            state.filters.splice(index,1)
        }
      },

      clearDashboardEventsFilters : (state) => {
        state.filters = []
      }
    },
    extraReducers(builder){
        builder

        .addCase(getClientsHistory.pending, (state)=>{
            state.requests.get = "pending"
        })

        .addCase(getClientsHistory.fulfilled, (state, {payload})=>{
            var currentHistory:HistoryEventDetails[] = payload.history
            // Trie par date
            currentHistory = currentHistory.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())

            state.history = currentHistory
            state.requests.get = "idle"
        })
    }
})


export const { 
    addDashboardEventFilter,
    removeDashboardEventFilter,
    clearDashboardEventsFilters
  } = dashboardSlice.actions

export default dashboardSlice.reducer



