import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import TitleTextField from "components/molecules/TitleTextField";
import { useEffect, useState } from "react";
import classes from "./styles";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { toast } from "react-toastify";
import Food, { FoodPortion } from "interfaces/Food";
import { createCustomFood } from "api/food";
import { Add, Check, CheckCircle, Delete } from "@mui/icons-material";
import { MOBILE } from "utils/constants";
import DialogTransition from "components/molecules/DialogTransition";
import DialogAppBar from "components/molecules/DialogAppBar";
import SecondaryButton from "components/atoms/Buttons/Secondary";

interface Props{
    open: boolean,
    onClose: ()=>void
}




export default function DialogAddFood({open, onClose}:Props){

    const createdBy = useSelector((state:RootState) => state.user.data._id)
    const [name, setName] = useState<string>('')
    const [proteins, setProteins] = useState<number>(0)
    const [carbs, setCarbs] = useState<number>(0)
    const [lipids, setLipids] = useState<number>(0)
    const [customPortions, setCustomPortions] = useState<FoodPortion[]>([])
    const pending = useSelector((state:RootState) => state.food.requests.create === "pending")
    const dispatch = useDispatch<AppDispatch>()
    const [label, setLabel] = useState<string>("")
    const [equivalent, setEquivalent] = useState<number>(0)
    const mobile = useMediaQuery(MOBILE)



    useEffect(()=>{
        if(!open && name !== ""){
            setTimeout(()=>{
                setName("")
                setProteins(0)
                setCarbs(0)
                setLipids(0)
                setCustomPortions([])
            },200)
            
        }
    },[open])


    useEffect(()=>{
        setLabel("")
        setEquivalent(0)
    },[customPortions.length])


    const onSubmit = () => {
        if(!proteins && proteins !== 0){
            toast.error('Tu dois définir les protéines')
            return

        }

        if(!carbs  && carbs !== 0){
            toast.error('Tu dois définir les glucides')
            return

        }

        if(!lipids  && lipids !== 0){
            toast.error('Tu dois définir les lipides')
            return

        }

        if(name === ""){
            toast.error('Tu dois nommer ton aliment')
            return
        }

        

        const newFood:Food = {
            name: {
                fr: name,
                en: ""
            },
            macros:{
                proteins,
                carbs,
                lipids
            },
            source: "custom",
            createdBy,

        }

        if(customPortions){
            newFood.portions = customPortions
            dispatch(createCustomFood(newFood)).unwrap().then((res)=>{
                if(res.food){
                    toast.success('Aliment créé')
                    onClose()
                }
                
            })
        }

        
    }


    const onAddMeasure = () => {
        if(label === ""){
            toast.error("Tu n'as pas complété le nom de ton label")
            return
        }

        if(equivalent === 0){
            toast.error("L'équivalence en grammes ne peut pas être égal à zéro")
            return
        }

        // Check name

        const find = customPortions.find((elem) => elem.label.fr === label)

        if(Boolean(find)){
            toast.error('Ce label existe déjà')
            return 
        }

        var newMeasures = [...customPortions]
        newMeasures.push({
            label:{
                fr:label,
                en:""
            },
            equivalent: {
                portion: 1,
                value: equivalent
            }
        })
        setCustomPortions(newMeasures)
    }


    const onRemoveMeasure =  (index:number) => ()=> {
        var filtered = [...customPortions]
        filtered.splice(index,1)
        setCustomPortions(filtered.splice(index,1))
    }

    return(
        <Dialog
            fullWidth
            maxWidth={"sm"}
            open={open}
            disableScrollLock={!mobile}
            fullScreen={mobile}
            TransitionComponent={DialogTransition}
        >
            <DialogAppBar
                title="Ajouter un aliment"
                onClose={onClose}
            />
            <DialogContent>
                <TitleTextField
                    value={name}
                    placeholder="Nom de votre aliment"
                    variant="standard"
                    sx={{width: "100%"}}
                    onChange={(e)=> setName(e.target.value)}
                />

                
                <Typography
                    sx={{marginTop: 3}}
                >
                    Indique la quantité de macronutriments de ton aliment pour 100g.
                </Typography>
                

                <Box
                    sx={classes.content}
                >   
                    
                    <Box sx={classes.macrosList}>
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Protéines</InputAdornment>,
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                        value={proteins}
                        sx={classes.macro}
                        type="number"
                        onChange={(e)=> setProteins(parseFloat(e.target.value))}
                        size="small"
                    />
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Glucides</InputAdornment>,
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                        value={carbs}
                        sx={classes.macro}
                        type="number"
                        onChange={(e)=> setCarbs(parseFloat(e.target.value))}
                        size="small"

                    />
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Lipides</InputAdornment>,
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                        value={lipids}
                        sx={classes.macro}
                        type="number"
                        onChange={(e)=> setLipids(parseFloat(e.target.value))}
                        size="small"


                    />
                    </Box>

                  
                </Box>


                {/*********************
                 * MESURES CUSTOM
                 *********************/}

                 <Typography
                    variant="h6"
                    sx={{marginTop: 3}}
                 >
                    Portions personnalisées
                 </Typography>

                <Typography
                    sx={{marginTop: 1}}
                >
                    Tu peux ajouter des portions personnalisées pour cet aliment. Indique le label de ta portion et son équivalence en grammes. <strong>Attention : une fois ton aliment créé, tu ne pourras plus le modifier. </strong>
                </Typography>

                <Box
                    sx={classes.measuresList}
                >
                    {customPortions.map((elem,index)=>{
                        return(
                            <Box
                                key={index}
                                sx={classes.measureItem}
                            >
                                <Box
                                    sx={classes.labelForm}
                                >
                                    <TextField
                                        value={elem.label.fr}
                                        disabled={true}
                                        variant="outlined"
                                        sx={{maxWidth: 260, minWidth: {xs: 120, sm:260}}}
                                        size="small"
                                    />
                                    <Typography
                                        sx={classes.equivalent}
                                    >
                                        =
                                    </Typography>
                                    <TextField
                                        value={elem.equivalent.value}
                                        disabled={true}
                                        variant="outlined"
                                        sx={{maxWidth: 110}}
                                        size="small"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{marginLeft: 1.5}}
                                >
                                    <IconButton
                                        onClick={onRemoveMeasure(index)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </Box>

                                
                                   

                            </Box>
                        )
                    })}

                </Box>


                <Box
                    sx={classes.measures}
                >
                   <Box
                        sx={classes.labelForm}
                   >
                        <TextField
                            value={label}
                            label="Label"
                            placeholder="Ex: Gros, Tartine, Tranche ..."
                            sx={{maxWidth: 260, minWidth: {xs: 120, sm:260}}}
                            variant="outlined"
                            onChange={(e)=> setLabel(e.target.value)}
                            size="small"
                        />
                        <Typography
                            sx={classes.equivalent}
                        >
                            =
                        </Typography>
                        <TextField
                            value={equivalent}
                            label="Grammes"
                            placeholder=""
                            sx={{maxWidth: 110}}
                            type="number"
                            variant="outlined"
                            onChange={(e)=> setEquivalent(parseInt(e.target.value))}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            size="small"

                        />
                        <Box
                            sx={{marginLeft: 1.5}}
                        >
                            <IconButton
                                onClick={onAddMeasure}
                            >
                                <Check/>
                            </IconButton>
                        </Box>
                    </Box>
                    {!mobile && (
                    <Box
                        sx={{marginTop: {xs:2, sm: 0}}}
                    >
                        <SecondaryButton
                            onClick={onAddMeasure}

                        >
                            Ajouter la portion
                        </SecondaryButton>
                    </Box>
                    )}
                </Box>

               
            </DialogContent>

         
                <DialogActions>
                    {!mobile && (
                    <Button
                        disabled={pending}
                        onClick={onClose}
                    >
                        Fermer
                    </Button>
                    )}
                    <LoadingButton
                        loading={pending}
                        disabled={pending}
                        onClick={onSubmit}
                    >
                        Enregistrer
                    </LoadingButton>
                </DialogActions>
       
        </Dialog>
    )
}