import { Dialog, DialogContent } from "@mui/material";
import Meal from "interfaces/Meal";
import DialogAppBar from "components/molecules/DialogAppBar";
import MicroNutriments from "../MicrosNutriments";
import DialogTransition from "components/molecules/DialogTransition";


interface Props{
    open: boolean,
    onClose: ()=>void,
    meals: Meal[],
    template: boolean
}




export default function DialogMicrosNutriments({open, onClose, meals, template}:Props){

    return(
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={DialogTransition}
    >
        <DialogAppBar 
            onClose={onClose}
            title={"Répartition des nutriments"}
        />
   
        <DialogContent>
            <MicroNutriments 
                meals={meals}
                template={template}
            />
        </DialogContent>
  </Dialog>

    )
}
