import { Box, Typography, useMediaQuery, LinearProgress, Tooltip } from "@mui/material";
import classes from "./styles";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { MOBILE, TABLET, DESKTOP, VNR_MICROS_FEMALE, VNR_MICROS_MALE, BORDER_COLOR} from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import calcMicros from "function/calcMicros";
import { Info } from "@mui/icons-material";
import Meal from "interfaces/Meal";
import { Micros } from "interfaces/Food";



interface Props{
   //day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"
   meals: Meal[],
   forFood?: boolean,
   template?: boolean,
   noPadding? :boolean
}


const calcMicroPart = (value:number, gender:"female"|"male", microName: keyof typeof VNR_MICROS_FEMALE ) => {
  if(gender==="female"){
    const part = (value / (VNR_MICROS_FEMALE[microName] *2)) *100
    return part > 100 ? 100 : part
  }else if(gender === "male"){
    const part = (value / (VNR_MICROS_MALE[microName] *2)) *100
    return part > 100 ? 100 : part
  } else {
    const part = (value / (VNR_MICROS_MALE[microName] *2)) *100
    return part > 100 ? 100 : part
  }
}


const formatString = (gender:"female"|"male", text:any, unit:string, field?: keyof typeof VNR_MICROS_FEMALE, forFood?:boolean) => {

  const VNR = gender === "female" ? VNR_MICROS_FEMALE[field] : VNR_MICROS_MALE[field] 
  if(text === "0.0"){
      return (
        <>
        <Typography sx={{fontWeight: 700, fontSize: 15}}>0</Typography>
        {!forFood ? (<Typography sx={{fontWeight: 400, fontSize: 15}}>&nbsp;{`${VNR? "/ "+VNR+" "+unit :unit}`}</Typography>) : <Typography sx={{fontWeight: 400, fontSize: 15}}>&nbsp;{unit}</Typography>}
        </>
      )
  }
  else if(text.substring(text.indexOf('.')) === ".00"){
      const newText = text.split(".")[0]
      return (
        <>
          <Typography sx={{fontWeight: 700, fontSize: 15}}>{newText}</Typography>
          {!forFood ? (<Typography sx={{fontWeight: 400, fontSize: 15}}>&nbsp;{`${VNR? "/ "+VNR+" "+unit : unit}`}</Typography>) : <Typography sx={{fontWeight: 400, fontSize: 15}}>&nbsp;{unit}</Typography>}
        </>
      )
  }
  else if(text === "NaN"){
      return "-"
  }
  else {
      return (
        <>
            <Typography sx={{fontWeight: 700, fontSize: 15}}>{text} </Typography>
            {!forFood ? (<Typography sx={{fontWeight: 400, fontSize: 15}}>&nbsp;{`${VNR? "/ "+VNR+" "+unit : unit}`}</Typography>) : <Typography sx={{fontWeight: 400, fontSize: 15}}>&nbsp;{unit}</Typography>}
        </>
      )
  }
  
}



function MicroProgress(
    {
        gender,
        field, 
        meals, 
        showVNP = false,
    }:{
        showVNP?: boolean, 
        gender:"female"|"male", 
        field: keyof typeof VNR_MICROS_FEMALE, 
        meals: Meal[],
        template: boolean
        //day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"
    }){

  //const meals = useSelector((state:RootState) => state.mealPlans.mealPlan? state.mealPlans.mealPlan[day] : [])


  const MEALS_MICROS = useMemo(()=>{
    var micros = calcMicros(meals)
    return micros
  },[JSON.stringify(meals)])





  
  return(
    <Box
      sx={{width: "100%", position: "relative", display:"flex", flex:1, alignItems:"center"}}
    >
        {showVNP && (<Box sx={classes.VNRIndicator}>
            <Tooltip
                title={`Valeur nutritionelle de référence - Anses 2021`}
            >
            <Box
                sx={classes.VNRContainer}
            >
                <Typography
                    sx={classes.VNRTypo}
                >
                    VNR
                </Typography>
                <Info sx={{fontSize: 13, color: "#424242"}}/>
            </Box>
            </Tooltip>
        </Box>)}

      <Box 
        sx={classes.absoluteIndicator}
      />

      <LinearProgress
        sx={classes.progress}
        variant="determinate"
        value={calcMicroPart(MEALS_MICROS[field], gender, field)}
      />
    </Box>
  )
}


function MicroNutriment({label, unit, field, forFood = false, meals, hideProgress= false, template = false}:{template: boolean, label: string, unit:string, field: keyof typeof VNR_MICROS_FEMALE, forFood?: boolean, meals:Meal[], hideProgress?: boolean }){
    
    const client = useSelector((state:RootState)=> state.clients.profile)
    const mobile = useMediaQuery(MOBILE)
    
    const MEALS_MICROS = useMemo(()=>{
        var micros = calcMicros(meals)
        return micros
      },[JSON.stringify(meals)])


    return(
        <Box
            sx={classes.macroContainer}
        >
            <Typography
                sx={classes.microLabel}
            >
                {label}
            </Typography>

            <Box
                sx={classes.microValue}
            >
                <Typography
                    sx={classes.micro}
                >
                    {formatString(client?.gender,(MEALS_MICROS[field])?.toFixed(2), unit, field, forFood)}
                </Typography>

                {(!mobile && !forFood && !hideProgress) && (
                    <MicroProgress
                        field={field}
                        meals={meals}
                        gender={client?.gender}
                        showVNP={field === "calcium"}
                        template={template}
                    />
                )}
            </Box>
       
     </Box>
    )
}



export default function MicroNutriments({meals, forFood = false, template, noPadding}:Props){

  const statsRef = useRef(null)
  const [size, setSize] = useState<any>([0, 0])


  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);




    return(

      <Box
        sx={[
            classes.stats, 
            {
                padding: noPadding? "0 !important" :{xs: 0, sm:3}, 
                border: noPadding? "0 !important" : {xs:0,sm:`solid 1px ${BORDER_COLOR}`},
            }
        ]}
        ref={statsRef}
      >
        <Typography
            sx={classes.statsTitle}
        >
          Répartition détaillée
        </Typography>

                       
        <Box
            sx={classes.macroContainer}
        >
            <Typography
                sx={classes.macroLabel}
            >
                Glucides
            </Typography>
            
            
            </Box>

            <MicroNutriment
                label={"Fibres"}
                field="fibers"
                unit="g"
                meals={meals}
                hideProgress={true}
                forFood={forFood}
                template={template}
            />


            <MicroNutriment
                label={"Sucres"}
                field="sugar"
                unit="g"
                meals={meals}
                hideProgress={true}
                forFood={forFood}
                template={template}
            />

            


                            {/************
                         * ACIDES GRAS
                         ***********/}
                         <Box
                            sx={[classes.macroContainer, classes.macroContainerTitle]}
                        >
                            <Typography
                                sx={classes.macroLabel}
                            >
                                Lipides
                            </Typography>
                            
                            
                         </Box>

                    
                         <MicroNutriment
                            label={"Acides gras saturés"}
                            field="SFA"
                            unit="g"
                            meals={meals}
                            hideProgress={true}
                            forFood={forFood}
                            template={template}
                           
                        />

                         
                         <MicroNutriment
                            label={"Acides gras monoinsaturés"}
                            field="MUFA"
                            unit="g"
                            meals={meals}
                            hideProgress={true}
                            forFood={forFood}
template={template}
                           
                        />
                         <MicroNutriment
                            label={"Acides gras polyinsaturés"}
                            field="PUFAs"
                            unit="g"
                            meals={meals}
                            hideProgress={true}
                            forFood={forFood}
template={template}
                           
                        />
                        
                         <MicroNutriment
                            label={"Cholesterol"}
                            field="cholesterol"
                            unit="mg"
                            meals={meals}
                            hideProgress={true}
                            forFood={forFood}
template={template}
                           
                        />
                        
                        


                         



                        {/************
                         * MINERAUX
                         ***********/}

                        <Box
                            sx={[classes.macroContainer, classes.macroContainerTitle]}
                        >
                            <Typography
                                sx={classes.macroLabel}
                            >
                                Minéraux
                            </Typography>
                            
                         </Box>
                         <MicroNutriment
                            label={"Calcium"}
                            field="calcium"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />
                        <MicroNutriment
                            label={"Fer"}
                            field="iron"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />

                        <MicroNutriment
                            label={"Iode"}
                            field="iodine"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Magnesium"}
                            field="magnesium"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Phosphore"}
                            field="phosphorus"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        /> 

                        <MicroNutriment
                            label={"Potassium"}
                            field="potassium"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  


                        <MicroNutriment
                            label={"Sélénium"}
                            field="selenium"
                            unit="μg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Zinc"}
                            field="zinc"
                            unit="μg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        
                       
                        
                         {/************
                         * Vitamines
                         ***********/}

                        <Box
                            sx={[classes.macroContainer, classes.macroContainerTitle]}
                        >
                            <Typography
                                sx={classes.macroLabel}
                            >
                                Vitamines
                            </Typography>
                            
                         </Box>

                         <MicroNutriment
                            label={"Niacine"}
                            field="niacine"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Riboflavine"}
                            field="riboflavin"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Thiamine"}
                            field="thiamine"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamine A"}
                            field="retinol"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines B5"}
                            field="pantothenicAcid"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines B6"}
                            field="vitaminB6"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines B9"}
                            field="folate"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines B12"}
                            field="vitaminB12"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines C"}
                            field="vitaminC"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines D"}
                            field="vitaminD"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  

                        <MicroNutriment
                            label={"Vitamines E"}
                            field="vitaminE"
                            unit="mg"
                            meals={meals}
                            forFood={forFood}
template={template}
                        />  


                        {/************
                         * AUTRES
                         ***********/}

                        <Box
                            sx={[classes.macroContainer, classes.macroContainerTitle]}
                        >
                            <Typography
                                sx={classes.macroLabel}
                            >
                                Autre
                            </Typography>
                            
                         </Box>
             

                        <MicroNutriment
                            label={"Eau"}
                            field="water"
                            unit="g"
                            meals={meals}
                            hideProgress={true}
                            forFood={forFood}
template={template}
                           
                        />  

                        <MicroNutriment
                            label={"Alcool"}
                            field="alcool"
                            unit="g"
                            meals={meals}
                            hideProgress={true}
                            forFood={forFood}
template={template}
                           
                        />  
                          
                         
                         
                       

       

  </Box>


        
    )
}


