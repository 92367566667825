import React, { useEffect, useMemo, useRef, useState } from "react";
// MUI
import { Box, Grid, useMediaQuery } from "@mui/material";
// Redux
import { AppDispatch, RootState } from "app/store";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// SHARED COMPONENTS
import Tabs from "components/molecules/Tabs";
import DialogExercice from "components/organisms/DialogExercice";

// LOCAL COMPONENTS
import DialogDeleteExercice from "components/molecules/DialogDeleteExercice";
import DialogCreateExercice from "components/organisms/DialogCreateExercice";

// INTERFACES
import Exercice from "interfaces/Exercice";
// REDUCERS
import { searchExercices, resetSearchExercices } from "features/exerciceSlice";
// ROUTER
import { definePageTitle, selectDrawerItem } from "features/appSlice";
import { useHistory } from "react-router-dom";


import MainContent from "components/molecules/MainContent";
import ListItemExercice from "components/molecules/ListItemExercice";
import { ViewportList } from "react-viewport-list";
import EmptyState from "components/molecules/EmptyState";
import ExercicesFilters from "components/organisms/ExercicesFilters";
import { DESKTOP, MOBILE, TABLET } from "utils/constants";
import EmptySearch from "components/molecules/EmptySearch";
import FixedSearchHeader from "components/molecules/FixedSearchHeader";
import Navbar from "components/organisms/NavBar";

const EXERCICES_LISTS: string[] = ["app", "custom", "draft"];

/************ EXERCICESLIST ************ */

export default function ExercicesList() {
    const navigate = useHistory();
    const dispatch = useDispatch<AppDispatch>();
    const filteredExercices = useSelector(
        (state: RootState) => state.exercices.filtered
    );
    const allExercices = useSelector((state: RootState) => state.exercices.list);
    const groupFilters = useSelector(
        (state: RootState) => state.exercices.groupsFilter
    );
    const typesFilters = useSelector(
        (state: RootState) => state.exercices.typesFilter
    );


    // REF
    const listRef = useRef(null);

    // Local
    const [tabs, setTabs] = useState(0);
    const [openDialogExercice, setOpenDialogExercice] = useState<boolean>(false);
    const [openDeleteDialogExercice, setOpenDeleteDialogExercice] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [exerciceSelected, setExerciceSelected] = useState<Exercice>(null);
    const [openCreateExercice, setOpenCreateExercice] = useState<boolean>(false);
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
   
    const mobile = useMediaQuery(MOBILE);
    const IS_TABLET = useMediaQuery(TABLET);
    const IS_DESKTOP = useMediaQuery(DESKTOP);

    useEffect(() => {
        // Lorsque la liste des exercices de bases change, on relance une recherche
        dispatch(searchExercices({ search }));
    }, [allExercices]);


    /************************************************** 
     * A l'arrivée sur l'écran, on reset la recherche 
     * ************************************************/

    useEffect(() => {
        dispatch(resetSearchExercices());
        dispatch(selectDrawerItem(2));
        dispatch(definePageTitle({ pageTitle: "Liste des exercices" }));
    }, [dispatch]);

    /********************
     *  GESTION DES TABS 
     * **************** */

    const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };

    /************************* 
     * CLICK SUR UN EXERCICE 
     * ********************** */

    const navigateTo = (exercice: Exercice) => {
        if (!exercice.appExercice) {
            navigate.push(`/exercices/${exercice._id}`);
        } else {
            setExerciceSelected(exercice);
            setOpenDialogExercice(true);
        }
    };

    /********** Gestion des dialogs ********** */

    // Fermeture de l'exercice details dialogue
    const onCloseDialogExercice = () => {
        setOpenDialogExercice(false);
        setExerciceSelected(null);
    };

    // Suppression
    const onOpenDeleteExerciceDialog = (exercice: Exercice) => async () => {
        setExerciceSelected(exercice);
        setOpenDeleteDialogExercice(true);
    };

    const onCloseDeleteExerciceDialog = () => {
        setOpenDeleteDialogExercice(false);
        setTimeout(() => {
            setExerciceSelected(null);
        }, 200);
    };

    /********** TABS LABELS ********** */

    const tabsLabels = useMemo(() => {
        return [
            `Application (${filteredExercices.app.length})`, 
            `Mes exercices (${filteredExercices.custom.length})`, 
            `Brouillons (${filteredExercices.draft.length})`
        ];
    }, [filteredExercices]);

    /********** EXERCICES A AFFICHER ********** */

    const exercicesToDisplay = useMemo(() => {
        let exercices: Exercice[] = [];
        if (tabs === 0) exercices = filteredExercices.app;
        else if (tabs === 1) exercices = filteredExercices.custom;
        else if (tabs === 2) exercices = filteredExercices.draft;
        return exercices;
    }, [tabs, filteredExercices]);

    /********** RECHERCHE ********** */

    const onSearch = (e: any) => {
        setSearch(e.currentTarget.value);
        dispatch(searchExercices({ search: e.currentTarget.value }));
    };

    /********** 
     * FILTRE 
     * ********* */

    useEffect(() => {
        dispatch(searchExercices({ search }));
    }, [groupFilters, typesFilters]);


     /********************* 
     * LISTE A AFFICHER 
     * ******************* */
    
    const CURRENT_LIST: "app" | "custom" | "draft" = useMemo(() => {
        return EXERCICES_LISTS[tabs] as "app" | "custom" |'draft';
    }, [tabs]);


    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    };

    /********** JSX ********** */

    return (
        <React.Fragment>

            {/**************
             * NAVIGATION
             ***************/}

            <Navbar
                pageTitle="Bibliothèque d'exercices"
            />


            {/********************** 
             * FIXED HEADER SEARCH 
             * ********************/}

            <FixedSearchHeader 
                onSearch={onSearch}
                onSubmit={()=> setOpenCreateExercice(true)}
                onOpenFilterList={onOpenFilterList}
                submitLabel={"Ajouter un exercice"}
                badge={groupFilters.length + typesFilters.length}
                searchLabel={"Rechercher un exercice"}

            />

            {/********* 
             * LISTE 
             * *******/}

            <MainContent
                withSearch={true}
            >

                

                {/***************************************************************
                *          LISTE DES EXERCICES AVEC MENU DE FILTRAGE
                ***************************************************************/}

              
                <Grid
                    container
                    justifyContent="center"
                    sx={{ paddingBottom: 10 }}
                >
                    

                    <Grid 
                        item xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >
                        
                        <Tabs 
                            tabs={tabsLabels} 
                            value={tabs} 
                            onChange={onChangeTabs} 
                            sx={{marginBottom: 2}}
                        />

                        
                        {/**** RESULTATS **** */}

                        {exercicesToDisplay.length > 0 && (
                            <Box 
                                ref={listRef}
                            >
                                <ViewportList
                                    ref={listRef}
                                    items={exercicesToDisplay}
                                    initialPrerender={15}
                                    itemSize={95}
                                >
                                    {(item) => (
                                        <ListItemExercice
                                            key={item._id}
                                            exercice={item}
                                            onOpenDeleteExerciceDialog={onOpenDeleteExerciceDialog}
                                            onClick={navigateTo}
                                        />
                                    )}
                                </ViewportList>
                            </Box>
                        )}

                        {/**** AUCUN RESULTAT **** */}

                        {exercicesToDisplay.length === 0 && (
                            <EmptySearch 
                                title={"Aucun exercice"}
                                description={"Tu n'as créé aucun exercice personnalisé pour le moment."} 
                            />
                        )}
                    </Grid>
                </Grid>
           

               

                {/********** DIALOGS ********** */}
                <DialogExercice
                    exerciceId={exerciceSelected?._id}
                    open={openDialogExercice}
                    onClose={onCloseDialogExercice}
                />

                <DialogDeleteExercice
                    exercice={exerciceSelected}
                    open={openDeleteDialogExercice}
                    onClose={onCloseDeleteExerciceDialog}
                />

                <DialogCreateExercice
                    open={openCreateExercice}
                    onClose={() => setOpenCreateExercice(false)}
                />

                <ExercicesFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />
            </MainContent>
        </React.Fragment>
    );
}
