import { theme } from "utils/theme"

const classes = {
    title: {
        fontSize: {
            xs: theme.typography.pxToRem(24),
            sm: theme.typography.pxToRem(34),
        },
        textAlign: "center",
        fontWeight: {
            xs: 700,
            md: 500
        },
        //marginTop: 4,
        marginBottom: {
            xs: 3,
            md: 0,
        }
    }
}

export default classes