import React, {useMemo, useState } from "react";
import { Block, Check, Circle,Close,CopyAll,Delete, Edit, Image, EmojiEvents, } from "@mui/icons-material";
import { Box, Card,Grow, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import Workout from "interfaces/Workout";
import classes from "./styles" 
import SuccessPopper from "components/molecules/SuccessPopper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { useHistory } from "react-router-dom";
import { copyWorkoutPlanning } from "features/planningSlice";
import { toast } from "react-toastify";
import checkVideoLink from "function/checkVideoLink";
import DialogDeleteWorkout from "components/molecules/DialogDeleteWorkout";
import DialogUpdateCover from "components/organisms/DialogUpdateCover";
import DialogActivateWorkout from "components/molecules/DialogActivateWorkout";
import { isBefore } from "date-fns";
import DialogWorkoutResults from "../DialogWorkoutResults";
import calcExercices from "function/calcExercices";


/********** INTERFACE ************* */

interface Props{
    workout: Workout,
}

export function PlanningWorkout({workout}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const history = useHistory()
    const planningRange = useSelector((state:RootState) => {return {start: new Date(state.planning.builder.startDate), end: new Date(state.planning.builder.endDate)}})
    const [openDeleteWorkout, setOpenDeleteWorkout] = useState<boolean>(false)
    const [openUpdateCover, setOpenUpdateCover] = useState<boolean>(false)
    const [openActivateWorkout, setOpenActivateWorkout] = useState<boolean>(false)
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openResults, setOpenResults] = useState<boolean>(false)


    /******** SEANCE ACTIVEE ? ******* */
    const enabled = useMemo(()=>{
        if(workout.status=== "enabled") return true
        return false
    },[workout])

    /******** SEANCE MANQUEE ? ******* */
    const missed = useMemo(()=>{
        const performed = Boolean(workout.status==="done")
        const isBeforeToday = isBefore(new Date(workout.schedule).setHours(23,59,59,59), new Date(new Date().setHours(0,0,0,0)))

        if(!performed && isBeforeToday && enabled){
            return true
        } else {
            return false
        }
    },[workout])


    const started = useMemo(()=>{
        return workout.status === "started"
    },[workout])


    /******** SEANCE EFFECTUEE ? ******* */
    const done = useMemo(()=>{
        const performed = Boolean(workout.status === "done")

        if(performed){
            return true
        } else {
            return false
        }
    },[workout])


     /******** Template de programme ? ******* */
     const isFromProgramTemplate = useMemo(()=>{
        return Boolean(workout.programTemplate) 
    },[workout])


    /******** GESTION DU MENU ******* */
    const openMenu = (event: React.MouseEvent<Element, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }


    /******** EDIT WORKOUT ******* */
    const onEditWorkout = () => {
        history.push({
            pathname: `/workout/${workout._id}`,
            state:{
                planningRange,
                planningScroll: window.scrollY,
            }
        })
        setAnchorEl(null)
    }


    /******** COPY WORKOUT ******* */
    const onCopyWorkout = (event: React.MouseEvent) => {
        //dispatch(copyWorkoutFromProgramBuilder({workout})) // Program Builder
        dispatch(copyWorkoutPlanning({workout:workout})) // ClientPlanning
        setPopoverAnchorEl(event.currentTarget)
        setAnchorEl(null)
    }


    /******** DELETE WORKOUT ******* */
    const onDeleteWorkout = () => {
        setAnchorEl(null)
        setOpenDeleteWorkout(true)
    }


    /******** UPDATE WORKOUT COVER ******* */
    const onUpdateCover = () => {
        setOpenUpdateCover(true)
        setAnchorEl(null)
    }

    /******** ACTIVATE WORKOUT ******* */
    const onOpenActivateWorkout = () => {
        if(workout.videoWorkout && !checkVideoLink(workout.video?.url)){
            setAnchorEl(null)
            return toast.error("Vidéo invalide")
        }
        setOpenActivateWorkout(true)
        setAnchorEl(null)
    }


    /******** VOIR LES RESULTATS ******* */
    const onViewResults = () => {
        setOpenResults(true)
        setAnchorEl(null)
    }




    
    return(
        <>
     

            <Grow in={true}>
                <Card 
                    sx={[classes.workout]} 
                    onClickCapture={openMenu}
                    elevation={0}
                >

                   
                    {missed && (
                        <Box sx={[classes.statusIndicator,classes.workoutIsMissed]}>
                            <Close sx={{fontSize: 20}} />
                        </Box>
                    )}

                    {/********************** 
                     * INDICATEUR DE RESULTATS 
                     * ********************** */}

                    {done && (
                        <Box sx={[
                            classes.statusIndicator,
                            classes.workoutIsDone
                        ]}>
                            <Check sx={{fontSize: 20}} />
                        </Box>
                    )}
                    

                    {/*********** 
                     * THUMBNAIL 
                     * ******* */}

                    <Box 
                        sx={classes.workoutCover}
                    >
                        <Box 
                            component="img" 
                            src={workout.cover? workout.cover.url : "/assets/wod.jpg"} 
                            sx={{opacity: (!enabled && !isFromProgramTemplate && !started && !done) ? "0.4 !important" : "1"}}
                        />
                    </Box>
            
                    {/*********** 
                     * INFOS 
                     * ******* */}

                    <Box 
                        sx={[classes.workoutInfos]}
                    >
                        <Typography 
                            sx={classes.workoutName}>
                                {workout.name}
                        </Typography>
                        <Box
                            sx={classes.blocks}
                        
                        >
                            <Typography
                                sx={classes.blockName}
                            >
                            {workout.blocks.length} bloc{workout.blocks.length >1 ?"s" :""} - {calcExercices(workout)} exercice{calcExercices(workout) > 1 ? "s" :""}
                            </Typography>
                            
                            
                        </Box>
                        
                       
                        
                        

                        {/******** 
                         * AFFICHAGE DU STATUS DE LA SEANCE (uniquement en planning perso) 
                         * *********/}

                        {!isFromProgramTemplate && (
                            <Box 
                                component={"span"}
                                sx={[
                                    classes.activationIndicator, 
                                    enabled && classes.activate
                                ]}
                            >
                                
                                {(!enabled && !started && !done) && (
                                    <Circle sx={{color: "#dfdfdf", fontSize: 14}}/>
                                )}  
                                

                                {(!started && !enabled && !done) && (
                                    <Typography 
                                        sx={classes.activationIndicatorFont}
                                    >
                                        Brouillon
                                    </Typography>
                                )}
                                
                            </Box>
                        )}
                        
                    </Box>
                </Card>
            </Grow>
   


            <Menu disableScrollLock={true} 
                id="workout-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={()=>setAnchorEl(null)}
            >
                {done && (
                    <MenuItem onClick={onViewResults}>
                        <ListItemIcon>
                            <EmojiEvents />
                        </ListItemIcon>
                        <ListItemText>
                            Consulter les résultats
                        </ListItemText>
                    </MenuItem>
                )}

                <MenuItem 
                    onClick={onEditWorkout} 
                    disabled={done || started}
                >
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>
                        Editer
                    </ListItemText>
                </MenuItem>

                {!workout.programTemplate && (
                    <MenuItem onClick={onOpenActivateWorkout} disabled={done || started}>
                        <ListItemIcon>
                            {workout.status === "enabled" ? <Block /> : <Check />}
                        </ListItemIcon>
                        <ListItemText>
                            {workout.status === "enabled" ? "Désactiver" : "Activer"}
                        </ListItemText>
                    </MenuItem>
                )}

                {!workout.videoWorkout && (
                    <MenuItem onClick={onUpdateCover} disabled={done || started}>
                        <ListItemIcon>
                            <Image />
                        </ListItemIcon>
                        <ListItemText>
                            Changer la couverture
                        </ListItemText>
                    </MenuItem>
                )}
                
                <MenuItem onClick={onCopyWorkout}>
                    <ListItemIcon>
                        <CopyAll />
                    </ListItemIcon>
                    <ListItemText>
                        Copier
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onDeleteWorkout} disabled={done || started}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        Supprimer
                    </ListItemText>
                </MenuItem>
            </Menu>


           {/********* DIALOGS ******** */}

        <DialogDeleteWorkout
            open={openDeleteWorkout}
            onClose={()=> setOpenDeleteWorkout(false)}
            workout={workout}
        />


        {/********* DIALOG DE CHOIX DE COUVERTURE ********/}

        <DialogUpdateCover
            open={openUpdateCover}
            onClose={()=>setOpenUpdateCover(false)}
            workout={workout}
        />

        {/********* DIALOG ACTIVATION DU WORKOUT *********/}

        <DialogActivateWorkout
            open={openActivateWorkout}
            onClose={()=>setOpenActivateWorkout(false)}
            workout={workout}
        />


        {/********* POPPER *********/}

        <SuccessPopper 
            open={Boolean(popoverAnchorEl)}
            onClose={()=>setPopoverAnchorEl(null)}
            anchorEl={popoverAnchorEl}
            text={"Copié !"}
        />


        {done && (
            <DialogWorkoutResults
                open={openResults}
                workout={workout}
                onClose={()=> setOpenResults(false)}
            />
        )}

       

        </>
    )
}

function areEqual(prev:Props, next:Props){
    return prev.workout === next.workout
}

export default React.memo(PlanningWorkout, areEqual)