import { useMemo, useRef } from "react"
import { BODY_MEASURES, MOBILE } from "utils/constants"
import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import Measure from "interfaces/Measure";
import { addDays, differenceInCalendarDays, endOfYear, isSameDay, startOfYear } from "date-fns";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "utils/theme";


interface ChartPropTypes{
    type: string,
    measures?: Measure[],
    start: Date,
    end: Date,
    yearsFetched: number[],
    dialogIsReady: boolean,
    year: number,
    interval: "month"|"year"|"quarter" |"week"
}


interface ChartData{
    _id: string,
    value: number,
    date: number,
    
}


export default function StatsChart({measures, type, start,end, yearsFetched, dialogIsReady,interval}:ChartPropTypes){

    const seriesRef = useRef<am5xy.LineSeries>(null)
    const xaxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer>>(null)
    const chartRef = useRef(null)
    const mobile = useMediaQuery(MOBILE)


    const BASE_INTERVAL:"day"|"month"|"week" = useMemo(()=>{
         {/**** DEFINITION DE L'INTERVAL PAR DEFAULT **** */}
         return "day"
    },[start, measures])


    /********* UNITE DE MESURE ********** */

    const yAxisUnit = useMemo(()=>{
        const units:string =  BODY_MEASURES.find((bodyPart) => bodyPart.label === type).units
        return units
    },[measures])


    //********* FILTRAGE ET FORMATAGE DES DONNEES INITIALE ******** */

    const chartData:ChartData[] = useMemo(() => {
        if(dialogIsReady){
            const minYear = Math.min(...yearsFetched)
            const maxYear = Math.max(...yearsFetched)

            const formatedMeasures:ChartData[] = []
            const firstDayOfYear = startOfYear(new Date(`01/01/${minYear}`))
            const lastDayOfYear = endOfYear(new Date(`01/01/${maxYear}`))

            const days = differenceInCalendarDays(lastDayOfYear, firstDayOfYear)

            Array.from(Array(days)).forEach((_, index)=>{
                const found = measures.find((measure) => isSameDay(new Date(measure.date), addDays(firstDayOfYear, index)))
                formatedMeasures.push({
                    _id: found?._id,
                    date : addDays(firstDayOfYear, index).getTime(),
                    value: found?.value
                })
            })
        
            return formatedMeasures
        }else{
            return []
        }
    },[measures, yearsFetched])



    /********* CREATION DE LA CHART ******** */
    useLayoutEffect(() => {
        if(chartData){
            
            let root = am5.Root.new("chartdiv-"+type);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: true,
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                pinchZoomX:false
            }));

            chart.zoomOutButton.set("forceHidden", true);


            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0.1,
                groupData: false,
                baseInterval: {
                    timeUnit: BASE_INTERVAL,
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
                bullet: function (root, axis, dataItem) {
                    return am5xy.AxisBullet.new(root, {

                      sprite: am5.Circle.new(root, {
                        radius: 1,
                        fill: am5.color(0x000000)
                      })
                    });
                  }
            }));

            xaxisRef.current= xAxis
            

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                numberFormat: `# ${yAxisUnit === "%"? "'%'" : yAxisUnit}`,
                maxDeviation: 0.2,
                renderer: am5xy.AxisRendererY.new(root, {}),
                min: 0,
                max: yAxisUnit !== "%" ? 130 : 60
            
            }));

           

            let xRenderer = xAxis.get("renderer");
            xRenderer.grid.template.setAll({
                stroke: am5.color("#FFFFFF"),
                strokeWidth: 0
            });

            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color("#bababa"),
                strokeWidth: 2
            });
            yRenderer.labels.template.setAll({
                fill: am5.color("#3b3b3b"),
                fontSize: "1em",

            });


            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            let series = chart.series.push(am5xy.LineSeries.new(root, {
                minBulletDistance: 0,
                connect: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
            }));

            /******** TOOLTIP¨******** */
            let tooltip = am5.Tooltip.new(root, {
                pointerOrientation: "vertical",
                labelText: "{valueY} "+yAxisUnit,
                visible: true,
                keepTargetHover: true,
                getFillFromSprite: false,
                autoTextColor: false,
                showTooltipOn: "always",
                
                
            });

            tooltip.get("background").setAll({
                fill: am5.color(0x000000),
                fillOpacity: 1
            });

            tooltip.label.setAll({
                fill: am5.color(0xFFFFFF)
            });


            series.set("tooltip", tooltip);
            series.set('stroke', am5.color(theme.palette.primary.main))
      

            series.fills.template.setAll({
                fillOpacity: 0.15,
                visible: true,
            });
            
            series.strokes.template.setAll({
                strokeWidth: 5,
                
            });

            


            // ExerciceSet up data processor to parse string dates
            // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"]
            });

            series.data.setAll(chartData);
            seriesRef.current = series
            
            series.bullets.push(function() {
                let circle = am5.Circle.new(root, {
                    radius: 5,
                    fill: root.interfaceColors.get("background"),
                    stroke: am5.color(theme.palette.primary.main),
                    strokeWidth: 4,
                    showTooltipOn: "always",
                    tooltip: am5.Tooltip.new(root, {})
                    
                })
            
                return am5.Bullet.new(root, {
                    sprite: circle
                })
            });

            


            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                xAxis: xAxis,
                behavior: "none"
            }));

            cursor.lineY.set("visible", false);
            

            series.appear(1000);
            chart.appear(1000, 100);

            chartRef.current = chart

        

        return () => {
            root.dispose();
            }
        }
    }, []);



    /********* MISE A JOUR DE LA CHART ******** */

    useLayoutEffect(() => {
        if(seriesRef.current){
            seriesRef.current.data.setAll(chartData)
            seriesRef.current.appear(1000);
            seriesRef.current.events.once("datavalidated", function(ev:any) {
                ev.target.get("xAxis").zoomToDates(start, end);
            });
        }
    }, [chartData]);


    useLayoutEffect(()=>{
        if(seriesRef.current){
            xaxisRef.current.zoomToDates(start, end);
            xaxisRef.current.get('baseInterval').timeUnit = BASE_INTERVAL
        }
    },[start, BASE_INTERVAL])


    return(
        <Box sx={{padding: mobile? 0 : 3}}>
            <Box 
                id={"chartdiv-"+type} 
                sx={{ width: "100%", height: "500px" }}
            />
        </Box>
    )
}