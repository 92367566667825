import { BORDER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {

day:{
    paddingbottom: 3, 
    display:"flex", 
    flex: 1,
    width:"auto",
    justifyContent:"flex-start",
    alignItems:"center",
    //overflow: "hidden",
    position: "relative",
    flexDirection: "column",
    backgroundColor: "transparent",
    minHeight: {
        sm: 250,
        md: 400,
    },
    padding: {
        xs: 1, 
        sm: 0.5
    },
    paddingTop: "0 !important",
    paddingBottom: 0,
    borderLeft: `solid 1px #e4e4e4`,
    borderRight: {
        xs: `solid 1px #e4e4e4`,
        sm: 0
    }, 
    borderTop: {
        xs: `solid 1px #e4e4e4`,
        sm: 0
    }, 
    overflow: {xs: "visible", sm: "hidden"}
},


program : {
    width: "100%",
    height: 30,
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start",
    borderRadius: 1,
    paddingLeft: 1,
    marginTop: 2,
},

programName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600
},

lastDay : {
    borderRight: {xs: 0, xl: `solid 1px #e4e4e4 !important`},
    borderBottom: {xs: `solid 6px #e1e1e1`, sm: 0}
},



addButtonContainer : {
    display:"block",
    //width: "calc(100% - 16px)",
    width: 40,
    height: 40,
    alignSelf: "center",
    alignItems:"center",
    justifyContent:"center",
    marginBottom: 2,
    //marginLeft: 1,
    backgroundColor: LIGHT_BUTTON_COLOR,
    borderRadius:"50px !important",

},

addButton: {
    backgroundColor: "#f7fbff",
    width:"100%",
    borderRadius:"50px !important",
},

addButtonIcon: {
    color: "#575757"
},


labelContainer:{
    width: "100%",
    //backgroundColor: "#f3f3f3",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start",
    height: 60,
    paddingLeft: 1,
    borderTop: {xs: "none", sm: "solid 1px #e4e4e4"}
},

labelContainerTemplate:{
    justifyContent:"flex-end",
    paddingRight: 1,
    color: "lightgrey"
},


dateLabelContainer : {
    display:"flex",
    flexDirection: "row",
    alignItems: "center"
},

dateLabel:{
    //marginBottom: 2, 
    height: 40,
    minWidth: 40,
    borderRadius: 50, 
    display:"flex", 
    alignItems:"center", 
    justifyContent: "flex-start",
    fontSize: {
        xs: theme.typography.pxToRem(16),
        xl: theme.typography.pxToRem(18)
    },
    fontWeight: 600,
    color: "#3d3d3d"
},

pastDayLabel:{
    color: "#dddddd"
},


today : {
    color: "white",
    fontWeight: 700,
    backgroundColor: "primary.main",
    textAlign: "center",
    justifyContent:"center",
    alignItems:"center"
},

firstDayOfMonth : {
    fontWeight: 700,
    
},

firstDayOfMonthToday: {
    backgroundColor: "primary.main",
    borderRadius: "5px",
},


emptyProgram :{
    height: 30, 
    width: "100%", 
    display:"flex", 
    alignItems:"center", 
    marginBottom: 1.5,
    
},

content: {
    //paddingLeft: 1.5,
    //paddingRight: 1.5,
    display:"flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 60
},

contentLoader : {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"absolute",
    top: "50%",
    left:"50%",
    tansform: "translateX(-50%) translateY(-50%)"
},



checkup:{
    width: "100%",
    position: "relative",
    backgroundColor: "#f69a12",
    color: "white",
    marginBottom: 1
},



}

export default classes