import { darken, lighten } from "@mui/material"
import { theme } from "utils/theme"

const classes = {

    // SUBSCRIPTION CARD

    currentIndicator:{
        position: "absolute", 
        top: -40, 
        right: -40, 
        backgroundColor: "#16385f", 
        width: 80, 
        height: 80, 
        transform: "rotate(45deg)"
    },

    header:{
        display: "flex", 
        flexDirection:"column", 
        alignItems:"flex-start",
        background: "linear-gradient(80deg, rgb(24 132 255) 0%, rgb(5 110 230) 100%)",
        padding: 3
    },

    title: {
        fontSize: theme.typography.pxToRem(28), 
        color:"white",
        fontWeight: 500
        
    },

    month: {
        fontSize: theme.typography.pxToRem(20), 
        color:"white",
        fontWeight: 500,
        marginLeft: 2,
        top: 12,
        position: "relative"
    },


    descriptionContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        height: 80,
        paddingLeft: 3,
        paddingRight: 3
    },

    description:{
        marginTop: 3,
        fontSize: {
            xs: theme.typography.pxToRem(15),
            md: theme.typography.pxToRem(15),
        },
        fontStyle: "italic",
        fontWeight: 500
    },

    clients :{
        fontSize: {
            xs: theme.typography.pxToRem(18),
            md: theme.typography.pxToRem(18),
        },
        color:"rgba(0,0,0,0.95)", 
        fontWeight: 700,
        marginBottom: 3
    },


    priceContainer:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        width: "100%"
    },

    price: {
        fontSize: {
            xs: theme.typography.pxToRem(30), 
            md: theme.typography.pxToRem(60), 
        },
        color:"white", 
        fontWeight: 800,

    },

    recurence:{
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
        color: "white"
    },

    feature:{
        display:"flex", 
        flexDirection:"row", 
        height: 48, 
        alignItems:"center"
    },

    featureLabel:{
        fontWeight: 500, 
        fontSize: {
            xs: theme.typography.pxToRem(16),
            lg: theme.typography.pxToRem(16),
        }
    },

    button: {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        height: 80, 
        mt: 3
    },

    card : {
        padding: 0,
        position: "relative",
        borderRadius: 6
    }


    
}


export default classes