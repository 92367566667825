
import {Grid, Typography, Box} from "@mui/material" 
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import User, { UserMeasures } from "interfaces/User"
import Measure from "interfaces/Measure"
import { BODY_MEASURES } from "utils/constants"
import classes from "./styles"
import React, { useMemo, useState } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import DialogChartMeasure from "components/organisms/DialogChartMeasure"
import OpenInFullIcon from '@mui/icons-material/OpenInFull';


/******** CHART JS ********* */

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


/******** INTERFACE ********* */

interface CardPropsTypes{
    type: keyof UserMeasures,
    measures: Measure[],
    user:User
}


/******** CARD COMPONENT ********* */

export default function MeasureCard({ type, measures, user }: CardPropsTypes) {

    const [open, setOpen] = useState<boolean>(false)

    ////// DONNEES DU LINE CHART FORMATEES ////////

    /*const chartData = useMemo(()=>{
        if(Boolean(measures)){
            
            const data:number[] = []
            const labels:string[] = []

            measures.forEach((elem)=> {
                data.push(elem.value)
                labels.push(new Date(elem.date).toDateString())
            })

            return {
                labels,
                datasets:[
                    {
                        label: type,
                        data,
                        borderColor: theme.palette.primary.main
                    }
                ]
            }
        }else {
            return null
        }
    },[type])*/
    

    ////// DONNEES DE LA MESURE ACTUELLE ////////
    const currentMeasure:Measure = useMemo(()=>{
        
         // Filtrage de la dernière mesure
        const lastMeasure:Measure = measures ? measures[0] : null
         // Récupération de l'unité et de la traduction
        const extendedData = BODY_MEASURES.find((elem)=> elem.label === type)


        return {
            ...lastMeasure,
            units: extendedData.units,
            fr: extendedData.fr 
        }
    
    },[type])


    return (
        <React.Fragment>
    
                <Grid item xs={12} sm={6} lg={4} xl={4}>
                    <Box sx={classes.measureCard} onClick={()=> setOpen(true)}>

                        <Box sx={classes.expandIcon}>
                            <OpenInFullIcon sx={{color: "#b8b8b8"}}/>
                        </Box>

                        <Typography style={{ fontSize: "1rem" }}>
                            {currentMeasure.fr}
                        </Typography>
                        <Box style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <Typography style={{ fontSize: "2.6rem", fontWeight: 700 }}>
                                {currentMeasure.value ? currentMeasure.value : "-"}
                            </Typography>
                            <Typography sx={{ marginLeft: 1 }}>
                                {currentMeasure.value ? currentMeasure.units : ""}
                            </Typography>
                        </Box>
                        <Typography style={{ fontSize: "0.9rem" }}>
                            {Boolean(currentMeasure.date) ? format(new Date(currentMeasure.date), "dd MMM à HH:MM", { locale: fr }) : "Aucune donnée disponible"}
                        </Typography>
                        {/*chartData?.datasets[0].data.length > 1 && (
                            <Box sx={{height: 110, paddingTop: 2}}>
                                <Line 
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        layout:{
                                            padding: 8 * 3
                                        },
                                        scales:{
                                            y:{
                                                display: false
                                            },
                                            x:{
                                                display: false
                                            }
                                        },
                                        //responsive: true,
                                        plugins: {
                                        legend: {
                                            display: false
                                        },
                                        title: {
                                            display: false,
                                        },
                                        },
                                    }} 
                                    data={chartData} 
                                />
                            </Box>
                        )*/}
                    </Box>
                </Grid>
            
            {/********** CHART DIALOG ********* */}

            <DialogChartMeasure
                open={open}
                onClose={()=>setOpen(false)}
                type={type}
                user={user}
            />


        </React.Fragment>
    )
}
