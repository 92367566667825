import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    goal:{
        border: `solid 1px ${BORDER_COLOR}`,
        height: "45px",
        "&:hover" : {
            backgroundColor: HOVER_COLOR
        },
        marginBottom: 1.5,
        display:"flex",
        alignItems:"center",
        borderRadius: 3
    }
}


export default classes