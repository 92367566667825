import { lighten } from "@mui/material"
import { LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme} from "utils/theme"

const classes ={
    checkup: {
        display:"flex",
        width: "100%",
        position: "relative",
        marginBottom: 3,
        backgroundColor: "rgb(172 125 248)",
        color: "white",
        flexDirection: "row",
        cursor: "pointer",
        overflow: "hidden",
        alignItems:"center",
        textOverflow: "ellipsis",
        padding: {
            xs:1,
            lg: 1,
        },
        flexShrink: 200,
        minHeight: {xs: 50, sm: 40}
   },

   checkupName: {
        fontSize: {
            xs: "0.8rem",
            lg: "0.9rem",
            xl: "1rem",
        }, 
        fontWeight: 500, 
        overflow: "hidden",
        textOverflow: "ellipsis", 
        //whiteSpace: "nowrap",
        display:"block",
        width: '100%',
        textWrap: "wrap",


   },

   icon: {
    marginRight: 1,
    fontSize: {
        xs: theme.typography.pxToRem(16),
        lg: theme.typography.pxToRem(20),
    }
   },

   monthlyCheckup : {
    //backgroundColor: "#ff8e00",
   },

   punctualCheckup : {
    backgroundColor: "rgb(65 120 174)"
   },


   invalid: {
    backgroundColor: "#d52e2e"
   },
 

    draftIndicator: {
      width: "100%",
      height: "100%",
      backgroundColor: lighten(theme.palette.primary.main, 0.30),
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      position:"absolute",
      top: 0,
      right: 0,
      zIndex: 2,
      borderRadius: "0 0 0 10px"
  },


  activationIndicator:{
      borderRadius: "5px", 
      backgroundColor: lighten(theme.palette.primary.main, 0.30),
      marginRight:1, 
      display:"inline-flex", 
      alignItems:"center", 
      justifyContent:"center", 
      width: 110,
      position: "absolute",
      top: 8,
      right: 1
  },

  activate: {
      backgroundColor: "rgb(196 240 164)"
  },

  activationIndicatorFont:{ 
      fontWeight: 700, 
      fontSize: "0.9rem", 
      marginLeft: 1
  },
    
}

export default classes