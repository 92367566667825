import { Fade, Slide, useMediaQuery } from "@mui/material";
import React from "react";
import { TransitionProps } from "react-transition-group/Transition";
import { MOBILE } from "utils/constants";


export const DialogTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
  ) {

    const mobile = useMediaQuery(MOBILE)
    
    if(mobile){
      return (
          <Slide 
              direction="left" 
              ref={ref} 
              {...props} 
          />
      )
  }else{
    return(
      <Fade
        ref={ref} 
        {...props} 
      />
    )
  }
  });

  export default DialogTransition
