import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import { Box, Collapse, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import {Delete, DonutSmall, ExpandMore, KeyboardArrowDown, MenuBook } from "@mui/icons-material";
import { useMemo, useState } from "react";
import DialogRecipe from "../DialogRecipe";
import { onChangeMealRecipeIngredientPortion, onChangeMealRecipePortion, onDeleteMealRecipe } from "features/mealPlanSlice";
import { MOBILE } from "utils/constants";
import MealFoodItem from "../MealFoodItem";
import RecipeItem from "../RecipeItem";
import MealRecipeIngredient from "../MealRecipeIngredient";
import styled from "@emotion/styled";
import { theme } from "utils/theme";
import MoreButton, { MenuElem } from "components/atoms/Buttons/MoreButton";

interface Props{
    recipeIndex: number,
    mealIndex: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
}




function Buttons ({expandRecipe, expanded, onDelete, onDialog}:{expandRecipe: ()=>void, expanded: boolean, onDelete: ()=>void, onDialog: ()=>void}){
    return(
        <Box
            sx={{display:"flex", alignSelf: "flex-start", alignItems:"center"}}
        >
            

            <IconButton 
                onClick={expandRecipe}
                sx={[
                    classes.dropDownButton(expanded), 
                    classes.expandButton, 
                    {marginRight: 0}
                ]}
            >
                <KeyboardArrowDown />
            </IconButton>
            <IconButton
                onClick={onDialog}
            >
                <DonutSmall />
            </IconButton>
            <IconButton
                onClick={onDelete}
            >
                <Delete/>
            </IconButton>
            </Box>
    )
}


export default function MealRecipeItem({recipeIndex, mealIndex, day}:Props){

    
    const portion = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].portion)
    const recipe = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe)
    //const thumbnail = useSelector((state:RootState) => state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe.cover.url)
    const dispatch = useDispatch<AppDispatch>()
    const [openRecipeDialog, setOpenRecipeDialog] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const [expanded, setExpanded] = useState<boolean>(false)


    const expandRecipe = () => {
        setExpanded(!expanded)
    }

    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeMealRecipePortion({
            day,
            recipeIndex,
            mealIndex,
            portion: event.target.value,
        }))
    }


    const onDelete = () => {
        dispatch(onDeleteMealRecipe({
            day,
            recipeIndex,
            mealIndex
        }))
    }



     {/************** 
        MENU MORE 
    ***************/}

    const MENU:MenuElem[] = useMemo(()=>{
        var menu:MenuElem[] = []


        menu.push(
            {
                label: "Supprimer",
                icon: <Delete />,
                callback: onDelete
            }
        )
       
           
   
        return menu

       
    },[])





    return(
        <Box
            sx={classes.item}
        >
            <Box
                sx={{display:"flex", 
                    flexDirection: "row",
                    alignItems:"flex-start",
                    justifyContent:"flex-start",
                    flex: 1,
                    width: "100%"
                }}
            >
                <Box
                    sx={classes.itemInfos}
                >
                    
                    {/**************
                     * RECIPE INFOS
                     *************/}
                    <Box
                        sx={classes.recipeInfos}>

                             {/*************
                             * PORTIONS
                             **************/}
                            
                            <TextField
                                value={portion}
                                sx={[
                                    classes.textField, 
                                    classes.portionInput
                                ]}
                                size="small"
                                onFocus={event => {
                                    event.target.select();
                                }}
                                onChange={onChangePortion}
                                variant="outlined"
                            />

                            <TextField
                                size="small"
                                value={"portion(s)"}
                        
                                sx={[
                                    classes.textField,
                                    classes.labelInput
                                ]}
                                disabled={true}
                            />  
                    </Box>

                    {/*************
                     * NAME
                     **************/}
                        
                    <Box
                        sx={classes.itemNameContainer}
                    >

                        {/**********
                         * NAME + ICON 
                         *************/}

                       
                        <MenuBook 
                            sx={{marginRight: 1.5, color: theme.palette.primary.main}}
                        />
                        <Typography
                            sx={classes.itemName}
                        >
                            {!mobile && "Recette - "}{recipe.name.fr}
                        </Typography>
                     


                            
                    </Box>

                    
                
                </Box>

                {!mobile && (
                    <Buttons
                        expandRecipe={expandRecipe}
                        expanded={expanded}
                        onDelete={onDelete}
                        onDialog={()=>setOpenRecipeDialog(true)}
                    />
                )}
       

                {/**********
                 *  MORE
                 *************/}

                {mobile && (
                    <Box
                    sx={{display:"flex", alignItems:"flex-start", alignSelf:"flex-start"}}
                >
                        <MoreButton
                            menuList={MENU}
                        />
                    </Box>
                )}


       

                </Box>


                {/**********
                 *  INGREDIENTS
                 *************/}
                <Box 
                    sx={classes.ingredients}
                >
                    
                    <Collapse 
                        in={expanded} 
                        mountOnEnter 
                        unmountOnExit
                    >
                        <Box 
                        sx={{paddingTop: 2}}>
                        {recipe.ingredients.map((_,index)=> {
                            return(
                                <MealRecipeIngredient 
                                    key={index}
                                    mealIndex={mealIndex}
                                    recipeIndex={recipeIndex}
                                    ingredientIndex={index}
                                    day={day}
                                />
                            )
                        })}


                    {expanded && (
                        <Box
                            sx={classes.lineIndicator}
                        />
                    )}
                    </Box>
                    </Collapse>
                </Box>

                <DialogRecipe
                    open={openRecipeDialog}
                    onClose={()=>setOpenRecipeDialog(false)}
                    recipe={recipe}
                />

        </Box>
    )
}