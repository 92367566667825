import { Box, Avatar, Typography, IconButton, useMediaQuery, Grid } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import User from "interfaces/User"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "components/molecules/Tabs"
import classes from "./styles"
import { differenceInCalendarYears } from "date-fns"
import DialogAvatar from "../DialogAvatar"
import DialogDeleteAvatar from "components/molecules/DialogDeleteAvatar/intdex"
import { Delete, Edit, Menu } from "@mui/icons-material"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import { toast } from "react-toastify"
import { logout } from "api/user"
import LogoutIcon from '@mui/icons-material/Logout';
import { GOALS, MOBILE } from "utils/constants"
import { handleDrawer } from "features/appSlice"

interface Props{
    data: User,
    tabs?: number,
    defineTabs?: (index:number)=> void,
    hideTabs?:boolean,
    userProfile?: boolean,
    showDisconnect?: boolean,
    grid?:boolean,
}


const tabsLabel = [
    "Historique",
    "Données",  
    "Planification",
    "Plan alimentaire"
]

const tradGender = (value:"male"|"female") => {
    switch(value) {
        case "male" : return "Homme";
        case "female" : return "Femme";
    }
}


export default function ProfileHeader({data, tabs, defineTabs, hideTabs, userProfile, showDisconnect = false, grid = false, }:Props){

    const dispatch = useDispatch<AppDispatch>()
    const currentUser = useSelector((state:RootState) => state.user.data)
    const [openAvatar, setOpenAvatar] = useState<boolean>(false)
    const [openDeleteAvatar, setOpenDeleteAvatar] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)

    /****** LOGOUT ******* */

    const onLogout = () => {
        dispatch(logout())
        toast.success('Vous êtes déconnecté')
    }

    return(

        <React.Fragment>

            <Box 
                sx={classes.header}
            >
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={grid? 10 : 12} xl={grid ? 9 : 12}>
                        <Box 
                            sx={[
                                classes.userData, 
                                !hideTabs && {paddingBottom: 0.5, paddingTop: 2}
                            ]}
                        >
                            <Box 
                                sx={{
                                    flexDirection: "row",  
                                    display:"flex", 
                                    flexGrow: 1,
                                    alignItems:"center",
                                    height: {xs: 43, sm: "auto"}
                                }}>


                                    {/********************
                                     * BOUTON MENU
                                     **********************/}

                                    <IconButton
                                        sx={classes.iconButton}
                                        onClick={()=>dispatch(handleDrawer({open:true}))}
                                    >
                                        <Menu 
                                            sx={{color: "rgba(0,0,0,0.85)"}}
                                        />
                                    </IconButton>
                                    

                                

                                {/*********** 
                                 * AVATAR 
                                 * ******* */}

                                <Box 
                                    sx={classes.avatarContainer}
                                >
                                    <Avatar 
                                        src={data?.avatar?.url ? data.avatar.url : "SC"} 
                                        sx={[classes.avatar, (userProfile && !currentUser.avatar?.url ) && classes.clickableAvatar]} 
                                    />

                                    {(userProfile && data.avatar?.cloudFlareId) && (
                                        <IconButton sx={classes.deleteAvatarButton} onClick={()=>setOpenDeleteAvatar(true)}>
                                            <Delete/>
                                        </IconButton>
                                    )}

                                    {(userProfile && !data.avatar?.cloudFlareId) && (
                                        <IconButton sx={classes.deleteAvatarButton} onClick={()=>setOpenAvatar(true)}>
                                            <Edit/>
                                        </IconButton>
                                    )}

                                </Box>
                                    

                                {/******************* 
                                 * Infos utilisateur 
                                 * ******* *********/}

                                <Box sx={classes.infos}>
                                    <Typography 
                                        sx={classes.userName}
                                    >
                                        {data?.firstname} {data?.lastname}
                                    </Typography>

                                    {(!mobile && !userProfile )&& (

                                    <Box sx={{ display: "flex", flexDirection: "row" }}>

                                        {(!userProfile && !mobile) && (
                                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                                {tradGender(data?.gender)} - 
                                            </Typography>
                                        )}

                                        {(data?.birthday && !userProfile && !mobile) && (
                                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                                {differenceInCalendarYears(new Date(), new Date(data.birthday))} ans - 
                                            </Typography>
                                        )}

                                        {(data.size && !userProfile && !mobile) && (
                                            <Typography variant="body2" sx={{ marginRight: 1 }}> 
                                                {data?.size} {(data && data?.size) && "cm"} -
                                            </Typography>
                                        )}

                                        {data.goal && (
                                            <Typography variant="body2" style={classes.email}>
                                                {GOALS.find((elem)=> elem.en === data.goal).fr}
                                            </Typography>

                                        )}

                                       


                                        {!data.goal && (
                                            <Typography variant="body2" style={classes.email}>
                                                Aucun objectif défini
                                            </Typography>

                                        )}
                                    </Box>
                                    )}
                                </Box>
                            </Box>
                            
                            {showDisconnect && (
                                <SecondaryButton 
                                    onClick={onLogout} 
                                    endIcon={<LogoutIcon />}
                                >
                                    Déconnexion
                                </SecondaryButton>
                            )}
                        </Box>

                        {/******** 
                         * TABS 
                         * *** */}
                        
                        {!hideTabs && (
                            <Box sx={classes.tabs}>
                                <Tabs
                                    value={tabs}
                                    onChange={(e,value)=>defineTabs(value)}
                                    tabs={tabsLabel}
                                />
                            </Box>
                        )}

                    </Grid>
                </Grid>
                
            
        
            </Box>


            <DialogAvatar
                open={openAvatar}
                onClose={()=> setOpenAvatar(false)}
            />


            <DialogDeleteAvatar
                open={openDeleteAvatar}
                onClose={()=> setOpenDeleteAvatar(false)}
            />
        </React.Fragment>
    )
}