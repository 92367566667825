import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Card, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { handleBlockInstructions, handleTrack } from "features/workoutSlice";
import { GroupExercice, WorkoutBlock as WorkoutBlockType, WorkoutExercice} from "interfaces/Workout";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles"
import { createSelector } from "@reduxjs/toolkit";
import DialogExercicesList from "components/organisms/DialogExercicesList";
import Exercice from "../WorkoutExercice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Add,Close, DragIndicator, MobileFriendly, PhonelinkErase } from "@mui/icons-material";
import DialogBlockDelete from "components/molecules/DialogBlockDelete";
import BlockTitle from "components/molecules/WorkoutBlockName";
import 'draft-js/dist/Draft.css';
import debounce from "lodash.debounce";
import 'react-quill/dist/quill.snow.css';
import "./editorStyle.css"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogBlockTracking from "../DialogBlockTracking";
import { MOBILE } from "utils/constants";
import SecondaryButton from "components/atoms/Buttons/Secondary";



////// REDUX SELECTORS ///////

const nameSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.name
    }
)


const contentSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.content
    }
)


const trackSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.track
    }
)


const typeSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.type
    }
)


const instructionsSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.instructions
    }
)



function TrackerButton({track, openBlockTracking}:{track:boolean, openBlockTracking: (tracker:boolean)=> void}){

    return(

        <Box 
            sx={[
                classes.trackingButton, 
                !track && classes.trackingButtonOff
            ]} 
            onClick={()=> openBlockTracking(true)}
        >
            {track && (
            <MobileFriendly 
                sx={{fontSize: 18, marginRight: 1, color: track ? "rgba(0,0,0,0.85)" : "#bcbcbc"}} 
            />)}

            {!track && (
                <PhonelinkErase 
                sx={{fontSize: 18, marginRight: 1, color: track ? "rgba(0,0,0,0.85)" : "#bcbcbc"}} 
            />
            )}
            
            <Typography 
                sx={[
                    classes.trackingButtonLabel, 
                    !track && classes.trackingButtonLabelOff
                ]}>
                    {track ? "Avec suivi des performances" : "Sans suivi des performances"}
            </Typography>
        </Box>
    )
}


////// INTERFACES ///////
interface Props {
    blockId: string, // Id unique du block
    blockIndex: number,
    showAddBlock: boolean,
    onAddBlockToWorkout: ()=> void
}


/*********** MAIN COMPONENT ********* */

export function WorkoutBlock({blockId, blockIndex, showAddBlock, onAddBlockToWorkout}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const name:string = useSelector((state:RootState)=>nameSelector(state,blockId))
    const content:GroupExercice[] = useSelector((state:RootState)=>contentSelector(state,blockId))
    const track:boolean = useSelector((state:RootState)=>trackSelector(state,blockId))
    const type = useSelector((state:RootState)=> typeSelector(state,blockId))
    const currentInstructions = useSelector((state:RootState)=> instructionsSelector(state,blockId))
    const [blockIsReady, setBlockIsReady] = useState<boolean>(false)
    const [openList, setOpenList] = useState<boolean>(false)
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [instructions, setInstructions] = useState('')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openBlockTracking, setOpenBlockTracking] = useState<boolean>(false)
    const [exerciceToSwap, setExerciceToSwap] = useState<{groupId:string, exerciceId: string}>(null)
    const mobile = useMediaQuery(MOBILE)

    /****** INSTRUCTIONS EN MODE TEXTE ***** */

    const debounceInstructions = useRef(debounce((data:string)=> {
        dispatch(handleBlockInstructions({instructions: data, blockId}))
    }, 1000)).current


    useEffect(()=>{
        //const stringifyInstructions = JSON.stringify(convertToRaw(instructions.editorState.getCurrentContent()))
        debounceInstructions(instructions)
    },[instructions])


    useEffect(()=>{
        if(currentInstructions && !blockIsReady){
            setInstructions(currentInstructions)
            setBlockIsReady(true)
        }
    },[currentInstructions, blockIsReady])


    const handleBlockStatus = () => {
        dispatch(handleTrack({blockId, track: !track}))
    }


    const onOpenDeleteBlock = () => {
        setOpenDelete(true)
        setAnchorEl(null)
    }


    const onOpenSwap = (exerciceId:string, groupId:string) => {
        setOpenList(true)
        setExerciceToSwap({
            groupId,
            exerciceId,
        })
    }


    const onCloseExercicesList = () => {
        setOpenList(false)
        setTimeout(()=>{
            setExerciceToSwap(null)
        },200)
        
    }

 
    /************ JSX ************ */

    return(

        <Box 
            sx={classes.block}
        >


            {/****************************** 
             * 
            *     HEADER DU BLOCK
            * 
            *  *************************** */}

            <Box sx={[
                classes.header, 
                content.length ===0 && classes.headerWithBorder
            ] }>

                {/********* TITRE DU BLOC ******** */}
                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center"}}>

                    {/***************** 
                     * BOUTON TRACKING 
                     * ****************/}

                   

                    {/** Blcok Title *** */}
                        <Typography
                            sx={classes.blockTitle}
                        >
                            #{blockIndex + 1} - &nbsp;
                        </Typography>
                        <BlockTitle
                            blockId={blockId}
                            name={name}
                            className={"block-name"}
                            blockIndex={blockIndex}
                        />
                </Box>


                {/****************************** 
                 * LISTE DES BOUTONS DU BLOCK 
                 * *************************** */}

                <Box sx={classes.blockButtons}>

                    {/** TRACKER DESKTOP ONLY**/}

                    {!mobile && (
                        <TrackerButton 
                            openBlockTracking={setOpenBlockTracking}
                            track={track}
                        />
                    )}
                  

                        

                    {/************** 
                     * MORE BUTTON
                     * ******** */}

                    <IconButton 
                        sx={{borderRadius: "100px !important", marginLeft: 2}} 
                        onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                    >
                        <MoreHorizIcon />
                    </IconButton>

                    <Menu disableScrollLock={true} 
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={()=>setAnchorEl(null)}
                    >
                        <MenuItem onClick={onOpenDeleteBlock}>
                            <ListItemIcon>
                                <Close fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Supprimer
                            </ListItemText>
                        </MenuItem>  
                    </Menu>
                </Box>
            </Box>



            {/** TRACKER MOBILE ONLY**/}

            {mobile && (
                <TrackerButton 
                    openBlockTracking={setOpenBlockTracking}
                    track={track}
                />
            )}
            

            {/******************* 
            * CONTENU DU BLOC 
            * **************** */}
            
            <Box>

                {type === "builder" && (
                    <Droppable 
                        droppableId={blockId}
                    >
                        {(provided: any) => (
                            <Box 
                                ref={provided.innerRef} 
                                {...provided.droppableProps}
                                sx={[
                                    classes.dropZone, 
                                    content.length ===0 && classes.emptyBlock
                                ]} 
                            >

                                {/***** ********
                                 * EMPTY BLOCK
                                 * ****** *****/}

                                {content.length ===0 && (
                                   <Box>
                                        <Typography
                                            sx={{fontWeight: 600, fontSize: "1.1rem", color: "rgb(82 82 82 / 75%)"}}
                                        >
                                            Aucun exercice ajouté
                                        </Typography>
                                    </Box>
                                )}
                                

                                {/***** **********************************************
                                 * LISTE DES ELEMENTS DRAGGABLE (EXERCICE OU SUPERSET) 
                                 * ****** ******************************************/}

                                {content.map((group:GroupExercice, index:number)=> {

                                    const isLastBlockElement:boolean = index === content.length - 1

                                    return(
                                        <Draggable 
                                            draggableId={group.groupId} 
                                            index={index}
                                            key={group.groupId}
                                        >
                                            
                                            {(provided: any, snapshot: any) => (
                                                <Card
                                                    elevation={snapshot.isDragging? 1 : 0} 
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    /*{...provided.dragHandleProps}*/
                                                    sx={classes.draggableElement(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Box sx={{position:"relative"}}>

                                                            {/***** SUPERSET INDICATOR **** */}
                                                            {(mobile && group.exercices.length > 1) && (
                                                                <Box sx={classes.superSetIndicator} />
                                                            
                                                            )}
                                                        
                                                            <Box 
                                                                sx={classes.group} 
                                                                key={index}
                                                            >


                                                            {/***** SUPERSET INDICATOR **** */}
                                                            {group.exercices.length > 1 && (
                                                                <Box sx={{flexDirection: "row", display:"flex", alignItems:"center"}}>
                                                                    <Box
                                                                        {...provided.dragHandleProps}
                                                                        sx={{height: "50px", display:"flex", alignItems:"center"}}
                                                                    >
                                                                        <DragIndicator />
                                                                    </Box>
                                                                    <Box sx={classes.supersetLabel}>
                                                                        Superset ({group.exercices.length})
                                                                    </Box>
                                                                </Box>

                                                                
                                                                
                                                            )}

                                                            {/***** SUPERSET INDICATOR **** */}
                                                            {(!mobile && group.exercices.length > 1) && (
                                                                <Box sx={classes.superSetIndicator} />
                                                            )}
                                                        

                                                            

                                                            {/*****************
                                                            Liste des exercices 
                                                            *******************/}
                                                            
                                                            <Box 
                                                                sx={classes.groupContent}
                                                            >
                                                                {group.exercices.map((workoutExercice:WorkoutExercice, exerciceIndex: number)=> {

                                                                    const isLastGroupExercice:boolean = group.exercices.length -1 === exerciceIndex

                                                                    return(
                                                                        <Exercice 
                                                                            key={exerciceIndex}
                                                                            _id={workoutExercice.exercice._id}
                                                                            exerciceId={workoutExercice.exerciceId}
                                                                            blockId={blockId}
                                                                            groupId={group.groupId}
                                                                            isLastBlockElement={isLastBlockElement}
                                                                            isLastGroupExercice={isLastGroupExercice}
                                                                            fromSuperset={group.exercices.length>1}
                                                                            track={track}
                                                                            groupIndex={index}
                                                                            exerciceIndex={exerciceIndex}
                                                                            onOpenSwap={onOpenSwap}
                                                                            type={workoutExercice.exercice.type._id}
                                                                            showDragIcon={group.exercices.length == 1}
                                                                            provided={provided}
                                                                        />
                                                                    )
                                                                })}

                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            )}

                                        </Draggable>
                                    )
                                })}

                                {provided.placeholder}

                            </Box>
                        )}


                    </Droppable>
                )}



                <Box 
                    sx={classes.addExerciceButton}
                >
                  
                        
                    <SecondaryButton 
                        onClick={()=> setOpenList(true)} 
                        sx={{
                            textTransform: "none", 
                            marginRight: showAddBlock? {xs:0, sm :2} : 0, 
                            borderRadius: "24px !important",
                            marginBottom: {
                                xs: 2,
                                sm: 0
                            }
                        }} 
                        disableElevation={true}
                        startIcon={<Add/>}
                    >
                        Ajouter un exercice
                    </SecondaryButton>
                    
                


                    {showAddBlock && (
                        <SecondaryButton
                            onClick={onAddBlockToWorkout}
                            startIcon={<Add/>}
                            sx={{
                                borderRadius: "24px !important"
                            }}
                        
                    >
                        Ajouter un bloc
                        </SecondaryButton>
                    )}
                 </Box>

                    

                {/********* TEXT ******** 

                {type === "text" && (
                    <Box sx={classes.blockInstructions}>
                        <ReactQuill 
                            theme="snow"
                            value={instructions} 
                            onChange={setInstructions} 
                            modules={editorModules}
                            formats={editorFormats}
                            placeholder="Entrez les instructions relatives à ce bloc"
                            
                        />
                        

                        {/*<Editor 
                            editorState={instructions.editorState} 
                            onChange={onChangeInstructions}
                            placeholder={`Entrez les prescriptions pour le bloc ${name}`}
                        />
                        <Box sx={classes.editorToolbar}>
                            <IconButton onClick={onBoldClick} sx={classes.toolbarButton}>
                                <FormatBoldIcon />
                            </IconButton>
                            <IconButton onClick={onItalicClick} sx={classes.toolbarButton}>
                                <FormatItalic />
                            </IconButton>
                        </Box>
                        
                    </Box>
                )}
                */}
            </Box>


            {/********** LISTE DES EXOS ******* */}

            <DialogExercicesList 
                open={openList} 
                onClose={onCloseExercicesList} 
                blockId={blockId}
                exerciceId={exerciceToSwap?.exerciceId}
                groupId={exerciceToSwap?.groupId}
                swap={Boolean(exerciceToSwap)}
            />

            {/********** Dialog de suppression d'un bloc ******* */}

            <DialogBlockDelete
                open={openDelete}
                onClose={()=> setOpenDelete(false)}
                blockId={blockId}
                blockName={name}
            />


            <DialogBlockTracking
                open={openBlockTracking}
                onClose={()=> setOpenBlockTracking(false)}
                blockIndex={blockIndex}
                blockId={blockId}
                currentTrack={track}
            />

        </Box>
    )
}





export default WorkoutBlock

