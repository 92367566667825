import Meal from "interfaces/Meal";
import {Micros} from "interfaces/Food";
import { DEFAULT_MICROS } from "utils/constants";




export default function calcMicros(meals:Meal[]){
    const micros:Micros = {...DEFAULT_MICROS}
    if(meals){
      meals.forEach((meal)=> {
        meal.content.forEach((mealContent)=> {
          if(mealContent.type === "food" || mealContent.type === "scanned"){
              Object.entries(micros).forEach((property) => {
                const microProperty = property[0] as keyof typeof micros
                if(mealContent.food.micros){
                  micros[microProperty] = micros[microProperty] + (mealContent.food.micros[microProperty] * (mealContent.grammage/100))
                }
              })
          } else if(mealContent.type === "recipe"){
              const baseRecipePortion = mealContent.recipe.portions
              const multiplicateur = mealContent.portion / baseRecipePortion

              mealContent.recipe.ingredients.forEach((ingredient)=>{
                if(ingredient?.ingredient?.food){
                  Object.entries(micros).forEach((property) => {
                    const microProperty = property[0] as keyof typeof micros
                    if(ingredient.ingredient?.food?.micros){
                      micros[microProperty] = micros[microProperty] + (ingredient.ingredient?.food?.micros[microProperty] * (((ingredient.ingredient.grammage/100)) * multiplicateur))
                    }
                    })
                }
            })
          }
        })
      })
    }
    return micros
}