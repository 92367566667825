import { Box, Typography } from "@mui/material"
import classes from "./styles"
import calcMacros from "function/calcMacros"
import { useSelector } from "react-redux"
import { RootState } from "app/store"

const days = ['monday', "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const trads = ['Lundi', "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]


interface Props{
    dayIndex: number,
    daySelected: string,
    onChangeDaySelected: (day:number)=> ()=> void,
    template?: boolean
}

export default function NutritionHeaderDays({dayIndex, onChangeDaySelected}:Props){

    const mealPlan = useSelector((state:RootState) => state.mealPlans.mealPlan)

    return(
        <Box
            sx={classes.menu}>
                {days.map((day, index)=> {

                    const thisDay = day as 'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"

                    return(
                        <Box
                            onClick={onChangeDaySelected(index)}
                            key={index}
                            sx={[
                                classes.day,
                                dayIndex === index && classes.daySelected,
                                index === (days.length - 1) && {marginRight: "0 !important"}
                            ]}>
                                <Typography
                                    sx={[
                                        classes.dayLabel,
                                        index === dayIndex && classes.dayLabelSelected
                                    ]}
                                >
                                    {trads[index]}
                                </Typography>
                                <Typography
                                    sx={[
                                        classes.dayLabel,
                                        classes.kcal,
                                        index === dayIndex && classes.kcalSelected
                                    ]}
                                >
                                    {mealPlan && calcMacros(mealPlan[thisDay]).kcal} kcal
                                </Typography>
                        </Box>
                    )
                })}
                
        </Box>
    )
}