import PropagateLoader from "react-spinners/PropagateLoader";
import { Dialog, DialogContent,Box, Typography, useMediaQuery, DialogTitle, DialogActions, Button } from "@mui/material";
import {primaryColor} from "utils/theme"
import classes from "./styles";
import { MOBILE } from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import defineNAPLevel from "function/defineNAPLevel";


interface LoaderProps {
    open: boolean,
    onClose: ()=>void
}


export default function DialogCaloriesNeeded({open, onClose}:LoaderProps){

    const mobile = useMediaQuery(MOBILE)
    const client = useSelector((state:RootState) => state.clients?.profile)

    return(
        <Dialog 
            open={open}
            disableScrollLock
            maxWidth={"sm"}
            
        >
            <DialogTitle>
                Besoins énergétiques
            </DialogTitle>
            <DialogContent>
                <Typography sx={{textAlign: "center"}} >
                    Les besoins énergétiques de votre client sont estimées grâce à son métabolisme de base, son niveau d'activité physique et son objectif. C'est une estimation théorique qui peut vous aider à évaluer ses besoins énergétiques pour affiner votre plan alimentaire.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}