import React, { useState, useMemo, useEffect, useRef } from "react"
//MUI
import { Dialog, DialogContent, Box, DialogActions, Button, useMediaQuery } from "@mui/material"
// REDUX
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "@reduxjs/toolkit"
// INTERFACES
import { AppDispatch, RootState } from "app/store"
import Exercice, { ExerciceMetrics } from "interfaces/Exercice"
// REDUCERS
import { clearExerciceFilter, resetSearchExercices, searchExercices } from "features/exerciceSlice"
// SHARED COMPONENTS
import SearchTextfield from "components/molecules/Search"
import classes from "./styles"
import { WorkoutBlock } from "interfaces/Workout"
import { addExerciceToWorkout, swapExerciceToWorkout } from "features/workoutSlice"
import { toast } from "react-toastify"
import { ViewportList } from "react-viewport-list"
import ListItemExercice from "components/molecules/ListItemExercice"
import {FilterList } from "@mui/icons-material"
import Tabs from "components/molecules/Tabs"
import { MOBILE } from "utils/constants"
import CustomIconButton from "components/atoms/Buttons/IconButton"
import DialogTransition from "components/molecules/DialogTransition"
import DialogAppBar from "components/molecules/DialogAppBar"
import ExercicesFilters from "../ExercicesFilters"


/************ REDUX SELECTORS ************ */

const blockSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, blockId) => {
        const block:WorkoutBlock = builder.blocks.find((elem:WorkoutBlock)=> elem.blockId === blockId)
        return block
    }
)



/************ INTERFACES ************ */

interface Props {
    open: boolean,
    onClose: () => void,
    blockId: string,
    groupId?: string,
    exerciceId?: string,
    swap?: boolean
}

/************* MAIN COMPONENT ********* */

export function DialogExercicesList({ open, onClose, blockId, groupId, exerciceId, swap }: Props) {
    
    const [tabs, setTabs] = useState(0)
    const dispatch = useDispatch<AppDispatch>()
    const exercices = useSelector((state:RootState) => state.exercices.filtered)
    const groupFilters = useSelector(
        (state: RootState) => state.exercices.groupsFilter
    );
    const typesFilters = useSelector(
        (state: RootState) => state.exercices.typesFilter
    );
    const block:WorkoutBlock = useSelector((state:RootState)=>blockSelector(state,blockId))
    const metrics:ExerciceMetrics[] = useSelector((state:RootState)=> state.metrics.list)
    const [search, setSearch] = useState<string>('')
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null)
    const listRef = useRef(null);
    const mobile = useMediaQuery(MOBILE)


    /*********** L'Exercice est il dans le groupe ?  ******* */
    const findExerciceInGroup = (exercice:Exercice) => {
        var found = false
        
        block.content.forEach((group)=>{
            const isFound = group.exercices.find((elem)=> elem.exercice._id === exercice._id)
            if(isFound){
                found = true
            }
        })

        return found
    }

    /*********** OUVERTURE DES FILTRES ******* */
    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    }

    /*********** OUVERTURE DU DIALOG ******* */

    useEffect(()=>{
        setTimeout(()=>{
            setSearch("")
            dispatch(searchExercices({search:""}))
            dispatch(resetSearchExercices())
            dispatch(clearExerciceFilter())
        },200)
    },[open])


    /*********** AJOUT D'UN EXERCICE AU WORKOUT BUILDER ******* */

    const onAddExerciceToWorkout = (exercice: Exercice) => (e: React.SyntheticEvent) => {
        var exercicesMetrics = []
        var exercicesMetricsValue:any[] = []

        // Musculation
        if(exercice.type._id === "63e2583d4634b611780b1f9a"){
            const reps = metrics.find((elem)=> elem._id === "63e36e9c5882116100ce5a1f")
            const rpe = metrics.find((elem)=> elem._id === "63e36e9c5882116100ce5a20")
            exercicesMetrics.push(reps) // REPS
            exercicesMetrics.push(rpe) // RPE
            exercicesMetrics.forEach((elem)=> {
                exercicesMetricsValue.push(elem.defaultValue)
            })
        }

        // CARDIO
        else if(exercice.type._id === "63e25db34634b611780b1fab"){
            const duration = metrics.find((elem)=> elem._id === "63e36e9c5882116100ce5a29")
            exercicesMetrics.push(duration) // DUREE
            exercicesMetrics.forEach((elem)=> {
                exercicesMetricsValue.push(elem.defaultValue)
            })
        }

        // STRETCHING / Mobilité / 
        else{
            const duration = metrics.find((elem)=> elem._id === "63e36e9c5882116100ce5a29")
            exercicesMetrics.push(duration) // DUREE
            exercicesMetrics.forEach((elem)=> {
                exercicesMetricsValue.push(elem.defaultValue)
            })
        }

        dispatch(addExerciceToWorkout({exercice, metrics: exercicesMetrics, metricsValue: exercicesMetricsValue, blockId}))
    }

    /*********** REMPLACEMENT D'UN EXERCICE ******* */

    const onSwapExerciceToWorkout = (newExercice: Exercice) => (e: React.SyntheticEvent) => {
        dispatch(swapExerciceToWorkout({blockId,groupId, exerciceId, newExercice}))
        toast.success('Exercice remplacé')
        onClose()
    }

    /*********** GESTION DES TABS ******* */

    const handleTabs = (_: React.SyntheticEvent, value: number) => {
        setTabs(value)
    }

    /********** LISTE DES EXERCICES SELON LA TABS ********** */

    const exercicesList = useMemo(()=>{
        if(tabs === 0) {
            return exercices.app.filter((elem)=> !elem.draft)
        }
        else {
            return exercices.custom.filter((elem)=> !elem.draft)
        }
    },[tabs, exercices.app, exercices.custom])


    /********** Recherche ********** */

    const onSearch = (e:any) => {
        setSearch(e.currentTarget.value)
        dispatch(searchExercices({search:e.currentTarget.value}))
    }


     /********** TABS LABELS ********** */

     const tabsLabels = useMemo(() => {
        return [
            `Application (${exercices.app.length})`,
            `Mes exercices (${exercices.custom.length})`,
        ]
    }, [exercices.app, exercices.custom])


    const onCloseModal = () => {
        onClose()
    }


    /************************* FILTRE *********************** */

    useEffect(()=>{
        if(open){
            dispatch(searchExercices({search}))
        }
    },[groupFilters,typesFilters,open])

    return (
        <Dialog 
            open={open} 
            onClose={onCloseModal} 
            fullWidth
            maxWidth={"md"}
            sx={{overflow:"hidden"}}
            fullScreen={mobile}
            disableScrollLock={mobile}
            TransitionComponent={DialogTransition}
           >

            <DialogAppBar
                title={block.name}
                onClose={onClose}
            />

            {/* ****** LISTE DES EXERCICES **********/}
            <DialogContent 
                sx={classes.dialogContent}
            >

                <Box
                    sx={classes.header}
                >
                    {/**************** 
                     * SEARCH + TABS 
                     * ************ */}

                    <Box 
                        sx={classes.searchContainer}
                    >
                        {!mobile && (
                            <Tabs 
                                tabs={tabsLabels} 
                                value={tabs} 
                                onChange={handleTabs} 
                            />
                        )}
                                
                        <Box sx={{display:"flex", flexDirection: "row"}}>

                            <CustomIconButton
                                Icon={FilterList}
                                onClick={onOpenFilterList}
                                white={true}
                                darkerBorder={false}
                                marginRight={1}
                                badge={groupFilters.length + typesFilters.length}
                                showLabel={false}
                            />

                           
                            
                            <SearchTextfield
                                placeholder="Rechercher un exercice"
                                onChange={onSearch}
                                fullWidth={true}
                                borderRadius={0}
                            />
                        </Box>
                    </Box>

                    
                </Box>

                <Box
                    sx={{marginTop: 2, marginBottom: 1, paddingLeft:1, paddingRight: 1}}
                >
                    {mobile && (
                        <Tabs 
                            tabs={tabsLabels} 
                            value={tabs} 
                            onChange={handleTabs} 
                        />
                    )}
                </Box>

                <Box 
                    ref={listRef} 
                    className="scroll-container" 
                    sx={classes.scrollContainer}
                >
                    <ViewportList
                        ref={listRef}
                        items={exercicesList}
                        initialPrerender={15}
                        itemSize={95}
                    >
                        {(item)=> (
                            <ListItemExercice
                                key={item._id}
                                exercice={item}
                                addExercice={swap ? null : onAddExerciceToWorkout}
                                onSwapExercice={swap ? onSwapExerciceToWorkout : null}
                                added={findExerciceInGroup(item)}
                            />
                        )}
                    </ViewportList>
                </Box>

                <ExercicesFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />

            </DialogContent>

                    {/* ****** ACTIONS **********/}
                    
                    {!mobile && (
                        <DialogActions>
                            <Button 
                                onClick={onCloseModal}>
                                    Fermer
                            </Button>
                        </DialogActions>
                    )}
             
        </Dialog >
    )
}


function areEqual(prev: any, next: any) {
    return prev.blockId === next.blockId && 
    prev.open === next.open && 
    prev.exericeId === next.exerciceId && 
    prev.groupId === next.groupId
}

export default React.memo(DialogExercicesList, areEqual)