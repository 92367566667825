import React, { useEffect, useMemo, useState } from "react"
// ROUTER
import { useHistory, useLocation, useParams } from "react-router-dom";
// APP
import { AppDispatch, RootState } from "app/store";
// REDUX
import { useDispatch, useSelector } from "react-redux"
// API
import { getPlanning, getUserData } from "api/clients";
// Molecules
import Loader from "components/molecules/Loader";
// Organisms
import ProfileHeader from "components/organisms/ProfileHeader";
import Planning from "components/organisms/Planning";
// STYLES
// Interfaces
import { definePageTitle, selectDrawerItem } from "features/appSlice";
import { defineNewPlanningRange, resetPlanning } from "features/planningSlice";
import MainContent from "components/molecules/MainContent";
import ClientHistory from "components/organisms/ClientHistory";
import Nutrition from "components/organisms/Nutrition";
import { resetClientHistory } from "features/clientsSlice";
import { resetMealPlanBuilder } from "features/mealPlanSlice";
import UserStats from "components/organisms/UserStats";


const weeks = [{_id: "01"},{_id: "02"},{_id: "03"},{_id: "04"},{_id: "05"},{_id: "06"}]


export default function ClientProfile() {

    const history = useHistory()
    const location = useLocation<{
        tabs: number, 
        openModal?: boolean,
        planningRange: {
            start:Date, 
            end: Date
        },
        planningScroll: number}>()
    const params:any = useParams()
    const id = params.clientid
    const dispatch = useDispatch<AppDispatch>()
    const [tabs, setTabs] = useState<number>(location.state?.tabs? location.state.tabs : 0)
    const client = useSelector((state:RootState) => state.clients.profile)
    const [pending, setPending] = useState<boolean>(true)

    const planningStart:string = useSelector((state:RootState) => state.planning.builder.startDate)
    const planningEnd:string = useSelector((state:RootState) => state.planning.builder.endDate)
    const planningIsready:boolean = useSelector((state:RootState) => state.planning.builder.isReady)


    const workoutsStats:{missed:number, done:number, attendance: number} = useMemo(()=>{
        const done = client?.workoutsStats?.done[0]?.records || 0
        const missed = client?.workoutsStats?.missed[0]?.records || 0


        return(
            {
                missed: client?.workoutsStats?.missed[0]?.records || 0,
                done: client?.workoutsStats?.done[0]?.records || 0,
                attendance: done * 100 / (done + missed) || 0
            }
        )
    },[client])





    /********** DONNEES DU PROFILE *********** */
    useEffect(() => {
        setPending(true)
        dispatch(selectDrawerItem(1))
        dispatch(definePageTitle({pageTitle: 'Profile'}))
        dispatch(getUserData({clientId: id})).unwrap()
        .then((res)=>{
            if(res.client){
                if(!planningIsready){
                    // Retour d'un checkup/workout builder
                    if(location?.state?.planningRange?.start){
                        dispatch(defineNewPlanningRange({
                            start: location.state.planningRange.start.toDateString(),
                            end: location.state.planningRange.end.toDateString()
                        }))
                    }

                    dispatch(getPlanning({
                        userId: id,
                        start: location?.state?.planningRange?.start ? location.state.planningRange.start : new Date(planningStart), 
                        end: location?.state?.planningRange?.end ? location.state.planningRange.end : new Date(planningEnd), 
                    }))

                    setPending(false)

                }
            }
        }).catch(()=>{
            history.push(`/subscriptions`)
        })
        
    }, [id])


     /******** CLEANUP ****** */

     useEffect(()=>{
        return function cleanup() {
            dispatch(resetPlanning())
            dispatch(resetMealPlanBuilder())
            dispatch(resetClientHistory())
        };
    },[dispatch])


    /********** GESTION DES TABS *********** */
    const defineTabs = (index:number) =>{
        setTabs(index)
        if(location?.state?.openModal){
            history.replace({state: null})
        } else if(location?.state?.planningScroll){
            history.replace({state: {
                ...location?.state,
                planningScroll: 0
            }})
        }
    }











    /*************** RENDER ********************* */

    if (pending || !Boolean(client)) {
        return (
            <Loader />
        )
    } else {
        return (
            <React.Fragment>
                {/************ 
                 * HEADER 
                 * ************** */}

                

                <ProfileHeader
                    data={client}
                    tabs={tabs}
                    defineTabs={defineTabs}
                />

                <MainContent 
                    header="clientProfile"
                >

                    {/**************** 
                     * HISTORIQUE 
                     * ************ */}

                    {(tabs === 0) && (
                        <ClientHistory/>
                    )}
                
                    {/***************** 
                     * STATISTIQUES 
                     * ************ */}

                    {tabs === 1 && (
                        <UserStats />
                    )}

                    
                    {/********** 
                     * PLANNING 
                     * *********/}

                    {(tabs === 2) && (
                        <Planning 
                            startDate={new Date(planningStart)}
                            endDate={new Date(planningEnd)}
                            weeks={weeks}
                        />
                    )}


                    {/********** 
                     * NUTRITION 
                     * *********/}


                    {(tabs === 3) && (
                        <Nutrition />
                    )}
                  
                    
                </MainContent>
            </React.Fragment>
        )
    }
}