const classes = {


    modalContainer : {
        width: "100%",
        height: "100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection: "row"
    },

    card:{
        p: '2px 4px', 
        display: 'flex', 
        alignItems: 'center', 
        minWidth: {sm: 280},
        maxWidth: {sm: 280},
        height: 40, 
        //border: "solid 1px #f2f2f2", 
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        border: "solid 1px #f6f6f6"
    },


    fullWidth: {
        width: "100%",
        maxWidth: "100%"
    },

    closeButton:{
        backgroundColor: "primary.main",
        borderRadius: "100px !important"
    },


    buttonSearchCard:{
        borderRadius: "100px !important",
        border: "solid 1px black"
    },

    buttonSearch:{
        backgroundColor: "white",
        borderRadius: "100px !important"
    },

    
}

export default classes