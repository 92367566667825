
import { lighten } from "@mui/material"
import DrawerItem from "interfaces/DrawerItem"
import { UserMeasures } from "interfaces/User"
import { theme } from "./theme"
import { FoodPortion, Micros } from "interfaces/Food"

export const BODY_BGCOLOR = "#f9f9f996"
export const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
export const HEADER_MIN_HEIGHT = 75
export const HEADER_MIN_HEIGHT_EXPANDED = 100
export const HEADER_MIN_HEIGHT_CLIENTPROFILE = 133
export const DRAWER_WIDTH = 245
export const DRAWER_WIDTH_MIN = 65
export const WOD_PANEL_WIDTH = 420
export const WOD_PANEL_WIDTH_TABLET = 300
export const DRAWER_ITEM_LABEL_COLOR = "#aaaaaa"
export const DRAWER_ACTIVE_ITEM = "whitesmoke"
export const DRAWER_ACTIVE_ITEM_TEXT_COLOR = "black"
export const DRAWER_PADDING = 1.5
export const DRAWER_ICON_SIZE = 48
export const DIALOG_WIDTH = 500
export const AUTOSAVE_TIMER = 3000
export const INPUT_DEBOUNCE_TIMER = 200
export const HOUSEHOLD_MEASURE_GRAMS_ID = "63ba971e82ae007d7cd09c4c"

export const PROGRAM_COLORS:string[] = ["#bbe2f8","#d1c6ef","#facca9","#d3f8db"]
export const HOVER_COLOR = lighten(theme.palette.primary.main,0.93)
export const LIGHT_BUTTON_COLOR = lighten(theme.palette.primary.main,0.90)
export const BORDER_COLOR = "#f4f4f4"
export const BORDER_WORKOUT_TEXTFIELD_COLOR = "#e3e3e3"
export const DARKER_BORDER_COLOR = "#e3e3e3"
export const INPUT_BG_COLOR = "#fafafa"
export const BUTTON_SECONDARY_COLOR = lighten(theme.palette.primary.main, 0.92)
export const BUTTON_SECONDARY_COLOR_HOVER = lighten(theme.palette.primary.main, 0.90)
export const BUTTON_SECONDARY_FONT_SIZE = theme.typography.pxToRem(14.5)
export const BUTTON_SECONDARY_FONT_WEIGHT = 700
export const WORKOUT_BUILDER_TEXTFIELD_BGCOLOR = "white"
export const WORKOUT_BUILDER_HEADER_TEXTFIELD_BGCOLOR = "transparent"
export const WORKOUT_BUILDER_TEXTFIELD_RADIUS = "20px !important"

export const ACTION_DONE_COLOR = "#4eba54"
export const THUMBNAIL_SIZE = 55

export const DRAG_INDICATOR_COLOR = "#333333"

export const FIXED_SEARCH_HEIGHT = 85

export const TRAD_LEVEL = {
    beginner: "Débutant",
    medium: "Intermédiaire",
    advanced: "Avancé"
}


export const COLOR_LEVEL = {
    beginner : "#4D9712",
    medium: "#c29d04",
    advanced: "#e3642d"
}

export const WORKOUT_COVERS = [
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/26683f90-785f-4ec1-27b5-5e6a75642100/workoutCover", // workout_cover_1.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/61df9fea-1c71-4037-cebb-ce2463a72600/workoutCover", // workout_cover_2.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/5ca28751-501e-49ee-26c2-a70c7e0c8800/workoutCover", // workout_cover_3.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/05b46e83-059f-4d30-70c1-fcdfd68f9100/workoutCover", // workout_cover_4.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/c9448d70-5863-4c8d-298b-6711fbdf8900/workoutCover", // workout_cover_5.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/dfc3cc96-cc24-4bff-ae71-67f5136da900/workoutCover", // workout_cover_6.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/a50b55d6-d9d5-48ca-5f8a-67d72a64b300/workoutCover", // workout_cover_7.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/e0924c41-7a72-4522-ad59-ec8a21aa9e00/workoutCover", // workout_cover_8.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/5620b69e-a26e-43b0-08c7-7241a5d7cb00/workoutCover", // workout_cover_9.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/b8ed31e1-ad1c-40d8-2c37-737be3eab000/workoutCover", // workout_cover_10.jpg
    "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/0aa5e00e-3339-4867-8198-96e355513800/workoutCover", // workout_cover_11.jpg
]

export const MOBILE = theme.breakpoints.down('sm')
export const TABLET = theme.breakpoints.up('sm') && theme.breakpoints.down('md')
export const SMALL_DESKTOP = theme.breakpoints.up('md') && theme.breakpoints.down('lg')
export const DESKTOP = theme.breakpoints.up('lg')
export const LARGE_DESKTOP = theme.breakpoints.up('xl')



export const TRAD: any = {
    weightlifting: {
        fr: "Renforcement"
    },
    cardio: {
        fr: "Cardio"
    },
    stretching: {
        fr: "Posture"
    },
    female: {
        fr: "Femme"
    },
    male: {
        fr: "Homme"
    },
    warmup: {
        fr: "échauffement"
    },
    content:{
        fr: "corps de séance"
    },
    cooldown:{
        fr: "retour au calme"
    },
    cholesterol:{
        fr: "Cholestérol"
    },
    water:{
        fr: "Eau"
    },
    sugar: {
        fr: "Sucre"
    },
    fructose:{
        fr: "Fructose"
    },
    glucose: {
        fr: "Glucose"
    },
    lactose:{
        fr: "Lactose"
    },
    maltose:{
        fr: "Maltose"
    },
    sucrose:{
        fr: "Sucrose"
    },
    starch: {
        fr: "Amidon"
    },
    ashes: {
        fr: "Cendres"
    },
    alcool:{
        fr: "Alcool"
    },
    fibers:{
        fr: "Fibres"
    },
    sodium:{
        fr: "Sodium"
    },
    polyols: {
        fr: "Polyols"
    },
    organicAcids:{
        fr: "Acides organiques"
    },
    SFA:{
        fr:"Acides gras saturés"
    },
    MUFA:{
        fr: "Acides gras mono-insaturés"
    },
    PUFAs:{
        fr: "Acides gras polyinsaturés"
    },
    calcium:{
        fr: "Calcium"
    },
    chloride:{
        fr: "Chlorure"
    },
    cooper:{
        fr: "Cuivre"
    },
    iron:{
        fr: "Fer"
    },
    iodine:{
        fr: "Iode"
    },
    magnesium:{
        fr: "Magnesium"
    },
    phosphorus: {
        fr: "Phosphore"
    },
    potassium:{
        fr: "Potassium"
    },
    selenium:{
        fr: "Selénium"
    },
    zinc:{
        fr: "Zinc"
    },
    retinol:{
        fr: "Rétinol"
    },
    vitaminD:{
        fr: "Vitamine D"
    },
    vitaminE:{
        fr: "Vitamine E"
    },
    vitaminC:{
        fr: "Vitamine C"
    },
    thiamine: {
        fr: "Thiamine"
    },
    riboflavin: {
        fr:"Riboflavine"
    },
    niacine: {
        fr:"Niacine"
    },
    pantothenicAcid:{
        fr: "Acide panthothénique"
    },
    vitaminB6: {
        fr: "Vitamine B6"
    },
    vitaminB12:{
        fr: "Vitamine B12"
    },
    folate: {
        fr:"Folate"
    }
}


export const INGREDIENT_SHORTER_UNITS = {
    grammes: "g",
    milligrams: "mg",
    centiliters: "cl",
    liters: "L"
}


export const MACROS_COLORS = {
    carbs: "rgb(184, 0, 216)",
    proteins: "rgb(2, 178, 175)",
    lipids: "rgb(46, 150, 255)",
    fibers: "#face55",
    calories: "#1edae1"
}

export const BODY_MEASURES:{label: keyof UserMeasures,fr:string, units:("kg" | "cm" | "%")}[] =[
    {
        label: "weight",
        fr: "Poids",
        units: "kg",
    },
    {
        label: "bodyfat",
        fr: "Masse grasse",
        units: "%",

    },
    {
        label: "arms",
        fr: "Bras",
        units: "cm"
    },
    {
        label: "forearm",
        fr: "Avant-bras",
        units: "cm"
    },
    {
        label: "neck",
        fr: "Cou",
        units: "cm"
    },
    {
        label: "chest",
        fr: "Poitrine",
        units: "cm"
    },
    {
        label: "shoulders",
        fr: "Epaules",
        units: "cm"
    },
    {
        label: "legs",
        fr: "Cuisses",
        units: "cm"
    },
    {
        label: "hips",
        fr: "Hanches",
        units: "cm"
    },
    {
        label: "waist",
        fr: "Taille",
        units: "cm"
    },
    {
        label: "glutes",
        fr: "Fessiers",
        units: "cm"
    },
    {
        label: "calves",
        fr: "Mollets",
        units: "cm"
    },
    
]



/************** DRAWER *************** */

export const MENU:DrawerItem[] = [
    {
        label: "Accueil",
        path: "/dashboard",
        icon: "home",
        needSubscription: false,
        needNutrition: false,
        showOnMobile: true
    },
    {
        label: "Clients",
        path: "/clients",
        icon: "users",
        needSubscription: false,
        needNutrition: false,
        showOnMobile: true

    },
    {
        label: "Exercices",
        path: "/exercices",
        icon: "dumbbell",
        needSubscription: true,
        needNutrition: false,
        showOnMobile: true,



    },
    {
        label: "Aliments",
        path: "/food",
        icon: "food",
        needSubscription: true,
        needNutrition: false,
        showOnMobile: true

    },
    {
        label: "Recettes",
        path: "/recipes",
        icon: "recipe",
        needSubscription: true,
        needNutrition: false,
        showOnMobile: true

    },
    {
        label: "Modèles",
        path: "/templates",
        icon: "template",
        needSubscription: true,
        needNutrition: false,
        showOnMobile: true

    },
    
    {
        label: "Mon planning",
        path: "/planning",
        icon: "program",
        needSubscription: true,
        needNutrition: false,
        showOnMobile: true

    },
    {
        label: "Mon compte",
        path: "/account",
        icon: "account",
        needSubscription: false,
        needNutrition: false,
        showOnMobile: true

    },

    /*{
        label: "Aliments",
        path: "/food",
        icon: "food",
        needSubscription: true,
        needNutrition: true
    },

    {
        label: "Recettes",
        path: "/recipes",
        icon: "meals",
        needSubscription: true,
        needNutrition: true

    },*/
]

/************** WORKOUT BUILDER *************** */

export const EXERCICE_TYPES = [
    {
        name:"weightlifting",
        fr: "Avec charge"
    },
    {
        name:"cardio",
        fr: "Cardio"
    },
    {
        name:"stretching",
        fr: "Stretching"
    },
    
]


export const EVENTS_TYPES = [
    'workout',
    "meal",
    "measure",
    "questionnaire"
]

export const EVENTS_TYPES_TARD:Record<string, {fr:string,en:string}> = {
    workout: {
        fr: "Séances",
        en: "Workouts"
    },
    meal: {
        fr: "Repas",
        en: "Meals"
    },
    measure: {
        fr: "Mesures",
        en: "Measures"
    },
    questionnaire: {
        fr: "Questionnaires",
        en: "Checkups"
    },
}

export const CLIENTS_FILTERS = [
    'active',
    "inactive",
    "withProgram",
    "withoutProgram",
    "withNutrition",
    "withoutNutrition"
]


export const CLIENTS_FILTERS_TRADS:Record<string, {fr:string,en:string}> = {
    active: {
        fr: "Actif",
        en: "Ative"
    },
    inactive: {
        fr: "Inactif",
        en: "Inactive"
    },
    withoutProgram: {
        fr: "Sans programme",
        en: "Without program"
    },
    withProgram: {
        fr: "Avec programme",
        en: "With program"
    },

    withNutrition: {
        fr: "Avec plan alimentaire",
        en: "With meal plan"
    },

    withoutNutrition: {
        fr: "Sans plan alimentaire",
        en: "Without meal plan"
    },
}

export const EXERCICE_GROUPS=[
    "shoulders",
    "biceps",
    "forarms",
    "triceps",
    "pecs",
    "back",
    "hamstrings",
    "quadriceps",
    "glutes",
    "abs",
    "calves",
    "lumbar",
    "adductors",
    "abductors",
]

export const EXERCICE_GROUPS_TRAD:Record<string, {fr:string,en:string}> = {
    shoulders: {
        fr: "Epaules",
        en: "Shoulders"
    },
    biceps: {
        fr: "Biceps",
        en: "Biceps"
    },
    forarms: {
        fr: "Avant-bras",
        en: "Forarms"
    },
    triceps: {
        fr: "Triceps",
        en: "Triceps"
    },
    pecs: {
        fr: "Pectoraux",
        en: "Pecs"
    },
    back: {
        fr: "Dos",
        en: "Back"
    },
    hamstrings: {
        fr: "Ischios",
        en: "Hamstrings"
    },
    quadriceps: {
        fr: "Quadriceps",
        en: "quadriceps"
    },
    glutes: {
        fr: "Fessiers",
        en: "Glutes"
    },
    abs: {
        fr: "Abdominaux",
        en: "Abs"
    },
    calves: {
        fr: "Mollets",
        en: "Calves"
    },
    lumbar: {
        fr: "Lombaires",
        en: "lumbar"
    },
    adductors: {
        fr: "Adducteurs",
        en: "Adductors"
    },
    abductors: {
        fr: "Abducteurs",
        en: "Abductors"
    },


}



export const EXERCICE_EQUIPMENTS = [
    { name: "Haltères" },
    { name: "Barre" },
    { name: "Kettlebells" },
    { name: "Poulie", },
    { name: "Machine", },
    { name: "Élastiques" },
    { name: "Medecine Ball" },
    { name: "Sac de sable" },
    { name: "Tapis" },
    { name: "Banc" },
    { name: "Mur" },
    { name: "Vélo" },
    { name: "Rameur" },
    { name: "TRX" },
    { name: "Corde ondulatoire" },
    { name: "Corde d'escalade" },
    { name: "Barre de traction" },
    { name: "Swiss Ball" },
    { name: "Bosu" },
    { name: "GHD" },
    { name: "Box" },
    { name: "Anneaux" },
    { name: "Barres parallèles" },
    { name: "Sled" },
    { name: "Autre" },

]





/************** SUBSCRIPTION *************** */

/*
export const PROD_SUBSCRIPTIONS:Subscription[] = [
    {
        name: "Starter",
        product: "prod_L8lmCEQWAwRmfY",
        clients: 5,
        price: {
            _id: "price_1KSUEvCrAsb0XaqYYKtWKs2f",
            amount: 23,
        }
    },
    {
        name: "Basic",
        product: "prod_L8lntu72DUNcKg",
        clients: 25,
        price: {
            _id: "price_1KSUFlCrAsb0XaqYUlWo6Aa5",
            amount: 49
        }

    },
    {
        name: "Pro",
        product: "prod_L8ln7E866AD9YM",
        clients: 120,
        price: {
            _id: "price_1KSUFzCrAsb0XaqYdHMb2TYU",
            amount: 99
        }
    }
]

export const DEV_SUBSCRIPTIONS:Subscription[] = [
    {
        name: "Starter test",
        product: "prod_KMcSwFjRxcq1mK",
        clients: 5,
        price: {
            _id: "price_1KT0KACrAsb0XaqY5QfkR1Gx",
            amount: 23
        },

    },
    {
        name: "Basic test",
        product: "prod_KblfzsUhElpJzL",
        clients: 25,
        price: {
            _id: "price_1JwY89CrAsb0XaqYJ7E9eH55",
            amount: 49
        }
    },
    {
        name: "Pro test",
        product: "prod_KblgUHH3YB9wg6",
        clients: 100,
        price: {
            _id: "price_1KT0IeCrAsb0XaqYcAu4FRBQ",
            amount: 99
        },

    }
]*/

export const SUBSCRIPTIONS_FEATURES = [
    {
        label: "Gestion du planning d'entraînement",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },
    {
        label: "Suivi avancée de vos clients",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },
    {
        label: "Création de modèles",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },
    {
        label: "Création d'exercices",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },
    
    {
        label: "Application mobile client",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },
    {
        label: "Support 7/7",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },
    {
        label: "Sans engagement",
        can: {
            "Starter": true,
            "Basic": true,
            "Pro": true,
            "Starter + Nutrition" : true,
            "Basic + Nutrition" : true,
            "Pro + Nutrition" : true,
            "Starter Test" : true,
            "Basic Test" : true,
            "Pro Test": true,
            "Starter Test + Nutrition" : true,
            "Basic Test + Nutrition" : true,
            "Pro Test + Nutrition" : true,
        }
    },

]



export const NUTRITION_MODULE_FEATURES = [
    {
        label: "Création de plan alimentaire",
        
    },
    {
        label: "Création de recettes",
        
    },
    {
        label: "Suivi nutritionnel journalier",
        
    },
    {
        label: "Accès à une table de plus de 3000 aliments",
       
    },
]


/*************** SEARCH COLLECTIONS *********** */

export const SEARCH_COLLECTIONS = {
    exercices: {
        keys: [
            "name.fr",
            "name.en",
            "primaryGroups.name",
        ]
    },
    workouts: {
        keys: [
            "name",
            "type"
        ]
    },

    trainingPrograms: {
        keys: [
            "name",
        ]
    },


    clients: {
        keys: [
            "firstname",
            "lastname",
            "email",
            "to.firstname",
            "to.lastname",
            "to.email",
        ]
    },
}

export interface RECIPE_TAG{
    id:string,
    label:{
        fr:string,
        en:string
    },
    color: string
}

export const RECIPE_TAGS:RECIPE_TAG[] = [
    {
        id: "breakfast",
        label: {
            fr: "Petit déjeuner",
            en: "Breakfast"
        },
        color: "#e7ea47"
    },
    {
        id: "incase",
        label: {
            fr: "Encas",
            en:"Incase"
        },
        color: "#dac7f5"
    },
    {
        id: "meal",
        label: {
            fr: "Plat",
            en: "Meal"
        },
        color: "#cfe3d4"
    },
    {
        id: "dessert",
        label: {
            fr: "Dessert",
            en: "Dessert"
        },
        color: "#92d0d8"
    },
    {
        id: "drink",
        label: {
            fr: "Boisson",
            en: "drink"
        },
        color: "#f3d0b5"
    },
    {
        id: "vegetarian",
        label: {
            fr: "Végétarien",
            en: "Vegetarian"
        },
        color: "#c7f5e1"
    },
    {
        id: "vegan",
        label: {
            fr: "Vegan",
            en: "Vegan"
        },
        color: "#6ae8b3"
    },
    {
        id: "gluten_free",
        label: {
            fr: "Sans gluten",
            en: "Gluten free"
        },
        color: "#d8d492"
    },
    {
        id: "fish",
        label: {
            fr: "Poisson",
            en: "Fish"
        },
        color: "#92d0d8"
    },
    {
        id: "meat",
        label: {
            fr: "Viande",
            en: "Meat"
        },
        color: "#f5ab96"
    },
    {
        id: "cheese",
        label: {
            fr: "Fromage",
            en: "Cheese"
        },
        color: "#f3d77a"
    },
    {
        id: "milk",
        label: {
            fr: "Lait",
            en: "Milk"
        },
        color: "#cbf0ff"
    },
    {
        id: "low_calorie",
        label: {
            fr: "Léger",
            en: "Low calories"
        },
        color: "#ebfbda"
    },
    {
        id: "proteins",
        label: {
            fr: "Protéiné",
            en: "proteins"
        },
        color: "#ffaab7"
    }
]


export const DEFAULT_PORTIONS:FoodPortion[] = [
    {
        label: {
            fr: "grammes",
            en: "grams"
        },
        equivalent: {
            portion: 100,
            value: 100
        } 
    },
    {
        label: {
            fr: "millilitres",
            en: "milliliters"
        },
        equivalent: {
            portion: 100,
            value: 100
        } 
    }
]


export const GOALS = [
    {
        en: "weightloss",
        fr: "Perte de poids"
    },
    {
        en: "fitness",
        fr: "Remise en forme"
    },
    {
        en: "bulking",
        fr: "Prise de muscle"
    },
    {
        en: "reathletization",
        fr: "Réathlétisation"
    },
    {
        en: "competition",
        fr: "Préparation sportive"
    },
]

export const DEFAULT_MICROS:Micros = {
    cholesterol: 0,
    water: 0,
    sugar: 0,
    fructose: 0,
    glucose: 0,
    lactose: 0,
    maltose: 0,
    sucrose: 0,
    starch: 0,
    ashes: 0,
    alcool: 0,
    fibers: 0,
    sodium: 0,
    polyols: 0,
    organicAcids: 0,
    SFA: 0,
    MUFA: 0,
    PUFAs: 0,
    calcium: 0,
    chloride: 0,
    cooper: 0,
    iron: 0,
    iodine: 0,
    magnesium: 0,
    phosphorus: 0,
    potassium: 0,
    selenium: 0,
    zinc: 0,
    retinol: 0,
    vitaminD: 0,
    vitaminE: 0,
    vitaminC: 0,
    thiamine: 0,
    riboflavin: 0,
    niacine: 0,
    pantothenicAcid: 0,
    vitaminB6: 0,
    vitaminB12: 0,
    folate: 0
  }


export const VNR_MICROS_FEMALE = {
        pantothenicAcid: 5.0, // b5
        // Biotine ?
        calcium: 950,
        chloride: 2300, // Chlorure
        // Choline ? pas ciqual
        cooper: 1.5,
        iron: 13.5,
        // Fluor ? pas ciqual
        folate: 330,
        iodine: 150,
        magnesium: 300,
        niacine: 18.8, // b3
        phosphorus: 550,
        potassium: 3500,
        riboflavin: 1.6, // b2
        selenium: 70,
        sodium: 1500,
        thiamine: 1.2, //b1
        retinol: 650, // Vitamine A
        vitaminB12: 4,
        vitaminB6: 1.6,
        vitaminC: 110,
        vitaminD: 15,
        vitaminE: 9,
        // Vitamin k1 ?
        zinc: 9.3,
        water: 0,
        alcool: 0,
        cholesterol: 0,
        sugar: 0,
        SFA:0,
        MUFA:0,
        PUFAs:0,
        fibers: 0
}

export const VNR_MICROS_MALE = {
    pantothenicAcid: 6.0, // b5
    // Biotine ?
    calcium: 950,
    chloride: 2300, // Chlorure
    // Choline ? pas ciqual
    cooper: 1.9,
    iron: 11,
    // Fluor ? pas ciqual
    folate: 330,
    iodine: 150,
    magnesium: 380,
    niacine: 18.8, // b3
    phosphorus: 550,
    potassium: 3500,
    riboflavin: 1.6, // b2
    selenium: 70,
    sodium: 1500,
    thiamine: 1.3, //b1
    retinol: 750, // Vitamine A
    vitaminB12: 4.0,
    vitaminB6: 1.7,
    vitaminC: 110,
    vitaminD: 15,
    vitaminE: 10,
    // Vitamin k1 ?
    zinc: 11.7,    
    water: 0,
    cholesterol: 0 ,  
    alcool: 0 ,
    sugar: 0,
    SFA:0,
    MUFA:0,
    PUFAs:0,
    fibers: 0

}





export const walkingLevels = [
    {
        points: "0",
        description: "Moins de 30 minutes"
    },
    {
        points: "1",
        description: "Entre 30 min et 1h"
    },
    {
        points: "2",
        description: "Entre 1h et 2h"
    },
    {
        points: "3",
        description: "Plus de 2h"
    }
]


export const workingLevels = [
    {
        points: "0",
        description: "Je suis sédentaire"
    },
    {
        points: "1",
        description: "Je bouge de temps en temps"
    },
    {
        points: "2",
        description: "Je bouge pas mal"
    },
    {
        points: "4",
        description: "J'ai un métier physique"
    },
    
]


export const fitnessLevels = [
    {
        points: "1",
        description: "1 à 2 fois par semaine"
    },
    {
        points: "3",
        description: "3 fois par semaine"
    },
    {
        points: "4",
        description: "entre 4 et 5 fois par semaine"
    },
    {
        points: "5",
        description: "Plus de 5 fois par semaine"
    },
    
    
]
