import { Collections, Delete, Upload } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { AppDispatch } from "app/store";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import calcBoxHeight from "function/calcBoxHeight";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DialogYoutubeThumbnail from "../DialogYoutubeThumbnail";
import Image from "interfaces/Image";
import classes from "./styles";
import YoutubeVideoPlayer from 'react-player'
import { BORDER_COLOR } from "utils/constants";


interface Props{
    formIsReady: boolean,
    imageSelected?: {url: string, cloudFlareId?: string},
    uploadImage?: (file:Blob)=> void ,
    onSelectThumbnail: (image:{url:string,cloudFlareId?: string})=> void,
    openDeleteConfirmation:()=> void,
    videoUrl?:string

}

export default function ImageVideoSelector({
    formIsReady, 
    imageSelected, 
    onSelectThumbnail, 
    uploadImage, 
    openDeleteConfirmation,
    videoUrl
}:Props){

    const [tabSelected, setTabSelected] = useState<number>(0)
    const [mediaHeight, setMediaHeight] = useState<number>(0)
    const [anchorElImage, setAnchorElImage] = useState<any>(null)
    const [openDialogYoutubeThumbnail, setOpenDialogYoutubeThumbnail] = useState<boolean>(false)
    const imageRef = useRef<any>(null);
    const uploadInput = useRef<any>(null)
    const [videoIsCorrect, setVideoIsCorrect] = useState<boolean>(false)


    /*********************************************************** 
     * SELECTION D'UN FICHIER LOCAL - Téléchargement de l'image 
     * **********************************************************/

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFile:Blob = e.target.files[0]
        if (newFile.size > 10 * 1024 * 1024) {
            toast.error('Le fichier est trop volumineux (maximum 10mo)')
        }else if (newFile.type !== "image/jpeg" && newFile.type !== "image/png") {
            toast.error('Format non pris en charge (PNG/JPEG exclusivement)')
        }else{
            uploadImage(newFile)
        }
    }


    /******************************************
     * CALCUL DE LA HAUTEUR DU CONTAINER VIDEO
     ******************************************/


    useEffect(() => {
        if (imageRef.current && formIsReady) {
            const boundingRect = imageRef.current.getBoundingClientRect()
            const videoWidth = boundingRect.width;
            setMediaHeight(calcBoxHeight(1080, 1920, videoWidth))
        }
    }, [imageRef.current, formIsReady]);


    const checkVideoLink = (url:string) => {
        var regExp = new RegExp(/^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/);
        var match = url.match(regExp);
        if(match) {
            setVideoIsCorrect(true)
        }
        else if(videoIsCorrect) {
            setVideoIsCorrect(false)
            if(!imageSelected?.cloudFlareId){
                onSelectThumbnail({url:null, cloudFlareId: null})
            }
        }
    }
    
    useEffect(()=>{
        checkVideoLink(videoUrl)
        if(videoUrl !== "")
        setTabSelected(1)
    },[videoUrl])


    /*******************************
     * LISTE DES MINIATURES YOUTUBE
     *******************************/

    const thumbnailsList:{url:string}[] = useMemo(()=>{
        if(videoIsCorrect){
            // Récupération de l'ID Vidéo
            var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/;
            var match = videoUrl.match(regExp);

            const videoId = match && match[1].length == 11 ? match[1] : false;
            

            var thumbnailsList:Image[] = [{url: "https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"}]

            // ON Parcourt les miniatures (il y'a 4 miniatures sur youtube par vidéo)
            for (var i = 1; i < 4; i++) {
                thumbnailsList.push({
                    url: "https://img.youtube.com/vi/" + videoId + "/maxres" + i + ".jpg"
                })
            }
            if(!imageSelected?.url && formIsReady){
                onSelectThumbnail({url:"https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"})
            }

            return thumbnailsList
        } else {
            return []
        }
    },[videoIsCorrect])


    return(
        <>
         {/******
         * TABS
         *******/}
         <Box
             sx={{border: `solid 1px ${BORDER_COLOR}`}}>
                
            <Box
                sx={classes.imageTabs}
                
            >
                <Box
                    sx={[classes.imageTab, tabSelected === 0 && classes.imageTabSelected]}
                    onClick={()=> setTabSelected(0)}
                >
                    Photo
                </Box>
                <Box
                    sx={[classes.imageTab, tabSelected === 1 && classes.imageTabSelected]}
                    onClick={()=> setTabSelected(1)}
                >
                    Vidéo
                </Box>
            </Box>
            <Box
                ref={imageRef}
                sx={[
                    classes.imageSelector, 
                    {
                        height: mediaHeight
                    }
                ]}
            >

                {/********************************
                 * AUCUNE IMAGE - BUTTON UPLOAD
                 *********************************/}
                
                {(tabSelected === 0 && !imageSelected?.url) && (
                    <Box
                        sx={{display:"flex", flexDirection: "column"}}
                    >
                        <SecondaryButton
                            startIcon={<Upload/>}
                            sx={{backgroundColor: "white", marginBottom: 3}}
                            onClick={()=> uploadInput.current?.click()}
                        >
                            <input 
                                type="file" 
                                ref={uploadInput}
                                id="upload-image" 
                                style={{display:"none", height: "100%", width: "100%"}} 
                                accept="image/png, image/jpeg" 
                                onChange={onFileChange}
                            />
                                Télécharger une image (jpg,png)
                            </SecondaryButton>
                        
                        <SecondaryButton
                            startIcon={<Collections/>}
                            disabled={!videoIsCorrect}
                            sx={{backgroundColor: "white"}}
                            onClick={()=> setOpenDialogYoutubeThumbnail(true)}
                        >
                            Sélectionner une image Youtube
                        </SecondaryButton>
                    </Box>
                )}


                {/********************************
                 * IMAGE UPLOADEE OU SELECTIONNEE
                 *********************************/}

                {(tabSelected === 0 && imageSelected?.url) && (
                    <>
                    <Box
                        component={"img"} 
                        src={imageSelected?.url} 
                        
                        sx={[
                            classes.thumbnail,
                        ]}
                    />
                    <Box
                        sx={classes.imageButton}>
                            <IconButton
                                onClick={imageSelected?.cloudFlareId? ()=>openDeleteConfirmation() : ()=> onSelectThumbnail(null)}
                            >
                                <Delete />
                            </IconButton>
                    </Box>
                    </>
                )}


                {/********************************
                 * LIEN YOUTUBE CORRECT
                 *********************************/}

                {(tabSelected === 1 && videoIsCorrect) && (
                    <Box
                        sx={[
                            classes.thumbnail,
                            classes.thumbnailSelected
                        ]}
                    >
                        <YoutubeVideoPlayer 
                            url={videoUrl} 
                            height={mediaHeight} 
                            width={'100%'} 
                            playing={false}
                            config={{
                                youtube: {
                                    playerVars:{
                                        modestbranding: 0,
                                        rel: 0,
                                        showinfo: 0,
                                        controls: 2
                                    }
                                }
                            }}
                            
                        />

                    </Box>
                )}

                {/********************************
                 * LIEN YOUTUBE INCORRECT
                 *********************************/}

                {(tabSelected === 1 && !videoIsCorrect) && (
                    <Typography>
                        Aucune vidéo
                    </Typography>
                )}
            </Box>
            </Box>

            {/*************
             * MENU IMAGE
             **************/}

            <Menu disableScrollLock={true} 
                id="week-menu"
                anchorEl={anchorElImage}
                open={Boolean(anchorElImage)}
                onClose={()=> setAnchorElImage(null)}
            >
                <MenuItem 
                    onClick={openDeleteConfirmation}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        Supprimer l'image
                    </ListItemText>
                </MenuItem>
            </Menu>

            {/*********************
             * YOUTUBE THUMBNAILS
             *********************/}

            <DialogYoutubeThumbnail
                open={openDialogYoutubeThumbnail}
                onClose={()=>setOpenDialogYoutubeThumbnail(false)}
                thumbnails={thumbnailsList}
                imageSelected={imageSelected}
                onSelectThumbnail={onSelectThumbnail}
            />

        </>
    )
}