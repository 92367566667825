import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { deleteExerciceImage, manualSaveExercice } from "api/exercices";
import { AppDispatch, RootState } from "app/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


interface Props{
    open: boolean,
    onClose: ()=> void,
    onSelectThumbnail: (thumbnail:{url:string})=> void

}

export default function DialogDeleteExerciceImage({open, onClose,onSelectThumbnail}:Props){

    const deletePending:boolean = useSelector((state:RootState) => state.exercices.requests.deleteImage === "pending")
    const exerciceId = useSelector((state:RootState) => state.exercices.builder._id)
    const dispatch = useDispatch<AppDispatch>()
    const isDraft: boolean = useSelector((state:RootState) => state.exercices.builder?.draft, shallowEqual)
    const exercice = useSelector((state:RootState) => state.exercices.builder)


    const onDeleteImage = () => {
        dispatch(deleteExerciceImage({exerciceId})).unwrap().then((res)=>{
            if(res.image){
                onSelectThumbnail(null)
                onClose()
                if(!isDraft){
                    dispatch(manualSaveExercice({exercice:{...exercice, image: null}}))
                }
            }
        })
    }

    return(
        <Dialog 
            open={open}
            disableScrollLock
        >
            <DialogTitle>
                Supprimer une image téléchargée
            </DialogTitle>
            <DialogContent>
                Voulez-vous supprimer l'image téléchargée de cet exercice ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={deletePending}>Annuler</Button>
                <LoadingButton loading={deletePending} disabled={deletePending} onClick={onDeleteImage}>
                    {deletePending ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}