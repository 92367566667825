import {Box, Grid, useMediaQuery } from "@mui/material";
import { DESKTOP, MOBILE, SMALL_DESKTOP, TABLET } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import MainContent from "components/molecules/MainContent";
import { useEffect, useMemo, useRef, useState } from "react";
import DialogCreateRecipe from "components/organisms/DialogCreateNewRecipe";
import RecipeCard from "components/organisms/RecipeCard";
import Tabs from "components/molecules/Tabs";
import { selectDrawerItem } from "features/appSlice";
import EmptySearch from "components/molecules/EmptySearch";
import { resetSearchRecipes, searchRecipe } from "features/recipesSlice";
import RecipesFilters from "components/organisms/RecipesFilters";
import Recipe from "interfaces/Recipe";
import { ViewportList } from "react-viewport-list";
import FixedSearchHeader from "components/molecules/FixedSearchHeader";
import Navbar from "components/organisms/NavBar";

export default function Recipes(){

    const dispatch = useDispatch<AppDispatch>()
    const IS_MOBILE = useMediaQuery(MOBILE)
    const IS_SMALL_DESKTOP = useMediaQuery(SMALL_DESKTOP)
    const recipes = useSelector((state:RootState) => state.recipes)
    const [openDialogRecipe, setOpenDialogRecipe] = useState<boolean>(false)
    const [tabs, setTabs] = useState(0);
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const [search, setSearch] = useState<string>("");
    const tagsFilter = useSelector(
        (state: RootState) => state.recipes.tagsFilter
    );
    const listRef = useRef(null);
    const mobile = useMediaQuery(MOBILE);
    const IS_TABLET = useMediaQuery(TABLET);
    const IS_DESKTOP = useMediaQuery(DESKTOP);


    /*******************  
     * RESET DE L'ECRAN 
     ***************** */

    useEffect(() => {
        dispatch(resetSearchRecipes());
        dispatch(selectDrawerItem(4));
    }, [dispatch]);


     /******************* 
      * GESTION DES TABS 
      * ******************/

     const tabsLabels = useMemo(() => {
        
        return [
            `Mes recettes (${recipes.filtered.custom.length})`, 
            `Brouillons (${recipes.filtered.draft.length})`
        ];
    }, [recipes]);

     const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };

    /************************* 
     *      RECHERCHE 
     * *********************** */

    const onSearch = (e:any) => {
        setSearch(e.currentTarget.value);
        
        dispatch(searchRecipe({search:e.currentTarget.value}))
    }

    useEffect(() => {
        dispatch(searchRecipe({ search, tagsFilter }));
    }, [tagsFilter]);


   
    /************************* 
     *  LISTE DES RECETTES 
     * *********************** */

    const RECIPES = useMemo(()=>{
       if(tabs === 0){
            return recipes.filtered.custom
       }else{
            return recipes.filtered.draft
       }
        
    },[tabs,recipes])


    const DISPLAY:{content:Recipe[]}[] = useMemo(()=>{
        var display:{content:Recipe[]}[] = []
        var row = 0
        var limit = 3

        if(IS_MOBILE) limit = 1
        if(IS_TABLET) limit = 2
        if(IS_SMALL_DESKTOP && IS_TABLET) limit = 2
        if(IS_SMALL_DESKTOP && !IS_TABLET) limit = 3
        if(IS_DESKTOP) limit = 4

        RECIPES.forEach((recipe)=> {
            if(row === limit){
                row = 0
            }
            if(row === 0){
                display.push({content:[]})
            }
            display[display.length - 1].content.push(recipe)
            row++
        })


        return display
    },[RECIPES, IS_DESKTOP, IS_MOBILE, IS_TABLET])


   


    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    };



    return(
        <>
                {/********************
                 *      HEADER 
                 ********************/}
                <Navbar
                    pageTitle="Mes recettes"
                />

                <FixedSearchHeader
                    onSubmit={()=>setOpenDialogRecipe(true)}
                    submitLabel="Ajouter une recette"
                    badge={tagsFilter.length}
                    onOpenFilterList={onOpenFilterList}
                    searchLabel="Rechercher une recette"
                    onSearch={onSearch}
                />  

                {/********************
                 *      CONTENT 
                 ********************/}

                <MainContent
                    withSearch={true}
                >

                {/**********************
                 * ADD BUTTON (TABLET)
                 **********************/}

               

     
                    <Grid 
                        container 
                        justifyContent="center" 
                        sx={{paddingBottom: 10,}}
                    >

                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={10} 
                                xl={8}
                            >

                                <Tabs 
                                    tabs={tabsLabels} 
                                    value={tabs} 
                                    onChange={onChangeTabs} 
                                    sx={{marginBottom: 2}}
                                />

                                    <ViewportList
                                        ref={listRef}
                                        items={DISPLAY}
                                        initialPrerender={12}
                                        //itemSize={95}
                                    >
                                        {(item, index) => (
                                            <Grid 
                                                container 
                                                spacing={3}
                                                key={index}
                                                sx={{marginBottom: 3}}
                                            >
                                                {item.content.map((recipe) => {
                                                    return(
                                                        <RecipeCard
                                                            key={recipe._id}
                                                            recipe={recipe}
                                                        />
                                                    )
                                                })}
                                            </Grid>
                                        )}
                                    </ViewportList>
                            {/************** 
                             * EMPTY STATE 
                             * ****** */}

                            {RECIPES.length === 0 && (
                                <Box>
                                    <EmptySearch
                                        title={"Aucune recette"}
                                        description={`Tu n'as enregistré aucune recette pour le moment`}
                                        
                                    />
                                </Box>
                            )}

                                
                        </Grid>
                        
                    </Grid>
            
            



                    

                    <DialogCreateRecipe
                        open={openDialogRecipe}
                        onClose={()=>setOpenDialogRecipe(false)}
                    />

                <RecipesFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />


                </MainContent>
        </>
    )
}