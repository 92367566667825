import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { deleteQuestionnaire } from "api/clients"
import { AppDispatch, RootState } from "app/store"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import { ScheduledQuestionnaire } from "interfaces/Checkup"
import { useDispatch, useSelector } from "react-redux"



interface Props{
    questionnaire: ScheduledQuestionnaire,
    open: boolean,
    onClose: ()=> void
}

export default function DialogDeletePlanningQuestionnaire({questionnaire, open, onClose}:Props){

    const checkups = useSelector((state:RootState) => state.checkups.templates.list)
    const pending = useSelector((state:RootState) => state.planning.requests.deleteQuestionnaire === "pending")
    const dispatch = useDispatch<AppDispatch>()
    const clientId = useSelector((state:RootState) => state.clients.profile._id)

    const onSubmit = () => {
        dispatch(deleteQuestionnaire({
            clientId, 
            questionnaireId:questionnaire._id
        })).unwrap().then(()=>{
            onClose()
        })
       
    }   
    


    return(
        <Dialog 
            open={open}
            fullWidth
            maxWidth={"xs"}
            disableScrollLock={true}
        >
            <DialogTitle>
                Supprimer le questionnaire
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer le questionnaire {questionnaire.recurrence === "none" ? "ponctuel" : "récurrent"} {checkups.find((elem)=> questionnaire.templateId)?.name} ? {questionnaire.recurrence !== "none" && "Cette action supprimera toutes les réuccurences de ce questionnaire."}
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={pending}
                >
                    Annuler
                </Button>
                <LoadingButton 
                    onClick={onSubmit} 
                    variant="contained" 
                    disabled={pending} 
                    loading={pending}
                >
                    {(pending) ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}