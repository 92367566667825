import { Box, Button, Dialog, DialogActions, DialogContent, Grid, useMediaQuery } from "@mui/material";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";
import { DESKTOP, MOBILE } from "utils/constants";
import classes from "./styles";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useEffect, useState } from "react";

interface Props{
    open: boolean,
    onClose: ()=>void,
    thumbnails: {url:string}[],
    imageSelected: {
        url: string;
        cloudFlareId?: string;
    },
    onSelectThumbnail: (thumbnail:{url:string})=> void
}

export default function DialogYoutubeThumbnail({open,onClose, thumbnails, imageSelected, onSelectThumbnail}:Props){

    const mobile = useMediaQuery(MOBILE)
    const desktop = !mobile
    const [selected, setSelected] = useState<{url:string}>(null)


    useEffect(()=>{
        setSelected(imageSelected)
    },[imageSelected])

    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"} 
            fullScreen={mobile? true:false}
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}
        >
            <DialogAppBar
                title={"Miniatures Youtube"}
                onClose={onClose}

            />

            <DialogContent>
                <Grid container spacing={3}>
                {thumbnails.map((thumbnail,index)=>{
                    const isSelected = thumbnail?.url === selected?.url

                    return(
                        <Grid 
                            item 
                            xs={12} 
                            sm={6}
                        >
                   
                                <Box
                                    component={"img"} 
                                    src={thumbnail.url} 
                                    onClick={()=>setSelected(thumbnail)} 
                                    key={index} 
                                    sx={[
                                        classes.thumbnail,
                                        isSelected && classes.thumbnailSelected
                                    ]}
                                />
                         
                        </Grid>
                    )
                })}
                </Grid>
            </DialogContent>
            <DialogActions 
                sx={{borderTop: desktop ? "solid 1px #e1e1e1" : "none"}}
            >
                {desktop && (
                    <Button 
                        onClick={onClose} 
                    >
                            Fermer
                    </Button>
                )}
                <LoadingButton 
                    variant="contained" 
                    onClick={()=>{ 
                        onSelectThumbnail(selected); 
                        onClose()
                    }}
                    disabled={false}
                    loading={false}
                >
                    Valider
                </LoadingButton>
            </DialogActions>
        

        </Dialog>
    )
}