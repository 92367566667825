
import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import Meal from "interfaces/Meal";
import { addDays, differenceInCalendarDays, endOfMonth, endOfWeek, format, isSameDay } from "date-fns";
import calcMacros from "function/calcMacros";
import { fr } from "date-fns/locale";
import { BarChart } from '@mui/x-charts/BarChart';
import { chartsGridClasses } from '@mui/x-charts/ChartsGrid';
import { theme } from "utils/theme";
import DialogDayMeals from "components/organisms/DialogDayMeals";
import { BarItemIdentifier } from "@mui/x-charts/models";


  interface Props{
    meals: Meal[],
    start: Date,
    range: "month"|"year"|"quarter" |"week",
    displayRange: {start:Date, end:Date}
  }


  export function valueFormatter(value: number | null) {
    return `${value} kcal`;
  }


export function MealsBarChart({meals, range, displayRange}:Props){

    const [open, setOpen] = useState<boolean>(false)
    const [item, setItem] = useState<{meals:Meal[], date: Date}>({meals: [], date: null})



    useEffect(()=>{
      if(item.date){
        setOpen(true)
      }
    },[item])

    const DATA = useMemo(()=>{
      const data:{date:number, value: number}[]= []
      var nbDays = 0
      const start = displayRange.start

      // On calcule le nombre de jours qui sépare la date de début et de fin pour l'affichage
      if(range=== "month"){
        nbDays = differenceInCalendarDays(endOfMonth(start), start) + 1
      } else {
        nbDays = differenceInCalendarDays(endOfWeek(start,{locale: fr}), start) + 1
      }
      

      Array.from(Array(nbDays)).forEach((_,index)=> {
        const date = addDays(start, index)
        data.push({
          date: date.getTime(),
          value: 0
        })
      })

      if(range === "month" && nbDays === 30){
        data.push({
          date:null,
          value: 0
        })
      } 

      meals.forEach((meal)=> {
        const index = data.findIndex((elem)=> isSameDay(new Date(meal.date), elem.date))
        if(index !== -1)
        data[index].value = data[index].value + calcMacros([{...meal}]).kcal
      })

      return data
    },[meals, displayRange])


    /********************
     * DONNEES DE Y
     *********************/
    const YAXIS = useMemo(()=>{
      const data:number[] = []
      DATA.forEach((elem)=> {
        data.push(elem.value)
      })
      return data
    },[JSON.stringify(DATA)])


     /********************
     * DONNEES DE X
     *********************/
     const XAXIS = useMemo(()=>{
      const data:string[] = []
      DATA.forEach((elem)=> {
        const formatage = elem.date ? format(elem.date, "dd", {locale: fr}) : ""
        data.push(formatage)
      })

      return data
    },[JSON.stringify(DATA), range])


    /********************
     * MAX
     *********************/

    const MAX = useMemo(()=>{
      var max = 3000
      DATA.forEach((elem)=> {
        if(elem.value > max) {
          max = elem.value + 1000
        }
      })

      return max
    },[DATA])

    const onClickItem = (e: React.MouseEvent<SVGElement, MouseEvent>, d: BarItemIdentifier) => {
        const mealsFiltered= meals.filter((elem)=> isSameDay(DATA[d.dataIndex].date, new Date(elem.date)))
        setItem({date: new Date(DATA[d.dataIndex].date), meals: mealsFiltered})
    }



    return(

    
            <>
            <Box 
                sx={{ width: "100%", height: 400}}
            >
              <BarChart
                series={[
                  { data: YAXIS, color:theme.palette.primary.main, valueFormatter},
                 
                ]}
                borderRadius={5}
                height={330}
                xAxis={[{ data:XAXIS, scaleType: 'band' }]}
                yAxis={[ {
                  min: 0,
                  max: MAX,
                },]}
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                grid={{ horizontal: true }}
                sx={{
                 
                  [`& .${chartsGridClasses.line}`]: { 
                    strokeDasharray: '5 3', 
                    strokeWidth: 2 
                  },
                }}
                onItemClick={onClickItem}

              />
            </Box>

            <DialogDayMeals
                open={open}
                onClose={()=> setOpen(false)}
                meals={item.meals}
                date={item.date}
            />
            </>
           
          
     
    )


}


export default React.memo(MealsBarChart)