
import { theme } from "utils/theme"
import { BORDER_COLOR, BORDER_WORKOUT_TEXTFIELD_COLOR, WORKOUT_BUILDER_TEXTFIELD_BGCOLOR, WORKOUT_BUILDER_TEXTFIELD_RADIUS } from "utils/constants"


export const classes = {
    item:{
        display:"flex",
        flexDirection: "column",
        alignItems:"center",
        paddingTop: 2,
        paddingBottom: 2,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
    },

    input: {
        width: {
            xs: 170,
            sm: 170,
        }, 
        minWidth: {
            xs: 170,
            sm: 170,
        }, 
        marginRight: {
            xs: 2,
            sm: 1,
        }, 
        height: 40, 
        maxHeight: 40
    },

    textField: {
  
        backgroundColor: WORKOUT_BUILDER_TEXTFIELD_BGCOLOR,
        borderRadius: WORKOUT_BUILDER_TEXTFIELD_RADIUS,

        '& .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${BORDER_WORKOUT_TEXTFIELD_COLOR} !important`,
        },
    
    
        "& .MuiOutlinedInput-root": {
            fieldset: {
                //border: "solid 1px #e2e2e2",
                border: `solid 1px ${BORDER_WORKOUT_TEXTFIELD_COLOR} !important`,
                borderRadius: WORKOUT_BUILDER_TEXTFIELD_RADIUS,

            },
            fontWeight: 400,
            fontSize: "1rem !important",
            height: 50,
            color: "#202020",
        

            "&.Mui-disabled":{
                fieldset: {
                    //border: "solid 1px #e2e2e2",
                    border: "none",
                },
                fontWeight: 500,
                fontSize: "1rem",
            }
        },


    
},

portionInput: {
    width: {
        xs: 70,
        sm: 70,
    }, 
    minWidth: {
        xs: 70,
        sm: 70,
    }, 
    marginRight: 1, 
    height: 40, 
    maxHeight: 40,
    
},

labelInput: {
    width: {
        xs: "calc(100% - 95px)",
        sm: 170,
    }, 
    minWidth: {
        xs: "calc(100% - 95px)",
        sm: 170,
    }, 
    marginRight: {
        xs: 2,
        sm: 1,
    }, 
    height: 40, 
    maxHeight: 40
},



    itemNameContainer : {
        
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        //justifyContent: "space-between",
        order: {xs: 1, sm: 2},
        marginBottom: {
            xs: 1, sm: 0
        },
        flex: 4,
    },

    itemName: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: {
            xs: 600,
            sm: 500,
        },
        lineHeight: "34px",
        display:"flex",
        flex: 2
        
    },

    icon: {
        width: 40,
        height: 40,
        backgroundColor: "#e8e8e8",
        borderRadius: "100px",
        marginRight: 1,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    itemInfos : {
        display:"flex",
        flexDirection: {
            xs: 'column',
            sm: "row",
        },
        flexWrap: "nowrap",
        flex: 2,
        flexGrow: 2,
    },

    

   

    recipeInfos: {
        flexDirection: "row",
        minWidth: {
            xs: 270
        },
        order:{
            xs: 2, 
            sm: 1
        }
    },

    dropDownButton: (expand:boolean)=>({
        transform: expand ? 'rotate(180deg)' : '',
        transition: 'transform 150ms ease', // smooth transition
        //border:"solid 1px #f7f7f7",
        //backgroundColor:"#fbfbfb",
        borderRadius: "100px !important",
    }),

    expandButton: {
        borderRadius: "100px !important",
        marginRight: {
            xs: 0,
            sm:0,
        }
    },

    ingredients:{
        width: "100%",
        position: "relative",

    },


    step : {

    },

    stepIndex: {

    },

    lineIndicator : {
        position: "absolute",
        width: "1px",
        height: "calc(100% - 32px)",
        borderRight: "dashed 3px #698baa",
        top:"0px",
        left: 35,
        zIndex:0,
        display:{xs: "none", sm: "block"},
    }
}

export default classes