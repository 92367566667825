import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import Recipe from "interfaces/Recipe";
import { MOBILE, RECIPE_TAGS } from "utils/constants";
import classes from "./styles";
import calcBoxHeight from "function/calcBoxHeight";
import Youtube from 'react-player/youtube'
import { useMemo, useState } from "react";
import { FoodPortion } from "interfaces/Food";
import { DEFAULT_PORTIONS } from "utils/constants";
import { Check } from "@mui/icons-material";
import MacroPieChart from "../MacroPieChart";
import calcMacros from "function/calcMacros";
import Meal from "interfaces/Meal";
import calcMicros from "function/calcMicros";
import DialogTransition from "components/molecules/DialogTransition";
import DialogAppBar from "components/molecules/DialogAppBar";
import MacrosListMobile from "../MacrosListMobile";
import Kcal from "components/atoms/Kcal";


interface Props{
    recipe: Recipe,
    open: boolean,
    onClose: ()=> void
}





export default function DialogRecipe({recipe, open, onClose}:Props){
    const mobile = useMediaQuery(MOBILE)
    const [playingVideo, setPlayIngVideo] = useState(false)
    const [loading, setLoading] = useState(false)

    const playerIsReady = () => {
        setTimeout(()=>{
            setLoading(false)
        },200)
    }


    const CHART_DATA = useMemo(()=>{
    
        const meals:Meal[] = [{
            name:"recipe",
            time:"00:00",
            content:[]
        }]

        recipe.ingredients.forEach((elem)=> {
            meals[0].content.push({
                type: "food",
                food: elem.ingredient.food,
                portion: elem.ingredient.portion,
                label: elem.ingredient.label,
                grammage: elem.ingredient.grammage
            })
        })


        const total = calcMacros(meals)
        var micros = calcMicros(meals)

        const proteinsPart = total.proteins ? (total.proteins *4) / total.kcal * 100 : 0
        const lipidsPart = total.lipids? (total.lipids * 9) / total.kcal * 100 : 0
        const carbsPart = total.carbs? (total.carbs * 4)/ total.kcal * 100 : 0
        
        if(proteinsPart === 0 && lipidsPart === 0 && carbsPart ===0){
            const labels:[] = []
            return labels
        }
  
        
        const labels = [
            { value: total.proteins, label: `Protéines`, part: proteinsPart},
            { value: total.lipids, label: `Lipides`, part: lipidsPart},
            { value: total.carbs, label: `Glucides`, part: carbsPart},
            {value: micros.fibers, label: "Fibres", part: 0}

          //{value: Math.round(total.kcal), label: "Kcal"}
      ]
  
        return labels
  
    },[recipe.ingredients])


    const MEALS = useMemo(()=>{
        const meals:Meal[] = [{
            name:"recipe",
            time:"00:00",
            content:[]
        }]

        recipe.ingredients.forEach((elem)=> {
            meals[0].content.push({
                type: "food",
                food: elem.ingredient.food,
                portion: elem.ingredient.portion,
                label: elem.ingredient.label,
                grammage: elem.ingredient.grammage
            })
        })


        return meals
    },[recipe])

    return(
        <Dialog
            open={open}
            fullScreen={mobile}
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}


        >
            <DialogAppBar
                title={"Recette"}
                onClose={onClose}
            />
            <DialogContent
                sx={{padding:0, width: mobile ? "100%" : 640}}
            >
                
                {/**** Vidéo ***** */}

                <Box sx={[classes.video, {
                    height: calcBoxHeight(1080,1920,640)
                }]}>

                    {/***************
                     * IMAGE CLIENT
                     ***************/}

                    {((!recipe.video || recipe.video?.url === "") &&  (recipe?.cover?.url && recipe?.cover?.url !== "")) && (
                        <Box 
                            component={'img'}
                            sx={[classes.cover, {height:calcBoxHeight(1080,1920,640), maxHeight: calcBoxHeight(1080,1920,640)}]}
                            src={recipe.cover.url}
                        />
                    )}


                      {/***************
                     * IMAGE DEFAULT
                     ***************/}

                    {((!recipe.video || recipe.video?.url === "") && (!recipe.cover?.url || recipe.cover?.url === "")) && (
                        <Box 
                            component={'img'}
                            sx={[classes.cover, {height:calcBoxHeight(1080,1920,640), maxHeight: calcBoxHeight(1080,1920,640)}]}
                            src={"https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/e9387640-fa47-410e-afc6-83e2b6037300/public"}
                        />
                    )}


                    {(recipe?.video && recipe.video.url !== "") && (
                        <Youtube
                            url={recipe?.video.url}
                            width={'100%'}
                            playing={playingVideo}
                            controls={true}
                            onReady={playerIsReady}
                            style={{
                                opacity: loading ? 0 : 1
                            }}
                        />
                    )}

                    {(recipe?.video && loading) && (
                        <Box 
                            sx={[
                                classes.loading,
                                {height: calcBoxHeight(1080,1920,640)}
                            ]}
                        >
                            <CircularProgress size={50} />
                        </Box>
                        
                    )}
                </Box>

                <Typography
                    sx={classes.recipeTitle}
                >
                {recipe.name.fr}
                </Typography>

                <Typography
                        sx={classes.recipePortions}
                    >
                        Pour {recipe.portions} portion{recipe.portions > 1 ? "s" : ""}
                </Typography>
                
                


                {/************
                 * TAGS
                 *************/}
                
                {recipe.tags.length >0 && (
                <Box
                    sx={classes.section}
                >
                    <Box sx={classes.tags}>
                        {recipe.tags.map((tag, tagIndex)=>{
                            return(
                                <Box 
                                    key={tagIndex}
                                    sx={[
                                        classes.tag,
                                        
                                    ]}>
                                        {RECIPE_TAGS.find((elem)=> elem.id === tag).label.fr}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                )}

                {/************
                 * MACROS
                 *************/}

                 <Box
                    sx={{paddingLeft: 3, paddingRight: 3, marginTop: 3}}
                 >
                
                    <Kcal
                        meals={MEALS}
                    />
               
                    <MacrosListMobile
                        meals={MEALS}
                    />

                   
                 </Box>

                {/************
                 * INGREDIENTS
                 *************/}

                <Box
                    sx={classes.section}
                >
                    <Typography
                        sx={classes.sectionTitle}
                    >
                        Ingrédients
                    </Typography>
                    
                    <Box>

                        {recipe.ingredients.length === 0 && (
                            <Typography
                            sx={{marginTop: 1}}
                        >
                            Aucun ingrédient
                            </Typography>
                        )}

                        {recipe.ingredients.map((elem,index)=> {

                            const portions = elem.ingredient.food.portions
                            const defaultPortions:FoodPortion[] =  [...DEFAULT_PORTIONS]
                            const allPortions = defaultPortions.concat(portions)
                            const ingredientPortion = allPortions.find((portion)=> portion.label.fr === elem.ingredient.label).label.fr

                            return(
                                <Box
                                    sx={classes.ingredientContainer}
                                    key={index}
                                >
                                    <Check 
                                        sx={{fontSize: 20}}
                                    />
                                    <Typography
                                        sx={classes.ingredient}
                                    >
                                        {elem.ingredient.portion} {ingredientPortion} {elem.ingredient.food.name.fr}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>


                {/************
                 * ETAPES
                 *************/}

                <Box
                    sx={classes.section}
                >
                    <Typography
                        sx={classes.sectionTitle}
                    >
                        Etapes (pour {recipe.portions} portion{recipe.portions > 1 ? "s" : ""})
                    </Typography>
                    
                    <Box>

                        {recipe.steps.length === 0 && (
                            <Typography
                                sx={{marginTop: 1}}
                            >
                                Aucune description
                            </Typography>
                        )}
                        {recipe.steps.map((step, stepIndex)=>{
                            return(
                                <Box
                                    sx={classes.step}
                                    key={stepIndex}
                                >
                                    <Typography
                                         sx={classes.stepIndex}
                                    >
                                        {stepIndex + 1} - 
                                    </Typography>
                                    <Typography>
                                        {step}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>

                </Box>


            </DialogContent>

            {!mobile && (
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
            )}
        </Dialog>
    )
}