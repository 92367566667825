import React, { useState, useMemo, useEffect } from "react";
// MUI
import { Card, Grid, Typography, Box} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
// Redux 
import { useDispatch, useSelector} from "react-redux";
// Constants
import {SUBSCRIPTIONS_FEATURES} from "utils/constants"
// Router
import { useHistory } from "react-router-dom";
// Interfaces
import { AppDispatch, RootState } from "app/store";
// API
import { createCustomerId } from "api/user";
import { postSessionPortal, postCheckout } from "api/subscriptions";
//Styles
import classes from "./styles"
// Shared Component
import DialogLoader from "components/molecules/DialogLoader";
import ButtonPrimary from "components/atoms/Buttons/ContainedButton";
import SubscriptionProduct from "interfaces/SubscriptionProduct";
import User from "interfaces/User";
import DialogIsTrialing from "components/molecules/DialogIsTrialing";
import { handleSubscriptionPresentation } from "features/appSlice";

/************ REDUX SELECTORS ************** */


/************ TYPES ************** */

interface Props{
    product: SubscriptionProduct,
    current: boolean,
    hasTooManyClients: boolean,
    color: string
}

enum subscriptions {
    "Starter",
    "Basic",
    "Pro",
}

export type Feature = keyof typeof subscriptions;


/************** SUBSCRIPTION CARD ******************* */

export default function SubscriptionCard({product, current, hasTooManyClients, color}:Props){


    const dispatch = useDispatch<AppDispatch>()
    const user:User = useSelector((state:RootState) => state.user.data)
    const [openLoader,setOpenLoader] = useState(false)
    const [openDialogTrial, setOpenDialogTrial] = useState<boolean>(false)

    const createCheckoutPage = () => {
        // Création de la page checkout, redirection
        dispatch(postCheckout({priceId: product.default_price.id})).unwrap().then((res)=>{
            window.location.replace(res.url)
        })
    }


    /******** SUBMIT - SELECTION D'UN ABONNEMENT ********** */

    const selectSubscription = async() => {

        if(!user.trialIsDone){
            dispatch(handleSubscriptionPresentation({open:true}))
            return
        }

        if(user.subscription?.current?.status==="trialing"){
            setOpenDialogTrial(true)
            return
        }

        setOpenLoader(true)

       // Création d'un customer id
        if(!user?.customerId){
            dispatch(createCustomerId()).unwrap().then(()=>{
                createCheckoutPage()
            })
        } else {
            // Upgrade vers un prix supérieur
            if(user.subscription?.current?.status === "active"){
                dispatch(postSessionPortal({flow:"subscription_update_confirm", priceId: product.default_price.id})).unwrap().then((res)=>{
                    window.location.replace(res.url)
                })
            }
            // Premier abonnement
            else{
                createCheckoutPage()
            }
            
        }

        
    }


    /******** JSX ********** */
  
    return(
        <React.Fragment>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                
                {/******* LOADER - Indicateur de récupération des données pour la page Checkout***** */}
                <DialogLoader 
                    open={openLoader} 
                    text="Préparation de la page de paiement ..." 
                />

                {/******* CARTE D'ABONNEMENT ***** */}
                <Card sx={[
                    classes.card, 
                    {backgroundColor: "white"}
                ]} 
                elevation={1}>
                    {current && (
                        <Box sx={classes.currentIndicator} />
                    )}

                    <Box sx={classes.header}>
                    
                        <Typography sx={classes.title}>
                            {product.name} 
                        </Typography>
                    
                        {/*<Typography sx={classes.clients}>
                            jusqu'à {product.metadata.clients} clients
                        </Typography>*/}
                        <Box sx={classes.priceContainer}>
                            <Typography sx={classes.price}>
                                {(product?.default_price?.unit_amount/100)}€
                            </Typography>
                            <Typography sx={classes.month}>/ mois</Typography>
                        </Box>
                        <Typography sx={classes.recurence}>
                             Sans engaement
                        </Typography>
                    </Box>


                    <Box sx={classes.descriptionContainer}>
                        <Typography sx={classes.description}>
                            {product.description}
                        </Typography>
                        
                    </Box>

                    {/********** LISTE DES FEATURES *********** */}

                    <Box sx={{mt:3, padding: 3, paddingTop: 0}}>

                        <Typography sx={classes.clients}>
                            jusqu'à {product.metadata.clients} clients
                        </Typography>

                        {SUBSCRIPTIONS_FEATURES.map((feature, index)=> {

                            const featureName = product.name as Feature
                            const check:boolean = feature.can[featureName]
                        
                            return(
                                <Box 
                                    sx={classes.feature} 
                                    key={index}
                                >
                                    {check ? <CheckCircle sx={{mr:1, color:"#23a8f8"}}/> : <Cancel sx={{mr:1, color:"error.main"}}/>}
                                    <Typography 
                                        sx={classes.featureLabel}
                                    >
                                        {feature.label}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>

                    {!Boolean(user.subscription?.scheduled) && (
                        <Box sx={classes.button}>
                            <ButtonPrimary 
                                variant="contained" 
                                disabled={hasTooManyClients || current} 
                                onClick={selectSubscription}
                                sx={{borderRadius: "30px !important"}}
                            >
                                {user.subscription?.current?.subscriptionId ? "Modifier ma formule" : "Choisir cette formule"}
                            </ButtonPrimary>
                        </Box>
                    )}
                
                

                   


                    
                </Card>
                
            </Grid>
            
           

            <DialogIsTrialing
                open={openDialogTrial}
                onClose={()=> setOpenDialogTrial(false)}
            />
        </React.Fragment>
    )
    
}
