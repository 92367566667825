import { Box, lighten, LinearProgress, Typography } from "@mui/material";
import calcMacros from "function/calcMacros";
import MealInterface from "interfaces/Meal";
import { useMemo } from "react";
import classes from "./styles";
import { Ingredient } from "interfaces/Recipe";
import { MACROS_COLORS } from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { differenceInCalendarYears } from "date-fns";
import { calcMb } from "function/calcMb";

interface Props{
    meals?: MealInterface[],
    ingredients?: {
        ingredient: Ingredient
    }[],
    macros?: {
        kcal: number,
        proteins:number,
        carbs: number,
        lipids:number
    }
}


function Macro({color,label, goal, current, unit}:{color:string, label: string, goal: number, current: number, unit: string}){
    return(
        <Box
            sx={classes.macro}
        > 
        <Typography
            sx={classes.macroLabel}
        >
            {label}
        </Typography>
        
        <Box
            sx={classes.macroProgress}
        >
            
            <Typography
                sx={classes.macroProgressLabel}
            >
               <Typography 
                    sx={{fontWeight: "600", fontSize:"0.9rem"}}>
                        {current}
                </Typography> 
                <Typography 
                    sx={{fontWeight: "400", fontSize:"0.9rem"}}
                >
                    / {goal} {unit}
                </Typography>
            </Typography>

            <LinearProgress 
                value={current/goal * 100 > 100 ? 100 : current/goal * 100}
                color="inherit"
                sx={{
                    height: 10, 
                    width: 200, 
                    //color, 
                    backgroundColor: `#eeeeee !important`,
                    borderRadius: 3,
                    "&::before": {
                            backgroundColor: "transparent !important"
                    },
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: color,
                        "&::before": {
                            backgroundColor: "red !important"
                        }
                    },

                   
                }}
                variant="determinate" 
                
            
            />
        </Box>
    </Box>

    )
}

export default function MacrosGoal({meals, ingredients, macros}:Props){

    const client = useSelector((state:RootState)=> state.clients.profile)

    /**********************************
     * METABOLISME DE BASE + ESTIMATION
     ***********************************/

    

    const METABOLISM = useMemo(()=>{
        if(client.birthday && client.gender && client.size && client.measures['weight'] && client.measures["weight"]?.length >0 ){
            var lastMeasure:number = client?.measures?.weight[0]?.value
            const age = differenceInCalendarYears(new Date(), new Date(client.birthday))
            const size = client.size/100
            const weight=(Boolean(lastMeasure) ? lastMeasure : null)

            const metabolism = calcMb(client.gender, size, weight, age)
            return Math.round(metabolism)

        } else {
            return null
        }
    },[client])


    const GOAL_KCAL = useMemo(()=>{

        if(client?.goal){
            switch (client.goal) {
                case "bulking" : {
                    return 200
                }
                case "weightloss" : {
                    return -200
                }
               
                case "competition" : {
                    return 200
                }
                default: return 0
                
            }
        } else {
            return 0
        }
    },[client?.goal, METABOLISM])

    



    const ENERGY_INTAKE:{kcal: number,proteins:number, carbs:number, lipids: number, useCustom?: boolean} = useMemo(()=>{
        // Définit par le coach
        if(client.energyIntake?.useCustom){
            const kcal = client.energyIntake.kcal
            return {
                kcal : kcal,
                proteins: Math.round(kcal * (client?.energyIntake.proteins/100) /4),
                carbs: Math.round(kcal * (client?.energyIntake.carbs/100) /4),
                lipids: Math.round(kcal * (client?.energyIntake.lipids/100) /9),
            }
        } 
        // Définit par Traener si NAP
        else 
        {
            if(client.nap){
                const kcal = Math.round((METABOLISM * (client.napDefinedBy === "coach" ? client.coachNap : client.nap?.value ? client.nap.value : 1)) + GOAL_KCAL)
                
                
                const proteinsG = Math.round(kcal as number * 0.25 /4)
                const carbsG = Math.round(kcal as number * 0.50 /4)
                const lipidsG = Math.round(kcal as number * 0.25 /9)


                return {
                    kcal,
                    proteins: proteinsG,
                    lipids: lipidsG,
                    carbs: carbsG,
                    useCustom: false
                }

            } else {
                return {
                    kcal:0,
                    proteins: 25,
                    lipids: 25,
                    carbs: 25,
                    useCustom: false

                }
            }
           
        }
    },[client])


    {/***********************
        LISTE DES MACROS
    *************************/}

    const MACROS = useMemo(()=>{

        var total = null
       if(macros){
            var labels = [
                { value: macros.proteins, label: 'Protéines'},
                { value: macros.lipids, label: 'Lipides'},
                { value: macros.carbs, label: 'Glucides'},
                { value: 0, label: `Fibres`},
                {value: macros.kcal, label: "Kcal"},
            ]

            return labels
        } else {
                total = calcMacros(meals)
                var labels = [
                    { value: total.proteins, label: 'Protéines'},
                    { value: total.lipids, label: 'Lipides'},
                    { value: total.carbs, label: 'Glucides'},
                    { value: total.fibers, label: `Fibres - ${total.fibers}g`},
                    {value: total.kcal, label: "Kcal"},
                ]
        
                return labels
            
        }
        
        

    },[meals, ingredients, macros])



    return(

    <Box
        sx={classes.mealMacros}
    >

        <Macro
            label="Kcal"
            color={MACROS_COLORS["calories"]}
            goal={ENERGY_INTAKE?.kcal}
            current={MACROS[4].value}
            unit={"kcal"}
        />  

        <Macro
            label="Protéines"
            color={MACROS_COLORS["proteins"]}
            goal={Number(ENERGY_INTAKE?.kcal) ? ENERGY_INTAKE?.proteins : 0}
            current={MACROS[0].value}
            unit={"g"}
        />  

        <Macro
            label="Glucides"
            color={MACROS_COLORS["carbs"]}
            goal={Number(ENERGY_INTAKE?.kcal) ? ENERGY_INTAKE?.carbs : 0}
            current={MACROS[2].value}
            unit={"g"}
        />  

        <Macro
            label="Lipides"
            color={MACROS_COLORS["lipids"]}
            goal={Number(ENERGY_INTAKE?.kcal) ? ENERGY_INTAKE?.lipids : 0}

            current={MACROS[1].value}
            unit={"g"}
        />  

            

</Box>
    )
}