import { BORDER_COLOR, FIXED_SEARCH_HEIGHT } from "utils/constants";

export const classes = {
  listHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: "70px",
    paddingLeft: 3,
    paddingRight: 3,
    height: {
      xs: FIXED_SEARCH_HEIGHT - 20,
      lg: FIXED_SEARCH_HEIGHT,
    },
    mb: {
      xs: 3,
      lg: 0
    },
    position: {
      xs: "fixed",
      lg: "relative"
    },
    zIndex: {
      xs:10,
      lg: 1
    },
    backgroundColor: {
      xs: "white",
      lg: "transparent"
    }, 
    borderBottom: {
      xs: `solid 1px ${BORDER_COLOR}`, 
      lg: "none"
    },
  },

  tabs: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
};

export default classes;
