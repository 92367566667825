import { Dialog, DialogActions, Button, DialogContent, Box, Typography, useMediaQuery } from "@mui/material";
import { MOBILE } from "utils/constants";
import classes from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import DialogTransition from "../DialogTransition";
import DialogAppBar from "../DialogAppBar";


interface Props{
    open: boolean,
    onClose: ()=>void,
    workoutsStats: {
        missed: number;
        done: number;
        attendance: number;
    },
    attendance: "Pas assez de données" | "Excellente" | "Très bonne" | "Bonne" | "Moyenne" | "Mauvaise" | "Très mauvaise"
}

export default function DialogWorkoutStats({open, onClose, workoutsStats, attendance}:Props){

    const client = useSelector((state:RootState)=> state.clients.profile)
    const dispatch = useDispatch<AppDispatch>()
    const mobile= useMediaQuery(MOBILE)


    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth={"xs"}
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}
            fullScreen={mobile}
        >
            <DialogAppBar
                title={"Statistiques d'assiduité"}
                onClose={onClose}
            />
            <DialogContent>

                <Box
                    sx={classes.row}
                >
                    <Typography>
                        Séances effectuées
                    </Typography>
                    <Typography
                        sx={classes.value}
                    >
                        {workoutsStats.done}
                    </Typography>
                </Box>

                <Box
                    sx={classes.row}
                >
                    <Typography>
                        Séances manquées
                    </Typography>
                    <Typography
                        sx={classes.value}
                    >
                        {workoutsStats.missed}
                    </Typography>
                </Box>

                <Box
                    sx={classes.row}
                >
                    <Typography>
                        Taux d'assiduité
                    </Typography>
                    <Typography
                        sx={classes.value}
                    >
                        {Math.round(workoutsStats.attendance)}%
                    </Typography>
                </Box>
                <Box
                    sx={classes.row}
                >
                    <Typography>
                        Niveau d'assiduité
                    </Typography>
                    <Typography
                        sx={classes.value}
                    >
                        {attendance}
                    </Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                {!mobile && (
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}