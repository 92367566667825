import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import { MOBILE } from "utils/constants";
import MacroPieChart from "../MacroPieChart";
import { useMemo } from "react";
import Meal, { MealContent } from "interfaces/Meal";
import calcMacros from "function/calcMacros";
import MicroNutriments from "../MicrosNutriments";
import calcMicros from "function/calcMicros";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";
import MacrosListMobile from "../MacrosListMobile";
import Kcal from "components/atoms/Kcal";

interface Props{
    open: boolean,
    onClose: ()=> void,
    item: MealContent
}


export default function DialogFood({item, open, onClose}:Props){

    const FAKE_MEALS:Meal[] = useMemo(()=>{
        const fakeMeals:Meal[] = [{
            name: "food",
            time: "00:00",
            content: [{...item}]
        }] 

        return fakeMeals
    },[item])


    const mobile = useMediaQuery(MOBILE)

    const DATA = useMemo(()=>{

        if(FAKE_MEALS.length > 0){

            const total = calcMacros(FAKE_MEALS)
           var micros = calcMicros(FAKE_MEALS)

           

            const proteinsPart = total.proteins ? (total.proteins *4) / total.kcal * 100 : 0
            const lipidsPart = total.lipids? (total.lipids * 9) / total.kcal * 100 : 0
            const carbsPart = total.carbs? (total.carbs * 4)/ total.kcal * 100 : 0
            
            if(proteinsPart === 0 && lipidsPart === 0 && carbsPart ===0){
                const labels:[] = []

                return labels
            }

            
            const labels = [
                { value: total.proteins, label: `Protéines`, part: proteinsPart},
                { value: total.lipids, label: `Lipides`, part: lipidsPart},
                { value: total.carbs, label: `Glucides`, part: carbsPart},
                {value: micros.fibers, label: "Fibres", part: 0}
                
                
            ]
  
            return labels
        }
  
      },[FAKE_MEALS]);

    return(
    <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={mobile}
        disableScrollLock={!mobile}
        TransitionComponent={DialogTransition}
    >
        <DialogAppBar
            title={"Composition"}
            onClose={onClose}
        />

        <DialogContent>

            <Typography
            sx={{fontSize: "1.4rem", fontWeight: 500, marginBottom: 0.5}}
            >
                {item?.food.name.fr}
            </Typography>
            <Typography
                sx={{marginBottom: 4}}
            >
                pour {item?.portion} {item?.label}
            </Typography>


            {item && (
                <>
                    

                    <Box
                        sx={{marginBottom: 6}}
                    >
                        
                        <MacrosListMobile 
                            meals={FAKE_MEALS}
                        />
                    </Box>

                    <MicroNutriments
                        forFood={true}
                        meals={FAKE_MEALS}
                        noPadding={true}

                    />
                </>
            )}
            

        </DialogContent>

        {!mobile && (
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        )}

    </Dialog>
    )
}