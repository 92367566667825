import { MACROS_COLORS } from "utils/constants"

const classes = {

    mealMacros:{
        display: "flex",
        flexDirection: "row",
        width: {xs: "100%", sm: 350},
        marginBottom: 3,
        //width: "auto",
        justifyContent:"space-between",
        marginTop: 3 
    },

    macro: {
        display:"flex",
        flexDirection: "column",
        justifyContent:"center",
        position: "relative",
        alingItems:"center",
    },

    macroLabel : {
        fontSize: "0.8rem",
        marginTop: 1,
        textAlign:"center",
        fontWeight: 500
    },

    proteins:{
        color: MACROS_COLORS.proteins,
        //backgroundColor: "#e6fbfb",

    },
    carbs:{
        color: MACROS_COLORS.carbs,
        //backgroundColor: "#fce9ff",
    },
    lipids:{
        color: MACROS_COLORS.lipids,
        //backgroundColor: "#e6f2ff",
    },
    energie:{
        color: MACROS_COLORS.calories,
        //backgroundColor: "#ffe2d0",

    },
    fibers:{
        color:"#a27806",
        //backgroundColor: "#fff0c5",

    }
}


export default classes