import { Box } from "@mui/material"
import classes from "./styles"
import { useEffect, useMemo, useRef, useState } from "react"

const DAYS_TRAD:string[] = ['Lun.','Mar.','Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.']

interface Props{
    coachProfile?: boolean
}


export default function PlanningHeaderDays({coachProfile}:Props){

    const daysLabel = useRef(null)
    const [isFixed, setIsFixed] = useState<boolean>(false)



    useEffect(()=>{
        if(daysLabel?.current){
            // Changement de la position du Header planning
            const handleScroll = () => {
                
                //if(daysLabel.current.getBoundingClientRect().y + daysLabel.current.offsetHeight < 0){
                if(daysLabel.current.getBoundingClientRect().y <= 83.5){
                    if(!isFixed) {
                        setIsFixed(true)
                    }
                } else {
                    if(isFixed) {
                        setIsFixed(false)

                    }
                }
            }

            if(daysLabel.current){
                window.addEventListener("scroll", handleScroll);
                return () => {
                    window.removeEventListener("scroll", handleScroll);
                };
            }
        }

       
    },[daysLabel?.current, isFixed])



            

        return(
            <>
                <Box sx={[classes.daysList]} ref={daysLabel}>
                    {DAYS_TRAD.map((day)=> {
                        return(
                            <Box 
                                sx={classes.day} 
                                key={day}
                            >
                                {day}
                            </Box>
                        )
                    })}
                </Box>

                <Box sx={[
                    classes.daysList, 
                    classes.fixed, 
                    isFixed && (classes.openFixed),
                    (coachProfile && isFixed) && classes.openFixedCoach
                    
                ]}>
                    {DAYS_TRAD.map((day)=> {
                        return(
                            <Box sx={classes.day} key={day}>
                                {day}
                            </Box>
                        )
                    })}
                </Box>
            </>
        )
    
}