import { lighten } from "@mui/material"
import { borderRadius } from "@mui/system"
import { HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
   
    signinLayout:{
        minHeight:"100vh",
        width: "100%",
        maxWidth: "100vw",
        display:"flex", 
        alignItems: {
            xs : "center", 
            sm: "center",
        }, 
        justifyContent:{
            xs: "center",
            sm:"center", 
        },
        flexDirection: "column",
        backgroundColor: {
            xs: "white", 
            sm: "rgb(247 250 252)"
        },
        paddingLeft:{
            xs: 2,
            sm: 0
        },
        paddingRight:{
            xs: 2,
            sm: 0
        },
        paddingTop: {
            xs: 3,
            sm: 6,
        },
        paddingBottom: {
            xs: 1,
            sm: 6,
        },
        
    },

    logoContainer:{
        display:"flex",
        width: "100%",
        marginBottom: 3,
        alignItems:"center",
        justifyContent:"center",
        marginTop: 1
    },

    title: {
        fontWeight: 900,
        fontSize: {
            xs: theme.typography.pxToRem(20),
            md: theme.typography.pxToRem(30),
        },
        marginBottom: 0.2,
        textAlign:{
            xs: "center",
            md: "auto"
        }
    },

    subtitle:{
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(18),
        marginBottom: 4,
        color: "rgba(0,0,0,0.80)"

    },


    card : {
        position: "relative",
         paddingLeft: {
             xs: 2,
             sm: 5
         },
         paddingRight: {
             xs: 2,
             sm: 5
         },
         paddingTop: {
             xs: 4,
             sm: 5
         },
         paddingBottom: {
             xs: 4,
             sm: 5
         },
         width: {
             xs: "100%",
             sm: 500,
         },
         borderRadius:{
             xs:  0,
             sm: "5px"
         },
         
    },

    textfieldLabel : {
        fontWeight: 700, 
        fontSize: theme.typography.pxToRem(17),
        marginBottom: 0.5, 
        color: "rgba(0,0,0,0.75)",
        
    },

    
    newAccountContainer: {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection: "column",
        marginTop: 3,
        
    },

    newAccountTypography : {
        fontSize: theme.typography.pxToRem(16),
        
    },

    newAccountButton : {
        fontSize: theme.typography.pxToRem(18),
        cursor: "pointer", 
        color:theme.palette.primary.main, 
        fontWeight: 700,
        padding: 0.5,
        borderRadius: 1,
        paddingLeft: 2,
        paddingRight: 2,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        "&:hover": {
            color: lighten(theme.palette.primary.main, 0.1),
        }
        
    },

    

    forgotPassword : {
        color: "primary.main", 
        textDecoration: "none", 
        fontWeight: 500, 
        fontSize: theme.typography.pxToRem(12)
    },


}


export default classes