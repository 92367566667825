import { darken } from "@mui/material"
import { BORDER_COLOR, HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {

    infosContainer:{
    minWidth: 150, 
    display:"flex", 
    flexDirection: "row", 
    alignItems:"center", 
    justifyContent:"flex-end", 
    flex:1, 
    flexWrap: "nowrap"
    },

    indicators : {
        minWidth: 250, 
        display:"flex", 
        flexDirection: "row", 
        alignItems:"center", 
        justifyContent:"flex-end", 
        flex:1, 
        flexWrap: "nowrap"
    },


    container:{
        width: "100%",
        display:"flex",
        flex: 1,
        flexDirection: "column",
        marginBottom: 4,
        padding:{xs:2, sm: 3},
        backgroundColor: "white",
        border: `solid 1px ${BORDER_COLOR}`
    
    },

    presentation : {
        paddingLeft: "0 !important",
        paddingRight:"0 !important",
        marginBottom: 3,
        border: 0
    },



    imageFood : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },


    imageContainer : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: 200
    },


mealButtons:{
    display:"flex", 
    flexDirection: "row",
    marginTop: 3
},

sectionLabel:{
    width: "100%",
    marginBottom: 3,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),

},


    imageButton: {
        position :"absolute",
        top: 15,
        right: 15,
        zIndex: 3,
        backgroundColor: HOVER_COLOR,
        borderRadius: 10
    },

step : {
    display:"flex",
    flexDirection: "row",
    alignItems:"flex-start",
    backgroundColor: "white",
    padding: 2,
    marginBottom: 1.5,
    border: `solid 1px ${BORDER_COLOR}`
},

stepIndex : {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginRight: 1,
    width: 50
},

chips:{
    display:"flex",
    flexDirection: "row",
    alignItems:"center",
    flexWrap: "wrap",
    backgroundColor: "white",
    padding: 3,
    border: `solid 1px ${BORDER_COLOR}`
},

portions:{
    display:"flex",
    flexDirection: "row",
    alignItems:"center",
    marginTop: 2
},



// Section Equipement - Muscles - type
section: {
    minHeight: 80, 
    padding: 3,
    display:"flex", 
    flexDirection: "column", 
    alignItems:"flex-start", 
    justifyContent:"space-between", 
    marginBottom: 3,
    border: `solid 1px ${BORDER_COLOR}`,
    backgroundColor: "white"
},




}

export default classes