import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "utils/axios"
import User, { DateHistoryEvent, HistoryEventDetails } from 'interfaces/User'
import MealPlan from 'interfaces/MealPlan'
import sleep from "function/sleep"
import { ScheduledQuestionnaire } from 'interfaces/Checkup'
import Invitation from 'interfaces/ClientInvitation'
import { UserDataToUpdate } from './user'


/************** RECUPERATION DE L'ENSEMBLE DES CLIENTS ****************/
export const getClients = createAsyncThunk('clients/getClients', async () => {
    try {
      const response = await axios.get(`clients`)
      const clients = response.data.clients
      return { clients }
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })

  /******************* 
   * UPDATE CLIENT DATA
   * ****************/

  export const updateClientData = createAsyncThunk('userSlice/updateClientData', async (data:{clientId: string, dataToUpdate: UserDataToUpdate})=>{

    try{
          const result = await axios.put(`clients/${data.clientId}/data`, data.dataToUpdate)
          const updatedUser:User = result.data.client
  
          return{client:updatedUser}

      }catch(err:any){
          return {error: err.response.data?.message[0]}
      }
  })
  
  /************** SUPPRESSION D'UN CLIENT ****************/
  export const deleteClient = createAsyncThunk('clients/deleteClient', async (clientId: string) => {
    try {
      await axios.delete(`clients/${clientId}`)
      await sleep(1000)
      return { clientDeleted: clientId }
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })

/************** RECUPERATION DES INVITATIONS ****************/
export const getInvitations = createAsyncThunk('clients/getInvitations', async () => {
  try{
    const response = await axios.get(`invitations/sent`)
    const invitations = response.data.invitations
    return {invitations}
  }catch(err:any){
      return {
        error : err.response.data?.message[0]
      }
  } 
})

/************** ENVOIE D'UNE INVITATION ****************/
export const postInvitation = createAsyncThunk('clients/postInvitation', async (data:{email:string, firstname:string,lastname:string}) => {
  try{
    const result = await axios.post(`invitations`, data)
    const invitation:Invitation = result.data.invitation
    await sleep(1000)
    return {invitation}
  }catch(err:any){
      return {
        error : err.response.data?.message
      }
  } 
})


/******************************** 
 * SUPPRESSION D'UNE INVITATION 
 * *******************************/

export const deleteInvitation = createAsyncThunk('clients/deleteInvitation', async (invitationId:string) => {
  try{
    await axios.delete(`invitations/${invitationId}`)
    await sleep(500)
    return {invitationId: invitationId}
  }catch(err:any){
      return {
        error : err.response.data?.message[0]
      }
  } 
})



  /*************************************** 
   * RECUPERATION DES DONNEES D'UN CLIENT 
   * *************************************/

  export const getUserData = createAsyncThunk('clients/getClientData', async (data:{clientId: string}) => {

    try {
      const result = await axios.get(`clients/${data.clientId}`)
      const client:User = result.data.client
      return { client }
    } catch (err: any) {
      const subscriptionEnd = err.response.data?.error === "Subscription end"
      return {
        subscriptionEnd: subscriptionEnd,
        error: err.response.data?.message[0] || "Impossible de récupérer les données utilisateurs"
      }
    }
  })



  /*********************************** 
   * RECUPERATION DU PLANNING CLIENT 
   * *********************************/

  export const getPlanning = createAsyncThunk('clients/getPlanning', async(data:{userId: string, start: Date, end: Date})=> {
      const {userId, start, end} = data


      try {
        const result = await axios.get(`clients/${userId}/planning/?start=${start.toDateString()}&end=${end.toDateString()}`)
        const planning:{workouts:[], checkups:[], programs:[]} = result.data.planning
        const {user} = result.data
        return { planning, user }
  
      } catch (err: any) {
        return {
          message: err.response.data?.message[0] || "Impossible de récupérer le planning de l'utilisateur"
        }
      }
  
  })

  


  /****************** 
   * CLIENTS MESURES
   * ****************/
  
  export const getUserMeasures = createAsyncThunk('clients/measures/get', async(data:{user:User, start: Date, end: Date, type:string})=> {
    const {type, start, end, user} = data

    try {
      const result = await axios.get(`clients/${user._id}/measures/?start=${start.toDateString()}&end=${end.toDateString()}&type=${type}`)
      const measures = result.data.measures
      return { measures }

    } catch (err: any) {
      return {
        message: err.response.data?.message[0] || "Impossible de récupérer les mesures de l'utilisateur"
      }
    }

  })

    /****************** 
     * CLIENTS MEALS
     * ****************/

    export const getClientMeals = createAsyncThunk('clients/meals', async(data:{userId:string, start: Date, end: Date})=> {
      const {start, end, userId} = data
  
      try {
        const result = await axios.get(`clients/${userId}/clientMeals/?start=${start.toDateString()}&end=${end.toDateString()}`)
        const meals = result.data.meals
        return { meals }
  
      } catch (err: any) {
        console.log('ERREUR', err)
        return {
          message: err.response.data?.message[0] || "Impossible de récupérer les mesures de l'utilisateur"
        }
      }
  
    })


  /************** 
   * CLIENTS HISTORY 
   * ****************/
  export const getClientsHistory = createAsyncThunk('client/history', async (data:{start:Date, end:Date}) => {

    try {
        const response = await axios.get(`clients/history/all/?start=${new Date(data.start)}&end=${new Date(data.end)}`)
        const history:HistoryEventDetails[] = response.data.history
        await sleep(200)
        return {history}
        
    } catch (err: any) {

        return {
            error: err.response.data?.message[0]
        }
    }
  })


  /************************************************* 
   * Récupération de l'historique d'un seul client 
   * ************************************************/

  export const getClientHistory = createAsyncThunk('client/profile/history', async (data:{start:Date, end:Date, user:User}) => {

    try {
        const response = await axios.get(`clients/${data.user._id}/history/?start=${new Date(data.start)}&end=${new Date(data.end)}`)
        const history:HistoryEventDetails[] = response.data.history
        await sleep(200)
        return {history}
        
    } catch (err: any) {

        return {
            error: err.response.data?.message[0]
        }
    }
  })


  /********************** 
   * POST QUESTIONNAIRE 
   * ****************** */

  export const postQuestionnaire = createAsyncThunk('client/createQuestionnaire', async (data:{clientId: string,start: Date, recurrence:"sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "month" | "none", templateId:string, punctual: boolean}) => {
    
    const newQuestionnaire:ScheduledQuestionnaire = {
      recurrence: data.recurrence,
      templateId: data.templateId,
      start: data.start
    }
    
    try{
      const result = await axios.post(`clients/${data.clientId}/questionnaire`, newQuestionnaire)
      const questionnaire:ScheduledQuestionnaire = result.data.questionnaire
      await sleep(500)
      return {questionnaire}
    }catch(err:any){
        return {
          error : err.response.data?.message[0]
        }
    } 
  })


  export const deleteQuestionnaire = createAsyncThunk('client/deleteQuestionnaire', async (data:{clientId: string, questionnaireId:string}) => {
    try{
      await axios.delete(`clients/${data.clientId}/questionnaire/${data.questionnaireId}`)
      await sleep(500)
      return {deleted:true, questionnaireId:data.questionnaireId}
    }catch(err:any){
      return {
        error : err.response.data?.message[0]
      }
    }
  })


  /********** MISE A JOUR DES PLANS ALIMENTAIRES ****** */

export const updateClientMealPlan = createAsyncThunk('userSlice/updateMealPlan', async (data:{mealPlan:MealPlan, clientId:string})=>{

  try{
    const result = await axios.put(`clients/${data.clientId}/mealplan`, data.mealPlan)
    const updatedUser:User = result.data.client

    return{client:updatedUser}
  }catch(err:any){
      return {error: err.response.data?.message[0]}
  }
})








    
  