import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles"
import Workout from "interfaces/Workout";
import { useState } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { pasteWorkout } from "api/workouts";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import TemplateWorkouts from "../TemplateWorkouts";
import { MOBILE, TABLET } from "utils/constants";
import { Close } from "@mui/icons-material";


//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
    date: Date,
    programTemplate?: string

}


/*********** MAIN ********* */


export default function DialogImportWorkout({ open, onClose, date}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const workouts = useSelector((state:RootState)=> state.workouts.templates.list)
    const [workoutSelected, setWorkoutSelected] = useState<Workout>(workouts[0])
    const userId:string = useSelector((state:RootState) => state.planning.user?._id) // Planning user le cas échéant
    const pending = useSelector((state:RootState)=> state.planning.requests.pasteWorkout === "pending")
    const programTemplateId = useSelector((state:RootState) => state.planning.builder?._id)
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const desktop = !mobile && !tablet

    const onSelectWorkout = (workout:Workout)=>{
        setWorkoutSelected(workout)
    }

    {/*********** IMPORT DE LA SEANCE ************ */}

    const onImport = () => {
        const newWorkout:Workout = {
            ...workoutSelected, 
            _id:undefined, 
            schedule: date.toDateString(), 
            template: programTemplateId ? true : false, 
            programTemplate: programTemplateId? programTemplateId : undefined,
            createdFor: userId,
        }

        dispatch(pasteWorkout({workout: newWorkout}))
        onClose()
    }
    return(
    <Dialog 
        open={open} 
        fullWidth 
        maxWidth={"md"}
        fullScreen={mobile}
    >
        
        <DialogTitle>

            {mobile && (
                <IconButton sx={{marginRight: 1.5}} onClick={onClose}>
                    <Close sx={{color: "rgba(0,0,0,0.85)"}}/>
                </IconButton>
            )}

            {!mobile && (
                <span>Importer une séance  {!programTemplateId ? `au ${format(new Date(date), "dd MMM yyyy", {locale: fr})}` : ""}</span>
            )}

            {mobile && (
                <span>Importer une séance</span>
            )}
        </DialogTitle>

        <DialogContent sx={classes.content}>

            {/******* LISTE DES SEANCES ********* */}
            <Box sx={classes.list}>
                <TemplateWorkouts 
                    fromImport={true}
                    onSelectWorkout={onSelectWorkout}
                    workoutSelectedForImport={workoutSelected}
                />
            </Box>
        </DialogContent>

        
        <DialogActions sx={{borderTop: "solid 1px #e1e1e1"}}>
            {!mobile && (

                <Button 
                    onClick={onClose}
                    disabled={pending}
                    >
                        Fermer
                </Button>
            )}
            <LoadingButton
                disabled={pending}
                loading={pending} 
                variant="contained" 
                onClick={onImport}
            >
                Importer
            </LoadingButton>
        </DialogActions>
    </Dialog>
    )
}