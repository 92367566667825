import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import TitleTextField from "components/molecules/TitleTextField";
import classes from "./styles";
import React, { useState } from "react";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import DialogFoodList from "../DialogFoodList";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import {Add, CopyAll, Delete, MoreHoriz } from "@mui/icons-material";
import DialogDeleteMeal from "../DialogDeleteMeal";
import MealFoodItem from "../MealFoodItem";
import { toast } from "react-toastify";
import DialogRecipesList from "../DialogRecipesList";
import MealRecipeItem from "../MealRecipeItem";
import { onChangeMealName, onCopyMeal } from "features/mealPlanSlice";
import DialogFood from "../DialogFood";
import { MealContent } from "interfaces/Meal";
import { MOBILE } from "utils/constants";
import MacrosListMobile from "../MacrosListMobile";
import Kcal from "components/atoms/Kcal";


interface Props{
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
    mealIndex: number,

}

export default function Meal({day, mealIndex}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const meal = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex])
    const mealName = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex].name)
    const [openDialogFoodList, setOpenFoodListDialog] = useState<boolean>(false)
    const [openDialogRecipe, setOpenDialogRecipe] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDeleteDialogFood, setOpenDeleteDialogFood] = useState<boolean>(false)
    const [openFoodDialog, setOpenFoodDialog] = useState<boolean>(false)
    const [foodSelected, setFoodSelected] = useState<MealContent>(null)
    const mobile = useMediaQuery(MOBILE)

    const onSelectFood = (ingredient:MealContent) => {

        setFoodSelected(ingredient)
        setOpenFoodDialog(true)
    }

    const onAddMealFood = () => {
        setOpenFoodListDialog(true)
    }


    const onAddRecipe = () => {
        setOpenDialogRecipe(true)
    }


    const changeMealName = (event: React.ChangeEvent<HTMLInputElement>)  => {
        dispatch(onChangeMealName({day,mealIndex, name: event.target.value}))
    }

    const deleteMeal = () => {
        setOpenDeleteDialogFood(true)
        setAnchorEl(null)
    }

    const copyMeal = () => {
        setAnchorEl(null)

        dispatch(onCopyMeal({meal}))
        toast.success('Repas copié')
    }


    return(
        <>
            <Box
                sx={classes.content}
            >
                <IconButton 
                    sx={classes.buttonClose} 
                    onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                >
                    <MoreHoriz />
                </IconButton>

                <Menu disableScrollLock={true} 
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={()=>setAnchorEl(null)}
                >
                    <MenuItem 
                        onClick={copyMeal}
                    >
                        <ListItemIcon>
                        <CopyAll />
                    </ListItemIcon>
                        <ListItemText>
                            Copier ce repas
                        </ListItemText>
                    </MenuItem>  
                    <MenuItem 
                        onClick={deleteMeal}
                    >
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Supprimer ce repas
                        </ListItemText>
                    </MenuItem>  
                </Menu>

         

                {/****************
                 * Repas titre
                ****************/}
                <Box
                    sx={classes.header}>
                       
                        {/**** NAME **** */}
                        <TitleTextField 
                            variant="standard"
                            value={mealName}
                            onChange={changeMealName} 
                            select
                            sx={{maxWidth: "200px !important", minWidth: "200px !important", marginRight: 2}}
                        >
                            <MenuItem 
                                value={"Petit déjeuner"}
                            >
                                Petit déjeuner
                            </MenuItem>
                            <MenuItem 
                                value={"En-cas"}
                            >
                                En-cas
                            </MenuItem>
                            <MenuItem 
                                value={"Déjeuner"}
                            >
                                Déjeuner
                            </MenuItem>
                            <MenuItem 
                                value={"Dîner"}
                            >
                                Dîner
                            </MenuItem>

                        </TitleTextField>

                        {/*******
                         * KCAL
                         ******
                        <Kcal
                            meals={[{...meal}]}
                        />
                        */}
                        
                </Box>

                {/************* 
                 * MEAL MACROS 
                 * ********* */}
                

               
                <MacrosListMobile 
                    meals={[{...meal}]}
                />
                

                {/********************************
                 * Liste des aliments / Recettes
                **********************************/}

                <Box 
                    sx={classes.mealContent}>

                        {meal.content.map((elem, index)=> {
                            if(elem.type === "food"){
                                return(
                                    <MealFoodItem
                                        key={index}
                                        ingredientIndex={index}
                                        day={day}
                                        mealIndex={mealIndex}
                                        onSelectFood={onSelectFood}
                                    />
                                )
                            } else if (elem.type === "recipe"){
                                return(
                                    <MealRecipeItem
                                        key={index}
                                        recipeIndex={index}
                                        day={day}
                                        mealIndex={mealIndex}
                                    />

                                )
                            }
                        })}

                       
                        {meal.content.length === 0 && (
                            <Typography>
                                Aucun aliment ou recette pour ce repas
                            </Typography>
                        )}

                        {/**************
                         * Boutons
                         ***************/}

                        <Box
                            sx={classes.mealButtons}
                        >

                                <SecondaryButton 
                                    onClick={()=> onAddMealFood()} 
                                    sx={{textTransform: "none", marginRight: {xs:0, sm:1}, marginBottom: {xs: 2, sm:0}, borderRadius: "30px !important"}} 
                                    disableElevation={true}
                                    startIcon={<Add />}
                                >
                                        Ajouter un aliment
                                </SecondaryButton>

                                <SecondaryButton 
                                    onClick={()=> onAddRecipe()} 
                                    sx={{textTransform: "none", borderRadius: "30px !important"}} 
                                    disableElevation={true}
                                    startIcon={<Add />}
                                >
                                        Ajouter une recette
                                </SecondaryButton>
                        </Box>
                </Box>
            </Box>


            {/*********************
             * Suppression d'un repas
             **********************/}

            <DialogDeleteMeal
                open={openDeleteDialogFood}
                onClose={()=> setOpenDeleteDialogFood(false)}
                mealIndex={mealIndex}
                day={day}
            />


            {/*********************
             * Liste des aliments
             **********************/}

            <DialogFoodList
                open={openDialogFoodList}
                onClose={()=> setOpenFoodListDialog(false)}
                mealIndex={mealIndex}
                day={day}
            />


            {/*********************
             * Liste des recettes
             **********************/}

            <DialogRecipesList
                open={openDialogRecipe}
                onClose={()=> setOpenDialogRecipe(false)}
                mealIndex={mealIndex}
                day={day}
            />


            <DialogFood
                open={openFoodDialog}
                onClose={()=>setOpenFoodDialog(false)}
                item={foodSelected}
            />
        </>
    )
}